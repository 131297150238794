// Svg Icons
// Rare

import JpgBuilderRareBase from '@assets/jpg/chests/Builder_Rare.jpg';
import JpgBuilderRarePlus from '@assets/jpg/chests/Builder_Rare_+.jpg';
import JpgBuilderRarePlusPlus from '@assets/jpg/chests/Builder_Rare_++.jpg';
import JpgExplorerRareBase from '@assets/jpg/chests/Explorer_Rare.jpg';
import JpgExplorerRarePlus from '@assets/jpg/chests/Explorer_Rare_+.jpg';
import JpgExplorerRarePlusPlus from '@assets/jpg/chests/Explorer_Rare_++.jpg';
import JpgInnovatorRareBase from '@assets/jpg/chests/Innovator_Rare.jpg';
import JpgInnovatorRarePlus from '@assets/jpg/chests/Innovator_Rare_+.jpg';
import JpgInnovatorRarePlusPlus from '@assets/jpg/chests/Innovator_Rare_++.jpg';
import JpgMysticRareBase from '@assets/jpg/chests/Mystic_Rare.jpg';
import JpgMysticRarePlus from '@assets/jpg/chests/Mystic_Rare_+.jpg';
import JpgMysticRarePlusPlus from '@assets/jpg/chests/Mystic_Rare_++.jpg';
import JpgPhilosopherRareBase from '@assets/jpg/chests/Philosopher_Rare.jpg';
import JpgPhilosopherRarePlus from '@assets/jpg/chests/Philosopher_Rare_+.jpg';
import JpgPhilosopherRarePlusPlus from '@assets/jpg/chests/Philosopher_Rare_++.jpg';
import JpgRulerRareBase from '@assets/jpg/chests/Ruler_Rare.jpg';
import JpgRulerRarePlus from '@assets/jpg/chests/Ruler_Rare_+.jpg';
import JpgRulerRarePlusPlus from '@assets/jpg/chests/Ruler_Rare_++.jpg';
import JpgStewardRareBase from '@assets/jpg/chests/Steward_Rare.jpg';
import JpgStewardRarePlus from '@assets/jpg/chests/Steward_Rare_+.jpg';
import JpgStewardRarePlusPlus from '@assets/jpg/chests/Steward_Rare_++.jpg';

// Super Rare
import JpgBuilderSuperRareBase from '@assets/jpg/chests/Builder_SuperRare.jpg';
import JpgBuilderSuperRarePlus from '@assets/jpg/chests/Builder_SuperRare_+.jpg';
import JpgBuilderSuperRarePlusPlus from '@assets/jpg/chests/Builder_SuperRare_++.jpg';
import JpgExplorerSuperRareBase from '@assets/jpg/chests/Explorer_SuperRare.jpg';
import JpgExplorerSuperRarePlus from '@assets/jpg/chests/Explorer_SuperRare_+.jpg';
import JpgExplorerSuperRarePlusPlus from '@assets/jpg/chests/Explorer_SuperRare_++.jpg';
import JpgInnovatorSuperRareBase from '@assets/jpg/chests/Innovator_SuperRare.jpg';
import JpgInnovatorSuperRarePlus from '@assets/jpg/chests/Innovator_SuperRare_+.jpg';
import JpgInnovatorSuperRarePlusPlus from '@assets/jpg/chests/Innovator_SuperRare_++.jpg';
import JpgMysticSuperRareBase from '@assets/jpg/chests/Mystic_SuperRare.jpg';
import JpgMysticSuperRarePlus from '@assets/jpg/chests/Mystic_SuperRare_+.jpg';
import JpgMysticSuperRarePlusPlus from '@assets/jpg/chests/Mystic_SuperRare_++.jpg';
import JpgPhilosopherSuperRareBase from '@assets/jpg/chests/Philosopher_SuperRare.jpg';
import JpgPhilosopherSuperRarePlus from '@assets/jpg/chests/Philosopher_SuperRare_+.jpg';
import JpgPhilosopherSuperRarePlusPlus from '@assets/jpg/chests/Philosopher_SuperRare_++.jpg';
import JpgRulerSuperRareBase from '@assets/jpg/chests/Ruler_SuperRare.jpg';
import JpgRulerSuperRarePlus from '@assets/jpg/chests/Ruler_SuperRare_+.jpg';
import JpgRulerSuperRarePlusPlus from '@assets/jpg/chests/Ruler_SuperRare_++.jpg';
import JpgStewardSuperRareBase from '@assets/jpg/chests/Steward_SuperRare.jpg';
import JpgStewardSuperRarePlus from '@assets/jpg/chests/Steward_SuperRare_+.jpg';
import JpgStewardSuperRarePlusPlus from '@assets/jpg/chests/Steward_SuperRare_++.jpg';

// Ultra Rare
import JpgBuilderUltraRareBase from '@assets/jpg/chests/Builder_UltraRare.jpg';
import JpgBuilderUltraRarePlus from '@assets/jpg/chests/Builder_UltraRare_+.jpg';
import JpgBuilderUltraRarePlusPlus from '@assets/jpg/chests/Builder_UltraRare_++.jpg';
import JpgExplorerUltraRareBase from '@assets/jpg/chests/Explorer_UltraRare.jpg';
import JpgExplorerUltraRarePlus from '@assets/jpg/chests/Explorer_UltraRare_+.jpg';
import JpgExplorerUltraRarePlusPlus from '@assets/jpg/chests/Explorer_UltraRare_++.jpg';
import JpgInnovatorUltraRareBase from '@assets/jpg/chests/Innovator_UltraRare.jpg';
import JpgInnovatorUltraRarePlus from '@assets/jpg/chests/Innovator_UltraRare_+.jpg';
import JpgInnovatorUltraRarePlusPlus from '@assets/jpg/chests/Innovator_UltraRare_++.jpg';
import JpgMysticUltraRareBase from '@assets/jpg/chests/Mystic_UltraRare.jpg';
import JpgMysticUltraRarePlus from '@assets/jpg/chests/Mystic_UltraRare_+.jpg';
import JpgMysticUltraRarePlusPlus from '@assets/jpg/chests/Mystic_UltraRare_++.jpg';
import JpgPhilosopherUltraRareBase from '@assets/jpg/chests/Philosopher_UltraRare.jpg';
import JpgPhilosopherUltraRarePlus from '@assets/jpg/chests/Philosopher_UltraRare_+.jpg';
import JpgPhilosopherUltraRarePlusPlus from '@assets/jpg/chests/Philosopher_UltraRare_++.jpg';
import JpgRulerUltraRareBase from '@assets/jpg/chests/Ruler_UltraRare.jpg';
import JpgRulerUltraRarePlus from '@assets/jpg/chests/Ruler_UltraRare_+.jpg';
import JpgRulerUltraRarePlusPlus from '@assets/jpg/chests/Ruler_UltraRare_++.jpg';
import JpgStewardUltraRareBase from '@assets/jpg/chests/Steward_UltraRare.jpg';
import JpgStewardUltraRarePlus from '@assets/jpg/chests/Steward_UltraRare_+.jpg';
import JpgStewardUltraRarePlusPlus from '@assets/jpg/chests/Steward_UltraRare_++.jpg';

const chestsByRarity: { [key: string]: TChestReward[] } = {
  rare: [],
  super: [],
  ultra: [],
};

enum ETraitType {
  Builder = 'builder',
  Explorer = 'explorer',
  Innovator = 'innovator',
  Mystic = 'mystic',
  Philosopher = 'philosopher',
  Ruler = 'ruler',
  Steward = 'steward',
}

enum ERarity {
  Rare = 'rare',
  SuperRare = 'super',
  UltraRare = 'ultra',
}

export type TRarity = keyof typeof chestsByRarity;

export type TChestReward = {
  type: 'base' | '+' | '++';
  img: string;
  trait: ETraitType;
  rarity: TRarity;
};

export const chestCards: TChestReward[] = [
  {
    type: 'base',
    img: JpgBuilderRareBase,
    trait: ETraitType.Builder,
    rarity: ERarity.Rare,
  },
  {
    type: '+',
    img: JpgBuilderRarePlus,
    trait: ETraitType.Builder,
    rarity: ERarity.Rare,
  },
  {
    type: '++',
    img: JpgBuilderRarePlusPlus,
    trait: ETraitType.Builder,
    rarity: ERarity.Rare,
  },
  {
    type: 'base',
    img: JpgBuilderSuperRareBase,
    trait: ETraitType.Builder,
    rarity: ERarity.SuperRare,
  },
  {
    type: '+',
    img: JpgBuilderSuperRarePlus,
    trait: ETraitType.Builder,
    rarity: ERarity.SuperRare,
  },
  {
    type: '++',
    img: JpgBuilderSuperRarePlusPlus,
    trait: ETraitType.Builder,
    rarity: ERarity.SuperRare,
  },
  {
    type: 'base',
    img: JpgBuilderUltraRareBase,
    trait: ETraitType.Builder,
    rarity: ERarity.UltraRare,
  },
  {
    type: '+',
    img: JpgBuilderUltraRarePlus,
    trait: ETraitType.Builder,
    rarity: ERarity.UltraRare,
  },
  {
    type: '++',
    img: JpgBuilderUltraRarePlusPlus,
    trait: ETraitType.Builder,
    rarity: ERarity.UltraRare,
  },
  {
    type: 'base',
    img: JpgExplorerRareBase,
    trait: ETraitType.Explorer,
    rarity: ERarity.Rare,
  },
  {
    type: '+',
    img: JpgExplorerRarePlus,
    trait: ETraitType.Explorer,
    rarity: ERarity.Rare,
  },
  {
    type: '++',
    img: JpgExplorerRarePlusPlus,
    trait: ETraitType.Explorer,
    rarity: ERarity.Rare,
  },
  {
    type: 'base',
    img: JpgExplorerSuperRareBase,
    trait: ETraitType.Explorer,
    rarity: ERarity.SuperRare,
  },
  {
    type: '+',
    img: JpgExplorerSuperRarePlus,
    trait: ETraitType.Explorer,
    rarity: ERarity.SuperRare,
  },
  {
    type: '++',
    img: JpgExplorerSuperRarePlusPlus,
    trait: ETraitType.Explorer,
    rarity: ERarity.SuperRare,
  },
  {
    type: 'base',
    img: JpgExplorerUltraRareBase,
    trait: ETraitType.Explorer,
    rarity: ERarity.UltraRare,
  },
  {
    type: '+',
    img: JpgExplorerUltraRarePlus,
    trait: ETraitType.Explorer,
    rarity: ERarity.UltraRare,
  },
  {
    type: '++',
    img: JpgExplorerUltraRarePlusPlus,
    trait: ETraitType.Explorer,
    rarity: ERarity.UltraRare,
  },
  {
    type: 'base',
    img: JpgInnovatorRareBase,
    trait: ETraitType.Innovator,
    rarity: ERarity.Rare,
  },
  {
    type: '+',
    img: JpgInnovatorRarePlus,
    trait: ETraitType.Innovator,
    rarity: ERarity.Rare,
  },
  {
    type: '++',
    img: JpgInnovatorRarePlusPlus,
    trait: ETraitType.Innovator,
    rarity: ERarity.Rare,
  },
  {
    type: 'base',
    img: JpgInnovatorSuperRareBase,
    trait: ETraitType.Innovator,
    rarity: ERarity.SuperRare,
  },
  {
    type: '+',
    img: JpgInnovatorSuperRarePlus,
    trait: ETraitType.Innovator,
    rarity: ERarity.SuperRare,
  },
  {
    type: '++',
    img: JpgInnovatorSuperRarePlusPlus,
    trait: ETraitType.Innovator,
    rarity: ERarity.SuperRare,
  },
  {
    type: 'base',
    img: JpgInnovatorUltraRareBase,
    trait: ETraitType.Innovator,
    rarity: ERarity.UltraRare,
  },
  {
    type: '+',
    img: JpgInnovatorUltraRarePlus,
    trait: ETraitType.Innovator,
    rarity: ERarity.UltraRare,
  },
  {
    type: '++',
    img: JpgInnovatorUltraRarePlusPlus,
    trait: ETraitType.Innovator,
    rarity: ERarity.UltraRare,
  },
  {
    type: 'base',
    img: JpgMysticRareBase,
    trait: ETraitType.Mystic,
    rarity: ERarity.Rare,
  },
  {
    type: '+',
    img: JpgMysticRarePlus,
    trait: ETraitType.Mystic,
    rarity: ERarity.Rare,
  },
  {
    type: '++',
    img: JpgMysticRarePlusPlus,
    trait: ETraitType.Mystic,
    rarity: ERarity.Rare,
  },
  {
    type: 'base',
    img: JpgMysticSuperRareBase,
    trait: ETraitType.Mystic,
    rarity: ERarity.SuperRare,
  },
  {
    type: '+',
    img: JpgMysticSuperRarePlus,
    trait: ETraitType.Mystic,
    rarity: ERarity.SuperRare,
  },
  {
    type: '++',
    img: JpgMysticSuperRarePlusPlus,
    trait: ETraitType.Mystic,
    rarity: ERarity.SuperRare,
  },
  {
    type: 'base',
    img: JpgMysticUltraRareBase,
    trait: ETraitType.Mystic,
    rarity: ERarity.UltraRare,
  },
  {
    type: '+',
    img: JpgMysticUltraRarePlus,
    trait: ETraitType.Mystic,
    rarity: ERarity.UltraRare,
  },
  {
    type: '++',
    img: JpgMysticUltraRarePlusPlus,
    trait: ETraitType.Mystic,
    rarity: ERarity.UltraRare,
  },
  {
    type: 'base',
    img: JpgPhilosopherRareBase,
    trait: ETraitType.Philosopher,
    rarity: ERarity.Rare,
  },
  {
    type: '+',
    img: JpgPhilosopherRarePlus,
    trait: ETraitType.Philosopher,
    rarity: ERarity.Rare,
  },
  {
    type: '++',
    img: JpgPhilosopherRarePlusPlus,
    trait: ETraitType.Philosopher,
    rarity: ERarity.Rare,
  },
  {
    type: 'base',
    img: JpgPhilosopherSuperRareBase,
    trait: ETraitType.Philosopher,
    rarity: ERarity.SuperRare,
  },
  {
    type: '+',
    img: JpgPhilosopherSuperRarePlus,
    trait: ETraitType.Philosopher,
    rarity: ERarity.SuperRare,
  },
  {
    type: '++',
    img: JpgPhilosopherSuperRarePlusPlus,
    trait: ETraitType.Philosopher,
    rarity: ERarity.SuperRare,
  },
  {
    type: 'base',
    img: JpgPhilosopherUltraRareBase,
    trait: ETraitType.Philosopher,
    rarity: ERarity.UltraRare,
  },
  {
    type: '+',
    img: JpgPhilosopherUltraRarePlus,
    trait: ETraitType.Philosopher,
    rarity: ERarity.UltraRare,
  },
  {
    type: '++',
    img: JpgPhilosopherUltraRarePlusPlus,
    trait: ETraitType.Philosopher,
    rarity: ERarity.UltraRare,
  },
  {
    type: 'base',
    img: JpgRulerRareBase,
    trait: ETraitType.Ruler,
    rarity: ERarity.Rare,
  },
  {
    type: '+',
    img: JpgRulerRarePlus,
    trait: ETraitType.Ruler,
    rarity: ERarity.Rare,
  },
  {
    type: '++',
    img: JpgRulerRarePlusPlus,
    trait: ETraitType.Ruler,
    rarity: ERarity.Rare,
  },
  {
    type: 'base',
    img: JpgRulerSuperRareBase,
    trait: ETraitType.Ruler,
    rarity: ERarity.SuperRare,
  },
  {
    type: '+',
    img: JpgRulerSuperRarePlus,
    trait: ETraitType.Ruler,
    rarity: ERarity.SuperRare,
  },
  {
    type: '++',
    img: JpgRulerSuperRarePlusPlus,
    trait: ETraitType.Ruler,
    rarity: ERarity.SuperRare,
  },
  {
    type: 'base',
    img: JpgRulerUltraRareBase,
    trait: ETraitType.Ruler,
    rarity: ERarity.UltraRare,
  },
  {
    type: '+',
    img: JpgRulerUltraRarePlus,
    trait: ETraitType.Ruler,
    rarity: ERarity.UltraRare,
  },
  {
    type: '++',
    img: JpgRulerUltraRarePlusPlus,
    trait: ETraitType.Ruler,
    rarity: ERarity.UltraRare,
  },
  {
    type: 'base',
    img: JpgStewardRareBase,
    trait: ETraitType.Steward,
    rarity: ERarity.Rare,
  },
  {
    type: '+',
    img: JpgStewardRarePlus,
    trait: ETraitType.Steward,
    rarity: ERarity.Rare,
  },
  {
    type: '++',
    img: JpgStewardRarePlusPlus,
    trait: ETraitType.Steward,
    rarity: ERarity.Rare,
  },
  {
    type: 'base',
    img: JpgStewardSuperRareBase,
    trait: ETraitType.Steward,
    rarity: ERarity.SuperRare,
  },
  {
    type: '+',
    img: JpgStewardSuperRarePlus,
    trait: ETraitType.Steward,
    rarity: ERarity.SuperRare,
  },
  {
    type: '++',
    img: JpgStewardSuperRarePlusPlus,
    trait: ETraitType.Steward,
    rarity: ERarity.SuperRare,
  },
  {
    type: 'base',
    img: JpgStewardUltraRareBase,
    trait: ETraitType.Steward,
    rarity: ERarity.UltraRare,
  },
  {
    type: '+',
    img: JpgStewardUltraRarePlus,
    trait: ETraitType.Steward,
    rarity: ERarity.UltraRare,
  },
  {
    type: '++',
    img: JpgStewardUltraRarePlusPlus,
    trait: ETraitType.Steward,
    rarity: ERarity.UltraRare,
  },
];

for (let i = 0; i < chestCards.length; i += 1) {
  chestsByRarity[chestCards[i].rarity].push(chestCards[i]);
}

export default chestsByRarity;
