import styled from 'styled-components';

const TwitterLeaderboardPageSC = styled.div`
  background: ${({ theme }) => theme.background.inverted.bg1};
  padding: 24px 0px;

  .leaderboard-wrapper {
    max-width: 800px;
    margin: auto;

    @media only screen and (max-width: 776px) {
      margin-left: 24px;
      margin-right: 24px;
    }

    button {
      max-width: 200px;
    }
  }

  table {
    width: 100%;
    margin: auto;
    border-radius: 18px;
    border: 1.5px solid ${({ theme }) => theme.input.border} !important;
    overflow: hidden;
    table-layout: fixed;
    margin-top: 24px;
    text-align: center;

    a {
      text-decoration: none;
      :hover {
        text-decoration: underline;
      }
    }

    tr {
      width: 100%;

      :not(:last-child) {
        border-bottom: 1.5px solid ${({ theme }) => theme.input.border};
      }

      &.header {
        background: ${({ theme }) => theme.neutral.neutral7};
        color: white;
        width: 100%;

        th:first-child {
          width: 20%;
        }

        th:nth-child(2) {
          text-align: left;
        }
      }

      :not(.header) {
        background: ${({ theme }) => theme.background.inverted.bg2};

        :hover {
          background: ${({ theme }) => theme.input.background} !important;
        }
      }

      td:nth-child(2) {
        text-align: left;

        a {
          color: ${({ theme }) => theme.button.secondary.text};

          img {
            margin-right: 0.5rem;
          }
        }
      }
    }

    th {
      padding: 12px 24px;
    }

    td {
      padding: 12px 24px;
      color: ${({ theme }) => theme.text.main};

      img {
        border-radius: 999px;
        height: 35px;
        margin-right: 0.5rem;
        margin: auto;
      }
    }
  }
`;

export default TwitterLeaderboardPageSC;
