import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { getRQKUserRewardsKey } from '@utils/rq-utils';
import api from '@utils/api';
import { collections } from '@components/Modal/CheckoutProcess/components/PrizeCarousel/collections';
import userInterface from '@store/user-interface';

const imageArray = collections.map((item) => item.image);

/**
 * Primitive button opening modal with ultimate prizes
 * @returns
 */
const UltimatePrizeBalance = () => {
  const dispatch = useDispatch();
  const [currentImage, setCurrentImage] = useState(0);

  const { isLoading, data } = useQuery(
    getRQKUserRewardsKey('getUltimateRewards'),
    async () => {
      const result = (await api.getUltimateRewards()).data.data;
      console.log('Result', result);
      // const claimed = result.claimed;
      // const unclaimed = result.unclaimed;

      return result;
    },
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((current) => (current + 1) % imageArray.length);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // If there are unclaimed prizes, show the button
  if (!isLoading && data.ultimatePrizes.unclaimed.length > 0) {
    return (
      <StyledUltimatePrizeBalance
        onClick={() => {
          dispatch(
            userInterface.setModal({
              type: 'ultimatePrizesModal',
              token: null,
            }),
          );
        }}
      >
        <div className="overlay">Super prizes</div>
        <div
          className="picture-holder"
          style={{ backgroundImage: `url(${imageArray[currentImage]})` }}
        >
          Super <br />
          prizes
        </div>
      </StyledUltimatePrizeBalance>
    );
  }

  return null;
};

const StyledUltimatePrizeBalance = styled.button`
  position: relative;
  background: transparent;
  margin-left: 16px;
  border: 1.5px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  width: 90px; /* Cover the entire button */
  height: 60px; /* Cover the entire button */
  padding: 0;

  div.picture-holder {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
  }

  div.overlay {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    text-align: center;
    padding: 12px 0;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 700;
    position: absolute; /* Position absolute to overlay */
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);

    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
`;

export default UltimatePrizeBalance;
