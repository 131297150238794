import { StyledTrayFrame } from '@pages/Home/components/PositionCard/StyledTrayFrame.styled';
import styled from 'styled-components';

export const V2ListingModalSC = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h6,
  h5 {
    color: ${({ theme }) => theme.text.main};
    margin: 0px;
  }
  .skeleton,
  .listing-item {
    display: grid;
    grid-template-columns: 100px 1fr;

    .skeleton-data {
      display: flex;
      gap: 6px;
      flex-direction: column;
    }
  }

  .position-data {
    margin-left: 16px;
    & > *:not(:first-child) {
      color: ${({ theme }) => theme.text.secondary};
      font-weight: 400;
    }
  }

  .price-data {
    text-align: right;
    p {
      color: ${({ theme }) => theme.text.secondary};
      margin-bottom: 0.5rem;
    }
  }

  svg {
    color: ${({ theme }) => theme.text.secondary};
    fill: ${({ theme }) => theme.text.secondary};
    height: 0.65rem;
    svg {
      transition: 0.1s ease-in-out;
    }
  }

  .form {
    border-top: 1.5px solid ${({ theme }) => theme.input.border};
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .advanced-grid {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'duration queue'
        'minimum-price buy-now-price';
      gap: 1.5rem;
      transition: 0.3s ease-in-out;
      .get-boosted {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background: ${({ theme }) => theme.input.background};
        border: 1px solid ${({ theme }) => theme.input.border};
        padding: 0.5rem;
        border-radius: 0.5rem;
        color: ${({ theme }) => theme.text.main};
      }
    }
    input {
      width: 100%;
      background: ${({ theme }) => theme.input.background};
      border: 1px solid ${({ theme }) => theme.input.border};
      padding: 0.5rem;
      border-radius: 0.5rem;
      color: ${({ theme }) => theme.text.main};
    }

    .gray {
      color: ${({ theme }) => theme.text.secondary};
      font-weight: 500;
    }

    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      color: white;
    }

    p {
      font-weight: 500;
      color: ${({ theme }) => theme.text.main};
      &.red {
        color: ${({ theme }) => theme.red};
      }
    }
  }

  .listing-price {
    grid-area: listing-price;
    width: 100%;
  }

  .duration {
    grid-area: duration;
    position: relative;

    .duration-btn {
      background: ${({ theme }) => theme.input.background};
      border: 1px solid ${({ theme }) => theme.input.border};
      padding: 0.5rem;
      border-radius: 0.5rem;
      color: ${({ theme }) => theme.text.main};
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:disabled {
        color: ${({ theme }) => theme.red};
        cursor: not-allowed;
      }

      svg {
        width: 0.75rem;
        text-align: right;
        right: 0px;
      }
    }

    .duration-dropdown {
      background: ${({ theme }) => theme.input.background};
      border: 1px solid ${({ theme }) => theme.input.border};
      padding: 0.5rem;
      border-radius: 0.5rem;
      color: ${({ theme }) => theme.text.main};
      position: absolute;
      width: 100%;
      margin-top: 0.5rem;
    }
  }

  .queue {
    grid-area: queue;
  }

  .minimum-price {
    grid-area: minimum-price;
  }

  .buy-now-price {
    grid-area: buy-now-price;
  }

  .confirm {
    border-top: 1.5px solid ${({ theme }) => theme.input.border};
    padding-top: 1rem;
  }

  .advanced-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0px;
    }
  }

  .unavailable {
    cursor: not-allowed;
    &::placeholder {
      color: ${({ theme }) => theme.red};
    }
  }

  @media (max-width: 768px) {
    gap: 12px;

    .listing-item {
      grid-template-columns: 80px 1fr;
    }

    .position-data {
      h6 {
        font-size: 0.85rem;
      }
    }
    .form,
    .confirm {
      gap: 12px;

      h3 {
        font-size: 1rem;
      }
      p {
        font-size: 0.85rem;
        margin-bottom: 12px;
      }

      .advanced-grid {
        gap: 12px;
      }
    }
  }
`;

export const ListingCardSC = styled(StyledTrayFrame)`
  border-width: 1.5px;
  padding: 0.5rem;

  &:hover {
    transform: translateY(-0px);
  }

  video {
    min-height: 0px;
  }
`;
