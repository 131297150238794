import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClientProvider } from 'react-query';
import ReactModal from 'react-modal';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import '@utils/meta-mask';
import App from './App';
import store from '@store/index';
import { queryClient } from '@utils/rq-utils';

ReactModal.setAppElement('#root');

Sentry.init({
  dsn: 'https://5050865281c94f4aac9bd5487c043956@o151750.ingest.sentry.io/6494819',
  integrations: [new BrowserTracing()],
  enabled: process.env.REACT_APP_ENVIRONMENT === 'production',
  environment: process.env.REACT_APP_ENVIRONMENT,
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
