import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LayoutWrapperSC from '@components/Layout/layout.styled';
import Home from '@pages/Home';
import SignIn from '@pages/SignIn';
import PurchaseOrder from '@pages/PurchaseOrder';
import OrdersPage from './OrdersPage';
import OpenBoxPage from './OpenBoxPage';
import ArtifactsPage from './ArtifactsPage';
import AuctionResults from './AuctionResults';
import AuctionSharing from './AuctionResults/AuctionSharingPage';
import TwitterCallback from './TwitterCallback/TwitterCallback';
import TwitterLeaderboardPage from './TwitterLeaderboardPage';
import OgemsDashboard from './OgemsDashboard';
import AmazonCallback from './AmazonCallback';
import ChestsPage from './ChestsPage';
import V2AuctionsPage from './V2AuctionsPage';

const Pages: React.FunctionComponent = () => {
  return (
    <LayoutWrapperSC>
      <div style={{ width: '100%' }}>
        <Routes>
          <Route path="/auth" element={<SignIn />} />
          <Route path="/purchase-orders" element={<OrdersPage />} />
          <Route path="/auth/twitter/callback" element={<TwitterCallback />} />
          <Route path="/auth/amazon/callback" element={<AmazonCallback />} />
          <Route path="/artifacts" element={<ArtifactsPage />} />
          <Route path="/chests" element={<ChestsPage />} />
          <Route path="/auctions/boosted" element={<V2AuctionsPage />} />
          <Route path="/auctions" element={<AuctionResults />} />
          <Route path="/auctions/:id" element={<AuctionSharing />} />
          <Route path="/purchase-orders/:id" element={<PurchaseOrder />} />
          <Route path="/ofriends/" element={<OpenBoxPage />} />
          <Route
            path="/twitter-leaderboard/"
            element={<TwitterLeaderboardPage />}
          />
          <Route path="/ogems" element={<OgemsDashboard />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </LayoutWrapperSC>
  );
};

const MemoizedPages = React.memo(Pages);

export default MemoizedPages;
