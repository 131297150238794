import React, { useEffect, useState } from 'react';
import { V2AuctionBarSC } from './V2AuctionBar.styled';
import { TV2AuctionBar } from './V2AuctionBar.types';
import { useNavigate } from 'react-router-dom';
import V2BannerCard from './V2BannerCard';
import { getColorByRarity } from '@utils/common';
import { getRQKAuctionsKey } from '@utils/rq-utils';
import { useQuery } from 'react-query';
import api from '@utils/api';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';

type TRarity = 'rare' | 'super' | 'ultra';

const V2AuctionBar: React.FunctionComponent<TV2AuctionBar> = () => {
  const navigate = useNavigate();

  const openGetBoostedModal = () => {
    navigate('/auctions/boosted');
  };

  const { isLoading, data: auctionData } = useQuery(
    getRQKAuctionsKey('getAuctions'),
    async () => {
      const result = (await api.getAuctions()).data.data;
      return result;
    },
  );

  const [currentRarity, setCurrentRarity] = useState<TRarity>('rare');

  useEffect(() => {
    const rarities: TRarity[] = ['rare', 'super', 'ultra'];
    let currentRarityIndex = 0;
    const interval = setInterval(() => {
      currentRarityIndex = (currentRarityIndex + 1) % rarities.length;
      setCurrentRarity(rarities[currentRarityIndex]);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const renderBannerCard = () => {
    if (!auctionData || !auctionData[currentRarity]) {
      return null;
    }

    const auctions = auctionData[currentRarity].filter(
      (auction) => auction.status === 'running',
    );
    const floor = Math.min(
      ...auctions.map((auction) =>
        auction.winningBid ? auction.winningBid.amount : auction.startingPrice,
      ),
    );
    return (
      <V2BannerCard
        rarity={currentRarity}
        floor={floor}
        color={getColorByRarity(currentRarity)}
      />
    );
  };

  return (
    <>
      <V2AuctionBarSC onClick={openGetBoostedModal}>
        {isLoading ? <SvgSpinner style={{ height: 35 }} /> : renderBannerCard()}
      </V2AuctionBarSC>
    </>
  );
};

export default V2AuctionBar;
