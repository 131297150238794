import React from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import ToBeRevealedPosition from '@pages/Home/components/PositionCard/ToBeRevealedPosition';
import BasicPosition from '@pages/Home/components/PositionCard/PositionCard';
import LoadingStatePosition from '@pages/Home/components/PositionCard/LoadingStatePosition';
import api from '@utils/api';
import { getEnergyLevel, TArtifact } from '../../../../data/gifts';
import { getRQKPositionsKey } from '@utils/rq-utils';
import { TBoosterRarity } from '@store/user-interface/types';

type TRevealedLandProps = {
  id: string;
  userHasTreats: boolean;
  toggleGlobalInventoryOn: boolean;
  activeBooster: {
    rarity: TBoosterRarity;
    boosterId: string;
  };
};

export const returnDominantBooster = (boosters) => {
  if (boosters.ultra > 0) {
    return 'ultra';
  } else if (boosters.super > 0) {
    return 'super';
  } else if (boosters.rare > 0) {
    return 'rare';
  } else return 'common';
};

const GenericPosition: React.FunctionComponent<TRevealedLandProps> = ({
  id,
  userHasTreats,
  toggleGlobalInventoryOn,
  activeBooster,
}: TRevealedLandProps) => {
  const { isLoading, data } = useQuery(
    getRQKPositionsKey('getPosition', id),
    async () => {
      const result = (await api.getPositionDetail(id)).data;

      return result.data;
    },
  );

  if (isLoading) {
    return <LoadingStatePosition />;
  }

  const {
    lat,
    lng,
    type,
    position,
    positionUpgrades,
    artifacts,
    mintable,
    rarity,
  } = data;

  const energyLevel = getEnergyLevel(artifacts);

  // resolve upgrading state
  const insInUpgradeProcess = positionUpgrades
    ? positionUpgrades.filter((up) => {
        return (
          !up.revealed && dayjs().diff(dayjs(up.revealableAt), 'second') < 0
        );
      }).length > 0
    : false;

  const readyForUpgradeReveal = positionUpgrades
    ? positionUpgrades.filter(
        (up) =>
          !up.revealed && dayjs().diff(dayjs(up.revealableAt), 'second') >= 0,
      ).length > 0
    : false;

  const currentUpgradeProcessTreatsAmount = positionUpgrades.find(
    (pu) => pu.revealed === 0,
  );

  const hasDogHouse = artifacts
    ? artifacts.map((e: TArtifact) => e.name).includes('dog')
    : false;

  if (readyForUpgradeReveal) {
    return <ToBeRevealedPosition id={id} />;
  }

  return (
    <BasicPosition
      isActive={lat && lng}
      positionId={id}
      positionNumber={position}
      currentUpgradeProcessTreatsAmount={
        currentUpgradeProcessTreatsAmount
          ? currentUpgradeProcessTreatsAmount.treatsPlaced
          : 0
      }
      positionType={type}
      cardType={hasDogHouse ? 'golden' : 'basic'}
      activeLat={lat}
      activeLng={lng}
      mintAvailable={mintable}
      upgrading={insInUpgradeProcess}
      energy={energyLevel}
      positionUpgrades={positionUpgrades}
      artifacts={artifacts}
      positionDataIsLoading={isLoading}
      userHasTreats={userHasTreats}
      toggleGlobalInventoryOn={toggleGlobalInventoryOn}
      rarity={rarity}
      activeBooster={activeBooster}
      dominantBooster={data.boosters && returnDominantBooster(data.boosters)}
    />
  );
};

const memoizedGenericPosition = React.memo(GenericPosition);

export default memoizedGenericPosition;
