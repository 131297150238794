import React, { useState, useCallback } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TRootState } from '@store/index';
import userInterface from '@store/user-interface';
import ModalSC from './Modal.styled';
import { InputSC } from '@components/Input.styled';
import { ButtonPrimarySC } from '@components/Button.styled';
import api, { defaultSearchPositionParams } from '@utils/api';
import { setAccessToken, setRefreshToken } from '@utils/storage';
import { TJwtToken } from '@custom-types/common';
import jwtDecode from 'jwt-decode';
import authSlice from '@store/auth';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import Toast from '@components/Toast';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import SvgSpinner from '@assets/svg/spinner.svg';
import { defaultModalProps } from './modal-settings';
import { getRQKPositionsKey, getRQKProfileKey } from '@utils/rq-utils';

const InitializationModal: React.FunctionComponent = () => {
  const { token } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { token: string };

  const queryClient = useQueryClient();

  const { signIn } = authSlice;
  const dispatch = useDispatch();
  const { setModal, setFreeOCashAmountClaimed } = userInterface;

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const navigate = useNavigate();

  const initialize = async () => {
    setIsLoading(true);
    try {
      const result = await api.initialize({
        verificationToken: token,
        password,
      });
      const { accessToken, refreshToken } = result.data.data;

      setAccessToken(accessToken);
      setRefreshToken(refreshToken);

      // Prefetch the data
      await queryClient.prefetchQuery(
        getRQKPositionsKey('getPositions'),
        async () =>
          (
            await api.getPositions(defaultSearchPositionParams)
          ).data.data,
      );

      const profileResult = (await api.getProfile()).data.data;
      queryClient.setQueryData(getRQKProfileKey('getProfile'), profileResult);

      const decodedHeader: TJwtToken = jwtDecode(String(accessToken));
      dispatch(
        signIn({
          id: decodedHeader.sub,
          daoRanking: profileResult.daoRanking,
          affiliateId: profileResult.affiliateId,
        }),
      );

      dispatch(setModal({ type: null, token: null }));

      setPassword('');
      setPasswordConfirmation('');

      // Ask for daily oCash right after user signs up
      const claimResult = (await api.claimDailyOCash()).data;
      dispatch(
        setFreeOCashAmountClaimed({
          amountClaimed: claimResult.data.amountClaimed,
        }),
      );

      navigate('/');
    } catch (e) {
      toast(
        <Toast
          type="error"
          header="Error"
          body="Something went wrong. Please contact support if the problem persists"
        />,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = useCallback(() => {
    dispatch(setModal({ type: null, token: null }));
  }, []);

  return (
    <ReactModal isOpen {...defaultModalProps} onRequestClose={handleCloseModal}>
      <ModalSC>
        <div className="header">
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            Create Password
          </Text>
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <div className="body">
          <form autoComplete="on">
            <span className="small">
              Must be at least 8-characters long and has at least one upper
              character and at least one number
            </span>
            <InputSC
              value={password}
              required
              type="password"
              className="modal-input"
              pattern="^(?=.*[A-Z])(?=.*\d)(.){8,}$"
              title="Must have at least 8 characters, one upper and one number"
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            <label htmlFor="password">Confirm password:</label>
            <InputSC
              value={passwordConfirmation}
              required
              type="password"
              className="modal-input"
              pattern="^(?=.*[A-Z])(?=.*\d)(.){8,}$"
              title="Must have at least 8 characters, one upper and one number"
              onChange={(e) => setPasswordConfirmation(e.currentTarget.value)}
            />

            <ButtonPrimarySC
              type="button"
              disabled={isLoading}
              onClick={initialize}
            >
              {isLoading ? (
                <img width={32} height={32} src={SvgSpinner} alt="spinning" />
              ) : (
                'Submit'
              )}
            </ButtonPrimarySC>
          </form>
        </div>
      </ModalSC>
    </ReactModal>
  );
};

export default InitializationModal;
