const MAGIC_BYTES = [0x00, 0x4f];
const SUPPORTED_CODEC_VERSIONS = [0x01];

function isArrayBuffer(value) {
  return value instanceof ArrayBuffer;
}

/**
 * TODO: Needs rewrite
 * @param msg
 */
export function parseWebSocketMessage(msg) {
  const decoder = new TextDecoder();

  if (!isArrayBuffer(msg)) {
    return false;
  }

  const bytes = new Uint8Array(msg);
  const magic = bytes.slice(0, 3);
  if (magic[0] !== MAGIC_BYTES[0] || magic[1] !== MAGIC_BYTES[1]) {
    // magic bytes where not correct
    console.error('Not Correct Magic Bytes');
    return false;
  }

  if (!SUPPORTED_CODEC_VERSIONS.includes(magic[2])) {
    // unsupported codec version
    console.error('Unsupported codec version');
    return false;
  }
  const decoded = decoder.decode(bytes.slice(4));

  const parsed = JSON.parse(decoded);

  if (!(parsed && typeof parsed === 'object' && !Array.isArray(parsed))) {
    return false;
  }

  try {
    return parsed;
  } catch (e) {
    // Sentry ??
    console.error('Unable to parse RMQS message');
  }
}

export function getWsAddress() {
  const addresses = ['wss://feed.overline.network/websocket'];
  return addresses[Math.floor(Math.random() * addresses.length)];
}

export function createTsHandshakeMessage() {
  const a = MAGIC_BYTES;
  a.push(0x01); // codec version 1
  a.push(0x01); // message type = TS handshake from client
  const s = '' + ((Date.now() / 1000) << 0);
  const textEncoder = new TextEncoder();
  a.push(...Array.from(textEncoder.encode(s)));
  return new Uint8Array(a);
}
