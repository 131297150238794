import { TNullableNumber, TNullableString } from '@custom-types/common';

export const AUTH_STATE_INIT = 'init';
export const AUTH_STATE_SUCCESS = 'success';
export const AUTH_STATE_FAIL = 'fail';

export type TState =
  | typeof AUTH_STATE_INIT
  | typeof AUTH_STATE_SUCCESS
  | typeof AUTH_STATE_FAIL;

export type TUser = {
  id: string;
  daoRanking: TNullableNumber;
  affiliateId: TNullableString;
};

export type TAuthState = {
  state: TState;
  user: TUser | null;
};

export type TSignIn = {
  id: string;
  daoRanking: TNullableNumber;
  affiliateId: string;
};
