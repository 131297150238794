import React, { useEffect, memo } from 'react';
import numeral from 'numeral';
import { useTheme } from 'styled-components';
import { useQueryClient } from 'react-query';
import { getRQKAuctionsKey } from '@utils/rq-utils';

type TAuctionTimerProps = {
  initialSeconds: number;
};

const AuctionTimer: React.FC<TAuctionTimerProps> = ({ initialSeconds }) => {
  const theme = useTheme();

  const [seconds, setSeconds] = React.useState(initialSeconds);

  useEffect(() => {
    setSeconds(initialSeconds);
  }, [initialSeconds]);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (seconds === 0) {
      queryClient.invalidateQueries(getRQKAuctionsKey('getAuctions'));
    }
  }, [seconds, queryClient]);

  useEffect(() => {
    const timer = setInterval(() => setSeconds((counter) => counter - 1), 1000);
    return () => clearInterval(timer);
  }, [seconds]);

  return (
    <span style={{ color: seconds <= 4 ? 'red' : theme.neutral.neutral6 }}>
      {numeral(Math.floor(seconds / 60)).format('00')}:
      {numeral(seconds % 60).format('00')}
    </span>
  );
};

const MemoizedAuctionTimer = memo(AuctionTimer);

export default MemoizedAuctionTimer;
