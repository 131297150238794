import React from 'react';
import Text, { TextVariant } from '@components/Text';
import { ArtifactCardSC } from '../ArtifactsPage.styled';
import { colorThemes } from '@assets/styles';
import { useDispatch } from 'react-redux';
import userInterface from '@store/user-interface';

export type TArtifactCardProps = {
  type: string;
  color: string;
  img: string;
  artifactQty: number;
  availableForSell: any;
};

/**
 * @constructor
 */

const ArtifactCard: React.FunctionComponent<TArtifactCardProps> = ({
  type,
  color,
  img,
  artifactQty,
  availableForSell,
}) => {
  const { setArtifactSelection } = userInterface;
  const isPresent = artifactQty > 0;

  const dispatch = useDispatch();

  return (
    <ArtifactCardSC
      disabled={!availableForSell}
      onClick={() => {
        if (process.env.REACT_APP_AUCTIONS_ENABLED !== 'true') {
          return null;
        }

        dispatch(
          setArtifactSelection({
            artifactSelection: {
              id: availableForSell?.id,
              name: availableForSell?.artifactId,
            },
          }),
        );
      }}
    >
      <img
        src={img}
        alt={`${type}-card`}
        style={{ filter: isPresent ? `saturate(100%)` : `saturate(0%)` }}
      />
      <div
        className="qty-badge"
        style={{
          backgroundColor: isPresent
            ? color
            : colorThemes.light.neutral.neutral4,
        }}
      >
        <Text
          variant={TextVariant.CardText}
          style={{
            color: isPresent ? 'white' : colorThemes.light.neutral.neutral7,
          }}
        >
          {artifactQty}
        </Text>
      </div>
    </ArtifactCardSC>
  );
};

export default ArtifactCard;
