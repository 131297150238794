import React, { useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

export default function ConfettiParticles() {
  const initParticles = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <Particles
      init={initParticles}
      style={{ position: 'absolute' }}
      options={{
        fpsLimit: 45,
        duration: 60000,
        fullScreen: {
          enable: true,
          zIndex: 99900,
        },
        interactivity: {
          detectsOn: 'window',
        },
        emitters: {
          position: {
            x: 50,
            y: 100,
          },
          rate: {
            quantity: 5,
            delay: 0.2,
          },
        },
        particles: {
          move: {
            decay: 0.05,
            direction: 'top',
            enable: true,
            gravity: {
              enable: true,
              maxSpeed: 150,
            },
            outModes: {
              top: 'none',
              default: 'destroy',
            },
            speed: { min: 25, max: 50 },
          },
          number: {
            value: 0,
          },
          opacity: {
            value: 1,
          },
          rotate: {
            value: {
              min: 0,
              max: 360,
            },
            direction: 'random',
            animation: {
              enable: true,
              speed: 30,
            },
          },
          tilt: {
            direction: 'random',
            enable: true,
            value: {
              min: 0,
              max: 360,
            },
            animation: {
              enable: true,
              speed: 30,
            },
          },
          size: {
            value: 8,
          },
          roll: {
            darken: {
              enable: true,
              value: 25,
            },
            enable: true,
            speed: {
              min: 5,
              max: 15,
            },
          },
          wobble: {
            distance: 30,
            enable: true,
            speed: {
              min: -7,
              max: 7,
            },
          },
          shape: {
            type: [
              'image',
              'image',
              'image',
              'image',
              'image',
              'image',
              'image',
              'image',
              'image',
            ],
            options: {
              image: [
                {
                  src: 'http://bitcoinsymbol.org/bitcoinsymbol-pack/3D/gold-coin-side.png',
                  width: 32,
                  height: 32,
                  particles: {
                    size: {
                      value: 16,
                    },
                  },
                },
              ],
            },
          },
        },
      }}
    />
  );
}
