import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useQuery, useQueryClient } from 'react-query';
import Text, { TextVariant } from '@components/Text';
import AuctionTimer from '@components/Auction/AuctionTimer';
import userInterfaceStore from '@store/user-interface';
import {
  getRQKAuctionsKey,
  getRQKOCashKey,
  getRQKPositionsKey,
} from '@utils/rq-utils';
import api from '@utils/api';
import { AuctionSC } from './Auction.styled';
import AuctionHeader from './components/AuctionHeader';
import AuctionCard from './components/AuctionCard';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { LeftArrow, RightArrow } from './components/Arrows';
import { findEnergyLevel } from '../../data/gifts';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';

import { TRootState } from '@store/index';

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
/**
 * @constructor
 */

const Arrows = () => (
  <div style={{ marginLeft: '10px', display: 'flex' }}>
    <LeftArrow /> <RightArrow />
  </div>
);

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
  const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isTouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}
const resolveNextUpdateSeconds = (item: any) => {
  // find next whole minute

  if (item.status === 'pending') {
    return 60 - dayjs().second();
  }

  // set submitted + 5
  if (item.status === 'running') {
    const ends = dayjs(item.submitted).add(
      parseInt(process.env.REACT_APP_AUCTIONS_LAST_FOR as string, 10),
      'minutes',
    );
    const now = dayjs();
    return ends.diff(now, 'seconds');
  }

  return 300;
};

const useGetAuctionsQuery = () =>
  useQuery(
    getRQKAuctionsKey('getAuctions'),
    async () => {
      const result = (await api.getAuctions()).data.data;
      return result;
    },
    // {
    //   enabled: true,
    //   refetchInterval: (data) => {
    //     if (!data || data.length <= 0) {
    //       return 30 * 1000;
    //     }
    //     return false;
    //   },
    // },
  );

// type TAuctionProps = {
//   lastMessage: TNullableString;
// };

const Auction = () => {
  console.debug('Rendering Auctions');
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  if (process.env.REACT_APP_AUCTIONS_ENABLED !== 'true') {
    return null;
  }

  const oCashPrice = queryClient.getQueryData(
    getRQKOCashKey('getOCashPrice'),
  ) as number;

  const userId = useSelector<TRootState>(
    (store: TRootState) => store.auth.user?.id,
  ) as string;

  const { lastMessage } = useWebSocket(
    process.env.REACT_APP_WS_URI as string,
    {
      reconnectAttempts: 10,
      reconnectInterval: 3000,
      share: true,
    },
    true,
  );

  useEffect(() => {
    (async () => {
      if (lastMessage !== null && lastMessage.data) {
        //onMessage(lastMessage.data);
        const firstLetter = Array.from(lastMessage.data)[0];
        // process new bid
        if (firstLetter === 'b') {
          const [auctionId, userId, bidId, bidAmount, bidCreatedAt] =
            lastMessage.data.split(':');

          const auctions = queryClient.getQueryData(
            getRQKAuctionsKey('getAuctions'),
          ) as any[];

          const auction = auctions.find((a: any) => a.id === auctionId);

          auction.winningBid = {
            id: bidId,
            amount: bidAmount,
            createdAt: new Date(parseInt(bidCreatedAt)).toISOString(),
            user: userId,
          };

          queryClient.setQueryData(getRQKAuctionsKey('getAuctions'), auctions);
        }

        // process winning bid
        if (firstLetter === 'w') {
          const [auctionId, position, winningUserId] =
            lastMessage.data.split(':');

          if (userId === winningUserId) {
            dispatch(
              userInterfaceStore.setWinningModal({
                winningModalContent: {
                  auctionId,
                },
              }),
            );
          }

          await queryClient.invalidateQueries(getRQKAuctionsKey('getAuctions'));
          await queryClient.invalidateQueries(
            getRQKAuctionsKey('getBlockedItems'),
          );

          await queryClient.invalidateQueries(
            getRQKPositionsKey('getPosition', position),
          );
          // TODO: !!! get position ID!!!
        }
        // refetch auctions list on cycle move
        if (firstLetter === 'n') {
          await queryClient.invalidateQueries(getRQKAuctionsKey('getAuctions'));
          queryClient.invalidateQueries(getRQKAuctionsKey('getAuctions'));
          await queryClient.invalidateQueries(getRQKAuctionsKey('getAuctions'));
          queryClient.invalidateQueries(getRQKAuctionsKey('getBlockedItems'));
        }
      }
    })();
  }, [lastMessage]);

  const { isLoading: isAuctionsLoading, data: auctionsData } =
    useGetAuctionsQuery();

  // useEffect(() => {

  // }, [lastMessage]);

  // TODO: Use Prefetch Blocked Items
  // TODO: Resolve this, invalidate on multiple occasions
  useQuery(getRQKAuctionsKey('getBlockedItems'), async () => {
    return (await api.getBlockedItems()).data.data;
  });

  // Page for specific auction

  //  Analytics page

  // Initially just for user (past auctions)

  if (isAuctionsLoading || !auctionsData) return null;

  return (
    <AuctionSC>
      <AuctionHeader title="Live Auction" />
      {auctionsData.general.length === 0 ? (
        <Text>No Auctions</Text>
      ) : (
        <>
          <Text
            className="gray plot-label"
            variant={TextVariant.CardText}
            style={{
              fontWeight: 600,
              textAlign: 'left',
              marginBottom: 12,
              marginLeft: '0.25rem',
            }}
          >
            {auctionsData.general[0].status === 'pending'
              ? 'Next auction in: '
              : 'Current auction ends in: '}
            <AuctionTimer
              initialSeconds={resolveNextUpdateSeconds(auctionsData.general[0])}
            />
          </Text>
          <div className="auction-bar">
            <ScrollMenu Footer={Arrows} onWheel={onWheel}>
              {auctionsData.general.map((item, idx) => {
                return (
                  <AuctionCard
                    key={idx}
                    id={item.id}
                    title={
                      item.position
                        ? `ōLand #${item.position.position.toLocaleString(
                            'en-US',
                          )}`
                        : `${item.artifact.artifactId}`
                    }
                    bidDisabled={item.status === 'pending' && idx === 0}
                    currentPrice={
                      item.winningBid !== null
                        ? parseFloat(item.winningBid.amount)
                        : parseFloat(item.startingPrice)
                    }
                    power={
                      // TODO: High Power for position must be computed on backend upon artifact retrieve
                      item.position
                        ? 800
                        : findEnergyLevel(item.artifact.artifactId)
                    }
                    active={true}
                    oCashPrice={oCashPrice}
                    type={item.type}
                    userIsTopBidder={
                      item.winningBid !== null &&
                      item.winningBid.user === userId
                    }
                  />
                );
              })}
            </ScrollMenu>
          </div>
        </>
      )}
    </AuctionSC>
  );
};

export default Auction;
