import styled, { keyframes } from 'styled-components';
import diamondBG from '@assets/jpg/card-backgrounds/diamond-bg.jpg';
import { shakeAnimation } from '@components/Button.styled';

const glowAnimation = () => keyframes`
    0% {
        -webkit-filter: drop-shadow(0 0 3px #fbf7c6) drop-shadow(0 0 10px #fbf7c6);
        filter: drop-shadow(0 0 3px #fbf7c6) drop-shadow(0 0 10px #fbf7c6);
    }

    25% {
        -webkit-filter: drop-shadow(0 0 8px #fbf7c6) drop-shadow(0 0 20px #fbf7c6);
        filter: drop-shadow(0 0 8px #fbf7c6) drop-shadow(0 0 20px #fbf7c6);
      }

      50% {
        -webkit-filter: drop-shadow(0 0 12px #fbf7c6) drop-shadow(0 0 26px #fbf7c6);
        filter: drop-shadow(0 0 12px #fbf7c6) drop-shadow(0 0 26px #fbf7c6);
        }

        75% {
            -webkit-filter: drop-shadow(0 0 8px #fbf7c6) drop-shadow(0 0 20px #fbf7c6);
            filter: drop-shadow(0 0 8px #fbf7c6) drop-shadow(0 0 20px #fbf7c6);
          }

    100% {
        -webkit-filter: drop-shadow(0 0 3px #fbf7c6) drop-shadow(0 0 10px #fbf7c6);
        filter: drop-shadow(0 0 3px #fbf7c6) drop-shadow(0 0 10px #fbf7c6);
      }
`;

export const OgemsDashboardSC = styled.div`
  background: #0e0f15;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  overflow: hidden;
  max-width: 2700px;
  margin: auto;

  .grid {
    display: grid;
    grid-template-rows: auto;
    gap: 20px;

    @media only screen and (min-width: 1024px) {
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas:
        'user user user user missions missions'
        'leaderboard leaderboard leaderboard leaderboard missions missions'
        'progress progress progress progress missions missions'
        'window window window window booster booster';
    }

    @media only screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        'user'
        'missions'
        'leaderboard'
        'progress'
        'window'
        'booster';
    }

    .progress {
      grid-area: progress;
      position: relative;
    }

    .user {
      grid-area: user;
    }

    .window {
      grid-area: window;
      position: relative;
    }

    .booster {
      grid-area: booster;
      position: relative;
    }

    .missions {
      grid-area: missions;
    }

    .leaderboard {
      grid-area: leaderboard;
    }
  }

  .section {
    height: 100%;
    background: #151622;
    padding: 20px;
    border-radius: 18px;
  }

  //   Typography

  .title,
  .minor-title {
    color: #fcfcfd;
    font-weight: 600;
  }

  .subtitle {
    color: #767da0;
    font-weight: 500;
  }

  .body-text {
    color: #767da0;
    font-weight: 400;
  }

  @media only screen and (max-width: 1280px) {
    .body-text {
      font-size: 0.8rem;
    }
  }

  // Skeletons

  .user-skeleton {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .details {
      display: flex;
      justify-content: space-between;

      br {
        display: none;
      }
      span {
        height: 36px;
        width: 125px;
      }
    }

    .balance {
      span {
        height: 80px;
      }
    }
  }

  .leaderboard-skeleton {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .title {
      span {
        height: 36px;
        width: 125px;
      }
    }

    .lower {
      .table {
        span {
          height: 30px;
          margin-bottom: 6px;
        }
      }
    }
  }

  .missions-skeleton {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .title {
      span {
        height: 36px;
        width: 50%;
      }
    }

    .lower {
      .table {
        span {
          height: 60px;
          margin-bottom: 12px;
        }
      }
    }
  }

  .mint-windows-skeleton {
    display: grid;
    grid-template-columns: 200px auto;
    gap: 24px;

    @media only screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
    .right {
      span {
        height: 36px;
        margin-bottom: 12px;
      }
    }

    .left {
      .tiers {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 12px;

        span {
          height: 150px;
          margin-top: 12px;
        }

        br {
          display: none;
        }
      }
    }
  }

  .progress-skeleton {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title {
      display: block;
      width: 200px;
      span {
        height: 30px;
      }
    }

    .progress {
      display: flex;
      justify-content: space-between;
      gap: 24px;
      span {
        width: 100%;
        height: 30px;
      }
    }

    .lower {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;

      .description {
        span {
          height: 18px;
          margin-bottom: 0.5rem;
          width: 80%;
        }
      }

      .tiers {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;

        br {
          display: none;
        }
        span {
          height: 30px;
        }
      }
    }
  }
`;

export const ProgressSC = styled.div`
  .progress-bar {
    margin: 20px auto;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .rate {
      .caption {
        font-size: 0.8rem;
        margin-bottom: 4px;
      }

      .title {
        animation: ${glowAnimation} 3s linear infinite;
        span {
          font-size: 0.8rem;
          font-weight: 500;
          animation: none !important;
        }
      }
    }
  }

  .information {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    gap: 20px;
  }

  .progress {
    margin-bottom: 32px;
    .progress-bar {
      margin: 20px 0px 0px 0px;
      background-color: #767da0;
      border-radius: 18px;
      height: 3px;

      .progress-bar-fill {
        background-color: #fbf7c6;
        border-radius: 18px;
        height: 3px;
        width: 0.1%;
      }
    }
    .progress-bar-labels {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      text-align: center;
      align-items: center;
      justify-items: center;
      width: 100%;

      > :not(:last-child) {
        .progress-bar-fill {
          border-radius: 18px 0px 0px 18px;
        }
      }

      > :not(:first-child) {
        .progress-bar-fill {
          border-radius: 0px 18px 18px 0px;
        }
      }

      .active {
        cursor: pointer !important;
      }

      .bronze {
        .active {
          background: linear-gradient(0deg, #c19075, #c19075),
            linear-gradient(
              339.03deg,
              rgba(255, 255, 255, 0) 52.79%,
              #ffffff 95.95%
            ),
            linear-gradient(
              76.82deg,
              #576265 11.6%,
              #9ea1a1 25.31%,
              #848b8a 48.06%,
              #576265 55.72%,
              #576265 77.23%,
              #757a7b 85.34%,
              #576265 91.31%
            );
          background-blend-mode: color, overlay, normal;
          border: 1.5px solid #d8bdae;
        }
      }

      .silver {
        .active {
          background: linear-gradient(0deg, #cdc9c2, #cdc9c2),
            linear-gradient(
              339.03deg,
              rgba(255, 255, 255, 0) 52.79%,
              #ffffff 95.95%
            ),
            linear-gradient(
              76.82deg,
              #576265 11.6%,
              #9ea1a1 25.31%,
              #848b8a 48.06%,
              #576265 55.72%,
              #576265 77.23%,
              #757a7b 85.34%,
              #576265 91.31%
            );
          background-blend-mode: color, overlay, normal;
          border: 1.5px solid #f3f3f3;
        }
      }

      .gold {
        .active {
          background: linear-gradient(0deg, #c1a875, #c1a875),
            linear-gradient(
              339.03deg,
              rgba(255, 255, 255, 0) 52.79%,
              #ffffff 95.95%
            ),
            linear-gradient(
              76.82deg,
              #576265 11.6%,
              #9ea1a1 25.31%,
              #848b8a 48.06%,
              #576265 55.72%,
              #576265 77.23%,
              #757a7b 85.34%,
              #576265 91.31%
            );
          background-blend-mode: color, overlay, normal;
          border: 1.5px solid #fff9bd;
        }
      }

      .diamond {
        .active {
          background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.4),
              rgba(255, 255, 255, 0.4)
            ),
            url(${diamondBG});
          background-size: cover;
          border: 1.5px solid #ffffff;
        }
      }

      svg {
        stroke: #565f81;
      }
      div {
        width: 100%;
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        .bar {
          width: 3px;
          height: 20px;
        }

        .label {
          max-width: 125px;
          padding: 8px 8px;
          border-radius: 12px;
          align-self: center;
          text-align: center;
          width: 100%;
          background: #3d435a;
          cursor: not-allowed;

          &.active {
            h1 {
              color: #1d2939;
            }
          }

          .body-text {
            display: flex;
            align-items: center;
            font-weight: 600;

            @media only screen and (max-width: 776px) {
              font-size: 12px;

              svg {
                height: 16px;
                width: 16px;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;

    .progress-bar-labels {
      gap: 6px;
    }

    .label {
      max-width: 60px;
      padding: 6px;
    }

    .information {
      grid-template-columns: 1fr;
      .tier-grid {
        gap: 12px;
        grid-template-areas:
          'header header'
          'bronze silver'
          'gold diamond';
      }
    }
  }

  .tier-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 20px;

    .highlight {
      color: white;
    }

    .header {
      grid-area: header;
    }

    h1(nth-child(1)) {
      grid-area: bronze;
    }

    h1(nth-child(2)) {
      grid-area: silver;
    }

    h1(nth-child(3)) {
      grid-area: gold;
    }

    h1(nth-child(4)) {
      grid-area: diamond;
    }

    @media only screen and (min-width: 1024px) {
      grid-template-areas:
        'header header'
        'bronze silver'
        'gold diamond';
    }
  }
`;

export const UserSC = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;

  .header {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 32px;

    .profile {
      border-radius: 999px;
      padding: 8px;
      background-color: rgba(255, 255, 255, 0.1);
      border: 1.5px solid #767da0;
      height: 45px;
      width: 45px;
      text-align: center;

      svg {
        stroke: #767da0;
      }
    }

    .rate {
      text-align: right;
      .subtitle {
      }
    }
  }

  .balance-frame {
    position: relative;
    padding: 40px 0px;

    .gem {
      position: absolute;
      -webkit-filter: drop-shadow(0 0 5px #fff) drop-shadow(0 0 15px #fff);
      filter: drop-shadow(0 0 5px #fff) drop-shadow(0 0 15px #fff);
      animation: ${glowAnimation} 3s linear infinite;
    }

    .gem:nth-child(1) {
      font-size: 2rem;
      right: 1rem;
      top: 0.5rem;
      transform: rotate(30deg);
    }
    .gem:nth-child(2) {
      font-size: 3rem;
      right: 1rem;
      bottom: 0px;
      transform: rotate(35deg);
      animation-delay: 0.25s;
    }
    .gem:nth-child(3) {
      font-size: 3rem;
      left: 10%;
      top: 0px;
      transform: rotate(-25deg);
      animation-delay: 0.5s;
    }
    .gem:nth-child(4) {
      font-size: 3.5rem;
      left: 45%;
      top: -1.5rem;
      transform: rotate(0deg);
      animation-delay: 0.75s;
    }
    .gem:nth-child(5) {
      font-size: 3rem;
      left: 70%;
      top: -1rem;
      transform: rotate(30deg);
      animation-delay: 1s;
    }
    .gem:nth-child(6) {
      font-size: 2rem;
      left: 30%;
      top: -0.5rem;
      transform: rotate(340deg);
      animation-delay: 0.75s;
    }
    .gem:nth-child(7) {
      font-size: 2.5rem;
      left: 30%;
      bottom: 0px;
      transform: rotate(-15deg);
      animation-delay: 0.5s;
    }
    .gem:nth-child(8) {
      font-size: 2.5rem;
      left: 1rem;
      top: 40%;
      transform: rotate(-45deg);
      animation-delay: 0.25s;
    }
    .gem:nth-child(9) {
      font-size: 2rem;
      right: 30%;
      bottom: -0.05rem;
      transform: rotate(15deg);
      animation-delay: 0.1s;
    }
    .gem:nth-child(10) {
      font-size: 1.5rem;
      left: 2rem;
      bottom: -0.05rem;
      transform: rotate(-35deg);
    }
    .gem:nth-child(11) {
      font-size: 2.5rem;
      right: 1rem;
      top: 40%;
      transform: rotate(45deg);
      animation-delay: 0.5s;
    }
  }

  .live-balance {
    background: linear-gradient(180deg, #248aff 0%, #325379 100%);
    border: 3px solid #decf67;
    border-radius: 999px;
    text-align: center;
    width: 75%;
    padding: 6px 12px;
    margin: auto;
    filter: drop-shadow(0 0 5px #fff) drop-shadow(0 0 15px #fff);

    .bal {
      font-size: 48px;
      animation: ${glowAnimation} 3s linear infinite;
    }

    .decimal {
      font-size: 1rem;
    }
  }

  .stats {
    margin-bottom: 12px;
    .highlight {
      color: #f2f4f7;
    }
  }

  @media only screen and (max-width: 1280px) {
    .live-balance {
      .bal {
        font-size: 28px;
      }
    }

    .balance-frame {
      .gem:nth-child(1) {
        font-size: 1rem;
        right: 1.5rem;
        top: 1rem;
      }
      .gem:nth-child(2) {
        font-size: 1.55rem;
        right: 1rem;
        bottom: 0.5rem;
      }
      .gem:nth-child(3) {
        font-size: 1.3rem;
        left: 10%;
        top: 0.5rem;
      }
      .gem:nth-child(4) {
        font-size: 1.5rem;
        left: 45%;
        top: -0.5rem;
      }
      .gem:nth-child(5) {
        font-size: 1.25rem;
        left: 65%;
        top: 0.1rem;
        transform: rotate(30deg);
        animation-delay: 1s;
      }
      .gem:nth-child(6) {
        font-size: 1rem;
        top: 0.25rem;
      }
      .gem:nth-child(7) {
        font-size: 1.25rem;
        left: 30%;
        bottom: 0.25rem;
      }
      .gem:nth-child(8) {
        font-size: 1rem;
        left: 0.5rem;
        top: 40%;
        transform: rotate(-45deg);
      }
      .gem:nth-child(9) {
        font-size: 1.25rem;
        right: 30%;
        bottom: 0.25rem;
      }
      .gem:nth-child(10) {
        font-size: 1rem;
        left: 2rem;
        bottom: 0.75rem;
      }
      .gem:nth-child(11) {
        font-size: 1rem;
        right: 0.5rem;
        top: 40%;
        transform: rotate(45deg);
      }
    }
  }
`;

export const MintWindowsSC = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .status-indicator {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    text-align: center;

    .status-indicator-item-icon {
      height: 3rem;
      font-size: 3rem;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }

    h1 {
      margin-top: 12px;
    }
  }

  .window-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    .window-item {
      border: 1.5px dashed #767da0;
      border-radius: 12px;
      text-align: center;
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      padding: 12px;

      .title {
        color: #1d2939;
      }

      .locked-button {
        border-radius: 12px;
        background: rgba(30, 34, 54, 0.3);
        border: none;
        height: 40px;
        width: 100%;
        font-weight: 600;
      }

      img {
        animation: ${glowAnimation} 3s linear infinite;
      }
    }

    .window-item:nth-child(4) {
      background: linear-gradient(0deg, #c19075, #c19075),
        linear-gradient(
          339.03deg,
          rgba(255, 255, 255, 0) 52.79%,
          #ffffff 95.95%
        ),
        linear-gradient(
          76.82deg,
          #576265 11.6%,
          #9ea1a1 25.31%,
          #848b8a 48.06%,
          #576265 55.72%,
          #576265 77.23%,
          #757a7b 85.34%,
          #576265 91.31%
        );
      background-blend-mode: color, overlay, normal;
      border: 1.5px solid #d8bdae;
    }

    .window-item:nth-child(3) {
      background: linear-gradient(0deg, #cdc9c2, #cdc9c2),
        linear-gradient(
          339.03deg,
          rgba(255, 255, 255, 0) 52.79%,
          #ffffff 95.95%
        ),
        linear-gradient(
          76.82deg,
          #576265 11.6%,
          #9ea1a1 25.31%,
          #848b8a 48.06%,
          #576265 55.72%,
          #576265 77.23%,
          #757a7b 85.34%,
          #576265 91.31%
        );
      background-blend-mode: color, overlay, normal;
      border: 1.5px solid #f3f3f3;
    }

    .window-item:nth-child(2) {
      background: linear-gradient(0deg, #c1a875, #c1a875),
        linear-gradient(
          339.03deg,
          rgba(255, 255, 255, 0) 52.79%,
          #ffffff 95.95%
        ),
        linear-gradient(
          76.82deg,
          #576265 11.6%,
          #9ea1a1 25.31%,
          #848b8a 48.06%,
          #576265 55.72%,
          #576265 77.23%,
          #757a7b 85.34%,
          #576265 91.31%
        );
      background-blend-mode: color, overlay, normal;
      border: 1.5px solid #fff9bd;
    }

    .window-item:nth-child(1) {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0.4)
        ),
        url(${diamondBG});
      background-size: cover;
      border: 1.5px solid #ffffff;
    }
  }

  .mint-windows {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
  }

  .mint-progress {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px;
    margin-bottom: 20px;

    .mint-progress-bar {
      height: 3px;
      width: 100%;
      background-color: #767da0;
      border-radius: 999px;

      .mint-progress-bar-fill {
        height: 100%;
        width: 0.2%;
        background-color: #00dcff;
      }
    }

    .mint-progress-bar-labels {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      text-align: center;
      align-items: center;
      justify-items: center;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        .bar {
          width: 3px;
          height: 20px;
          background-color: #767da0;
        }
      }
    }
  }

  @media only screen and (max-width: 1500px) {
    grid-template-columns: 1fr 4fr;

    .status-indicator .status-indicator-item-icon {
      height: 2rem;
      font-size: 2rem;
    }

    .window-grid {
      gap: 12px;
    }
  }

  @media only screen and (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr;

    .mint-progress {
      display: none;
    }

    .window-grid {
      grid-template-columns: 1fr 1fr;

      .window-item {
        width: 100%;
      }
    }
  }
`;

export const BoosterInfoSC = styled.div`
  .header {
    border-bottom: 1.5px solid #767da0;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .booster-body {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      background: #3d435a;
      height: 40px;
      width: 100%;
      font-weight: 600;
      color: #565f81;
      border: none;
      border-radius: 12px;
      cursor: not-allowed;
      margin-top: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const MissionsSC = styled.div`
  .rate {
    text-align: center;
    .title {
      animation: ${glowAnimation} 3s linear infinite;
      font-size: 48px;

      @media only screen and (max-width: 1024px) {
        font-size: 28px;
      }
    }
  }
  .header {
    border-bottom: 1.5px solid #767da0;
    border-top: 1.5px solid #767da0;
    padding-bottom: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    margin-top: 20px;
  }

  .mission-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    .mission-item {
      background-color: #282d40;
      border-radius: 12px;
      display: grid;
      grid-template-columns: 2fr 1fr;
      justify-content: space-between;
      gap: 20px;
      padding: 12px;
      position: relative;

      .badge {
        z-index: 2;
        background: ${({ theme }) => theme.blue.blue100};
        padding: 4px 8px;
        border-radius: 8px;
        border: none;
        display: flex;
        gap: 0.5rem;

        @media only screen and (max-width: 1024px) {
          h1 {
            font-size: 0.65rem;
          }
        }

        :hover {
          animation: ${glowAnimation} 3s linear infinite;
        }
        h1 {
          font-size: 0.8rem;
        }

        img {
          animation: ${shakeAnimation} 1s infinite;
          height: 20px;
        }
      }

      .rate-score {
        display: flex;
        background: #3d435a;
        border-radius: 12px;
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;

        &.inactive {
          border: 1.5px solid ${({ theme }) => theme.red};
          .title {
            color: #767da0;
          }
        }

        &.active {
          border: 1.5px solid ${({ theme }) => theme.green};
          .title {
            animation: ${glowAnimation} 3s linear infinite;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .mission-grid {
      grid-template-columns: 1fr;
    }
  }
`;

export const RateSC = styled.div`
  width: 100%;
  text-align: center;
`;

export const LeaderboardSC = styled.div`
  .header {
    border-bottom: 1.5px solid #767da0;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .leaderboard-body {
    max-height: 220px;
    overflow-y: scroll;
  }

  .leaderboard-header,
  .leaderboard-item {
    display: grid;
    grid-template-columns: 1fr 2fr 3fr;

    h1:last-child {
      text-align: right;
    }
  }

  .leaderboard-item {
    background-color: #282d40;
    padding: 12px;
    margin-bottom: 12px;
    border-radius: 12px;
  }

  table {
    width: 100%;
    thead {
      color: #767da0;
      padding-bottom: 12px;
    }

    tbody {
      max-height: 500px;
      overflow-y: scroll;
      color: #fcfcfd;
      font-weight: 600;
      tr {
        background-color: #282d40;
        border-radius: 12px;

        td {
          padding: 10px;
        }
      }
    }
  }
`;
