import { ButtonOutlineSC, ButtonPrimarySC } from '@components/Button.styled';
import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import {
  ManualInputSC,
  ManualIssueBoxSC,
  OpenBoxPageContainerSC,
} from './OpenBoxPage.styled';
import commonBox from '@assets/animations/boxes/common.mp4';
import coffinBox from '@assets/animations/boxes/coffin.mp4';
import holographicBox from '@assets/animations/boxes/holographic.mp4';
import punkBox from '@assets/animations/boxes/punk.mp4';
import robotBox from '@assets/animations/boxes/robot.mp4';
import contract from '@assets/smart_contracts/ofriends_abi.json';

import Toast from '@components/Toast';
import { toast } from 'react-toastify';
import Text, { TextVariant } from '@components/Text';
import openBoxAnim from '@assets/animations/open-box.mp4';
import Confetti from 'react-dom-confetti';
import { useQuery } from 'react-query';

import { confettiConfig } from '@components/Modal/RevealResultModal';
import { handleMetamaskTooltip, handleTwitterTooltip } from '@store/common';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '@store/index';
import Select from 'react-select';
import { colorThemes } from '@assets/styles';
import { CustomOptionSC } from '@pages/AuctionResults/AuctionResults.styled';
import { useNavigate } from 'react-router-dom';
import { InputSC } from '@components/Input.styled';
import { ReactComponent as SvgChevron } from '@assets/svg/chevron-down.svg';
import api from '@utils/api';

import { getRQKOfriendBalance, queryClient } from '@utils/rq-utils';
import Loader from '@components/Loader';
import OfriendCard from './components/OfriendCard';
import { AxiosResponse } from 'axios';

const OpenBoxPage: React.FunctionComponent = () => {
  const [openingBox, setOpeningBox] = useState(false);
  const [playAnim, setPlayAnim] = useState(true);
  const [shootConfetti, setShootConfetti] = useState(false);
  const dispatch = useDispatch();
  const { ethereum } = window;
  const provider = new ethers.providers.Web3Provider(ethereum);
  const [manualEntry, setManualEntry] = useState(false);
  const [manuallyEnteredOfriend, setManuallyEnteredOfriend] =
    useState<any>(null);
  const metamaskAddress = ethereum.selectedAddress;
  const [ofriendBal, setOfriendBal] = useState(0);
  const [unopened, setUnopened] = useState<any>([]);
  const [opened, setOpened] = useState<any>([]);
  const [selectedOfriend, setSelectedOfriend] = useState<any>(null);
  const [showOpenedOfriends, setShowOpenedOfriends] = useState(false);
  const showMetamaskTooltip = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.showMetamaskTooltip,
  ) as boolean;
  const navigate = useNavigate();

  const { isLoading: isNftBalanceLoading, data: tokenBalance } = useQuery(
    ['getOfriendBalance'],
    async () => {
      const response = (await api.getOfriendBalance(
        ethereum.selectedAddress,
      )) as AxiosResponse<any>;
      return response.data.nfts;
    },
    {
      enabled: ethereum !== undefined && ethereum.selectedAddress != null,
      retry: true,
    },
  );

  useEffect(() => {
    if (metamaskAddress != null && tokenBalance) {
      const allOfriends = tokenBalance.filter(
        (obj) =>
          obj.contract === process.env.REACT_APP_OFRIEND_CONTRACT_ADDRESS,
      );

      const unopenedOfriends = tokenBalance.filter(
        (obj) =>
          obj.contract === process.env.REACT_APP_OFRIEND_CONTRACT_ADDRESS &&
          parseInt(obj.identifier) <= 10000,
      );

      const openedOfriends = tokenBalance.filter(
        (obj) =>
          obj.contract === process.env.REACT_APP_OFRIEND_CONTRACT_ADDRESS &&
          parseInt(obj.identifier) > 10000,
      );

      setOfriendBal(allOfriends.length);
      setUnopened(unopenedOfriends);
      setOpened(openedOfriends);
    }
  }, [tokenBalance]);

  const openBox = async () => {
    setOpeningBox(true);
    if (unopened.length < 1 && !manualEntry) {
      toast(
        <Toast
          type="error"
          header="Error"
          body="You do not have any unrevealed ōFriends. Try a different wallet."
        />,
      );
    } else {
      try {
        const signer = provider.getSigner(metamaskAddress);

        const contractInstance = new ethers.Contract(
          process.env.REACT_APP_OFRIEND_CONTRACT_ADDRESS as string,
          contract,
          signer,
        );
        await provider.getFeeData();

        const tx = await contractInstance.openHouse(
          manualEntry ? manuallyEnteredOfriend : selectedOfriend.token_id,
        );
        await tx.wait();
        await setPlayAnim(false);
        await setShootConfetti(true);

        toast(
          <Toast
            type="success"
            header="Reveal Successful!"
            body="Your ōFriend has been revealed. View it on Opensea or reveal another."
          />,
        );
        setManualEntry(false);
        setManuallyEnteredOfriend(null);
        await queryClient.invalidateQueries(
          getRQKOfriendBalance('getOfriendBalance'),
        );
      } catch (error) {
        toast(
          <Toast
            type="error"
            header="Error"
            body="Reveal unsuccessful - please confirm your wallet is properly connected and has enough ETH for gas."
          />,
        );
        setOpeningBox(false);
      }
    }
  };

  const ManualEntryPrompt = () => {
    return (
      <ManualIssueBoxSC>
        <Text variant={TextVariant.Disclaimer}>
          Is your ōFriend not showing?{' '}
          <button
            onClick={() => {
              setManualEntry(true);
            }}
          >
            Click here
          </button>{' '}
          to enter your ōFriend&apos;s number and perform a manual reveal.
        </Text>
      </ManualIssueBoxSC>
    );
  };

  const CustomOption = (props: any) => {
    const { data, innerRef, innerProps } = props;

    return (
      <CustomOptionSC
        ref={innerRef}
        {...innerProps}
        className="custom-option"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 24,
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <Text
            variant={TextVariant.CardText}
            style={{ fontWeight: 600, color: colorThemes.light.text.main }}
          >
            ōFriend #{data.token_id}
          </Text>
        </div>
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            className="icon-wrapper"
            id="opening"
            style={{ borderColor: colorThemes.light.neutral.neutral4 }}
          >
            {unopened === undefined ? (
              <div>Loading</div>
            ) : (
              <>
                <img src={data.image_original_url} alt="box" />
              </>
            )}
          </div>
        </div>
      </CustomOptionSC>
    );
  };

  const OfriendInventory = () => {
    return (
      <div id="inventory-wrapper">
        <Text
          variant={TextVariant.Subtitle}
          style={{
            fontWeight: 600,
            textAlign: 'left',
            color: colorThemes.light.text.main,
          }}
        >
          Revealed ōFriends
        </Text>
        {opened.length > 0 && (
          <div style={{ textAlign: 'left' }}>
            <Text
              variant={TextVariant.CardText}
              style={{ fontWeight: 500, textAlign: 'left' }}
              className="dgray"
            >
              Congratulation&apos;s you are now the proud owner of an ōFriend!
            </Text>
          </div>
        )}
        <OfriendCard opened={opened} />
        <Text
          variant={TextVariant.Body}
          style={{ textAlign: 'left', margin: '0.5rem 0px' }}
          className="dgray"
        >
          Do you have missing ōFriends?{' '}
          <a href={`https://opensea.io/${metamaskAddress}/ofriends`}>
            Click here
          </a>{' '}
          to view on Opensea.
        </Text>
      </div>
    );
  };

  if (isNftBalanceLoading) {
    return (
      <div
        style={{
          minHeight: 200,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader />
      </div>
    );
  }

  const BoxOpenAnimation = () => {
    return (
      <video
        style={{
          borderRadius: 10,
        }}
        autoPlay={true}
        loop={false}
        playsInline={true}
        preload={'auto'}
        muted
        className="treat-anim"
      >
        <source src={openBoxAnim} type="video/mp4" />
      </video>
    );
  };

  const RevealSuccessfulWindow = () => {
    return (
      <>
        <Text variant={TextVariant.CardTitle} style={{ fontWeight: 500 }}>
          Meet your new ōFriend!
        </Text>
        <Text
          variant={TextVariant.CardText}
          style={{ fontWeight: 500, marginTop: 12, marginBottom: 24 }}
          className="dgray"
        >
          Congratulations! You may now join the exclusive ōFriend Treehouse.
        </Text>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
          }}
        >
          <ButtonOutlineSC
            style={{ maxWidth: 200, margin: 'auto' }}
            className="color bounce"
            onClick={() =>
              window.location.replace(
                `https://discord.com/channels/810256382145724436/1068639115081429062`,
              )
            }
          >
            Enter Treehouse
          </ButtonOutlineSC>
          <ButtonOutlineSC
            style={{ maxWidth: 200, margin: 'auto' }}
            onClick={() => window.location.reload()}
          >
            Reveal More
          </ButtonOutlineSC>
        </div>

        <div className="hoo-hoo" style={{ zIndex: 2, maxWidth: '100%' }}>
          <Confetti active={shootConfetti} config={confettiConfig} />
        </div>
      </>
    );
  };

  const ManualRevealWindow = () => {
    return (
      <div>
        <Text variant={TextVariant.CardTitle}>Manual reveal</Text>
        <Text
          variant={TextVariant.CardText}
          className="dgray"
          style={{ marginTop: 16 }}
        >
          Is your ōFriend not showing? Enter your ōFriend&apos;s ID and click
          the button to perform a manual reveal.
        </Text>
        <ManualInputSC>
          <InputSC
            className="bid-input"
            placeholder="ōFriend #1-10,000"
            type="number"
            value={manuallyEnteredOfriend}
            onChange={(e) => {
              const value = e.target.value.replace(/[^\d,.]+|[.,]/, '');

              if (parseInt(value) !== 0 && parseInt(value) <= 10000) {
                setManuallyEnteredOfriend(e.currentTarget.value);
              }
            }}
          />

          <ButtonPrimarySC
            onClick={openBox}
            disabled={
              manuallyEnteredOfriend === null || manuallyEnteredOfriend === ''
            }
          >
            ✓
          </ButtonPrimarySC>
        </ManualInputSC>
      </div>
    );
  };

  const StandardRevealWindow = () => {
    return (
      <>
        <div style={{ margin: '24px auto', maxWidth: 300 }}>
          <Select
            defaultValue={selectedOfriend}
            onChange={(selection) => {
              setSelectedOfriend(selection);
            }}
            options={unopened}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            placeholder="Select ōFriend"
            menuPortalTarget={document.body}
            components={{ Option: CustomOption }}
          />
        </div>
        {selectedOfriend != null && (
          <>
            <Text style={{ color: colorThemes.light.text.main }}>
              ōFriend #{selectedOfriend.token_id}
            </Text>
            <div
              style={{
                padding: 2,
                border: `1.5px solid ${colorThemes.light.blue.blue100}`,
                borderRadius: 5,
                width: 60,
                height: 60,
                margin: '24px auto',
              }}
            >
              <img
                src={selectedOfriend.image_original_url}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
            <ButtonOutlineSC
              onClick={openBox}
              className="color bounce"
              style={{
                maxWidth: 200,
                width: '100%',
                margin: 'auto',
              }}
            >
              Reveal ōFriend
            </ButtonOutlineSC>
          </>
        )}
      </>
    );
  };

  if (metamaskAddress != null && ofriendBal === 0 && manualEntry === false) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: 600,
            margin: 'auto',
          }}
        >
          <div style={{ justifyContent: 'center', textAlign: 'center' }}>
            <div style={{ margin: '40px auto', padding: '0px 24px' }}>
              <Text
                variant={TextVariant.Subtitle}
                style={{
                  fontWeight: 600,
                  marginBottom: 24,
                  color: colorThemes.light.text.main,
                }}
              >
                Feeling lonely?
              </Text>
              <Text variant={TextVariant.Body} className="dgray">
                You do not have any ōFriends at this address.
                <br />
                <br />
                You can attract ōFriends by placing treats on your land, or
                through buying them from others on Opensea.
              </Text>
            </div>
            <div
              style={{
                display: 'flex',
                gap: 24,
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              <ButtonPrimarySC
                onClick={() =>
                  window.location.replace(
                    'https://opensea.io/collection/ofriends',
                  )
                }
                style={{
                  maxWidth: 200,
                }}
              >
                Buy on Opensea
              </ButtonPrimarySC>
              <ButtonOutlineSC
                onClick={() => navigate('/')}
                style={{
                  maxWidth: 200,
                }}
              >
                Take me to my land
              </ButtonOutlineSC>
            </div>
            <ManualEntryPrompt />
          </div>
        </div>
      </>
    );
  }

  const addressIsConnected = window.ethereum.selectedAddress != null;

  return (
    <OpenBoxPageContainerSC>
      <div className="content-container">
        <div style={{ display: 'flex', gap: 24 }}>
          <ButtonOutlineSC
            onClick={() =>
              showOpenedOfriends ? setShowOpenedOfriends(false) : navigate('/')
            }
            style={{
              background: 'white',
              maxWidth: 100,
              marginBottom: 20,
              textAlign: 'center',
              color: colorThemes.light.blue.blue100,
              borderColor: colorThemes.light.neutral.neutral4,
            }}
          >
            <SvgChevron
              style={{
                width: 10,
                fill: colorThemes.light.blue.blue100,
                transform: 'rotate(90deg)',
                marginRight: '0.5rem',
              }}
            />
            To ōLand
          </ButtonOutlineSC>

          {!showOpenedOfriends && (
            <ButtonPrimarySC
              onClick={() => setShowOpenedOfriends(true)}
              style={{ maxWidth: 150, marginBottom: 20 }}
            >
              Revealed ōFriends
            </ButtonPrimarySC>
          )}
        </div>

        {showOpenedOfriends ? (
          <OfriendInventory />
        ) : (
          <>
            <div className="doghouse-row" style={{ display: 'flex' }}>
              <video
                width="auto"
                style={{ width: '100%' }}
                autoPlay={true}
                loop={true}
                playsInline={true}
                preload={'auto'}
                muted
              >
                <source src={commonBox} type="video/mp4" />
              </video>
              <video
                width="auto"
                style={{ width: '100%' }}
                autoPlay={true}
                loop={true}
                playsInline={true}
                preload={'auto'}
                muted
              >
                <source src={coffinBox} type="video/mp4" />
              </video>
              <video
                width="auto"
                style={{ width: '100%' }}
                autoPlay={true}
                loop={true}
                playsInline={true}
                preload={'auto'}
                muted
              >
                <source src={punkBox} type="video/mp4" />
              </video>
            </div>
            <div className="doghouse-row">
              <video
                width="auto"
                style={{ width: '100%' }}
                autoPlay={true}
                loop={true}
                playsInline={true}
                preload={'auto'}
                muted
              >
                <source src={robotBox} type="video/mp4" />
              </video>

              {addressIsConnected ? (
                <>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      {openingBox ? (
                        playAnim ? (
                          <BoxOpenAnimation />
                        ) : (
                          <RevealSuccessfulWindow />
                        )
                      ) : (
                        <>
                          {manualEntry ? (
                            <ManualRevealWindow />
                          ) : (
                            <StandardRevealWindow />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <ButtonOutlineSC
                  style={{ maxWidth: 200, margin: 'auto', color: 'black' }}
                  onClick={() => {
                    handleMetamaskTooltip(dispatch, !showMetamaskTooltip);
                    handleTwitterTooltip(dispatch, false);
                  }}
                >
                  Connect Metamask
                </ButtonOutlineSC>
              )}

              <video
                width="auto"
                style={{ width: '100%' }}
                autoPlay={true}
                loop={true}
                playsInline={true}
                preload={'auto'}
                muted
              >
                <source src={holographicBox} type="video/mp4" />
              </video>
            </div>
            <div className="doghouse-row" style={{ display: 'flex' }}>
              <video
                width="auto"
                style={{ width: '100%' }}
                autoPlay={true}
                loop={true}
                playsInline={true}
                preload={'auto'}
                muted
              >
                <source src={coffinBox} type="video/mp4" />
              </video>
              <video
                width="auto"
                style={{ width: '100%' }}
                autoPlay={true}
                loop={true}
                playsInline={true}
                preload={'auto'}
                muted
              >
                <source src={punkBox} type="video/mp4" />
              </video>
              <video
                width="auto"
                style={{ width: '100%' }}
                autoPlay={true}
                loop={true}
                playsInline={true}
                preload={'auto'}
                muted
              >
                <source src={commonBox} type="video/mp4" />
              </video>
            </div>
          </>
        )}
      </div>
    </OpenBoxPageContainerSC>
  );
};

export default OpenBoxPage;
