import Text, { TextVariant } from '@components/Text';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import React, { FC } from 'react';
import { ReactComponent as SvgLock } from '@assets/svg/ogems/lock.svg';

type TProgressTierProps = {
  isLocked: boolean;
  threshold: number;
  type: string;
};

const ProgressTier: FC<TProgressTierProps> = ({
  isLocked,
  threshold,
  type,
}) => {
  return (
    <div className={type.toLowerCase()}>
      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: isLocked ? '0%' : '100%' }}
        />
      </div>
      <div
        className="bar"
        style={{ backgroundColor: isLocked ? '#767da0' : '#fbf7c6' }}
      />
      <Tippy
        {...defaultTippyProps}
        content={`Unlocked at ${threshold.toLocaleString('en-US')} ōGems`}
      >
        <div className={`label ${!isLocked && 'active'}`}>
          <Text variant={TextVariant.CardText} className="body-text">
            {isLocked && (
              <SvgLock
                height={24}
                width={24}
                style={{ display: 'inline', marginRight: '0.25rem' }}
              />
            )}
            {type}
          </Text>
        </div>
      </Tippy>
    </div>
  );
};

export default ProgressTier;
