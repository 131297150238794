import BoosterImage from '@components/InventoryBar/BoosterImage';
import { BoosterButtonSC } from '@components/InventoryBar/InventoryBar.styled';
import userInterface from '@store/user-interface';
import { TBoosterRarity } from '@store/user-interface/types';
import Tippy from '@tippyjs/react';
import { getColorByRarity, getRarityName } from '@utils/common';
import { defaultTippyProps } from '@utils/ui';
import React from 'react';
import { useDispatch } from 'react-redux';
import SvgCross from '@assets/svg/icons/x.svg';
import { TActiveBooster } from '@pages/Home/components/PositionCard/types';

// export default function Booster(rarity, isEmpty, qty, activeBooster) {
type TBoosterComponentProps = {
  rarity: string;
  isEmpty: boolean;
  qty: number;
  activeBooster: TActiveBooster;
  boosterId: string;
};

/**
 * @constructor
 */

const Booster: React.FunctionComponent<TBoosterComponentProps> = ({
  rarity,
  isEmpty,
  qty,
  activeBooster,
  boosterId,
}) => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(
      userInterface.setModal({
        type: null,
        token: null,
      }),
    );
    dispatch(
      userInterface.setKeysModalStage({
        keysModalStage: null,
      }),
    );
  };
  return (
    <Tippy
      {...defaultTippyProps}
      content={`${getRarityName(rarity)} Booster`}
      disabled={false}
    >
      <span>
        <BoosterButtonSC
          empty={isEmpty}
          color={getColorByRarity(rarity)}
          className={isEmpty ? 'empty' : ''}
          style={{ animation: isEmpty ? 'none' : '' }}
          disabled={isEmpty}
          onClick={() => {
            dispatch(
              userInterface.setActiveBooster({
                activeBooster:
                  activeBooster?.rarity === rarity
                    ? null
                    : {
                        rarity: rarity as TBoosterRarity,
                        boosterId: boosterId,
                        positionId: null,
                      },
              }),
            );
            handleCloseModal();
          }}
        >
          <div className="booster-wrapper">
            <div className="qty-box">
              <span>{qty}</span>
            </div>
            {activeBooster?.rarity === rarity ? (
              <div className="cancel">
                <div className="inner">
                  <img src={SvgCross} alt="cross-btn" />
                </div>
              </div>
            ) : (
              <>
                <BoosterImage rarity={rarity} />
              </>
            )}
          </div>
        </BoosterButtonSC>
      </span>
    </Tippy>
  );
};

export default Booster;
