import styled from 'styled-components';

const HomeSC = styled.div`
  margin: auto;
  padding-top: 32px;

  @media screen and (max-width: 1000px) {
    padding-top: 0px;
  }
`;

export default HomeSC;
