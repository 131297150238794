import Text, { TextVariant } from '@components/Text';
import React from 'react';
import { MintWindowsSC } from '../OgemsDashboard.styled';
import {
  TOgemDashboardComponentProps,
  convertTierToNumber,
} from './ProgressBar';
import diamondChest from '@assets/svg/ogems/diamond-chest.svg';
import goldChest from '@assets/svg/ogems/gold-chest.svg';
import silverChest from '@assets/svg/ogems/silver-chest.svg';
import bronzeChest from '@assets/svg/ogems/bronze-chest.svg';

import LockedButton from './LockedButton';
import { getRQKBoosterPhase, queryClient } from '@utils/rq-utils';

const windows = [
  { title: 'Diamond Wave', img: diamondChest, rank: 4 },
  { title: 'Gold Wave', img: goldChest, rank: 3 },
  { title: 'Silver Wave', img: silverChest, rank: 2 },
  { title: 'Bronze Wave', img: bronzeChest, rank: 1 },
];

const MintWindows: React.FunctionComponent<
  TOgemDashboardComponentProps
> = () => {
  const boosterPhase = queryClient.getQueryData(
    getRQKBoosterPhase('getBoosterPhase'),
  ) as any;

  const userRank = convertTierToNumber(boosterPhase.rank);

  const currentPhase = convertTierToNumber(boosterPhase.phase);

  return (
    <MintWindowsSC>
      <div className="header">
        <Text variant={TextVariant.CardTitle} className="title">
          Auction Booster Mint Windows
        </Text>
        <Text variant={TextVariant.CardText} className="subtitle">
          Your ōGem status will determine your eligible mint window.
        </Text>
      </div>
      <div className="mint-windows">
        <div className="mint-progress">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text
              variant={TextVariant.CardText}
              className="title"
              style={{ marginBottom: 12 }}
            >
              Mint Progress
            </Text>
            {/* TODO: Add percentage once mint begins */}
            <Text variant={TextVariant.CardText} className="subtitle">
              Current Phase:{' '}
              {boosterPhase.phase.charAt(0).toUpperCase() +
                boosterPhase.phase.slice(1)}
            </Text>
          </div>
        </div>
        <div className="window-grid">
          {windows.map((window, index) => {
            const locked = userRank < window.rank && userRank < currentPhase;
            return (
              <div
                className="window-item"
                key={index}
                style={{ opacity: window.rank < currentPhase ? 0.3 : 1 }}
              >
                <div className="window-header">
                  <Text variant={TextVariant.CardText} className="title">
                    {window.title}
                  </Text>
                </div>
                <div className="window-body">
                  <img src={window.img} alt={window.title} />
                </div>
                <LockedButton isLocked={locked} />
              </div>
            );
          })}
        </div>
      </div>
    </MintWindowsSC>
  );
};

export default MintWindows;
