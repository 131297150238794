import React from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import api from '@utils/api';
import { TRootState } from '@store/index';
import { getRQKProfileKey } from '@utils/rq-utils';
import { TBoosterRarity } from '@store/user-interface/types';
import InventoryBarSC from './InventoryBar.styled';
import BoosterButtons from './BoosterButtons';
import TreatButtons from './TreatButtons';
import InventoryBalance from './InventoryBalance';
import KeyBalance from './KeyBalance';
import Skeleton from 'react-loading-skeleton';
import UltimatePrizeBalance from './UltimatePrizeBalance';

export type TSortedBoosters = {
  [key in TBoosterRarity]: string[];
};

const InventoryBar: React.FunctionComponent = () => {
  const expandInventoryBar = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.expandInventoryBar,
  ) as boolean;

  const { isLoading: isOCashDataLoading, data: oCashAmountData } = useQuery(
    getRQKProfileKey('getOCashAmount'),
    async () => {
      const result = (await api.getOCashAmount()).data.data;
      return result;
    },
  );

  if (isOCashDataLoading) {
    return (
      <InventoryBarSC expanded={expandInventoryBar}>
        <div className="inventory-body">
          <Skeleton
            count={2}
            width="50%"
            height={18}
            style={{ margin: '6px 0px' }}
          />
        </div>
      </InventoryBarSC>
    );
  }

  const airdropBalanceNum = parseFloat(oCashAmountData.oCashAmountSpent);

  return (
    <InventoryBarSC expanded={expandInventoryBar}>
      <div className="keys-wrapper">
        <KeyBalance />
        <UltimatePrizeBalance />
      </div>
      <div className="inventory-body">
        <InventoryBalance airdropBalanceNum={airdropBalanceNum} />
        <TreatButtons />
        <BoosterButtons />
      </div>
    </InventoryBarSC>
  );
};

const memoizedInventoryBar = React.memo(InventoryBar);

export default memoizedInventoryBar;
