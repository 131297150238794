import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import chestStore from '@store/chest';
import userInterfaceStore from '@store/user-interface';
import orderStore from '@store/order';
import authInterfaceStore from '@store/auth';

const rootReducer = combineReducers({
  chest: chestStore.reducer,
  userInterface: userInterfaceStore.reducer,
  auth: authInterfaceStore.reducer,
  order: orderStore.reducer,
});

export type TRootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export default store;
