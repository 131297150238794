import styled from 'styled-components';

export enum JustificationTypes {
  SpaceBetween = 'space-between',
  Subtitle = 'centered',
  SpaceAround = 'space-around',
  SpaceEvenly = 'space-evenly',
}

export enum GapSizes {
  Xl = '40px',
  Lg = '24px',
  Md = '16px',
  Sm = '8px',
}

export type TRowProps = {
  justify?: JustificationTypes;
  gapSize?: GapSizes;
  wrap?: boolean;
  mobileWrap?: boolean;
};

const Row = styled.div<TRowProps>`
  margin-top: 16px;
  display: flex;
  width: 100%;
  justify-content: ${(props) => props.justify};
  gap: ${(props) => props.gapSize};
  ${(props) => (props.wrap ? 'flex-wrap: wrap;' : '')};

  @media only screen and (max-width: 1024px) {
    ${(props) => (props.mobileWrap ? 'flex-wrap: wrap;' : '')};
  }

  &.attribute-row {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export default Row;
