import { colorThemes } from '@assets/styles';
import Wrapper from '@components/Wrapper';
import { sheenAnimation } from '@pages/Home/components/PositionCard/StyledTrayFrame.styled';
import styled from 'styled-components';

export const OpenBoxPageContainerSC = styled(Wrapper)`
  padding-top: 24px;
  // padding-bottom: 80px;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  background: white;
  min-height: calc(100vh - 80px);
  .content-container {
    max-width: 900px;
    text-align: center;
    width: 100%;

    video {
      max-width: 200px;

      @media only screen and (max-width: 776px) {
        max-width: 75px;
      }
    }
    .doghouse-row {
      display: flex;
      margin: 24px 0px;
      justify-content: space-between;
      width: 100%;
    }
  }

  #inventory-wrapper {
    a {
      text-decoration: none;

      :hover {
        text-decoration: underline;
        color: ${({ theme }) => theme.blue.blue100};
      }
    }
    #cards {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 24px;
      margin: 24px auto;
      width: 100%;
      align-items: center;
      cursor: pointer;

      @media only screen and (max-width: 776px) {
        grid-template-columns: 1fr 1fr !important;
      }

      @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr !important;
      }
    }
  }
`;

export const ManualIssueBoxSC = styled.div`
  background: ${({ theme }) => theme.blue.blue10};
  padding: 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 100%;
  text-align: left;
  max-width: 400px;
  margin: 24px auto;

  @media only screen and (max-width: 1024px) {
    margin: 24px;
  }

  h1 {
    margin: 0px;
  }

  button {
    background: none;
    border: none;
    color: ${({ theme }) => theme.blue.blue100};
    padding: 0px;
    margin: 0px;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const ManualInputSC = styled.div`
  display: flex;
  max-width: 250px;
  margin: 24px auto;

  input {
    border-radius: 5px 0px 0px 5px;
    height: 40px;
    border-right: none;
    border-width: 1.5px;
  }
  button {
    max-width: 50px;
    border-radius: 0px 5px 5px 0px;
    height: 40px;
  }
`;

export const OfriendCardSC = styled.button`
  width: 100%;
  border-radius: 8px;
  padding: 12px;
  position: relative;
  transition: 0.3s;
  border: 5px solid ${({ theme }) => theme.neutral.neutral5};
  background: none;
  filter: saturate(125%);

  #img-wrapper {
    border-style: ridge;
    border-color: ${({ theme }) => theme.neutral.neutral1};
    border-width: 8px;
    box-shadow: 6px 6px 12px -2px rgba(0, 0, 0, 0.65);
    -webkit-box-shadow: 6px 6px 12px -2px rgba(0, 0, 0, 0.65);
    -moz-box-shadow: 6px 6px 12px -2px rgba(0, 0, 0, 0.65);
  }

  #stats {
    margin-top: 16px;
  }

  h1 {
    color: ${colorThemes.light.text.main} !important;
  }

  img {
    width: 100%;
    transition: 0.3s !important;
  }

  hr {
    color: black;
    height: 1.5px;
    opacity: 1;
    margin: 0.5rem 0;
  }

  :hover {
    filter: saturate(300%);
    img {
      scale: 1.15;
    }
  }

  &::after {
    content: '';
    position: absolute;
    background: linear-gradient(
      45deg,
      rgba(224, 32, 32, 0.4),
      rgba(250, 100, 0, 0.4) 17%,
      rgba(247, 181, 0, 0.4) 33%,
      rgba(109, 212, 0, 0.4) 50%,
      rgba(0, 145, 255, 0.4) 67%,
      rgba(98, 54, 255, 0.4) 83%,
      rgba(182, 32, 224, 0.4)
    );
    -webkit-animation: ${sheenAnimation} 6s ease-in-out infinite;
    animation: ${sheenAnimation} 6s ease-in-out infinite;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: -1;
  }
`;
