import React, { FormEvent, useState } from 'react';
import { Turnstile } from '@marsidev/react-turnstile';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import userInterface from '@store/user-interface';
import { InputSC } from '@components/Input.styled';
import { ButtonOutlineSC, ButtonPrimarySC } from '@components/Button.styled';
import api, { defaultSearchPositionParams } from '@utils/api';
import { setAccessToken, setRefreshToken } from '@utils/storage';
import { TJwtToken, TNullableString } from '@custom-types/common';
import authSlice from '@store/auth';
import Toast from '@components/Toast';
import Text, { TextVariant } from '@components/Text';
import IntroSC from './Intro.styled';
import GlobalFeed from '@components/GlobalFeed';
import { getRQKPositionsKey, getRQKProfileKey } from '@utils/rq-utils';

const Intro: React.FunctionComponent = () => {
  const { signIn } = authSlice;
  const { setModal, setFreeOCashAmountClaimed } = userInterface;

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const [forgottenPassword, setForgottenPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [turnstileToken, setTurnstileToken] = useState<TNullableString>(null);

  const navigate = useNavigate();

  const isDevelopment = process.env.REACT_APP_IS_DEVELOPMENT === 'true';

  const signInByEmail = async () => {
    if (!turnstileToken && !isDevelopment) {
      return;
    }

    try {
      setIsLoading(true);
      const result = await api.signIn(
        email,
        password,
        isDevelopment ? 'random' : (turnstileToken as string),
      );

      const { accessToken, refreshToken, amountClaimed } = result.data.data;

      setAccessToken(accessToken);
      setRefreshToken(refreshToken);

      // Prefetch the data
      await queryClient.prefetchQuery(
        getRQKPositionsKey('getPositions'),
        async () =>
          (
            await api.getPositions(defaultSearchPositionParams)
          ).data.data,
      );

      const profileResult = (await api.getProfile()).data.data;
      queryClient.setQueryData(getRQKProfileKey('getProfile'), profileResult);

      const decodedHeader: TJwtToken = jwtDecode(String(accessToken));
      dispatch(
        signIn({
          id: decodedHeader.sub,
          daoRanking: profileResult.daoRanking,
          affiliateId: profileResult.affiliateId,
        }),
      );

      setEmail('');
      setPassword('');

      dispatch(setFreeOCashAmountClaimed({ amountClaimed }));

      navigate('/');
    } catch (err) {
      if (
        (err as AxiosResponse).data &&
        (err as AxiosResponse).data.errNo === 1203
      ) {
        toast(
          <Toast
            type="error"
            header="Error"
            body="We were not able to sign you in. If you have an account, you can reset your password."
          />,
        );
      } else if (
        (err as AxiosResponse).data &&
        (err as AxiosResponse).data.errNo === 1400
      ) {
        toast(
          <Toast
            type="error"
            header="Error"
            body="Invalid captcha, please try to refresh the page and try again."
          />,
        );
      } else {
        toast(
          <Toast
            type="error"
            header="Error"
            body="We are experiencing heavy traffic. Try again later and follow our Twitter announcements."
            links={true}
            // body="We were not able to sign you in. Please try again later"
          />,
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const resetPassword = async () => {
    if (!turnstileToken) {
      return;
    }

    try {
      setIsLoading(true);
      await api.requestResetPassword(email, turnstileToken);
      setEmail('');
      dispatch(setModal({ type: null, token: null }));
      setForgottenPassword(false);

      toast(
        <Toast
          type="success"
          header="Success!"
          body="You have requested a password reset. If an account exists, we will send reset instructions to your email."
        />,
      );
    } catch (err) {
      if (
        (err as AxiosResponse).data &&
        (err as AxiosResponse).data.errNo === 1400
      ) {
        toast(
          <Toast
            type="error"
            header="Error"
            body="Invalid captcha, please try to refresh the page and try again."
          />,
        );
      } else {
        toast(
          <Toast
            type="error"
            header="Error"
            body="Something went wrong. Please contact our support if the problem persists."
          />,
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (forgottenPassword) {
      resetPassword().then();
    } else {
      signInByEmail().then();
    }
  };

  return (
    <IntroSC>
      <div
        style={{
          margin: 'auto',
          width: '100%',
          maxWidth: 500,
        }}
      >
        <div
          style={{
            textAlign: 'center',

            margin: 'auto',
            width: '100%',
          }}
        >
          {!forgottenPassword &&
            process.env.REACT_APP_IS_DEVELOPMENT === 'false' && <GlobalFeed />}
          <div style={{ maxWidth: 400, margin: 'auto' }}>
            <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
              {!forgottenPassword ? 'Sign In' : 'Reset Password'}
            </Text>
            <Text variant={TextVariant.Body}>Manage your ōLand</Text>
            <form
              style={{
                paddingBottom: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: 24,
                marginTop: 24,
              }}
              onSubmit={handleFormSubmit}
              autoComplete="on"
            >
              <InputSC
                value={email}
                type="email"
                required
                onChange={(e) => setEmail(e.currentTarget.value)}
                placeholder="Email address"
                autoComplete="on"
              />

              {!forgottenPassword ? (
                <InputSC
                  value={password}
                  required
                  placeholder="Password"
                  type="password"
                  pattern="^(?=.*[A-Z])(?=.*\d)(.){8,}$"
                  title="Must have at least 8 characters, one upper and one number."
                  onChange={(e) => setPassword(e.currentTarget.value)}
                />
              ) : null}

              <ButtonPrimarySC disabled={isLoading}>
                {isLoading ? (
                  <SvgSpinner
                    height={30}
                    width={30}
                    style={{ display: 'inline', fill: 'white' }}
                  />
                ) : null}
                {!forgottenPassword ? 'Sign In' : 'Reset Password'}
              </ButtonPrimarySC>

              <ButtonOutlineSC
                type="button"
                onClick={() =>
                  setForgottenPassword(
                    (forgottenPassword) => !forgottenPassword,
                  )
                }
              >
                {!forgottenPassword ? 'Forgotten password?' : 'Go back'}
              </ButtonOutlineSC>

              <Text
                onClick={() =>
                  (window.location.href = process.env
                    .REACT_APP_OROUTER_LANDING as string)
                }
                variant={TextVariant.Link}
                className="no-underline gray"
              >
                Don&apos;t have an account?{' '}
                <a href="/" className="link-span">
                  Sign Up
                </a>
              </Text>
            </form>
          </div>
        </div>
        {!isDevelopment && (
          <Turnstile
            siteKey={process.env.REACT_APP_TURNSTILE_KEY as string}
            onSuccess={(token) => setTurnstileToken(token)}
            onError={() => console.error('Turnistile error')}
          />
        )}
      </div>
    </IntroSC>
  );
};

export default Intro;
