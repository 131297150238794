import { colorThemes } from '@assets/styles';
import { borderAnimation } from '@components/InventoryBar/InventoryBar.styled';
import { sheenAnimation } from '@pages/Home/components/PositionCard/StyledTrayFrame.styled';
import styled from 'styled-components';
import revealOverlay from '@assets/png/reveal-overlay.png';

export const CardFlipRevealSC = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  h4 {
    color: ${colorThemes.dark.text.main};
    text-align: center;
  }

  .active-item {
    background: ${colorThemes.dark.background.regular.bg1};
    border: 1.5px solid ${colorThemes.dark.text.secondary};
    border-radius: 12px;
    padding: 12px;
    width: 250px;
    height: 120px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;

    .matched-collection {
      color: white;
    }

    img,
    video {
      max-height: 50%;
      height: 100%;
    }
  }

  .reveal-cards {
    display: flex;
    gap: 12px;
  }

  @media (max-width: 883px) {
    .reveal-cards {
      gap: 6px;
    }
  }
`;

export const CardSC = styled.button`
  width: 80px;
  height: 80px;
  border-radius: 12px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
  padding: 0px;
  border: 1.5px solid ${colorThemes.dark.input.border};
  position: relative;

  .badge {
    position: absolute;
    top: -8px;
    right: 0;
    color: ${(props) => props.color as string};
  }

  &:hover {
    opacity: 0.8;
    transform: translateY(-0.25rem);
  }

  &.ultimate {
    overflow: hidden;
    img,
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &:not(.ultimate) {
    .no-prize {
      display: none;
    }
  }

  &.card-front {
    background: ${colorThemes.dark.background.regular.bg1};
    img,
    video {
      width: 100%;
    }

    img {
      -webkit-filter: drop-shadow(
        0px 0px 6px ${(props) => props.color as string}
      );
      filter: drop-shadow(0px 0px 6px ${(props) => props.color as string});
    }

    &.ultimate {
      color: ${({ theme }) => theme.text.secondary};
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.card-back {
    background: ${colorThemes.dark.input.background};
    animation: ${borderAnimation} 0.8s infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &.ultimate {
      background: white;
      border-radius: 12px;
      .question-box {
        opacity: 0.6;
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: url(${revealOverlay}),
          linear-gradient(
            45deg,
            rgb(224 32 32),
            rgb(250 100 0) 17%,
            rgb(247 181 0) 33%,
            rgb(109 212 0) 50%,
            rgb(0 145 255) 67%,
            rgb(98 54 255) 83%,
            rgb(182 32 224)
          );
        -webkit-animation: ${sheenAnimation} 6s ease-in-out infinite;
        animation: ${sheenAnimation} 6s ease-in-out infinite;
        background-size: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        border-radius: 0px;
        color: black;
        line-height: 100%;

        svg {
          fill: white;
        }
      }
    }

    .question-box {
      position: absolute;
      width: 45px;
      height: 45px;
      background-color: ${colorThemes.dark.input.border};
      border: 1.5px solid ${colorThemes.dark.text.secondary};
      color: white;
      border-radius: 999px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: 600;
    }
  }

  @media (max-width: 883px) {
    width: 55px;
    height: 55px;

    &.ultimate .question-box {
      font-size: 14px !important;
    }

    &.card-back .question-box {
      width: 30px;
      height: 30px;
      font-size: 18px;
    }
  }
`;
