import styled from 'styled-components';

export type TCheckboxProps = {
  checked: boolean;
  disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const CheckboxSC = styled.button<TCheckboxProps>`
  border: 1px solid;
  border-color: ${({ theme }) => theme.blue.blue100};
  border-radius: 4;
  background: ${(props) => (props.checked ? 'rgba(32, 129, 226, 1)' : 'white')};
  width: 16px;
  height: 16px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  justify-content: center;
  max-width: 16px;
  max-height: 16px;
`;
