import styled, { keyframes } from 'styled-components';

const slideAnimation = keyframes`
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-100%);
    }
  `;

export const PrizeCarouselSC = styled.div`
  width: 100%;
  margin-bottom: 12px;

  h6,
  h2,
  p {
    text-align: center;
    color: white;
    margin-bottom: 0.25rem;
  }

  h6 {
    margin-bottom: 12px;
  }

  .slide {
    text-align: center;
    img {
      height: 75px;
    }
  }

  .looper {
    width: 100%;
    overflow: hidden;
    margin: 0px !important;
  }

  .looper__innerList {
    display: flex;
    justify-content: center;
    width: fit-content;
  }

  .looper__innerList[data-animate='true'] .looper__listInstance {
    animation: ${slideAnimation} linear infinite;
  }

  .looper__listInstance {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    gap: 12px;

    .image {
      background: black;

      img {
        width: 75px;
        height: 75px;
        border: 1.5px solid ${({ theme }) => theme.input.border};

        @media only screen and (max-width: 768px) {
          width: 50px !important;
          height: 50px !important;
        }
      }
    }
  }
`;
