import styled from 'styled-components';

export const PlotActionsModalSC = styled.div`
  h2 {
    color: ${({ theme }) => theme.text.main};
    font-size: 1.25rem;
    font-weight: 400;
  }

  .selections {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;
  }
`;

export const PlotActionsButtonSC = styled.button`
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 24px;
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  background: ${({ theme }) => theme.input.background};
  border: 1.5px solid ${({ theme }) => theme.input.border};
  min-height: 100px;
  transition: 0.3s;
  position: relative;

  .badge {
    position: absolute;
    top: -8px;
    right: 0;
    background: ${({ theme }) => theme.text.secondary};
    color: white;
    padding: 4px 12px;
    border-radius: 6px;
  }

  svg {
    stroke: ${({ theme }) => theme.blue.blue100};
    width: 100%;
    height: auto;
    stroke-width: 1.5px;
    text-align: center;
    justify-self: center;
  }

  &:hover:not(:disabled) {
    border-color: ${({ theme }) => theme.blue.blue100};
    background: ${({ theme }) => theme.blue.blue10};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.75;
    svg {
      stroke: ${({ theme }) => theme.text.secondary};
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;

    h3,
    p {
      text-align: left;
      margin: 0px;
    }

    h3 {
      color: ${({ theme }) => theme.text.main};
      font-size: 1.5rem;
    }

    p {
      color: ${({ theme }) => theme.text.secondary};
      font-size: 1rem;
      font-weight: 400;
    }
  }
`;
