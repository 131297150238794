import React from 'react';
import { useQuery } from 'react-query';
import { OgemsDashboardSC } from './OgemsDashboard.styled';
import ProgressBar from './components/ProgressBar';
import UserInfo from './components/UserInfo';
import MintWindows from './components/MintWindows';
import Missions from './components/Missions';
import Leaderboard from './components/Leaderboard';
import { getRQKBoosterPhase, getRQKOgemData } from '@utils/rq-utils';
import api from '@utils/api';
import OgemSkeletons, { EDashboardSection } from './components/OgemSkeletons';
import { ButtonOutlineSC } from '@components/Button.styled';
import { useNavigate } from 'react-router-dom';

const OgemsDashboard: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { isLoading: isOgemDataLoading, data: oGemData } = useQuery(
    getRQKOgemData('getOgemData'),
    async () => (await api.getOgemsData()).data.data,
  );

  const { isLoading: boosterPhaseDataIsLoading } = useQuery(
    getRQKBoosterPhase('getBoosterPhase'),
    async () => {
      const result = (await api.getBoosterPhase()).data.data;
      return result;
    },
  );

  return (
    <OgemsDashboardSC>
      <ButtonOutlineSC
        onClick={() => navigate('/')}
        style={{ maxWidth: 120, marginBottom: 20 }}
      >
        Go Back
      </ButtonOutlineSC>

      <div className="grid">
        <div className="progress">
          <div className="section">
            {isOgemDataLoading || boosterPhaseDataIsLoading ? (
              <OgemSkeletons type={EDashboardSection.Progress} />
            ) : (
              <ProgressBar oGemData={oGemData} isLoading={isOgemDataLoading} />
            )}
          </div>
        </div>
        <div className="section user">
          {isOgemDataLoading ? (
            <OgemSkeletons type={EDashboardSection.UserInfo} />
          ) : (
            <UserInfo oGemData={oGemData} isLoading={isOgemDataLoading} />
          )}
        </div>
        <div className="window">
          <div className="section">
            {isOgemDataLoading || boosterPhaseDataIsLoading ? (
              <OgemSkeletons type={EDashboardSection.MintWindows} />
            ) : (
              <MintWindows oGemData={oGemData} isLoading={isOgemDataLoading} />
            )}
          </div>
        </div>
        <div className="booster">
          {/* <div className="blur" /> */}
          {/* This thing is not decided yet. Boosterpack should be a separate product
            There is always only one purchase process */}
          {/* <div className="section">
              <BoosterInfo oGemData={oGemData} isLoading={isOgemDataLoading} />
            </div> */}
        </div>
        <div className="section missions">
          {isOgemDataLoading ? (
            <OgemSkeletons type={EDashboardSection.Missions} />
          ) : (
            <Missions oGemData={oGemData} isLoading={isOgemDataLoading} />
          )}
        </div>
        <div className="section leaderboard">
          {isOgemDataLoading ? (
            <OgemSkeletons type={EDashboardSection.Leaderboard} />
          ) : (
            <Leaderboard oGemData={oGemData} isLoading={isOgemDataLoading} />
          )}
        </div>
      </div>
    </OgemsDashboardSC>
  );
};

export default OgemsDashboard;
