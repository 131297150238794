import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as SvgChevron } from '@assets/svg/chevron-down.svg';
import { handleMetamaskTooltip, handleTwitterTooltip } from '@store/common';
import { TRootState } from '@store/index';
import userInterface from '@store/user-interface';
import { shortenEthAddress } from '@utils/common';

import ConnectButton from '../ConnectButton';
import MetamaskWalletTooltip from '../ContextTooltip/MetamaskWalletTooltip';

const MetamaskButton = () => {
  const dispatch = useDispatch();

  const { ethereum } = window;
  const { setConnectedAddress } = userInterface;

  const connectedAddress = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.connectedAddress,
  ) as string;

  const showMetamaskTooltip = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.showMetamaskTooltip,
  ) as boolean;

  useEffect(() => {
    if (ethereum) {
      if (ethereum.selectedAddress !== null) {
        // Metamask address is connected lets store it to Redux
        dispatch(
          setConnectedAddress({
            connectedAddress: ethereum.selectedAddress,
          }),
        );
      }

      window.ethereum.on('accountsChanged', (res) => {
        // Put new address into redux
        dispatch(
          setConnectedAddress({
            connectedAddress: res[0],
          }),
        );
      });
    }
  }, []);

  return (
    <>
      <ConnectButton
        qty={2}
        onClick={() => {
          handleMetamaskTooltip(dispatch, !showMetamaskTooltip);
          handleTwitterTooltip(dispatch, false);
        }}
        showBadge={connectedAddress === null}
      >
        {connectedAddress
          ? shortenEthAddress(connectedAddress)
          : 'Connect Wallet'}

        <span> | </span>
        <SvgChevron
          style={{
            width: 12,
            fill: '#fff',
            transform: showMetamaskTooltip ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: '0.2s',
          }}
        />
      </ConnectButton>
      <MetamaskWalletTooltip isOpen={showMetamaskTooltip} />
    </>
  );
};

export default MetamaskButton;
