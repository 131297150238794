import styled from 'styled-components';

export const V2PurchaseModalSC = styled.div`
  h3 {
    color: ${({ theme }) => theme.text.main};
    font-size: 1.25rem;
    font-weight: 500;
  }

  .skeleton {
    display: grid;
    grid-template-columns: 100px 1fr 1fr;
    gap: 12px;
    border-top: 1.5px solid ${({ theme }) => theme.input.border};
    padding-top: 16px;

    .skeleton-data {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }

  .buy-now,
  .bidding {
    border-top: 1.5px solid ${({ theme }) => theme.input.border};
    padding-top: 16px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .confirm {
    border-top: 1.5px solid ${({ theme }) => theme.input.border};
    padding-top: 16px;
  }

  .buy-now {
    .price {
      display: grid;
      grid-template-columns: 1fr 0.5fr;

      div {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
    }
    .method-options {
      display: flex;
      flex-direction: column;
      gap: 12px;

      label {
        color: ${({ theme }) => theme.text.main};
        display: flex;
        gap: 12px;
      }
    }
  }

  .bidding {
    .price {
      display: grid;
      grid-template-columns: 1fr 0.5fr;
      justify-content: space-between;
      gap: 16px;

      div {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
    }
    input {
      width: 100%;
      background: ${({ theme }) => theme.input.background};
      border: 1px solid ${({ theme }) => theme.input.border};
      padding: 0.5rem;
      border-radius: 0.5rem;
      color: ${({ theme }) => theme.text.main};
    }
  }
`;
