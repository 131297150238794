import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import SvgCross from '@assets/svg/icons/x.svg';
import Text, { TextVariant } from '@components/Text';
import { TRootState } from '@store/index';
import userInterface from '@store/user-interface';
import { chestModalProps } from '../modal-settings';
import ModalSC, { BoosterInventoryModalSC } from '../Modal.styled';
import { useQuery } from 'react-query';
import { getRQKBoosters } from '@utils/rq-utils';
import api from '@utils/api';
import { TSortedBoosters } from '@components/InventoryBar/InventoryBar';
import { getRarityName, updateSortedBoosters } from '@utils/common';
import PngRedOboo from '@assets/png/oboo/oboo-red.png';
import PngGreenOboo from '@assets/png/oboo/oboo-green.png';
import PngYellowOboo from '@assets/png/oboo/oboo-yellow.png';
import Booster from './components/Booster';
import { TActiveBooster } from '@pages/Home/components/PositionCard/types';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import { openChestModal } from '@store/common';

const getImgFromRarity = (rarity) => {
  switch (rarity) {
    case 'rare':
      return PngRedOboo;
    case 'super':
      return PngGreenOboo;
    case 'ultra':
      return PngYellowOboo;
    default:
      return PngRedOboo;
  }
};

const BoosterInventoryModal: React.FunctionComponent = () => {
  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };
  const activeBooster = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.activeBooster,
  ) as TActiveBooster;
  const [sortedBoosters, setSortedBoosters] = useState<TSortedBoosters>({
    rare: [],
    super: [],
    ultra: [],
  });

  const [oboos, setOboos] = useState([] as string[]);

  // User's available Booster balances
  const { isLoading: boosterBalanceIsLoading } = useQuery(
    getRQKBoosters('getBoosters'),
    async () => {
      const result = (await api.getBoosters()).data.data.boosters;
      const availableBoosters = result.available;
      const claimedBoosters = result['non-available'];

      setSortedBoosters((prevState) =>
        updateSortedBoosters(prevState, availableBoosters as any[], {
          'super-rare': 'super',
          'ultra-rare': 'ultra',
        }),
      );

      setOboos(() => claimedBoosters.map((booster) => booster.meta.type));
      return result.available;
    },
  );

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(
      userInterface.setModal({
        type: null,
        token: null,
      }),
    );
    dispatch(
      userInterface.setKeysModalStage({
        keysModalStage: null,
      }),
    );
  };

  if (boosterBalanceIsLoading) {
    return null;
  }

  return (
    <ReactModal
      isOpen={type === 'boosterInventoryModal'}
      {...chestModalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC className="chest-modal" color={'red'}>
        <div className="header">
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <BoosterInventoryModalSC className="body">
          <div className="boosters">
            {Object.keys(sortedBoosters).map((boosterRarity, index) => {
              const items = sortedBoosters[boosterRarity];
              const isEmpty = sortedBoosters[boosterRarity] < 1;
              const qty = items.length;

              return (
                <span key={index} className="booster-btn-wrapper">
                  <Booster
                    rarity={boosterRarity}
                    isEmpty={isEmpty}
                    activeBooster={activeBooster}
                    qty={qty}
                    boosterId={items[0]}
                  />
                </span>
              );
            })}
          </div>
          <Text variant={TextVariant.CardText}>ōBoos</Text>
          <div className="inventory oboos">
            {oboos.map((oboo, index) => {
              return (
                <Tippy
                  {...defaultTippyProps}
                  content={`${getRarityName(oboo)} ōBoo`}
                  disabled={false}
                  key={index}
                >
                  <div className="oboo-item">
                    <img src={getImgFromRarity(oboo)} />;
                  </div>
                </Tippy>
              );
            })}
          </div>
        </BoosterInventoryModalSC>
        <div className="banner">
          <Text variant={TextVariant.CardTitle}>Treasure Hunter</Text>
          <Text variant={TextVariant.CardText}>
            Obtain keys to unlock chests in search of Land Boosters, ōCash, and
            rare artifacts!
          </Text>
          <button
            className="buy-keys-btn"
            onClick={() => openChestModal(dispatch)}
          >
            <Text variant={TextVariant.CardText}>Get Keys</Text>
          </button>
        </div>
      </ModalSC>
    </ReactModal>
  );
};

export default BoosterInventoryModal;
