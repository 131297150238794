import { getEnergyLevel } from '@data/gifts';
import CardVideo from '@pages/Home/components/CardVideo';
import {
  gen6Style,
  generateClassNames,
} from '@pages/Home/components/PositionCard/PositionCard';
import { getRQKPositionsKey, queryClient } from '@utils/rq-utils';
import React, { FC, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ListingCardSC } from '../V2ListingModal.styled';

type TListingCardProps = {
  positionId: string;
  isLoading: boolean;
  dominantBooster: string | null;
  cardType: string;
};

const ListingCard: FC<TListingCardProps> = ({
  isLoading,
  positionId,
  dominantBooster,
  cardType,
}) => {
  const positionDetails = queryClient.getQueryData(
    getRQKPositionsKey('getPosition', positionId),
  ) as any;

  const energy = getEnergyLevel(positionDetails.artifacts);
  //
  if (isLoading)
    return (
      <div className="skeleton">
        <Skeleton count={1} height={120} />
        <div className="skeleton-data">
          <Skeleton count={1} height={24} />
          <Skeleton count={1} height={18} />
          <Skeleton count={1} height={18} />
          <Skeleton count={1} height={18} />
        </div>
      </div>
    );

  const isGen6 = positionDetails.type === 5;

  const cardClassNames = useMemo(
    () => generateClassNames({ dominantBooster, cardType, isGen6 }),
    [dominantBooster, cardType, isGen6],
  );

  //
  return (
    <div className="listing-item">
      <ListingCardSC className={cardClassNames} style={isGen6 ? gen6Style : {}}>
        <CardVideo focus={true} isGen6={isGen6} />
      </ListingCardSC>
      <div className="position-data">
        <h6>ōLand #{positionDetails.position.toLocaleString('en-US')}</h6>
        <h6>
          {positionDetails.type === 0
            ? 'Genesis'
            : `Generation ${positionDetails.type + 1}`}
        </h6>
        <h6>Energy: ⚡️{energy}</h6>
        <h6>{positionDetails.rarityMeta.city}</h6>
        <h6>Upgrades: {positionDetails.positionUpgrades.length}</h6>
      </div>
      <div className="price-data">
        {/* <p>Listing price</p>
        <h5>-- ōCash</h5> */}
      </div>
    </div>
  );
};

export default ListingCard;
