import React from 'react';

import api from '@utils/api';
import { useQuery } from 'react-query';

import Text, { TextVariant } from '@components/Text';
import Row, { GapSizes, JustificationTypes } from '@components/Row';
import { ButtonPrimarySC } from '@components/Button.styled';

import PageWrapper, { OrderContainer } from './PurchaseOrder.styled';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '@components/Loader';

import numeral from 'numeral';
import {
  getHumanReadableOrderType,
  ORDER_PAYMENT_TYPE_CUSTOM_GW,
  USD_NUMERAL_FMT,
} from '@utils/common';
import { O_ROUTER_PRODUCT_ID } from '@store/order/products';

const resolvePayment = (order: any, isTotal = false) => {
  // currently only oCash can be paid by this method
  if (order.type === ORDER_PAYMENT_TYPE_CUSTOM_GW) {
    return <span>{numeral(order.dEthTotal).format(USD_NUMERAL_FMT)}</span>;
  }
  // oRouter has special rules (pre-release price)
  else if (
    order.orderProducts[0].product.id !== O_ROUTER_PRODUCT_ID ||
    isTotal
  ) {
    return <span>{numeral(order.total).format(USD_NUMERAL_FMT)}</span>;
  }
  return (
    <span>
      {numeral(
        order.orderProducts[0].quantity *
          order.orderProducts[0].product.preReleasePrice,
      ).format(USD_NUMERAL_FMT)}
    </span>
  );
};

const PurchaseOrder: React.FunctionComponent = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { isLoading, data: order } = useQuery(['getOrder', id], async () => {
    const order = (await api.getOrder(id as string)).data.data;

    order.type = order.orderPayments[0] ? order.orderPayments[0].type : null;
    order.dEthTotal = null;

    if (order.type === ORDER_PAYMENT_TYPE_CUSTOM_GW) {
      order.dEthTotal = 0;
      order.oCash = 0;
      for (const orderPayment of order.orderPayments) {
        for (const tx of orderPayment.meta.txs) {
          order.dEthTotal += tx.usd;
          order.oCash += parseFloat(tx.ocash);
        }
      }

      order.dEthTotal = order.dEthTotal.toFixed(2);
      order.oCash = order.oCash.toFixed(2);
    }

    return order;
  });

  if (isLoading) return <Loader />;

  const productTitle = order.orderProducts.map((op) => op.product.title)[0];
  let productType = 'oRouter';

  if (productTitle.indexOf('oCash') !== -1) productType = 'oCash';
  if (productTitle.indexOf('Key') !== -1) productType = 'keys';

  if (!order)
    return (
      <h4 style={{ textAlign: 'center', marginTop: '140px' }}>
        Purchase not found
      </h4>
    );

  return (
    <PageWrapper>
      <div style={{ padding: '0px 24px' }}>
        <Text
          variant={TextVariant.CardTitle}
          style={{ textAlign: 'center', marginBottom: 16 }}
        >
          Order Confirmation
        </Text>
        <Text style={{ textAlign: 'center' }} variant={TextVariant.CardText}>
          Order No. #{order.orderNumber}
        </Text>

        <OrderContainer>
          <Text style={{ textAlign: 'center' }} variant={TextVariant.CardTitle}>
            Order Details
          </Text>
          <Text
            variant={TextVariant.ColorText}
            style={{ textAlign: 'center', padding: '24px 0px' }}
          >
            {resolvePayment(order, true)}
          </Text>

          <Text
            variant={TextVariant.CardSubtitle}
            className="order-details"
            style={{ textAlign: 'center' }}
          >
            Shipping Address:
          </Text>
          {order.shippingAddress ? (
            <Text
              variant={TextVariant.CardText}
              style={{ marginBottom: 8, textAlign: 'center' }}
            >
              {order.shippingAddress.firstName} {order.shippingAddress.lastName}
              <br />
              {order.shippingAddress.street} {order.shippingAddress.streetOpt}
              <br />
              {order.shippingAddress.city} {order.shippingAddress.region}{' '}
              {order.shippingAddress.postal}
              <br />
              {order.shippingAddress.country}
            </Text>
          ) : (
            <Text>
              <i>not applicable</i>
            </Text>
          )}
          <div id="order-details-wrapper">
            <Row
              justify={JustificationTypes.SpaceBetween}
              gapSize={GapSizes.Md}
            >
              <Text
                variant={TextVariant.CardSubtitle}
                className="order-details"
              >
                {productType === 'oCash'
                  ? 'ōCash'
                  : productType === 'keys'
                  ? 'Keys'
                  : 'ōRouter'}{' '}
                Qty
              </Text>
              <Text
                variant={TextVariant.CardText}
                style={{ textAlign: 'right' }}
              >
                {productType === 'oCash' && (
                  <span>
                    {order.type === ORDER_PAYMENT_TYPE_CUSTOM_GW ? (
                      <span>{order.oCash}</span>
                    ) : (
                      <span>
                        {productTitle.substring(0, productTitle.indexOf(' '))}
                      </span>
                    )}
                  </span>
                )}
                {productType === 'keys' &&
                  productTitle.substring(0, productTitle.indexOf(' '))}
                {productType === 'oRouter' && order.orderProducts[0].quantity}
              </Text>
            </Row>
            <Row
              justify={JustificationTypes.SpaceBetween}
              gapSize={GapSizes.Md}
            >
              <Text
                variant={TextVariant.CardSubtitle}
                className="order-details"
              >
                Discount applied:
              </Text>
              <Text
                variant={TextVariant.CardText}
                style={{ textAlign: 'right' }}
              >
                {numeral(
                  order.discount * order.orderProducts[0].quantity,
                ).format(USD_NUMERAL_FMT)}
              </Text>
            </Row>
            <Row
              justify={JustificationTypes.SpaceBetween}
              gapSize={GapSizes.Md}
            >
              <Text
                variant={TextVariant.CardSubtitle}
                className="order-details"
              >
                Payment Type:
              </Text>
              <Text
                variant={TextVariant.CardText}
                style={{ textAlign: 'right' }}
              >
                {getHumanReadableOrderType(order.type)}
              </Text>
            </Row>

            <Row
              justify={JustificationTypes.SpaceBetween}
              gapSize={GapSizes.Md}
            >
              <Text
                variant={TextVariant.CardSubtitle}
                className="order-details"
              >
                {productType === 'oRouter' ? 'Preorder payment' : 'Payment'}
              </Text>
              <Text
                variant={TextVariant.CardText}
                style={{ textAlign: 'right' }}
              >
                {resolvePayment(order)}
              </Text>
            </Row>

            <Row
              justify={JustificationTypes.SpaceBetween}
              gapSize={GapSizes.Md}
            >
              <Text
                variant={TextVariant.CardSubtitle}
                className="order-details"
              >
                Payment Status:
              </Text>
              <Text
                variant={TextVariant.CardText}
                style={{ textAlign: 'right', textTransform: 'uppercase' }}
              >
                {order.orderPayments[0].status}
              </Text>
            </Row>
          </div>
        </OrderContainer>
        <div style={{ maxWidth: 200, padding: '40px 0px', margin: 'auto' }}>
          <ButtonPrimarySC onClick={() => navigate('/purchase-orders')}>
            Back to ōLand Page
          </ButtonPrimarySC>
        </div>
      </div>
    </PageWrapper>
  );
};

export default PurchaseOrder;
