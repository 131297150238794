import React from 'react';
import { ReactComponent as SvgLock } from '@assets/svg/ogems/lock.svg';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import { openChestModal } from '@store/common';
import { useDispatch } from 'react-redux';

export type TLockButtonProps = {
  isLocked: boolean;
};

const LockedButton: React.FunctionComponent<TLockButtonProps> = ({
  isLocked,
}) => {
  const dispatch = useDispatch();
  return (
    <Tippy
      {...defaultTippyProps}
      content={
        isLocked
          ? 'You need more ōGems to participate in this wave'
          : 'Get 1 booster for every $14.99 spent on keys!'
      }
    >
      <span>
        <button
          className="locked-button"
          disabled={isLocked}
          onClick={() => openChestModal(dispatch)}
        >
          {isLocked ? (
            <SvgLock
              height={24}
              width={24}
              style={{ display: 'inline', stroke: '#1E2236' }}
            />
          ) : (
            'Get Boosters'
          )}
        </button>
      </span>
    </Tippy>
  );
};

export default LockedButton;
