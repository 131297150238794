import { ButtonOutlineSC } from '@components/Button.styled';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuctionResultsSC } from './AuctionResults.styled';
import AuctionsTable from './components/AuctionsTable';

const AuctionResults: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const handleGoBack = async () => {
    navigate('/');
  };

  return (
    <AuctionResultsSC>
      <div className="content-wrapper">
        <ButtonOutlineSC
          onClick={handleGoBack}
          style={{ maxWidth: 120, marginBottom: 20 }}
        >
          Go Back
        </ButtonOutlineSC>
        <AuctionsTable />
      </div>
    </AuctionResultsSC>
  );
};

export default AuctionResults;
