export type TTextColor = {
  main: string;
  secondary: string;
  accent: string;
};

// #141926

export type TColorTheme = {
  primary: string; // used
  secondary: string; // used
  tertiary: string; //
  red: string; // used
  redTransparent: string; // used
  green: string; //used
  gray: string; //used
  blue: {
    blue10: string;
    blue20: string;
    blue30: string;
    blue40: string;
    blue50: string;
    blue60: string;
    blue70: string;
    blue80: string;
    blue90: string;
    blue100: string;
  };
  white: {
    white10: string;
    white20: string;
    white30: string;
    white40: string;
    white50: string;
    white60: string;
    white70: string;
    white80: string;
    white90: string;
    white100: string;
  };
  black: {
    black10: string;
    black20: string;
    black30: string;
    black40: string;
    black50: string;
    black60: string;
    black70: string;
    black80: string;
    black90: string;
    black100: string;
  };
  background: {
    regular: {
      bg1: string; //used
      bg2: string; //used
    };
    inverted: {
      bg1: string; //used
      bg2: string; //used
    };
  }; // used

  text: TTextColor;
  neutral: {
    neutral1: string;
    neutral2: string;
    neutral3: string;
    neutral4: string;
    neutral5: string;
    neutral6: string;
    neutral7: string;
    neutral8: string;
    neutral9: string;
  };
  button: {
    primary: {
      background: string; // used
      hover: string;
    };
    secondary: {
      background: string;
      hover: string; // used
      text: string; // used
    };
    tertiary: {
      background: string;
    };

    red: {
      background: string;
      hover: string;
    };
    disabled: string;
  };
  card: {
    background: string;
    hover: string;
    border: string;
  };
  input: {
    color: string;
    placeholder: string;
    border: string;
    background: string;
  };
  container: {
    background: string;
    boxShadow: string;
    border: string;
  };
  header: {
    background: string;
    boxShadow: string;
  };
  table: {
    boxShadow: string;
    border: string;
    backgroundEven: string;
  };
  toast: {
    error: {
      header: {
        background: string;
      };
    };
    success: {
      header: {
        background: string;
      };
    };
  };
};

const colorThemes = {
  light: {
    primary: `#333333`,
    secondary: '#282D40',
    tertiary: 'rgba(127, 143, 169, 1)',
    red: 'rgba(220,53,69,1)',
    redTransparent: 'rgba(220,53,69,0.15)',
    green: '#68cc45',
    gray: 'rgb(110, 110, 115)',
    blue: {
      blue10: 'rgba(32, 129, 226, .10)',
      blue20: 'rgba(32, 129, 226, .20)',
      blue30: 'rgba(32, 129, 226, .30)',
      blue40: 'rgba(32, 129, 226, .40)',
      blue50: 'rgba(32, 129, 226, .50)',
      blue60: 'rgba(32, 129, 226, .60)',
      blue70: 'rgba(32, 129, 226, .70)',
      blue80: 'rgba(32, 129, 226, .80)',
      blue90: 'rgba(32, 129, 226, .90)',
      blue100: 'rgba(32, 129, 226, 1)',
    },
    white: {
      white10: 'rgba(255,255,255,0.1)',
      white20: 'rgba(255,255,255,0.2)',
      white30: 'rgba(255,255,255,0.3)',
      white40: 'rgba(255,255,255,0.4)',
      white50: 'rgba(255,255,255,0.5)',
      white60: 'rgba(255,255,255,0.6)',
      white70: 'rgba(255,255,255,0.7)',
      white80: 'rgba(255,255,255,0.8)',
      white90: 'rgba(255,255,255,0.9)',
      white100: 'rgba(255,255,255,1)',
    },
    black: {
      black10: 'rgba(0,0,0,0.1)',
      black20: 'rgba(0,0,0,0.2)',
      black30: 'rgba(0,0,0,0.3)',
      black40: 'rgba(0,0,0,0.4)',
      black50: 'rgba(0,0,0,0.5)',
      black60: 'rgba(0,0,0,0.6)',
      black70: 'rgba(0,0,0,0.7)',
      black80: 'rgba(0,0,0,0.8)',
      black90: 'rgba(0,0,0,0.9)',
      black100: 'rgba(0,0,0,1)',
    },
    background: {
      regular: {
        bg1: 'rgba(255,255,255,1)',
        bg2: 'rgba(232, 237, 242, 1)',
      },
      inverted: {
        bg1: 'rgba(232, 237, 242, 1)',
        bg2: 'rgba(255,255,255,1)',
      },
    },

    neutral: {
      neutral1: '#f5f8fc',
      neutral2: '#eef3fb',
      neutral3: '#dee6f1',
      neutral4: '#c5cee0',
      neutral5: '#adb8cf',
      neutral6: '#8f9bb3',
      neutral7: '#57627b',
      neutral8: '#303b50',
      neutral9: '#1d273d',
    },
    card: {
      background: '#fff',
      hover: '#fff',
      border: '#c5cee0',
    },
    text: {
      main: 'black',
      secondary: '#767DA0',
      accent: '#63A2D3',
    },
    button: {
      primary: {
        background: '#248AFF',
        hover: '#217ce5',
      },
      secondary: {
        background: '#000',
        hover: 'rgba(0,0,0,0.8)',
        text: 'rgba(32, 129, 226, 1)',
      },

      tertiary: {
        background: 'rgba(27, 200, 112, 1)',
      },

      red: {
        background: 'rgba(250, 65, 65, 1)',
        hover: 'rgba(250, 65, 65, 0.75)',
      },
      disabled: '#343A3F',
    },
    input: {
      color: 'rgba(0, 0, 0, 1)',
      placeholder: '#767DA0',
      border: '#c5cee0',
      background: 'rgba(255, 255, 255, 1)',
    },
    container: {
      background: `rgba(255,255,255,1)`,
      boxShadow: `rgba(187, 202, 220, 0.37)`,
      border: `rgba(204, 215, 233, 1)`,
    },
    header: {
      background: `rgba(0, 0, 0, 0.75)`,
      boxShadow: `rgba(0, 0, 0, 0.74)`,
    },
    table: {
      boxShadow: 'rgba(187, 202, 220, 0.7)',
      border: 'rgba(204, 215, 233, 1)',
      backgroundEven: 'rgba(232, 238, 246, 1)',
    },
    toast: {
      error: {
        header: {
          background: '#FF6A6A',
        },
      },
      success: {
        header: {
          background: '#1BC870',
        },
      },
    },
  },
  dark: {
    primary: `#333333`,
    secondary: 'rgba(255,255,255,1)',
    tertiary: 'rgba(127, 143, 169, 1)',
    red: 'rgba(220,53,69,1)',
    redTransparent: 'rgba(220,53,69,0.15)',
    green: '#68cc45',
    gray: 'rgb(110, 110, 115)',
    blue: {
      blue10: 'rgba(32, 129, 226, .10)',
      blue20: 'rgba(32, 129, 226, .20)',
      blue30: 'rgba(32, 129, 226, .30)',
      blue40: 'rgba(32, 129, 226, .40)',
      blue50: 'rgba(32, 129, 226, .50)',
      blue60: 'rgba(32, 129, 226, .60)',
      blue70: 'rgba(32, 129, 226, .70)',
      blue80: 'rgba(32, 129, 226, .80)',
      blue90: 'rgba(32, 129, 226, .90)',
      blue100: 'rgba(32, 129, 226, 1)',
    },
    white: {
      white10: 'rgba(255,255,255,0.1)',
      white20: 'rgba(255,255,255,0.2)',
      white30: 'rgba(255,255,255,0.3)',
      white40: 'rgba(255,255,255,0.4)',
      white50: 'rgba(255,255,255,0.5)',
      white60: 'rgba(255,255,255,0.6)',
      white70: 'rgba(255,255,255,0.7)',
      white80: 'rgba(255,255,255,0.8)',
      white90: 'rgba(255,255,255,0.9)',
      white100: 'rgba(255,255,255,1)',
    },
    black: {
      black10: 'rgba(0,0,0,0.1)',
      black20: 'rgba(0,0,0,0.2)',
      black30: 'rgba(0,0,0,0.3)',
      black40: 'rgba(0,0,0,0.4)',
      black50: 'rgba(0,0,0,0.5)',
      black60: 'rgba(0,0,0,0.6)',
      black70: 'rgba(0,0,0,0.7)',
      black80: 'rgba(0,0,0,0.8)',
      black90: 'rgba(0,0,0,0.9)',
      black100: 'rgba(0,0,0,1)',
    },
    // background: '#121619',
    background: {
      regular: {
        bg1: '#0E0F15',
        bg2: '#151622',
      },
      inverted: {
        bg1: '#151622',
        bg2: '#0E0F15',
      },
    },
    neutral: {
      neutral1: '#f5f8fc',
      neutral2: '#262C31',
      neutral3: '#dee6f1',
      neutral4: '#2A3136',
      neutral5: '#adb8cf',
      neutral6: '#8f9bb3',
      neutral7: '#57627b',
      neutral8: '#303b50',
      neutral9: '#121619',
    },
    card: {
      background: '#151622',
      hover: '#282D40',
      border: '#282D40',
    },
    text: {
      main: '#F4F5FB',
      secondary: '#767DA0',
      accent: '#63A2D3',
    },
    button: {
      primary: {
        background: '#248AFF',
        hover: '#217ce5',
      },
      secondary: {
        background: '#000',
        hover: 'rgba(0,0,0,0.8)',
        text: '#F4F5FB',
      },
      tertiary: {
        background: 'rgba(27, 200, 112, 1)',
      },

      red: {
        background: 'rgba(250, 65, 65, 1)',
        hover: 'rgba(250, 65, 65, 0.75)',
      },
      disabled: '#282D40',
    },
    input: {
      color: 'rgba(0, 0, 0, 1)',
      placeholder: '#767DA0',
      border: '#282D40',
      background: '#151622',
    },
    container: {
      background: `rgba(255,255,255,1)`,
      boxShadow: `rgba(187, 202, 220, 0.37)`,
      border: `rgba(204, 215, 233, 1)`,
    },
    header: {
      background: `rgba(0, 0, 0, 0.75)`,
      boxShadow: `rgba(0, 0, 0, 0.74)`,
    },
    table: {
      boxShadow: 'rgba(187, 202, 220, 0.7)',
      border: 'rgba(204, 215, 233, 1)',
      backgroundEven: 'rgba(232, 238, 246, 1)',
    },
    toast: {
      error: {
        header: {
          background: '#FF6A6A',
        },
      },
      success: {
        header: {
          background: '#1BC870',
        },
      },
    },
  },
};

export default colorThemes;
