import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import ReactModal from 'react-modal';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import SvgCross from '@assets/svg/icons/x.svg';
import Text, { TextVariant } from '@components/Text';
import { chestModalProps } from './modal-settings';
import ModalSC from './Modal.styled';
import userInterface from '@store/user-interface';
import { getRQKUserRewardsKey, queryClient } from '@utils/rq-utils';
import api from '@utils/api';
import { TRootState } from '@store/index';
import { openChestModal } from '@store/common';
import { collections } from './CheckoutProcess/components/PrizeCarousel/collections';
import { ButtonPrimarySC } from '@components/Button.styled';
import { InputSC } from '@components/Input.styled';
import Toast from '@components/Toast';
import { toast } from 'react-toastify';
import { TNullableString } from '@custom-types/common';

const findImageByCollection = (name: string) => {
  return collections.find((item) => item.collection === name)?.image;
};

const UltimatePrizesModal: React.FunctionComponent = () => {
  const [claiming, setClaiming] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const [claimAddress, setClaimAddress] = useState('');
  const [rewardId, setRewardId] = useState<TNullableString>(null);
  const dispatch = useDispatch();

  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const { isLoading, data } = useQuery(
    getRQKUserRewardsKey('getUltimateRewards'),
    async () => (await api.getUltimateRewards()).data.data,
  );

  const handleCloseModal = () => {
    dispatch(
      userInterface.setModal({
        type: null,
        token: null,
      }),
    );
  };

  const handleInitClaimBtnClick = (id: string) => {
    setRewardId(id);
    setClaiming(true);
  };

  const handleClaimBtnClick = async () => {
    if (claimAddress.length === 0) {
      return;
    }
    setClaimLoading(true);

    try {
      await api.claimUltimateReward(rewardId as string, claimAddress);
      toast(
        <Toast
          type="success"
          header="Success"
          body="The reward has been claimed successfully"
        />,
      );
      queryClient.invalidateQueries(getRQKUserRewardsKey('getUltimateRewards'));
    } catch (e) {
      toast(
        <Toast
          type="error"
          header="Error"
          body="We were not able to proceed the request. If you
            believe there is an error, please contact our support team."
        />,
      );
    } finally {
      setClaimAddress('');
      setRewardId(null);
      setClaimLoading(false);
      setClaiming(false);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <ReactModal
      isOpen={type === 'ultimatePrizesModal'}
      {...chestModalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC className="chest-modal" color={'red'}>
        <div className="header">
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <StyledModalBody className="body">
          {data.ultimatePrizes.unclaimed.length > 0 && (
            <>
              <Text variant={TextVariant.CardText} style={{ fontWeight: 700 }}>
                Unclaimed Prizes
                <span style={{ fontWeight: 400, fontSize: 12 }}>
                  {' '}
                  (click on the picture to claim)
                </span>
              </Text>
              <div className="inventory">
                {data.ultimatePrizes.unclaimed.map((e) => (
                  <button
                    key={e.id}
                    onClick={() => handleInitClaimBtnClick(e.id)}
                  >
                    {rewardId != e.id && <div className="overlay"></div>}
                    <img src={findImageByCollection(e.meta.collection)} />
                  </button>
                ))}
              </div>
            </>
          )}
          {claiming && (
            <div className="claim-row">
              <InputSC
                name="eth-address"
                type="string"
                value={claimAddress}
                onChange={(e) => setClaimAddress(e.target.value)}
                placeholder="Your ETH address..."
              />
              <ButtonPrimarySC
                onClick={handleClaimBtnClick}
                disabled={claimLoading}
              >
                {claimLoading ? <SvgSpinner /> : 'Claim'}
              </ButtonPrimarySC>
            </div>
          )}
        </StyledModalBody>
        <div className="banner">
          <Text variant={TextVariant.CardTitle}>Win more super prizes</Text>
          <Text variant={TextVariant.CardText}>
            Improve your chances by opening more chests. No keys? Get more by
            clicking on the button below. Search for Land Boosters, ōCash, and
            rare artifacts!
          </Text>
          <button
            className="buy-keys-btn"
            onClick={() => openChestModal(dispatch)}
          >
            <Text variant={TextVariant.CardText}>Get Keys</Text>
          </button>
        </div>
      </ModalSC>
    </ReactModal>
  );
};

export const StyledModalBody = styled.div`
  &.body {
    padding: 12px;
  }

  .claim-row {
    margin: 24px auto 0 auto;

    input {
      margin-bottom: 16px;
    }
    > button {
      svg {
        fill: #fff;
        width: 32px;
        height: 32px;
      }
    }
  }

  .inventory {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 20px;
    background: #282d40;
    border: 1.5px solid #575f83;
    margin-bottom: 12px;
    padding: 12px;
    width: 100%;
    margin-top: 12px;
    max-height: 200px;
    overflow-y: scroll;

    > div {
      margin: 0 auto;
    }

    button {
      max-height: 50px;
      max-width: 50px;
      border: none;
      position: relative;
      padding: 0;

      > div.overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        &:hover {
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      max-height: 50px;
    }

    @media only screen and (max-width: 1024px) {
      max-height: 162px;
      overflow-y: scroll;
    }

    h1 {
      font-weight: 700;
    }
  }
`;

export default UltimatePrizesModal;
