import { TNullableString } from '@custom-types/common';
import { TArtifact } from '../../data/gifts';
import { EKeysModalPhase } from '@components/Modal/CheckoutProcess/OcashChestModal';

export const COLOR_THEME_LIGHT = 'light';
export const COLOR_THEME_DARK = 'dark';

export type TColorTheme = typeof COLOR_THEME_LIGHT | typeof COLOR_THEME_DARK;

export enum EChestType {
  Small = '🟥',
  Medium = '🟩',
  Large = '🟨',
}

export type TOTreatFlavour =
  | 'chocolate'
  | 'lime'
  | 'cranberry'
  | 'honey'
  | 'caramel'
  | 'watermelon';

export type TBoosterRarity = 'rare' | 'super' | 'ultra';

// TODO: prob. delete those related to purchase process
export type TModalType =
  | 'initialization'
  | 'resetPassword'
  | 'referralModal'
  | 'initialWelcome'
  | 'deviceModal'
  | 'getBoostedModal'
  | 'oLandModal'
  | 'purchaseModal'
  | 'oTreatModal'
  | 'claimModal'
  | 'claimOFriendModal'
  | 'claimAuctionModal'
  | 'treatPlacingModal'
  | 'revealResultModal'
  | 'locationUnlockModal'
  | 'upgradesInventoryModal'
  | 'auctionListingModal'
  | 'winningModal'
  | 'ocashChestModal'
  | 'boosterInventoryModal'
  | 'ultimatePrizesModal'
  | 'confirmBoosterApplication'
  | 'v2ListingModal'
  | 'v2PurchaseModal'
  | 'v2CancelAuctionModal'
  | 'plotActionsModal'
  | null;

export type TWinningModalContent = {
  auctionId: string;
};

export type TArtifactPlacement = {
  auctionId: string;
};

export type TUserInterfaceState = {
  colorTheme: TColorTheme;
  mobileView: boolean;
  modal: {
    type: TModalType;
    token: TNullableString;
  };
  sidebarOpen: boolean;
  cartOpen: boolean;
  freeOCashAmountClaimed: number;
  selectedTreatType: TOTreatFlavour | null;
  oTreatPlacement: {
    id: string;
    currentOTreatAmount: number;
  } | null;
  minting: {
    id: string;
  } | null;
  revealProcess: {
    id: string;
    result: false | string;
  } | null;
  earlyPositionReveal: {
    id: string;
    type: number;
  } | null;
  selectedPositionArtifacts: string[] | null;
  auction: {
    itemId: string | null;
    type: string | null;
    startingPrice: number;
    purpose: string | null;
  };
  artifactSelection: {
    id: string;
    name: string;
  } | null;
  ownedPositionArtifacts: TArtifact[] | null;
  winningModalContent: TWinningModalContent | null;
  artifactPlacement: TArtifactPlacement | null;
  connectedAddress: string | null;
  showMetamaskTooltip: boolean;
  showTwitterTooltip: boolean;
  expandInventoryBar: boolean;
  keysModalStage: null | EKeysModalPhase;
  chestType: null | EChestType;
  activeBooster: {
    rarity: TBoosterRarity;
    boosterId: string;
    positionId: TNullableString;
  } | null;
  activePositionId: TNullableString;
};

export type TSetColorTheme = {
  colorTheme: TColorTheme;
};

export type TSetExpandInventoryBar = {
  expandInventoryBar: boolean;
};

export type TSetMobileView = {
  mobileView: boolean;
};

export type TSetFreeOCashAmountClaimed = {
  amountClaimed: number;
};

export type TSetSelectedPositionArtifacts = {
  selectedPositionArtifacts: string[] | null;
};

export type TSetSelectedTreatType = {
  selectedTreatType: null | TOTreatFlavour;
};

export type TPlaceOTreatsOnPosition = {
  selectedPlot: {
    id: string;
    currentOTreatAmount: number;
  } | null;
};

export type TSetModal = {
  type: TModalType;
  token: TNullableString;
};

export type TSetPositionIdToMint = {
  minting: {
    id: string;
  };
};

export type TSetRevealProcessResult = {
  revealProcess: {
    id: string;
    result: false | string;
  };
};

export type TSetPositionIdToEarlyReveal = {
  earlyPositionReveal: {
    id: string;
    type: number;
  };
};

export type TSetArtifactSelection = {
  artifactSelection: {
    id: string;
    name: string;
  };
};

export type TSetOwnedPositionArtifacts = {
  ownedPositionArtifacts: TArtifact[];
};

export type TSetWinningModalContent = {
  winningModalContent: TWinningModalContent;
};

export type TSetArtifactPlacement = {
  artifactPlacementContent: TArtifactPlacement;
};

export type TSetConnectedAddress = {
  connectedAddress: string;
};

export type TSetShowMetamaskTooltip = {
  showMetamaskTooltip: boolean;
};

export type TSetShowTwitterTooltip = {
  showTwitterTooltip: boolean;
};

export type TSetKeysModalStage = {
  keysModalStage: null | EKeysModalPhase;
};

export type TSetChestType = {
  chestType: null | EChestType;
};

export type TSetBooster = {
  activeBooster: {
    rarity: TBoosterRarity;
    boosterId: string;
    positionId: TNullableString;
  } | null;
};

export type TSetActivePositionId = {
  activePositionId: TNullableString;
};
