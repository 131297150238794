import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { AuctionsTableSC } from '../AuctionResults.styled';
import { ReactComponent as SvgPower } from '@assets/svg/icons/power.svg';
import { colorThemes } from '@assets/styles';
import userInterface from '@store/user-interface';
import { getRQKAuctionsKey, getRQKPositionsKey } from '@utils/rq-utils';
import api from '@utils/api';
import { getColorByRarity, getHumanReadable } from '@utils/common';
import { findEnergyLevel, getEnergyLevel } from '@data/gifts';
import dayjs from 'dayjs';
import { TRootState } from '@store/index';
import { useNavigate } from 'react-router-dom';
import { ButtonPrimarySC, ButtonRedSC } from '@components/Button.styled';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import { toast } from 'react-toastify';
import Toast from '@components/Toast';

const tableColumns = [
  'Item',
  'Action',
  'Energy Score',
  'Date Auction Listed',
  'Price',
  'Status',
  'Auction #',
  'Details',
];

type TLandRowProps = {
  item: any;
  currentUserId: string;
  index: number;
  resolveBidStatus: (
    currentUserId: string,
    auctionUserId: string,
    winningBid: any,
    status: string,
  ) => string;
};

const LandRow: React.FunctionComponent<TLandRowProps> = ({
  item,
  currentUserId,
  index,
  resolveBidStatus,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalLoading, setModalLoading] = useState(false);

  const { setModal } = userInterface;

  const { isLoading: positionDetailIsLoading, data: positionDetail } = useQuery(
    getRQKPositionsKey('getPosition', item.position.id),
    async () => {
      const result = (await api.getPositionDetail(item.position.id)).data;

      return result.data;
    },
  );

  if (positionDetailIsLoading) {
    return null;
  }

  const handleCancelBtnClick = async (positionId: string) => {
    setModalLoading(true);
    try {
      dispatch(
        userInterface.setActivePositionId({
          activePositionId: positionId,
        }),
      ),
        dispatch(
          setModal({
            type: 'v2CancelAuctionModal',
            token: null,
          }),
        );
    } catch (e) {
      console.error(e);
      toast(
        <Toast
          type="error"
          header="Something went wrong"
          body="Unable to open cancellation modal, try again or contact support."
        />,
      );
    } finally {
      setModalLoading(false);
    }
  };

  const energy = getEnergyLevel(positionDetail.artifacts);

  return (
    <tr
      key={index}
      onClick={() => navigate(`/auctions/${item.id}`)}
      style={{ cursor: 'pointer' }}
    >
      <td>
        {item.queueType !== 'general' ? (
          <span style={{ color: getColorByRarity(item.queueType) }}>
            ōLand #{positionDetail.position}
          </span>
        ) : (
          `ōLand #${positionDetail.position}`
        )}
      </td>
      <td>
        {item.queueType !== 'general' &&
        item.status === 'running' &&
        currentUserId === item.user ? (
          <ButtonRedSC
            style={{ height: 25, borderRadius: 8 }}
            disabled={modalLoading}
            onClick={async (e) => {
              e.stopPropagation();
              e.preventDefault();
              handleCancelBtnClick(positionDetail.id);
            }}
          >
            {modalLoading ? (
              <SvgSpinner fill={'white'} style={{ height: 20 }} />
            ) : (
              'Cancel'
            )}
          </ButtonRedSC>
        ) : (
          'N/A'
        )}
      </td>
      <td className="energy">
        <SvgPower
          fill={colorThemes.light.blue.blue100}
          style={{ height: 16, width: 16 }}
        />{' '}
        {energy.toLocaleString()}
      </td>
      <td className="gray">
        {dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}
      </td>
      <td className="price">
        {parseFloat(
          item.winningBid ? item.winningBid.amount : item.startingPrice,
        ).toLocaleString()}{' '}
        ōCash
      </td>
      <td style={{ color: colorThemes.light[item.status] }}>
        {resolveBidStatus(
          currentUserId,
          item.user,
          item.winningBid,
          item.status,
        )}
      </td>
      <td className="gray">{item.id.split('-').pop()}</td>

      <td>
        <button
          onClick={() => navigate(`/auctions/${item.id}`)}
          className="link"
        >
          View
        </button>
      </td>
    </tr>
  );
};

/**
 * Statuses:
 * - no one did bid on your auction -> failed CH
 * - you won your own auction -> won CH
 * - you won not your own -> won CH
 * - someone else won yor auction -> complete
 * - you did bid, but someone else won, not your auction -> failed
 */
const resolveBidStatus = (
  currentUserId: string,
  auctionUserId: string,
  winningBid: any,
  status: string,
) => {
  if (status === 'running') {
    return 'Running';
  }

  if (status === 'pending') {
    if (currentUserId === auctionUserId) {
      return 'Pending (Sell)';
    } else {
      return 'Pending (Buy)';
    }
  }

  // there are no bids
  if (!winningBid || status === 'failed') {
    return 'Not Sold';
  }

  // lets resolve if auctions is your own
  const yourAuction = currentUserId === auctionUserId;

  // lets work with IDX
  const winningUser = winningBid.user;
  const youWon = winningUser === currentUserId;

  if (youWon) {
    return 'Won';
  } else if (yourAuction && !youWon) {
    return 'Sold';
  }
  // not your action and not won
  return 'Lost';
};

const statusColor = {
  succeeded: colorThemes.light.green,
  failed: colorThemes.light.red,
  complete: colorThemes.light.blue.blue100,
  pending: colorThemes.light.neutral.neutral6,
};

const resolveClaimBtn = (item: any, currentUserId: string) => {
  // no bids meaning it did fail, there is nothing to claim
  if (!item.winningBid) {
    return false;
  }

  const artifactUnassigned = item.artifact.position === null;
  const winningUser = item.winningBid.user === currentUserId;

  return winningUser && artifactUnassigned;
};

const AuctionsTable: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { setArtifactPlacement } = userInterface;
  const dispatch = useDispatch();

  const currentUserId = useSelector<TRootState>(
    (store: TRootState) => store.auth.user?.id,
  ) as string;

  const { isLoading, data } = useQuery(
    getRQKAuctionsKey('getUserAuctions'),
    async () => (await api.getUserAuctions()).data.data,
  );

  const handleClaimBtnClick = (auctionId: string) => {
    dispatch(setArtifactPlacement({ artifactPlacementContent: { auctionId } }));
  };

  if (isLoading) return null;

  if (!data.length)
    return (
      <div className="no-items">
        You have not created or bid on any auctions yet.
      </div>
    );

  return (
    <div className="table-container">
      <AuctionsTableSC>
        <tr>
          {tableColumns.map((item, index) => (
            <th key={index}>{item}</th>
          ))}
        </tr>
        {data.map((item, index) => {
          if (item.type === 'land')
            return (
              <LandRow
                item={item}
                currentUserId={currentUserId}
                index={index}
                resolveBidStatus={resolveBidStatus}
              />
            );

          return (
            <tr
              key={index}
              onClick={() => navigate(`/auctions/${item.id}`)}
              style={{ cursor: 'pointer' }}
            >
              <td>{getHumanReadable(item.artifact.artifactId)}</td>
              <td>
                {resolveClaimBtn(item, currentUserId) ? (
                  <ButtonPrimarySC
                    style={{
                      height: 25,
                    }}
                    className="bounce"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleClaimBtnClick(item.id);
                    }}
                  >
                    Claim
                  </ButtonPrimarySC>
                ) : (
                  'N/A'
                )}
              </td>
              <td className="energy">
                <SvgPower
                  fill={colorThemes.light.blue.blue100}
                  style={{ height: 16, width: 16 }}
                />{' '}
                {findEnergyLevel(item.artifact.artifactId).toLocaleString()}
              </td>
              <td className="gray">
                {dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}
              </td>
              <td className="price">
                {parseFloat(
                  item.winningBid ? item.winningBid.amount : item.startingPrice,
                ).toLocaleString()}{' '}
                ōCash
              </td>
              <td style={{ color: statusColor[item.status] }}>
                {resolveBidStatus(
                  currentUserId,
                  item.user,
                  item.winningBid,
                  item.status,
                )}
              </td>
              <td className="gray">{item.id.split('-').pop()}</td>

              <td>
                <button
                  onClick={() => navigate(`/auctions/${item.id}`)}
                  className="link"
                >
                  View
                </button>
              </td>
            </tr>
          );
        })}
      </AuctionsTableSC>
    </div>
  );
};

export default AuctionsTable;
