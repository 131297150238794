import { ButtonOutlineSC } from '@components/Button.styled';
import { TNullableString } from '@custom-types/common';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import React from 'react';

type TLocationBtnProps = {
  activeLat: TNullableString;
  activeLng: TNullableString;
  unrevealed: boolean;
};

const LocationBtn: React.FunctionComponent<TLocationBtnProps> = ({
  activeLat,
  activeLng,
  unrevealed,
}) => {
  return (
    <Tippy
      {...defaultTippyProps}
      content={`Each ōLand covers 1.67 acres in the real world. Click to view yours.`}
    >
      <a
        href={
          !unrevealed
            ? `https://gps-coordinates.org/my-location.php?lat=${activeLat}&lng=${activeLng}`
            : ``
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <ButtonOutlineSC className="smaller dark" style={{ fontWeight: 700 }}>
          LOCATION
        </ButtonOutlineSC>
      </a>
    </Tippy>
  );
};

export default LocationBtn;
