import { ButtonPrimarySC } from '@components/Button.styled';
import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

const Bidding: FC = () => {
  return (
    <div className="bidding">
      <div className="form">
        <div className="price">
          <h3>Current bid</h3>
          <div>
            <Skeleton count={1} height={24} />
            <Skeleton count={1} height={16} />
          </div>
        </div>
        <div className="listing-price">
          <h3>Enter bid</h3>
          <input
            type="number"
            // value={listingPrice}
            placeholder="Enter bid amount (ōCash)"
            // onChange={(e) => setListingPrice(Number(e.target.value))}
          />
        </div>
      </div>
      <div className="confirm">
        <ButtonPrimarySC disabled>Confirm bid</ButtonPrimarySC>
      </div>
    </div>
  );
};

export default Bidding;
