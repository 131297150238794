import React, { useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactCardFlip from 'react-card-flip';
import { toast } from 'react-toastify';

import CardFront from './CardFront';
import Toast from '@components/Toast';

import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import noUltimateSfx from '@assets/sfx/keys/no-ultimate.mp3';
import fanfareSfx from '@assets/sfx/fanfare.mp3';

import { CardSC } from '../CardFlipReveal.styled';
import { TRootState } from '@store/index';
import chestSlice from '@store/chest';

const noUltimateSound = new Audio(noUltimateSfx);
const fanfareSound = new Audio(fanfareSfx);

type TUltimateRollCard = {
  color?: string;
  firstBadge?: string;
  secondBadge?: string;
  cardIndex: number;
};

const UltimateRollCard: FC<TUltimateRollCard> = ({
  color,
  firstBadge,
  secondBadge,
  cardIndex,
}) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);

  const { ultimatePrize, matchedPrize } = useSelector(
    (state: TRootState) => state.chest,
  );

  const { setActiveFlipCardIndex, setPrizeChecked } = chestSlice;

  const handleUltimateClick = () => {
    dispatch(setPrizeChecked({ prizeChecked: true }));
    dispatch(setActiveFlipCardIndex({ activeFlipCardIndex: cardIndex }));

    // Ultimate Prize is USER_REWARD
    setChecked(true);

    (async () => {
      if (ultimatePrize) {
        await fanfareSound.play();
      } else {
        await noUltimateSound.play();
        toast(
          <Toast
            type="info"
            header="Better Luck Next Time!"
            body="Keep going, your next chest could be the one!"
          />,
        );
      }
    })();
  };

  return (
    <ReactCardFlip
      isFlipped={!checked}
      flipDirection="horizontal"
      flipSpeedBackToFront={0.6}
      flipSpeedFrontToBack={0.6}
    >
      <CardFront
        className={`card card-front ultimate`}
        key="front"
        handleClick={() =>
          dispatch(setActiveFlipCardIndex({ activeFlipCardIndex: cardIndex }))
        }
        image={matchedPrize ? matchedPrize.image : false}
        isLand={false}
        color={color}
        firstBadge={firstBadge}
        secondBadge={secondBadge}
      />
      <CardSC
        className={`card card-back ultimate`}
        key="back"
        onClick={handleUltimateClick}
      >
        <div className="question-box">
          Super
          <br />
          Prize
          <br />
          Roll
        </div>
      </CardSC>
    </ReactCardFlip>
  );
};

export default UltimateRollCard;
