import Wrapper from '@components/Wrapper';
import styled from 'styled-components';
import meshGradient from '@assets/png/mesh-gradient.png';

export const AuctionSharingSC = styled(Wrapper)`
  min-height: 100vh;
  border-radius: 15px;
  overflow: auto;
  box-shadow: rgb(0 0 0 / 16%) 2px 4px 16px;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.background.inverted.bg1};
  .content-wrapper {
    max-width: 600px;
    padding-top: 20px;
    margin: auto;
  }

  .gain {
    margin: 24px 0px;
  }

  .header-table {
    width: 100%;
    margin: 24px 0px;
    th {
      //   width: 30%;
      text-align: center;
      padding: 12px 0px;
      :not(:last-child) {
        border-right: 1.5px solid ${({ theme }) => theme.neutral.neutral4};
      }
    }
  }

  .wrapper {
    background: ${({ theme }) => theme.background.inverted.bg2};
    padding: 24px;
    border: 1.5px solid ${({ theme }) => theme.neutral.neutral4};
    border-radius: 10px;
  }

  .auction-details {
    width: 100%;

    table {
      width: 100%;

      td {
        h1 {
          font-weight: 500;
        }
      }

      td:first-child {
        h1 {
          color: ${({ theme }) => theme.neutral.neutral6};
        }
      }

      td:last-child {
        text-align: right;
      }
    }

    span {
      font-size: 12px;
    }
  }

  .item-details {
    margin-top: 40px;
    display: flex;
    gap: 24px;
    align-items: center;
    padding: 16px;
    border-radius: 10px;
    width: 100%;

    border: 1.5px solid ${({ theme }) => theme.blue.blue100};
    background-color: ${({ theme }) => theme.blue.blue10};

    .img-container {
      padding: 8px;
      border-radius: 5px;
      border: 1.5px solid ${({ theme }) => theme.neutral.neutral6};
      background-image: url(${meshGradient});
      background-position: center;
      background-size: cover;
    }

    img,
    video {
      max-width: 150px;

      @media only screen and (max-width: 1024px) {
        max-width: 75px;
      }
    }
  }
`;
