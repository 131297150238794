import React from 'react';
import { CheckboxSC } from './Checkbox.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export type TCheckboxProps = {
  checked: boolean;
  onClick?: () => void;
  disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Checkbox: React.FunctionComponent<TCheckboxProps> = (
  props: TCheckboxProps,
) => (
  <CheckboxSC
    checked={props.checked}
    onClick={props.onClick}
    disabled={props.disabled}
  >
    <FontAwesomeIcon
      style={{
        color: 'white',
        textAlign: 'center',
        alignSelf: 'center',
        display: props.checked ? '' : 'none',
      }}
      icon={faCheck}
    />
  </CheckboxSC>
);

export default Checkbox;
