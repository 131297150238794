import { ButtonOutlineSC } from '@components/Button.styled';
import Toast from '@components/Toast';
import { getRQKProfileKey, queryClient } from '@utils/rq-utils';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import api from '@utils/api';

const CustomerIoButton: React.FunctionComponent = () => {
  const [isE2eBtnLoading, setIsE2eBtnLoading] = useState(false);

  const [customerIoBtnState, setCustomerIoBtnState] = useState<
    'default' | 'subscribe'
  >('default');

  const handleBtnClick = useCallback(async () => {
    if (customerIoBtnState === 'subscribe') {
      window.open(
        'https://overliner.typeform.com/to/EILEuE7D#source=olandportal',
        '_blank',
      );
      setCustomerIoBtnState('default');
    } else {
      try {
        setIsE2eBtnLoading(true);
        const result = (await api.checkEmailToEarn()).data;
        if (result.data.newDeliveries > 0) {
          toast(
            <Toast
              type="success"
              header="Email Rewards Earned!"
              body={`Congratulations! You’ve been awarded ${result.data.increase} ōCash for reading our emails. Keep it up!`}
            />,
          );
          queryClient.invalidateQueries(getRQKProfileKey('getOCashAmount'));
        } else {
          toast(
            <Toast
              type="success"
              header="No Email Rewards Available"
              body={`Keep checking your inbox for messages from hello@overline.network to receive new rewards!`}
            />,
          );
        }
      } catch (err: any) {
        if (err && err.data.errNo === 1200) {
          toast(
            <Toast
              type="error"
              header="Email Rewards Inactive"
              body={`Sorry, your email address is not subscribed. Click the “Subscribe” button to be eligible for Email to Earn Rewards.`}
            />,
          );
          setCustomerIoBtnState('subscribe');
        } else {
          toast(
            <Toast
              type="error"
              header="Unexpected error"
              body={`Something went wrong. Please try again later.`}
            />,
          );
        }
      } finally {
        setIsE2eBtnLoading(false);
      }
    }
  }, []);

  return (
    <ButtonOutlineSC
      disabled={isE2eBtnLoading}
      className={
        customerIoBtnState === 'subscribe' ? 'flashing e2earn' : 'e2earn'
      }
      onClick={handleBtnClick}
    >
      {isE2eBtnLoading ? (
        <SvgSpinner
          height={30}
          width={30}
          style={{ display: 'inline', fill: 'rgba(32,129,226,1)' }}
        />
      ) : customerIoBtnState === 'default' ? (
        'Email to Earn'
      ) : (
        'Subscribe'
      )}
    </ButtonOutlineSC>
  );
};

export default CustomerIoButton;
