import React from 'react';
import { useSelector } from 'react-redux';
import numeral from 'numeral';
import glowDevice from '@assets/png/deviceglow.png';
import { TRootState } from '@store/index';
import Text, { TextVariant } from '@components/Text';
import { USD_NUMERAL_FMT } from '@utils/common';
import { colorThemes } from '@assets/styles';

type TCartSubTotal = {
  type: string;
} & React.HTMLAttributes<HTMLDivElement>;

type TRow = {
  left: string;
  right: string;
  strong?: boolean;
  strike?: boolean;
  gray?: boolean;
};

const Row: React.FunctionComponent<TRow> = ({
  left,
  right,
  strong = false,
  strike,
  gray,
}) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Text
        style={{
          textAlign: 'left',
          display: 'inline',
          fontWeight: strong ? 900 : 400,
        }}
        variant={TextVariant.CardText}
        className={gray ? 'gray' : ''}
      >
        {left}
      </Text>
      <Text
        style={{
          textAlign: 'right',
          display: 'inline',
          fontWeight: strong ? 900 : 400,
          textDecoration: strike ? 'line-through' : 'none',
        }}
        variant={TextVariant.CardText}
        className={gray ? 'gray' : ''}
      >
        {right}
      </Text>
    </div>
  );
};

const CartSubtotal: React.FunctionComponent<TCartSubTotal> = ({
  type,
  ...divProps
}) => {
  const order = useSelector((store: TRootState) => store.order);

  const data: React.ReactNode[] = [];

  if (type === 'init') {
    data.push(<Row left="ōRouter Quantity" right={`${order.productCount}`} />);
    data.push(
      <Row
        left="ōRouter Price"
        right={`${numeral(order.productPrice).format(USD_NUMERAL_FMT)}`}
      />,
    );
    data.push(
      <Row
        strong={true}
        left="Total Preorder Deposit (Pay Today)"
        right={`${numeral(
          order.productPreReleasePrice * order.productCount,
        ).format(USD_NUMERAL_FMT)}`}
      />,
    );
    data.push(
      <Row
        strong={true}
        left="Remainder (Pay Later)"
        right={`${numeral(
          order.productPrice * order.productCount -
            order.productPreReleasePrice * order.productCount,
        ).format(USD_NUMERAL_FMT)}`}
      />,
    );
  } else if (type === 'discount') {
    data.push(
      <Row
        left="Total Discount"
        right={`${numeral(order.discount * order.productCount).format(
          USD_NUMERAL_FMT,
        )}`}
      />,
    );
    data.push(
      <Row
        strike={true}
        left="Price"
        right={`${numeral(order.productPrice).format(USD_NUMERAL_FMT)}`}
      />,
    );
    data.push(
      <Row
        left=""
        right={`${numeral(order.productPrice - order.discount).format(
          USD_NUMERAL_FMT,
        )}`}
      />,
    );
    data.push(
      <Row
        strong={true}
        left="Total Preorder Deposit (Pay Today)"
        right={`${numeral(
          order.productPreReleasePrice * order.productCount,
        ).format(USD_NUMERAL_FMT)}`}
      />,
    );
    data.push(
      <Row
        strong={true}
        left="Remainder (Pay Later)"
        right={`${numeral(
          (order.productPrice - order.discount - order.productPreReleasePrice) *
            order.productCount,
        ).format(USD_NUMERAL_FMT)}`}
      />,
    );
  } else if (type === 'keys') {
    data.push(
      <Row
        left="Price"
        right={`${numeral(order.productPrice).format(USD_NUMERAL_FMT)}`}
      />,
    );
  } else {
    data.push(
      <>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', gap: 24 }}>
            <div className="glow-device-container">
              <img src={glowDevice} alt="device image" />

              <span
                style={{
                  fontWeight: 400,
                  lineHeight: '1.75em',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                  borderRadius: '1.75em',
                  backgroundColor: colorThemes.light.blue.blue100,
                  color: 'white',
                  WebkitBoxSizing: 'border-box',
                  boxSizing: 'border-box',
                  minWidth: '1.75em',
                  padding: '0 0.58333em',
                  position: 'absolute',
                  right: '-0.75em',
                  top: '-0.75em',
                  zIndex: 3,
                }}
              >
                {order.productCount}
              </span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text
                style={{
                  textAlign: 'left',
                  display: 'inline',
                  fontWeight: 700,
                }}
                variant={TextVariant.CardText}
              >
                ōRouter (Preorder)
              </Text>
              <Text
                style={{
                  textAlign: 'left',
                  display: 'inline',
                }}
                variant={TextVariant.CardText}
              >
                33% Deposit
              </Text>
            </div>
          </div>

          <div style={{ alignSelf: 'center' }}>
            <Text
              style={{
                textAlign: 'left',
                display: 'inline',
                fontWeight: 900,
              }}
              variant={TextVariant.CardText}
            >
              {numeral(order.productPreReleasePrice).format(USD_NUMERAL_FMT)}
            </Text>
          </div>
          {/* <Row
        left={`${order.productCount}x ōRouter (Preorder)`}
        right={numeral(
          order.productCount * order.productPrice - order.discount,
        ).format(USD_NUMERAL_FMT)}
      />, */}
        </div>

        <hr />
      </>,
    );

    data.push(
      <Row
        left="Preorder Deposit Subtotal"
        right={numeral(
          order.productPreReleasePrice * order.productCount,
          //order.productCount * order.productPrice - order.discount,
        ).format(USD_NUMERAL_FMT)}
      />,
    );
    data.push(
      <Row
        left="Shipping"
        right="Charged Later"
        // right={numeral(order.shipping).format(USD_NUMERAL_FMT)}
      />,
    );
    // data.push(
    //   <Row left="Tax" right={numeral(order.tax).format(USD_NUMERAL_FMT)} />,
    // );
    data.push(
      <Row
        strong
        left="Total Due Now"
        right={numeral(
          order.productPreReleasePrice * order.productCount,
        ).format(USD_NUMERAL_FMT)}
      />,
    );
  }

  return (
    <div {...divProps}>
      <hr />
      <div
        style={{
          paddingTop: 12,
        }}
      >
        {data.map((el) => el)}
      </div>
    </div>
  );
};

export default CartSubtotal;
