import styled from 'styled-components';

const PageWrapper = styled.div`
  background: ${({ theme }) => theme.background.inverted.bg1};
  padding-top: 50px;
  width: 100%;
  padding-bottom: 50px;
  height: 100%;
`;

export default PageWrapper;

export const OrderContainer = styled.div`
  background-color: ${({ theme }) => theme.background.inverted.bg2};
  border-radius: 30px;
  padding: 40px;
  box-shadow: rgb(0 0 0 / 16%) 2px 4px 16px;
  max-width: 550px;
  margin: auto;
  margin-top: 40px;

  #order-details-wrapper {
    background-color: ${({ theme }) => theme.background.inverted.bg1};
    border-radius: 18px;
    padding: 24px;
    margin-top: 24px;
  }
`;
