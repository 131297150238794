import { ButtonOutlineSC } from '@components/Button.styled';
import Text, { TextVariant } from '@components/Text';
import api from '@utils/api';
import {
  getColorByRarity,
  getHumanReadable,
  getRarityName,
} from '@utils/common';
import { getRQKAuctionsKey, getRQKOCashKey } from '@utils/rq-utils';
import { allRewards } from 'data/gifts';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { AuctionSharingSC } from './AuctionSharing.styled';
import { ReactComponent as SvgPower } from '@assets/svg/icons/power.svg';
import { colorThemes } from '@assets/styles';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import { ReactComponent as SvgChevron } from '@assets/svg/chevron-down.svg';
import olandSqVideo from '@assets/animations/oland-sq.mp4';

const AuctionSharing: React.FunctionComponent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const handleGoBack = async () => {
    navigate('/auctions');
  };

  const { isLoading: isAuctionsLoading, data: auctionData } = useQuery(
    getRQKAuctionsKey('getAuction', id),
    async () => {
      const result = (await api.getAuction(id as string)).data.data;
      return result;
    },
  );

  const { isLoading: isOcashPriceLoading, data: oCashPrice } = useQuery(
    getRQKOCashKey('getOCashPrice'),
    () => {
      if (isOcashPriceLoading) {
        return (
          <SvgSpinner
            height={30}
            width={30}
            style={{ display: 'inline', fill: 'black' }}
          />
        );
      } else return oCashPrice;
    },
  );

  if (isAuctionsLoading) {
    return null;
  }

  const isLand = auctionData.type === 'land';

  const artifactObject = allRewards.filter((obj) => {
    if (isAuctionsLoading || isLand) return null;
    if (obj.id === auctionData.artifact.artifactId) {
      return obj;
    }
  });

  const winningBid = auctionData.winningBidAmount;

  return (
    <AuctionSharingSC>
      <div className="content-wrapper">
        <ButtonOutlineSC
          onClick={handleGoBack}
          style={{ maxWidth: 150, marginBottom: 20 }}
        >
          <SvgChevron
            style={{
              width: 10,
              fill: colorThemes.light.blue.blue100,
              transform: 'rotate(90deg)',
              marginRight: '0.5rem',
            }}
          />{' '}
          Back to ōLand
        </ButtonOutlineSC>
        <div className="wrapper">
          <div>
            <Text variant={TextVariant.Subtitle} style={{ fontWeight: 600 }}>
              ōLand Auction
            </Text>
            <Text variant={TextVariant.CardText} className="gray">
              ID: {auctionData.id}
            </Text>
          </div>
          <div className="details-wrapper">
            <div className="gain">
              <Text
                variant={TextVariant.Title}
                className={!winningBid ? 'gray' : 'green'}
              >
                {!winningBid ? (
                  'No Bids'
                ) : (
                  <>
                    {/* TODO: Make sure this is always winning bid */}+
                    {(
                      (winningBid / auctionData.startingPrice) * 100 -
                      100
                    ).toFixed(2)}
                    %
                  </>
                )}
              </Text>
            </div>
            <div className="auction-details">
              <table>
                <tr>
                  <td>
                    <Text variant={TextVariant.CardSubtitle}>Status:</Text>
                  </td>
                  <td>
                    <Text variant={TextVariant.CardSubtitle}>
                      {auctionData.status === 'succeeded'
                        ? 'Successful'
                        : getHumanReadable(auctionData.status)}
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Text variant={TextVariant.CardSubtitle}>
                      Listing Price:
                    </Text>
                  </td>
                  <td>
                    <Text variant={TextVariant.CardSubtitle} className="blue">
                      {auctionData.startingPrice.toLocaleString()} ōCash{' '}
                      <span className="gray">
                        (~${' '}
                        {(
                          auctionData.startingPrice * oCashPrice
                        ).toLocaleString('en-US')}{' '}
                        USD)
                      </span>
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Text variant={TextVariant.CardSubtitle}>
                      Closing Price:
                    </Text>
                  </td>
                  <td>
                    <Text variant={TextVariant.CardSubtitle} className="blue">
                      {!winningBid ? (
                        'No Bids'
                      ) : (
                        <>
                          {winningBid.toLocaleString()} ōCash{' '}
                          <span className="gray">
                            (~$ {(winningBid * oCashPrice).toLocaleString()}{' '}
                            USD)
                          </span>
                        </>
                      )}
                    </Text>
                  </td>
                </tr>
              </table>
            </div>
            <div className="item-details">
              <div className="img-container">
                {isLand ? (
                  <video
                    width="auto"
                    height="auto"
                    autoPlay={true}
                    loop={true}
                    playsInline={true}
                    preload={'auto'}
                    muted
                  >
                    <source src={olandSqVideo} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    src={artifactObject[0].img}
                    alt={getHumanReadable(auctionData.artifact.artifactId)}
                  />
                )}
              </div>

              <div>
                <Text
                  variant={TextVariant.CardSubtitle}
                  style={{ fontWeight: 600 }}
                >
                  {isLand
                    ? 'ōLand'
                    : getHumanReadable(auctionData.artifact.artifactId)}
                </Text>
                {isLand && (
                  <Text
                    variant={TextVariant.CardSubtitle}
                    style={{
                      fontWeight: 600,
                      color: auctionData.queueType
                        ? getColorByRarity(auctionData.queueType)
                        : '',
                    }}
                  >
                    {auctionData.queueType
                      ? `${getRarityName(auctionData.queueType)} Boosted`
                      : 'Common'}
                  </Text>
                )}
                {!isLand && (
                  <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <SvgPower
                        fill={colorThemes.light.blue.blue100}
                        style={{ height: 18, width: 18 }}
                      />{' '}
                      <Text
                        variant={TextVariant.CardSubtitle}
                        style={{ fontWeight: 600, marginBottom: 0 }}
                        className="blue"
                      >
                        {artifactObject[0].energy}
                      </Text>
                    </div>
                    <Text variant={TextVariant.CardSubtitle} className="gray">
                      {getHumanReadable(artifactObject[0].purpose as string)}
                    </Text>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuctionSharingSC>
  );
};

export default AuctionSharing;
