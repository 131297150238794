import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import Toast from '@components/Toast';
import Text, { TextVariant } from '@components/Text';
import userInterfaceStore from '@store/user-interface';
import api from '@utils/api';
import { getRQKPositionsKey } from '@utils/rq-utils';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import { StyledTrayFrame } from './StyledTrayFrame.styled';

export type TTreatReveal = {
  id: string;
};

const ToBeRevealedPosition: React.FC<TTreatReveal> = ({ id }) => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const { setRevealProcessResult } = userInterfaceStore;

  const [isLoading, setIsLoading] = useState(false);

  const handleRevealUpgrades = async () => {
    setIsLoading(true);
    try {
      const result = await api.revealUpgrades(id);

      dispatch(
        setRevealProcessResult({
          revealProcess: {
            id,
            result: result.data.data.artifact,
          },
        }),
      );

      await queryClient.invalidateQueries(
        getRQKPositionsKey('getPosition', id),
      );
      toast(
        <Toast
          type="success"
          header="Success!"
          body="ōLand upgrades complete!"
        />,
      );
    } catch (e) {
      toast(
        <Toast
          type="error"
          header="Something went wrong"
          body="Reveal unsuccessful. Please try again."
        />,
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      style={{
        background: 'none',
        border: 'none',
        width: '100%',
        height: '100%',
      }}
      disabled={isLoading}
      onClick={handleRevealUpgrades}
    >
      <StyledTrayFrame activePlot={false} className="treat-reveal-card shiny">
        <div className="treat-reveal-sheen"></div>
        <div className="treat-reveal-text-box">
          {!isLoading && (
            <Text
              className="plot-label color"
              variant={TextVariant.CardText}
              style={{ fontWeight: 600, textAlign: 'center', margin: 0 }}
            >
              Click to reveal
            </Text>
          )}
          {isLoading && (
            <SvgSpinner
              height={30}
              width={30}
              style={{ display: 'inline', fill: 'black' }}
            />
          )}
        </div>
      </StyledTrayFrame>
    </button>
  );
};

//

const memoizedToBeRevealed = React.memo(ToBeRevealedPosition);

export default memoizedToBeRevealed;
