import React, { FC } from 'react';
import { CardSC } from '../CardFlipReveal.styled';
import cardFlipSfx from '@assets/sfx/keys/flip-card.mp3';
import flipSuccessSfx from '@assets/sfx/chime.mp3';
const flipSuccessSound = new Audio(flipSuccessSfx);
const cardFlipSound = new Audio(cardFlipSfx);

type TCardBack = {
  className?: string;
  handleClick: () => void;
};

const CardBack: FC<TCardBack> = ({ className, handleClick }) => {
  return (
    <CardSC
      className={className}
      onClick={() => {
        cardFlipSound.play();
        handleClick();
        flipSuccessSound.play();
      }}
    >
      <div className="question-box">?</div>
    </CardSC>
  );
};

export default CardBack;
