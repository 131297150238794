/**
 * A way to have uniform React query keys
 * !!! ALWAYS !!! return content of data. response.data.data <- this
 */
import { TArtifact } from '@data/gifts';
import { TOTreatFlavour } from '@store/user-interface/types';
import { Query, QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: true,
    },
  },
});

type TRQKCoinGecko = 'getEthPrice';

export const getRQKCoinGeckoKey = (key: TRQKCoinGecko) => {
  switch (key) {
    case 'getEthPrice':
      return ['get-eth-price'];
  }
};

type TRQKOfriendBalance = 'getOfriendBalance';

export const getRQKOfriendBalance = (key: TRQKOfriendBalance) => {
  switch (key) {
    case 'getOfriendBalance':
      return ['get-ofriend-balance'];
  }
};

type TRQKOCash = 'getOCashPrice';

export const getRQKOCashKey = (key: TRQKOCash) => {
  switch (key) {
    case 'getOCashPrice':
      return ['get-oCash-price'];
  }
};
// ?? Should be in TRQKAuctions...
type TRQKUsersAuctions = 'getUserRunningAuctions';

export const getRQKUsersAuctionsKey = (key: TRQKUsersAuctions) => {
  switch (key) {
    case 'getUserRunningAuctions':
      return ['get-users-auctions'];
  }
};

type TRQKAuctions =
  | 'getAuctions'
  | 'getAuction'
  | 'getBlockedItems'
  | 'getUserAuctions';

export const getRQKAuctionsKey = (key: TRQKAuctions, id?: string) => {
  switch (key) {
    case 'getAuctions':
      return ['get-auctions'];
    case 'getAuction':
      return ['get-auction', id];
    case 'getBlockedItems':
      return ['get-blocked-items'];
    case 'getUserAuctions':
      return ['get-user-auctions'];
  }
};

type TRQKStatistics = 'getStatistics';

export const getRQKStatisticsKey = (key: TRQKStatistics) => {
  switch (key) {
    case 'getStatistics':
      return ['get-statistics'];
  }
};

type TRQKProducts = 'getProducts';

export const getRQKProductsKey = (key: TRQKProducts) => {
  switch (key) {
    case 'getProducts':
      return ['get-products'];
  }
};

type TRQKProfile = 'getProfile' | 'getOCashAmount';

export const getRQKProfileKey = (key: TRQKProfile) => {
  switch (key) {
    case 'getOCashAmount':
      return ['get-o-cash-amount'];
    case 'getProfile':
      return ['get-profile'];
  }
};

type TRQKUserKeys = 'getUserKeys';

export const getRQKUserKeys = (key: TRQKUserKeys) => {
  switch (key) {
    case 'getUserKeys':
      return ['get-user-keys'];
  }
};

type TRQKUserRewards =
  | 'getUserRewards'
  | 'getFilteredUserRewards'
  | 'getAvailableUserRewards'
  | 'getUltimateRewards';

export const getRQKUserRewardsKey = (
  key: TRQKUserRewards,
  flavour?: TOTreatFlavour,
) => {
  switch (key) {
    case 'getUserRewards':
      return ['get-user-rewards'];
    case 'getFilteredUserRewards':
      return ['get-filtered-user-rewards', flavour];
    case 'getAvailableUserRewards':
      return ['get-available-user-rewards'];
    case 'getUltimateRewards':
      return ['get-ultimate-rewards'];
  }
};

type TRQKPositions =
  | 'getPositions'
  | 'getPosition'
  | 'getPositionCount'
  | 'getAvailabeForPlacement';

export const getRQKPositionsKey = (key: TRQKPositions, id?: string) => {
  switch (key) {
    case 'getPositionCount':
      return ['get-position-count'];
    case 'getPositions':
      return ['get-positions'];
    case 'getAvailabeForPlacement':
      return ['get-available-for-placement'];
    case 'getPosition':
      return ['get-position', id];
  }
};

type TRQKArtifacts = 'getArtifacts';

export const getRQKArtifacts = (key: TRQKArtifacts) => {
  switch (key) {
    case 'getArtifacts':
      return ['get-artifacts'];
  }
};

export const getArtifactAvailableForSell = (
  name: string,
  ownedArtifacts: any[],
): TArtifact | undefined => {
  const matches = ownedArtifacts.filter((e) => {
    const check = e.artifactId ?? e.name;
    return check === name;
  });

  const whitelisted: TArtifact[] = [];

  // only if matched nto in blocked, and get first
  const {
    state: {
      //@ts-expect-error they don't know
      data: { blockedArtifacts },
    },
  } = queryClient
    .getQueryCache()
    .find(getRQKAuctionsKey('getBlockedItems')) as Query;

  for (const match of matches) {
    if (!blockedArtifacts.includes(match.id)) {
      whitelisted.push(match);
    }
  }

  return whitelisted.length >= 1 ? whitelisted[0] : undefined;
};

export const getArtifactQty = (name: string) => {
  const ownedArtifacts = queryClient.getQueryData(
    getRQKArtifacts('getArtifacts'),
  ) as any;
  return ownedArtifacts.filter((e) => e.artifactId === name).length || 0;
};

// TODO: Unify this into one
type TRQKTwitterAuth = 'getTwitterAuth';

export const getRQKTwitterAuth = (key: TRQKTwitterAuth) => {
  switch (key) {
    case 'getTwitterAuth':
      return ['get-twitter-auth'];
  }
};

type TRQKTwitterRewards = 'getTwitterRewards';

export const getRQKTwitterRewards = (key: TRQKTwitterRewards) => {
  switch (key) {
    case 'getTwitterRewards':
      return ['get-twitter-rewards'];
  }
};

type TRQKTwitterLeaderboard = 'getTwitterLeaderboard';

export const getRQKTwitterLeaderboard = (key: TRQKTwitterLeaderboard) => {
  switch (key) {
    case 'getTwitterLeaderboard':
      return ['get-twitter-leaderboard'];
  }
};

type TRQKAmazon = 'getAmazonStatus';

export const getRQKAmazon = (key: TRQKAmazon) => {
  switch (key) {
    case 'getAmazonStatus':
      return ['get-amazon-status'];
  }
};

type TRQKOgemData = 'getOgemData';

export const getRQKOgemData = (key: TRQKOgemData) => {
  switch (key) {
    case 'getOgemData':
      return ['get-ogem-data'];
  }
};

type TRQKOgemLeaderboard = 'getOgemLeaderboard';

export const getRQKOgemLeaderboard = (key: TRQKOgemLeaderboard) => {
  switch (key) {
    case 'getOgemLeaderboard':
      return ['get-ogem-leaderboard'];
  }
};

type TRQKKeyPurchases = 'getKeyPurchases' | 'claimKeyPurchase';

export const getRQKKeyPurchases = (key: TRQKKeyPurchases) => {
  switch (key) {
    case 'getKeyPurchases':
      return ['get-key-purchases'];
    case 'claimKeyPurchase':
      return ['claim-key-purchase'];
  }
};

type TRQKChestCards = 'getChestCards';

export const getRQKChestCards = (key: TRQKChestCards) => {
  switch (key) {
    case 'getChestCards':
      return ['get-chest-cards'];
  }
};

export const getChestCardQty = (card: string) => {
  const chestCards = queryClient.getQueryData(
    getRQKChestCards('getChestCards'),
  ) as any;
  return (
    chestCards.filter(
      (e) => e.meta.rarity + e.meta.trait + e.meta.type === card,
    ).length || 0
  );
};

type TRQKOpenedChests = 'getOpenedChests';

export const getRQKOpenedChests = (key: TRQKOpenedChests) => {
  switch (key) {
    case 'getOpenedChests':
      return ['get-opened-chests'];
  }
};

type TRQKBoosters = 'getBoosters' | 'getBoostersToBeFound';

export const getRQKBoosters = (key: TRQKBoosters) => {
  switch (key) {
    case 'getBoosters':
      return ['get-boosters'];
    case 'getBoostersToBeFound':
      return ['get-boosters-to-be-found'];
  }
};

type TRQKBoosterPhase = 'getBoosterPhase';

export const getRQKBoosterPhase = (key: TRQKBoosterPhase) => {
  switch (key) {
    case 'getBoosterPhase':
      return ['get-booster-phase'];
  }
};
