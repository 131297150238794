import React, { useState, useCallback } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as SvgPower } from '@assets/svg/icons/power.svg';
import { TRootState } from '@store/index';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { defaultModalProps } from '@components/Modal/modal-settings';
import ModalSC from '../Modal.styled';
import { ButtonPrimarySC } from '@components/Button.styled';
import api from '@utils/api';
import { closeModal } from '@store/common';
import {
  getRQKAuctionsKey,
  getRQKCoinGeckoKey,
  getRQKOCashKey,
} from '@utils/rq-utils';
import { getHumanReadable } from '@utils/common';
import { allRewards, findEnergyLevel } from 'data/gifts';
import { InputSC } from '@components/Input.styled';
import { colorThemes } from '@assets/styles';
import { useQueryClient } from 'react-query';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import Toast from '@components/Toast';
import { toast } from 'react-toastify';

const StyledAuctionListingModal = styled.div`
  .upper {
    display: flex;
    justify-content: space-between;

    .left {
      &__wrapper {
        display: flex;

        > img {
          max-width: 140px;
          width: 100%;
          height: auto;

          @media only screen and (max-width: 1024px) {
            max-width: 50px;
            max-height: 50px;
          }
        }

        > div > span {
          margin-left: 16px;
          display: block;
        }
      }
    }

    .right {
      > span {
        display: block;
      }
    }
  }
`;

/**
 * TODO: Min/Max allowd value
 * @constructor
 */
const ListingModal: React.FunctionComponent = () => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [startingBid, setStartingBid] = useState('200');
  const dispatch = useDispatch();
  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const { id, name } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.artifactSelection,
  ) as { id: string; name: string };

  const artifactObject = allRewards.filter((obj) => {
    if (obj.id === name) {
      return obj;
    }
  });

  const artifactName = (artifact) => {
    if (artifact === 'dog') {
      return 'ōFriend';
    }
    if (artifact === 'orouter-upverter') {
      return 'ōRouter Upverter';
    }

    return getHumanReadable(artifact);
  };

  const ethPrice = queryClient.getQueryData(
    getRQKCoinGeckoKey('getEthPrice'),
  ) as { usd: number };

  const oCashPrice = queryClient.getQueryData(
    getRQKOCashKey('getOCashPrice'),
  ) as number;

  const handleSubmitBtnClick = useCallback(async () => {
    setIsLoading(true);
    try {
      await api.createAuction({
        itemId: id,
        itemType: 'artifact',
        startingPrice: parseInt(startingBid, 10),
      });
      await queryClient.invalidateQueries(getRQKAuctionsKey('getAuctions'));
      await queryClient.invalidateQueries(getRQKAuctionsKey('getBlockedItems'));
      closeModal(dispatch);
    } catch (e: any) {
      console.error('error placing auction', e);
      const errMsg = e.data.errMsg.split('');
      errMsg[0] = errMsg[0].toUpperCase();
      toast(
        <Toast
          type="error"
          header="Something went wrong"
          body={e.data.errMsg}
        />,
      );
    } finally {
      setIsLoading(false);
    }
  }, [startingBid]);

  return (
    <ReactModal
      isOpen={type === 'auctionListingModal'}
      {...defaultModalProps}
      onRequestClose={() => closeModal(dispatch)}
    >
      <ModalSC>
        <div className="header">
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            List your artifact
          </Text>
          <button onClick={() => closeModal(dispatch)}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <StyledAuctionListingModal>
          <div className="upper">
            <div className="left">
              <div className="left__wrapper">
                <img src={artifactObject[0].img} alt="cactus" />

                <div
                  style={{
                    marginLeft: 16,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <Text
                      variant={TextVariant.CardText}
                      style={{ fontWeight: 600 }}
                      className="listing"
                    >
                      {artifactName(artifactObject[0].id)}
                    </Text>
                    <Text
                      variant={TextVariant.CardText}
                      style={{ fontWeight: 600 }}
                      className="gray listing"
                    >
                      {getHumanReadable(artifactObject[0].purpose as string)}
                    </Text>
                    <div style={{ display: 'flex' }}>
                      <SvgPower
                        fill={colorThemes.light.blue.blue100}
                        style={{
                          height: '1rem',
                          width: '1rem',
                          marginTop: 2,
                        }}
                      />
                      <Text
                        variant={TextVariant.CardText}
                        style={{ fontWeight: 600 }}
                        className="blue listing"
                      >
                        {findEnergyLevel(name)}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="right"
              style={{ display: 'flex', alignItems: 'center', maxWidth: 150 }}
            >
              <div>
                <InputSC
                  value={startingBid}
                  onChange={(e) => setStartingBid(e.currentTarget.value)}
                  maxLength={11}
                />
                <Text
                  variant={TextVariant.CardText}
                  className="gray"
                  style={{ marginTop: 8 }}
                >
                  ~(
                  {isNaN(parseFloat(startingBid))
                    ? '0'
                    : (
                        (parseFloat(startingBid) * oCashPrice) /
                        ethPrice.usd
                      ).toFixed(4)}{' '}
                  ETH)
                </Text>
              </div>
            </div>
          </div>
          <hr />
          <Text variant={TextVariant.CardSubtitle} style={{ fontWeight: 600 }}>
            Confirm listing
          </Text>
          <Text
            variant={TextVariant.CardText}
            style={{ fontWeight: 400, marginBottom: 16 }}
            className="gray"
          >
            Each listing deducts a{' '}
            <span style={{ fontWeight: 600, textDecoration: 'underline' }}>
              non-refundable
            </span>{' '}
            fee of <span className="blue">2 ōCash</span>
          </Text>
          <Text
            variant={TextVariant.CardText}
            style={{ fontWeight: 400, marginBottom: 16 }}
            className="gray"
          >
            Once listed your auction cannot be cancelled. Do you wish to
            proceed?
          </Text>

          {/* Input goes here */}

          <ButtonPrimarySC
            disabled={
              isLoading || parseInt(startingBid) === 0 || startingBid === ''
            }
            onClick={handleSubmitBtnClick}
          >
            {isLoading ? (
              <SvgSpinner width={24} height={24} fill="white" />
            ) : parseInt(startingBid) === 0 || startingBid === '' ? (
              'Increase listing price'
            ) : (
              'Create Listing'
            )}
          </ButtonPrimarySC>
        </StyledAuctionListingModal>
      </ModalSC>
    </ReactModal>
  );
};

export default ListingModal;
