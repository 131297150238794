import styled from 'styled-components';
import Text, { TextVariant } from '@components/Text';
import React from 'react';
import Lottie from 'lottie-react';
import loaderAnim from '@assets/animations/loader.json';

const StyledChoiceBox = styled.button`
  background-color: ${({ theme }) => theme.background.regular.bg1};
  border-width: 3px;
  border-style: solid;
  border-color: rgba(127, 143, 169, 0.3);
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 20px;
  width: 100%;
  text-align: left;
  outline: none;

  &:focus-visible {
    outline: none;
  }
  &:focus {
    outline: none;
  }

  &:hover {
    border: double 3px transparent;
    /* first gradient is for card background, second for border background */
    background-image: linear-gradient(
        ${({ theme }) => theme.background.regular.bg2},
        ${({ theme }) => theme.background.regular.bg2}
      ),
      linear-gradient(
        to right,
        #f8ab5e 0,
        #f36961 20%,
        #a176c8 40%,
        #759beb 60%,
        #65beb3 80%,
        #70db96 100%
      );
    background-clip: padding-box, border-box;
    background-origin: border-box;
  }

  &.active {
    border: double 3px transparent;
    /* first gradient is for card background, second for border background */
    background-image: linear-gradient(white, white),
      linear-gradient(
        to right,
        #f8ab5e 0,
        #f36961 20%,
        #a176c8 40%,
        #759beb 60%,
        #70db96 100%
      );
    background-clip: padding-box, border-box;
    background-origin: border-box;
  }
`;

const texting = {
  wert: {
    title: 'Credit or Debit Card',
    description: 'Visa, Mastercard, AMEX, Discovery, UnionPay',
  },
  fiat: {
    title: 'Credit or Debit Card',
    description: 'Visa, Mastercard, AMEX, Discovery, UnionPay',
  },
  crypto: {
    title: 'Cryptocurrency',
    description: 'Bitcoin, Ethereum, Stablecoins & more',
  },
  'cs-crypto-gw_v1': {
    title: 'Ethereum',
    description: 'Direct ETH payment (Recommended)',
  },
};

type TPaymentChoiceBox = {
  method: 'crypto' | 'fiat' | 'cs-crypto-gw_v1' | 'wert';
  unavailable?: boolean;
  loading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const PaymentChoiceBox: React.FunctionComponent<TPaymentChoiceBox> = ({
  method,
  unavailable = false,
  loading = false,
  ...btnProps
}) => {
  if (loading) {
    return (
      <StyledChoiceBox {...btnProps}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Lottie
            animationData={loaderAnim}
            loop={true}
            autoPlay={true}
            style={{
              width: 64,
              height: 64,
            }}
          />
        </div>
      </StyledChoiceBox>
    );
  }

  return (
    <StyledChoiceBox
      {...btnProps}
      style={loading ? { padding: '9px 0 9px 0' } : undefined}
    >
      <Text
        style={unavailable ? { textDecoration: 'line-through' } : undefined}
        variant={TextVariant.CardText}
      >
        {texting[method].title}
      </Text>
      <Text className="gray" variant={TextVariant.CardText}>
        {unavailable
          ? ' This option is temporarily unavailable'
          : texting[method].description}
      </Text>
    </StyledChoiceBox>
  );
};

export default PaymentChoiceBox;
