import React, { FC, useState, useEffect } from 'react';
import V2AuctionCard from './V2AuctionCard';
import { useQuery } from 'react-query';
import { getRQKPositionsKey } from '@utils/rq-utils';
import api from '@utils/api';
import LoadingStatePosition from '@pages/Home/components/PositionCard/LoadingStatePosition';

type TAuctionCardProps = {
  auction: any;
};

const CardContainer: FC<TAuctionCardProps> = ({ auction }) => {
  const { isLoading: positionDetailIsLoading, data: positionDetail } = useQuery(
    getRQKPositionsKey('getPosition', auction.position.id),
    async () => {
      const result = (await api.getPositionDetail(auction.position.id)).data;
      return result.data;
    },
  );

  if (positionDetailIsLoading) return <LoadingStatePosition />;
  return (
    <V2AuctionCard
      key={auction.id}
      auctionData={auction}
      positionDetail={positionDetail}
    />
  );
};

type TAuctionCardsProps = {
  auctionData: any[];
};

const AuctionCards: FC<TAuctionCardsProps> = ({ auctionData }) => {
  const [sortOption, setSortOption] = useState('endDateAsc');
  const [sortedAuctionData, setSortedAuctionData] = useState(auctionData);

  const rarityRank = {
    Rare: 1,
    'Super-Rare': 2,
    'Ultra-Rare': 3,
  };

  useEffect(() => {
    const sortedData = [...auctionData].sort((a, b) => {
      switch (sortOption) {
        case 'endDateAsc':
          return (
            new Date(a.auctionEndAt).getTime() -
            new Date(b.auctionEndAt).getTime()
          );
        case 'buyNowAsc':
          return (a.buyNow || Infinity) - (b.buyNow || Infinity);
        case 'buyNowDesc':
          return (b.buyNow || -Infinity) - (a.buyNow || -Infinity);
        case 'rarityAsc':
          return (
            rarityRank[a.position.rarityMeta.rank] -
            rarityRank[b.position.rarityMeta.rank]
          );
        case 'rarityDesc':
          return (
            rarityRank[b.position.rarityMeta.rank] -
            rarityRank[a.position.rarityMeta.rank]
          );
        default:
          return 0;
      }
    });
    setSortedAuctionData(sortedData);
  }, [auctionData, sortOption]);

  return (
    <>
      <div className="sort-select">
        <label htmlFor="sortOption">Sort By</label>
        <select
          id="sortOption"
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
        >
          <option value="endDateAsc">End Date</option>
          <option value="buyNowAsc">Buy Now Price (Ascending)</option>
          <option value="buyNowDesc">Buy Now Price (Descending)</option>
          <option value="rarityAsc">Rarity Rank (Ascending)</option>
          <option value="rarityDesc">Rarity Rank (Descending)</option>
        </select>
      </div>
      <div className="auction-cards">
        {sortedAuctionData.map((auction) => (
          <CardContainer key={auction.id} auction={auction} />
        ))}
      </div>
    </>
  );
};

export default AuctionCards;
