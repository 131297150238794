import Text from '@components/Text';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import React, { useMemo } from 'react';
import { BoosterButtonSC, BoosterInventoryBtnSC } from './InventoryBar.styled';
import BoosterImage from './BoosterImage';
import { TBoosterRarity } from '@store/user-interface/types';
import userInterface from '@store/user-interface';
import {
  getColorByRarity,
  getRarityName,
  updateSortedBoosters,
} from '@utils/common';
import { useDispatch, useSelector } from 'react-redux';
import SvgCross from '@assets/svg/icons/Cross.svg';
import PngBackpack from '@assets/png/backpack.png';
import { TSortedBoosters } from './InventoryBar';
import { TRootState } from '@store/index';
import { TActiveBooster } from '@pages/Home/components/PositionCard/types';
import { useQuery } from 'react-query';
import { getRQKBoosters } from '@utils/rq-utils';
import api from '@utils/api';
import Skeleton from 'react-loading-skeleton';

const BoosterButtons: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const activeBooster = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.activeBooster,
  ) as TActiveBooster;

  const sortedBaseObject = {
    rare: [],
    super: [],
    ultra: [],
  };

  const { isLoading: boosterDataIsLoading, data: availableBoosters } = useQuery(
    getRQKBoosters('getBoosters'),
    async () => {
      const result = (await api.getBoosters()).data.data.boosters;
      return result.available;
    },
  );

  const sortedBoosters: TSortedBoosters = useMemo(() => {
    if (!availableBoosters) {
      return updateSortedBoosters(sortedBaseObject, [], {
        'super-rare': 'super',
        'ultra-rare': 'ultra',
      });
    }

    return updateSortedBoosters(sortedBaseObject, availableBoosters || [], {
      'super-rare': 'super',
      'ultra-rare': 'ultra',
    });
  }, [availableBoosters]);

  return (
    <div className="booster-container">
      {window.innerWidth >= 1024 && (
        <Tippy
          {...defaultTippyProps}
          content={`Select a Booster and apply it to your chosen ōLand plot`}
          disabled={false}
        >
          <Text>
            Boosters{' '}
            <span className="gray" style={{ fontSize: 16 }}>
              ⓘ
            </span>
          </Text>
        </Tippy>
      )}
      {boosterDataIsLoading ? (
        <Skeleton count={4} containerClassName="booster-skeleton" />
      ) : (
        <div className="pack-wrapper">
          {Object.keys(sortedBoosters).map((rarity, index) => {
            const items = sortedBoosters[rarity];
            const isEmpty = sortedBoosters[rarity] < 1;

            return (
              <Tippy
                key={index}
                {...defaultTippyProps}
                content={`${getRarityName(rarity)} Booster`}
                disabled={false}
              >
                <span>
                  <BoosterButtonSC
                    empty={isEmpty}
                    color={getColorByRarity(rarity)}
                    className={isEmpty ? 'empty' : ''}
                    style={{ animation: isEmpty ? 'none' : '' }}
                    disabled={isEmpty}
                    onClick={() =>
                      dispatch(
                        userInterface.setActiveBooster({
                          activeBooster:
                            activeBooster?.rarity === rarity
                              ? null
                              : {
                                  rarity: rarity as TBoosterRarity,
                                  boosterId: items[0],
                                  positionId: null,
                                },
                        }),
                      )
                    }
                  >
                    <div className="outer-wrapper">
                      <div className="qty-box">
                        <span>{items.length}</span>
                      </div>
                      {activeBooster?.rarity === rarity ? (
                        <div className="cancel">
                          <div className="inner">
                            <img src={SvgCross} alt="cross-btn" />
                          </div>
                        </div>
                      ) : (
                        <>
                          <BoosterImage rarity={rarity} />
                        </>
                      )}
                    </div>
                  </BoosterButtonSC>
                </span>
              </Tippy>
            );
          })}
          <BoosterInventoryBtnSC
            onClick={() =>
              dispatch(
                userInterface.setModal({
                  type: 'boosterInventoryModal',
                  token: null,
                }),
              )
            }
          >
            <div className="inner">
              <img src={PngBackpack} alt="inventory bag" />
              <h1>Inventory</h1>
            </div>
          </BoosterInventoryBtnSC>
        </div>
      )}
    </div>
  );
};

export default BoosterButtons;
