import React from 'react';
import { useCountdown } from '@hooks/useCountdown';
import Text, { TextVariant } from '@components/Text';
import { useQueryClient } from 'react-query';
import { getRQKPositionsKey } from '@utils/rq-utils';
import 'react-circular-progressbar/dist/styles.css';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';

type TUpgradingBox = {
  revealDate: string;
  upgrading: boolean;
  positionId: string;
  percentage: number;
};

const UpgradingTimer: React.FunctionComponent<TUpgradingBox> = ({
  revealDate,
  upgrading,
  positionId,
}) => {
  const todaysDate = new Date().getTime();
  const launchDate = new Date(revealDate).getTime();
  const queryClient = useQueryClient();

  const timeDifference = launchDate - todaysDate;

  const [days, hours, minutes, seconds] = useCountdown(
    todaysDate + timeDifference,
  );

  if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
    queryClient.invalidateQueries(
      getRQKPositionsKey('getPosition', positionId),
    );
  }

  if (isNaN(days) || isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
    queryClient.invalidateQueries(
      getRQKPositionsKey('getPosition', positionId),
    );
  }

  const countdownDataLoading =
    isNaN(days) || isNaN(hours) || isNaN(minutes) || isNaN(seconds);

  return (
    <div
      className="treat-reveal-text-box"
      style={{ display: upgrading ? 'block' : 'none' }}
    >
      <Text
        className="plot-label"
        variant={TextVariant.CardText}
        style={{
          fontWeight: 600,
          textAlign: 'center',
          margin: 0,
          color: 'black',
        }}
      >
        Upgrade in progress
      </Text>
      {countdownDataLoading ? (
        <SvgSpinner fill={'black'} style={{ maxHeight: 24 }} />
      ) : (
        <Text
          className="plot-label"
          variant={TextVariant.CardText}
          style={{
            fontWeight: 600,
            textAlign: 'center',
            margin: 0,
            lineHeight: '100%',
            marginTop: 4,
          }}
        >
          {days <= 0 ? (
            <span className="color" style={{ marginTop: 8 }}>
              {hours}H : {minutes}M : {seconds}S
            </span>
          ) : (
            <span className="color">
              {days}D : {hours}H : {minutes}M
            </span>
          )}
        </Text>
      )}

      <Text
        className="plot-label"
        variant={TextVariant.CardText}
        style={{
          fontWeight: 600,
          textAlign: 'center',
          margin: 0,
          color: 'black',
        }}
      >
        <span className="fine-print">Place ōTreats to speed up</span>
      </Text>
    </div>
  );
};

export default UpgradingTimer;
