import React, { FC, useEffect } from 'react';
import { V2AuctionsPageSC } from './V2AuctionsPage.styled';
import { ButtonOutlineSC } from '@components/Button.styled';
import LoadingStatePosition from '@pages/Home/components/PositionCard/LoadingStatePosition';
import { useDispatch } from 'react-redux';
import userInterface from '@store/user-interface';
import AuctionCards from './components/AuctionCards';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getRQKAuctionsKey, getRQKProfileKey } from '@utils/rq-utils';
import api from '@utils/api';
import { ReactComponent as SvgChevron } from '@assets/svg/chevron-down.svg';
import { getColorByRarity } from '@utils/common';

const renderAuctionCards = (
  selectedAuctionQueue: string,
  rareItems: any[],
  superItems: any[],
  ultraItems: any[],
) => {
  switch (selectedAuctionQueue) {
    case 'rare':
      return <AuctionCards auctionData={rareItems} />;
    case 'super':
      return <AuctionCards auctionData={superItems} />;
    default:
      return <AuctionCards auctionData={ultraItems} />;
  }
};

const V2AuctionsPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedAuctionQueue, setSelectedAuctionQueue] = React.useState<
    'rare' | 'super' | 'ultra'
  >('ultra');

  const { isLoading: isOCashDataLoading, data: oCashAmountData } = useQuery(
    getRQKProfileKey('getOCashAmount'),
    async () => (await api.getOCashAmount()).data.data,
  );

  const { isLoading, data: auctionData } = useQuery(
    getRQKAuctionsKey('getAuctions'),
    async () => {
      const result = (await api.getAuctions()).data.data;

      return result;
    },
  );

  return (
    <V2AuctionsPageSC>
      <div className="header">
        <ButtonOutlineSC onClick={() => navigate('/')}>
          <SvgChevron
            style={{
              transform: 'rotate(90deg)',
            }}
          />
        </ButtonOutlineSC>

        <h1>Boosted Auctions</h1>
        <div className="balance">
          ōCash:{' '}
          {isOCashDataLoading
            ? 'Loading'
            : oCashAmountData.oCashAmountAvailable.toLocaleString('en-US')}
        </div>
      </div>

      <div className="buttons">
        <h3>Choose Rarity Queue</h3>
        <div className="inner">
          <ButtonOutlineSC
            onClick={() => setSelectedAuctionQueue('ultra')}
            style={{
              borderColor:
                selectedAuctionQueue === 'ultra'
                  ? getColorByRarity('ultra')
                  : '',
            }}
          >
            Ultra Rare 🟨
          </ButtonOutlineSC>
          <ButtonOutlineSC
            onClick={() => setSelectedAuctionQueue('super')}
            style={{
              borderColor:
                selectedAuctionQueue === 'super'
                  ? getColorByRarity('super')
                  : '',
            }}
          >
            Super Rare 🟩
          </ButtonOutlineSC>
          <ButtonOutlineSC
            onClick={() => setSelectedAuctionQueue('rare')}
            style={{
              borderColor:
                selectedAuctionQueue === 'rare' ? getColorByRarity('rare') : '',
            }}
          >
            Rare 🟥
          </ButtonOutlineSC>
        </div>
      </div>
      <div className="cards-wrapper">
        {isLoading || isOCashDataLoading ? (
          <div className="auction-cards">
            {Array.from(Array(20).keys()).map((i) => (
              <button
                key={i}
                onClick={() => {
                  dispatch(
                    userInterface.setModal({
                      type: 'v2ListingModal',
                      token: null,
                    }),
                  );
                }}
                style={{ background: 'none', border: 'none', padding: '0px' }}
              >
                <LoadingStatePosition />
              </button>
            ))}
          </div>
        ) : (
          renderAuctionCards(
            selectedAuctionQueue,
            auctionData['rare'],
            auctionData['super'],
            auctionData['ultra'],
          )
        )}
      </div>
    </V2AuctionsPageSC>
  );
};

export default V2AuctionsPage;
