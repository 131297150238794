import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Confetti from 'react-dom-confetti';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import Decimal from 'decimal.js';
import QRCode from 'react-qr-code';
import metaMask from '@utils/meta-mask';
import userInterfaceStore from '@store/user-interface';
import chimeSfx from '@assets/sfx/chime.mp3';
import Text, { TextVariant } from '@components/Text';
import { InputSC } from '@components/Input.styled';
import { TRootState } from '@store/index';
import api from '@utils/api';
import { confettiConfig } from '../RevealResultModal';
import { getRQKUserKeys } from '@utils/rq-utils';
import SvgGreenCheck from '@assets/svg/green-check.svg';
import { ButtonOutlineSC } from '@components/Button.styled';
import { useTheme } from 'styled-components';
import { TNullableNumber } from '@custom-types/common';

export const getFullCryptoAddress = (address: string, amount: number) => {
  const buffer = ['ethereum:'];
  // add address
  buffer.push(address);
  // add amounts
  buffer.push(`?amount=${new Decimal(amount).toFixed(4)}`);

  return buffer.join('');
};

const HavePaidContent = ({ txs }: { txs: any[] }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const userKeysKey = getRQKUserKeys('getUserKeys');
  const [currKeysAmount, setCurrKeysAmount] = useState('');

  useEffect(() => {
    (async () => {
      await queryClient.invalidateQueries(userKeysKey);
      const currentKeysAmount = await queryClient.fetchQuery(userKeysKey);
      // @ts-expect-error we know its defined
      setCurrKeysAmount(String(currentKeysAmount.available.length));
    })();
  }, []);

  const ethAmount = txs.reduce(
    (accumVariable: number, curValue: any) =>
      accumVariable + parseFloat(curValue.balance),
    0,
  );

  const handleNavigateClick = () => {
    dispatch(userInterfaceStore.setModal({ type: null, token: null }));
    navigate('/purchase-orders');
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src={SvgGreenCheck}
          style={{ marginTop: 32, marginBottom: 32 }}
          alt="green-check"
        />
      </div>
      <span style={{ marginTop: 16, marginBottom: 16, color: 'white' }}>
        Amount Paid (ETH)
      </span>
      <InputSC
        style={{ width: '100%', marginBottom: 16 }}
        value={ethAmount}
        readOnly={true}
      />
      <span style={{ marginTop: 16, marginBottom: 16, color: 'white' }}>
        Updated Keys balance
      </span>
      <InputSC
        style={{ width: '100%', marginBottom: 16 }}
        value={currKeysAmount}
        readOnly={true}
      />
      <ButtonOutlineSC
        style={{ marginBottom: 32 }}
        type="button"
        onClick={handleNavigateClick}
      >
        View orders
      </ButtonOutlineSC>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a
          target="_blanK"
          href={`https://etherscan.io/address/${'0X'}`}
          rel="noreferrer noopener"
        >
          View tx on Etherscan
        </a>
      </div>
    </div>
  );
};

const CustomGWPaymentModal = () => {
  const theme = useTheme();

  const [copied, setCopied] = useState<false | 'ethAddress' | 'ethAmount'>(
    false,
  );

  const [havePaid, setHavePaid] = useState<boolean | any[]>(false);

  const productTitle = useSelector(
    (store: TRootState) => store.order.productTitle,
  );

  // @ts-expect-error we know its defined
  const { ethAmount, ethAddress } = useSelector(
    (store: TRootState) => store.order.customEthPayment,
  );

  const copyToClipboard = useCallback(
    async (text, type: 'ethAddress' | 'ethAmount') => {
      await navigator.clipboard.writeText(text);
      setCopied(type);
      setTimeout(() => {
        setCopied(false);
      }, 3500);
    },
    [],
  );

  useEffect(() => {
    metaMask.requestPayment(ethAddress, ethAmount).then();
  }, []);

  // poll every fifteen seconds
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!havePaid) {
        const response = (await api.pollCustomGw()).data;
        if (response.data.status === 'paid') {
          setHavePaid(response.data.meta.txs);
          const audio = new Audio(chimeSfx);
          await audio.play();
        }
      }
    }, 15000);

    return () => clearInterval(intervalId);
  }, [havePaid, setHavePaid]);

  return (
    <div className="body">
      <Confetti active={havePaid != false} config={confettiConfig} />
      {!havePaid && (
        <>
          <Text
            variant={TextVariant.CardText}
            style={{
              fontWeight: 500,
              fontSize: 12,
              marginBottom: 16,
              color: theme.neutral.neutral6,
            }}
          >
            Scan the QR code, or copy the ETH payment details below to complete
            your ōCash purchase. It is recommended that you wait 1-3 minutes
            after making the transaction before closing the page.
          </Text>

          <div
            style={{
              maxWidth: 160,
              maxHeight: 160,
              margin: '16px auto 32px auto',
            }}
          >
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={getFullCryptoAddress(ethAddress, ethAmount.toFixed(4))}
              viewBox={`0 0 256 256`}
            />
          </div>
          <Text
            variant={TextVariant.CardText}
            style={{
              fontWeight: 500,
              marginTop: 16,
              marginBottom: 4,
              color: theme.neutral.neutral6,
            }}
          >
            Amount Due
          </Text>
          <InputSC
            onClick={() => copyToClipboard(ethAmount, 'ethAmount')}
            style={{ width: '100%', cursor: 'pointer' }}
            value={
              copied === 'ethAmount'
                ? 'Copied to clipboard!'
                : ethAmount.toFixed(4)
            }
            readOnly={true}
            className="referral-input"
          />
          <Text
            variant={TextVariant.CardText}
            style={{
              fontWeight: 500,
              marginTop: 16,
              marginBottom: 4,
              color: theme.neutral.neutral6,
            }}
          >
            Address
          </Text>
          <InputSC
            onClick={() => copyToClipboard(ethAddress, 'ethAddress')}
            style={{ width: '100%', cursor: 'pointer' }}
            value={
              copied === 'ethAddress' ? 'Copied to clipboard!' : ethAddress
            }
            readOnly={true}
            className="referral-input"
          />
          <Text
            variant={TextVariant.CardText}
            style={{
              fontWeight: 500,
              marginTop: 16,
              marginBottom: 4,
              color: theme.neutral.neutral6,
            }}
          >
            Buying
          </Text>
          <InputSC
            style={{ width: '100%', cursor: 'pointer' }}
            value={productTitle}
            readOnly={true}
            className="referral-input"
          />
        </>
      )}
      {havePaid && <HavePaidContent txs={havePaid as any[]} />}
    </div>
  );
};

export default CustomGWPaymentModal;
