import { signOut } from '@utils/auth';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';

const TWENTY_MINUTES = 1000 * 60 * 20;

const AppLogout = ({ children }) => {
  const location = useLocation();

  const onIdle = () => {
    if (location.pathname === '/auth') return;
    signOut();
  };

  useIdleTimer({
    onIdle,
    timeout: TWENTY_MINUTES,
  });

  return children;
};
export default AppLogout;
