import Text, { TextVariant } from '@components/Text';
import React from 'react';
import { OfriendCardSC } from '../OpenBoxPage.styled';

export type TOfriendCardProps = {
  opened: any[];
};

const OfriendCard: React.FunctionComponent<TOfriendCardProps> = ({
  opened,
}) => {
  return (
    <div id="cards">
      {opened.map((item, index) => {
        const level = item.traits.filter((trait) => {
          if (trait.trait_type === 'Level') return trait;
        });
        const homeland = item.traits.filter((trait) => {
          if (trait.trait_type === 'Homeland') return trait;
        });
        const highestSkill = item.traits.filter((trait) => {
          if (trait.trait_type === 'Highest Magical Skill') return trait;
        });
        const racingPosition = item.traits.filter((trait) => {
          if (trait.trait_type === 'Racing Position') return trait;
        });
        return (
          <OfriendCardSC
            key={index}
            onClick={() => {
              window.open(
                `https://opensea.io/assets/ethereum/${process.env.REACT_APP_OFRIEND_CONTRACT_ADDRESS}/${item.token_id}`,
                '_blank',
                'noopener,noreferrer',
              );
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Text
                variant={TextVariant.CardText}
                style={{
                  fontWeight: 700,
                  textAlign: 'left',
                  marginBottom: 4,
                }}
              >
                ōFriend #{item.token_id}
              </Text>
              <Text
                variant={TextVariant.CardText}
                style={{
                  fontWeight: 700,
                  textAlign: 'right',
                  marginBottom: 4,
                }}
              >
                Lv. {level[0].value}
              </Text>
            </div>

            <div style={{ overflow: 'hidden' }} id="img-wrapper">
              <img src={item.image_original_url} />
            </div>
            <div id="stats">
              <Text
                variant={TextVariant.CardText}
                style={{
                  fontWeight: 700,
                  textAlign: 'left',
                  marginBottom: 4,
                }}
              >
                <span className="fine-print dgray">Highest Magical Skill:</span>
                <br />
                {highestSkill[0].value}
              </Text>
              <hr />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Text
                  variant={TextVariant.CardText}
                  style={{
                    fontWeight: 700,
                    textAlign: 'left',
                    marginBottom: 4,
                    width: '100%',
                  }}
                >
                  <span className="fine-print dgray">Homeland:</span>
                  <br />
                  {homeland[0].value}
                </Text>
                <Text
                  variant={TextVariant.CardText}
                  style={{
                    fontWeight: 700,
                    textAlign: 'right',
                    marginBottom: 4,
                    width: '100%',
                  }}
                >
                  <span className="fine-print dgray">Position:</span>
                  <br />
                  {racingPosition[0].value}
                </Text>
              </div>
            </div>
          </OfriendCardSC>
        );
      })}
    </div>
  );
};

export default OfriendCard;
