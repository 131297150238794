import React, { FC } from 'react';
import { PlotActionsButtonSC } from '../PlotActionsModal.styled';

type TPlotActionsButtonProps = {
  onClick?: () => void;
  title: string;
  description: string;
  image: React.SVGProps<SVGSVGElement>;
  disabled: boolean;
};

const PlotActionsButton: FC<TPlotActionsButtonProps> = ({
  image,
  title,
  description,
  onClick,
  disabled,
}) => {
  return (
    <PlotActionsButtonSC onClick={onClick} disabled={disabled}>
      {disabled && <div className="badge">Unavailable</div>}
      {image}
      <div className="description">
        <h3>{title}</h3>
        <p>
          {disabled
            ? 'You have artifacts from this plot currently on auction. Wait for them to finish first.'
            : description}
        </p>
      </div>
    </PlotActionsButtonSC>
  );
};

export default PlotActionsButton;
