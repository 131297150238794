import { colorThemes } from '@assets/styles';
import Wrapper from '@components/Wrapper';
import styled from 'styled-components';

export const ArtifactsContainerSC = styled(Wrapper)`
  background: ${({ theme }) => theme.background.inverted.bg1};
  overflow: auto;
  box-shadow: rgb(0 0 0 / 16%) 2px 4px 16px;
  width: 100%;
  height: 100%;

  .content-wrapper {
    max-width: 1260px;
    margin: 20px auto 0 auto;
  }

  table {
    width: 100%;
    text-align: center;
  }

  td {
    padding: 24px;
    font-size: 16px;

    @media only screen and (max-width: 772px) {
      font-size: 10px;
      padding: 12px;
    }
  }

  th {
    font-size: 18px;
    font-weight: 600;
    padding: 24px;

    @media only screen and (max-width: 772px) {
      font-size: 10px;
      padding: 12px;
    }
  }

  tr {
    border-bottom: 1px solid ${({ theme }) => theme.background.regular.bg2};

    :not(:first-child) {
      :hover {
        background: ${colorThemes.light.blue.blue10};
      }
    }
  }
`;

export const TableLinkSC = styled.button`
  background: none;
  color: ${colorThemes.light.blue.blue100};
  text-decoration: underline;
  border: none;
  font-size: 16px;
  cursor: pointer;

  @media only screen and (max-width: 772px) {
    font-size: 10px;
  }

  :hover {
    opacity: 0.7;
  }
`;

export const ArtifactSectionSC = styled.div`
  background: ${({ theme }) => theme.background.inverted.bg2};
  border: 1.5px solid ${({ theme }) => theme.input.border};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;

  .artifacts-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 12px;

    @media only screen and (max-width: 776px) {
      justify-content: center;
    }
  }
`;

export const ArtifactCardSC = styled.button`
  background: none;
  border: none;
  width: 100px;
  height: auto;

  img {
    max-width: 80px;
    margin-bottom: 8px;
  }

  .qty-badge {
    border-radius: 5px;
    padding: 8px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

    max-height: 25px;
    max-width: 80%;
    margin: auto;

    h1 {
      margin: 0px;
      font-weight: 600;
    }
  }
`;
