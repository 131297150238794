import React from 'react';
import Text, { TextVariant } from '@components/Text';
import { ChestCardSC } from '../ChestsPage.styled';
import { colorThemes } from '@assets/styles';

export type TChestCardProps = {
  type: string;
  color: string;
  img: string;
  chestQty: number;
};

/**
 * @constructor
 */

const ChestCard: React.FunctionComponent<TChestCardProps> = ({
  type,
  color,
  img,
  chestQty,
}) => {
  const isPresent = chestQty > 0;

  return (
    <ChestCardSC>
      <img
        src={img}
        alt={`${type}-card`}
        style={{ filter: isPresent ? `saturate(100%)` : `saturate(0%)` }}
      />
      <div
        className="qty-badge"
        style={{
          backgroundColor: isPresent
            ? color
            : colorThemes.light.neutral.neutral4,
        }}
      >
        <Text
          variant={TextVariant.CardText}
          style={{
            // color: isPresent ? 'white' : colorThemes.light.neutral.neutral7,
            color: isPresent
              ? type === 'ultra'
                ? colorThemes.light.neutral.neutral7
                : 'white'
              : colorThemes.light.neutral.neutral7,
          }}
        >
          {chestQty}
        </Text>
      </div>
    </ChestCardSC>
  );
};

export default ChestCard;
