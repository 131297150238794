import React, { useState, useCallback, useEffect } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useQueryClient } from 'react-query';
import Slider from 'react-rangeslider';
import { toast } from 'react-toastify';
import 'react-rangeslider/lib/index.css';
import chocolate from '@assets/png/treats/chicken.png';
import lime from '@assets/png/treats/fish.png';
import cranberry from '@assets/png/treats/goat.png';
import honey from '@assets/png/treats/honey.png';
import caramel from '@assets/png/treats/lobster.png';
import watermelon from '@assets/png/treats/watermelon.png';
import SvgCross from '@assets/svg/icons/Cross.svg';
import Toast from '@components/Toast';
import { ButtonPrimarySC, ButtonOutlineSC } from '@components/Button.styled';
import Text, { TextVariant } from '@components/Text';
import { defaultModalProps } from '@components/Modal/modal-settings';
import { TRootState } from '@store/index';
import userInterfaceStore from '@store/user-interface';
import { TOTreatFlavour } from '@store/user-interface/types';
import api from '@utils/api';
import { getHoursToFinishUpgrade } from '@utils/common';
import ModalSC from './Modal.styled';
import { getRQKPositionsKey, getRQKUserRewardsKey } from '@utils/rq-utils';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import { TreatBag, treatBags } from './OTreatModal';
import InfiniteLooper from '@components/InfiniteLooper';
import GaugeChart from 'react-gauge-chart';
import userInterface from '@store/user-interface';

export type TSortedTreats = {
  [key in TOTreatFlavour]: string[];
};

const treatImages = {
  chocolate,
  lime,
  cranberry,
  honey,
  caramel,
  watermelon,
};

const getLuckValue = (treatsToPlace: number) => {
  if (treatsToPlace >= 4 && treatsToPlace < 30) {
    return 'Luck x4';
  }
  if (treatsToPlace >= 30) {
    return 'Luck x15';
  }

  return 'Luck x1';
};

const TreatPlacingModal: React.FunctionComponent = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { setModal } = userInterface;

  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const selectedTreatType = useSelector(
    (store: TRootState) => store.userInterface.selectedTreatType,
  );
  const oTreatPlacement = useSelector(
    (store: TRootState) => store.userInterface.oTreatPlacement,
  ) as { id: string; currentOTreatAmount: number };

  const [treatsToPlace, setTreatsToPlace] = useState(1);
  const [isPlacingLoading, setIsPlacingLoading] = useState(false);
  const [luck, setLuck] = useState('Luck x1');

  // get available oTreats for this operation
  const { isLoading, data: filteredOTreats } = useQuery(
    getRQKUserRewardsKey('getFilteredUserRewards'),
    async () => {
      const result = await api.getUserRewards();

      const availableTreats = result.data.data.available;
      const filteredOTreats: string[] = [];

      if (selectedTreatType === null) {
        for (const oTreat of availableTreats) {
          filteredOTreats.push(oTreat.id);
        }
      }

      for (const oTreat of availableTreats) {
        if (oTreat.meta.type === selectedTreatType) {
          filteredOTreats.push(oTreat.id);
        }
      }
      return filteredOTreats;
    },
  );

  useEffect(() => {
    setLuck(getLuckValue(treatsToPlace + oTreatPlacement.currentOTreatAmount));
  }, [treatsToPlace]);

  const handleCloseModal = useCallback(() => {
    dispatch(userInterfaceStore.closePositionPlacementModal());
  }, []);

  const handleTreatPlacing = async () => {
    setIsPlacingLoading(true);
    try {
      const oTreatsToBePlaced = (filteredOTreats as string[]).slice(
        0,
        treatsToPlace,
      );
      await api.placeTreat(oTreatPlacement.id, oTreatsToBePlaced);
      await queryClient.invalidateQueries(
        getRQKUserRewardsKey('getUserRewards'),
      );
      await queryClient.invalidateQueries(
        getRQKUserRewardsKey('getAvailableUserRewards'),
      );
      await queryClient.invalidateQueries(
        getRQKPositionsKey('getPosition', oTreatPlacement.id),
      );
      toast(
        <Toast
          type="success"
          header="Success!"
          body="ōTreats successfully placed! Please refresh page."
        />,
      );
    } catch (e) {
      console.error('Error when placing treats: ', e);
      toast(
        <Toast
          type="error"
          header="Something went wrong"
          body="Your ōTreats were not successfully placed. Please try again."
        />,
      );
    } finally {
      setIsPlacingLoading(false);
      handleCloseModal();
    }
  };

  /**
   * Start oTreat
   * purchase process
   */
  const openOTreatModal = () => {
    // open Address Check modal
    dispatch(
      setModal({
        type: 'oTreatModal',
        token: null,
      }),
    );
  };

  if (isLoading) {
    return null;
  }

  const handleChangeHorizontal = (value) => {
    setTreatsToPlace(value);
  };

  const needMoreOTreats =
    filteredOTreats && filteredOTreats.length < treatsToPlace;
  const dataLoading = isLoading || isPlacingLoading;

  const maxAllowedAmount = 84 - oTreatPlacement.currentOTreatAmount;

  return (
    <ReactModal
      isOpen={type === 'treatPlacingModal'}
      {...defaultModalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC>
        <div className="header">
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            Upgrading ōLand
          </Text>
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <div className="body">
          <Text variant={TextVariant.CardText} className="gray">
            Type:{' '}
            <span className="blue capitalize" style={{ fontWeight: 700 }}>
              {selectedTreatType === null
                ? 'Random'
                : selectedTreatType.charAt(0).toUpperCase() +
                  selectedTreatType.slice(1)}{' '}
              ōTreats
            </span>
          </Text>
          <div
            style={{ width: '100%', textAlign: 'center', margin: '16px 0px' }}
          >
            {selectedTreatType === null ? (
              <InfiniteLooper speed={10} direction="left">
                {treatBags.map((item, index) => (
                  <div key={index}>
                    <TreatBag size={75} src={item} />
                  </div>
                ))}
              </InfiniteLooper>
            ) : (
              <img
                src={treatImages[selectedTreatType as TOTreatFlavour]}
                className="treat-bag"
                alt="Treat Bag"
              />
            )}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              gap: 16,
              alignItems: 'center',
            }}
          >
            <div style={{ width: '100%' }}>
              <Slider
                min={1}
                max={maxAllowedAmount}
                value={treatsToPlace}
                handleLabel={treatsToPlace.toString()}
                onChange={handleChangeHorizontal}
              />
            </div>
            <ButtonOutlineSC
              style={{ width: 70 }}
              onClick={() => {
                if ((filteredOTreats as string[]).length < maxAllowedAmount) {
                  setTreatsToPlace((filteredOTreats as string[]).length);
                } else {
                  setTreatsToPlace(maxAllowedAmount);
                }
              }}
            >
              Max
            </ButtonOutlineSC>
          </div>

          <div
            style={{
              display: 'flex',
              gap: 12,
              justifyContent: 'center',
              pointerEvents: 'none',
              marginBottom: 8,
            }}
          >
            <div className="placing-stat">
              <Text
                variant={TextVariant.CardText}
                className="gray stat-heading"
                style={{ textAlign: 'center', fontWeight: 600 }}
              >
                Upgrade Speed:
              </Text>
              <div className="gauge-chart-container">
                <GaugeChart
                  id="speed-dial"
                  nrOfLevels={30}
                  colors={['#f36961', '#f8ab5e', '#70db96']}
                  arcPadding={0.02}
                  arcWidth={0.3}
                  percent={
                    (oTreatPlacement.currentOTreatAmount + treatsToPlace) / 84
                  }
                  hideText={true}
                  needleColor={'#248AFF'}
                  animate={false}
                  style={{ height: 60, width: 115 }}
                />
              </div>
              <div className="gauge-label">
                <Text
                  variant={TextVariant.CardTitle}
                  className="color"
                  style={{
                    textAlign: 'center',
                    fontWeight: 600,
                    margin: 'auto',
                  }}
                >
                  {getHoursToFinishUpgrade(
                    oTreatPlacement.currentOTreatAmount + treatsToPlace,
                  )}{' '}
                  Hours
                </Text>
              </div>
            </div>
            <div className="placing-stat">
              <Text
                variant={TextVariant.CardText}
                className="gray stat-heading"
                style={{ textAlign: 'center', fontWeight: 600 }}
              >
                Luck Multiplier:
              </Text>
              <div className="gauge-chart-container">
                <GaugeChart
                  id="luck-dial"
                  className="gauge"
                  nrOfLevels={3}
                  arcsLength={[0.1, 0.25, 0.65]}
                  colors={['#70b3ff', '#248AFF', '#0152ae']}
                  arcPadding={0.02}
                  percent={
                    (oTreatPlacement.currentOTreatAmount + treatsToPlace) / 84
                  }
                  hideText={true}
                  needleColor={'#248AFF'}
                  animate={false}
                  style={{ height: 60, width: 115 }}
                />
              </div>
              <div className="gauge-label">
                <Text
                  variant={TextVariant.CardTitle}
                  className="color"
                  style={{
                    textAlign: 'center',
                    fontWeight: 600,
                    margin: 'auto',
                  }}
                >
                  {luck}
                </Text>
              </div>
            </div>
          </div>
          <div>
            <ButtonPrimarySC
              onClick={handleTreatPlacing}
              style={{ margin: '16px 0px' }}
              disabled={needMoreOTreats || dataLoading || treatsToPlace < 1}
            >
              <>
                {needMoreOTreats && !dataLoading && `You need more ōTreats`}
                {dataLoading && (
                  <SvgSpinner
                    height={30}
                    width={30}
                    style={{ display: 'inline', fill: 'white' }}
                  />
                )}
                {!needMoreOTreats &&
                  !dataLoading &&
                  `Place ${treatsToPlace} ōTreats`}
              </>
            </ButtonPrimarySC>
            <ButtonOutlineSC onClick={openOTreatModal}>
              Get more ōTreats
            </ButtonOutlineSC>
          </div>
        </div>
      </ModalSC>
    </ReactModal>
  );
};

export default TreatPlacingModal;
