import React, { useMemo } from 'react';
import api from '@utils/api';
import { getRQKBoosters, getRQKUserKeys } from '@utils/rq-utils';
import { useQueries } from 'react-query';
import { useDispatch } from 'react-redux';
import ClaimableBooster from './ClaimableBooster';
import PngChest from '@assets/png/treasure/yellow-chest-png.png';
import PngKey from '@assets/png/treasure/key.png';
import Text from '@components/Text';
import { openChestModal } from '@store/common';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import { defaultTippyProps } from '@utils/ui';
import Tippy from '@tippyjs/react';

function KeyBalance() {
  const dispatch = useDispatch();

  const [claimableBoosterQuery, userKeyDataQuery] = useQueries([
    {
      queryKey: getRQKBoosters('getBoostersToBeFound'),
      queryFn: async () => {
        const result = (await api.getBoosters()).data.data;
        return result.readyToBeFoundBoosters;
      },
    },
    {
      queryKey: getRQKUserKeys('getUserKeys'),
      queryFn: async () => (await api.getUserKeys()).data.data,
    },
  ]);

  const claimableBoostersData = claimableBoosterQuery.data ?? [];

  const userKeyDataAvailable = userKeyDataQuery.data?.available ?? [];
  const isUserKeyDataLoading = userKeyDataQuery.isLoading;

  const userKeyDataAvailableCount = useMemo(
    () => userKeyDataAvailable.length,
    [userKeyDataAvailable],
  );

  return (
    <div>
      {claimableBoostersData > 0 && (
        <Tippy
          className="hello"
          {...defaultTippyProps}
          content={`You have a booster waiting in your next chest!`}
        >
          <span className="booster-waiting-container">
            <ClaimableBooster />
          </span>
        </Tippy>
      )}
      <button className="keys glow" onClick={() => openChestModal(dispatch)}>
        <img src={PngChest} alt="Treasure Chest" className="chest" />
        <div className="key-bal">
          <img src={PngKey} alt="Key" className="key-img" />
          <Text>
            {isUserKeyDataLoading ? (
              <SvgSpinner
                fill={'white'}
                style={{ maxHeight: 24, maxWidth: 24 }}
              />
            ) : (
              userKeyDataAvailableCount
            )}
          </Text>
        </div>
      </button>
    </div>
  );
}

export default KeyBalance;
