import React, { FC, useMemo } from 'react';
import PlotActionsButton from './PlotActionsButton';
import { useDispatch, useSelector } from 'react-redux';
import userInterface from '@store/user-interface';
import {
  getRQKAuctionsKey,
  getRQKPositionsKey,
  getRQKUserRewardsKey,
  queryClient,
} from '@utils/rq-utils';
import { TRootState } from '@store/index';
import { ReactComponent as SvgAuction } from '@assets/svg/icons/coins-hand.svg';
import { ReactComponent as SvgUpgrade } from '@assets/svg/icons/lightning.svg';
import { ReactComponent as SvgBackpack } from '@assets/svg/backpack.svg';
import { colorThemes } from '@assets/styles';
import { toast } from 'react-toastify';
import Toast from '@components/Toast';
import dayjs from 'dayjs';

type TPlotActionsProps = {
  currentUpgradeProcessTreatsAmount: number;
};

const PlotAction: FC<TPlotActionsProps> = ({
  currentUpgradeProcessTreatsAmount,
}) => {
  const dispatch = useDispatch();

  const activePositionId = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.activePositionId,
  ) as string;

  const rewards = queryClient.getQueryData(
    getRQKUserRewardsKey('getAvailableUserRewards'),
  ) as string[] | null;

  const positionDetails = queryClient.getQueryData(
    getRQKPositionsKey('getPosition', activePositionId),
  ) as any;

  const userHasTreats = rewards && rewards.length > 0;

  const blockedItems = queryClient.getQueryData(
    getRQKAuctionsKey('getBlockedItems'),
  ) as any;

  const isBlocked = useMemo(() => {
    if (blockedItems) {
      return blockedItems.blockedPositions.some(
        (item) => item === activePositionId,
      );
    }

    return false;
  }, [blockedItems, activePositionId]);

  const isInUpgradeProcess = positionDetails.positionUpgrades
    ? positionDetails.positionUpgrades.filter((up) => {
        return (
          !up.revealed && dayjs().diff(dayjs(up.revealableAt), 'second') < 0
        );
      }).length > 0
    : false;

  const openUpgradeModal = () => {
    if (userHasTreats) {
      dispatch(
        userInterface.placeOTreatsOnPosition({
          selectedPlot: {
            id: activePositionId,
            currentOTreatAmount: currentUpgradeProcessTreatsAmount,
          },
        }),
      );
    } else {
      dispatch(
        userInterface.setModal({
          type: 'oTreatModal',
          token: null,
        }),
      );
    }
  };

  const openUpgradesInventoryModal = () => {
    dispatch(
      userInterface.setModal({
        type: 'upgradesInventoryModal',
        token: null,
      }),
    );
  };

  const openListingModal = () => {
    if (
      !isInUpgradeProcess &&
      positionDetails.rarity &&
      positionDetails.rarityMeta &&
      positionDetails.lat &&
      positionDetails.lng
    ) {
      dispatch(
        userInterface.setModal({
          type: 'v2ListingModal',
          token: null,
        }),
      );
    }
    if (isInUpgradeProcess) {
      toast(
        <Toast
          type="error"
          header="Listing failed"
          body="Your ōLand's upgrade process must be finished first."
        />,
      );
    }
    if (!positionDetails.rarityMeta) {
      toast(
        <Toast
          type="error"
          header="Listing failed"
          body="Your ōLand's rarity & location must be revealed first."
        />,
      );
    }
  };

  return (
    <>
      <h2>What would you like to do with your ōLand?</h2>
      <div className="selections">
        <PlotActionsButton
          title={'Upgrade ōLand'}
          description={
            'Attract creatures to build upgrades on your ōLand by placing treats.'
          }
          image={<SvgUpgrade />}
          onClick={openUpgradeModal}
          disabled={false}
        />
        <PlotActionsButton
          title={'View Upgrades'}
          description={
            'View all upgrades currently applied to your ōLand or list them for sale.'
          }
          image={<SvgBackpack fill={colorThemes.light.blue.blue100} />}
          onClick={openUpgradesInventoryModal}
          disabled={false}
        />
        <PlotActionsButton
          title={'List ōLand for Sale'}
          description={
            'Put your ōLand up for sale on the ōLand Auction marketplace.'
          }
          image={<SvgAuction />}
          onClick={openListingModal}
          disabled={isBlocked}
        />
      </div>
    </>
  );
};

export default PlotAction;
