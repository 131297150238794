import styled from 'styled-components';

export type TToastType = 'success' | 'error' | 'warning' | 'info';

const ToastSC = styled.div<{ type: TToastType }>`
  height: 100%;
  width: 100%;
  span {
    color: ${({ theme }) => theme.primary};
    font-weight: 500;
  }

  div.header {
    display: flex;
    justify-content: space-between;

    background-color: ${(props) => {
      switch (props.type) {
        case 'success':
          return '#28a745'; // green
        case 'error':
          return '#dc3545'; // red
        case 'warning':
          return '#f1c40f'; // yellow
        case 'info':
          return '#3498db'; // blue
        default:
          return `${({ theme }) => theme.text.secondary};`; // default color (gray)
      }
    }};
    button {
      background-color: transparent;
      border-width: 0;
    }
  }

  div.body {
  }
`;

export default ToastSC;
