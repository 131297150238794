export const USD_NUMERAL_FMT = '$0,0.00';
import PngRedBooster from '@assets/png/boosters/red-booster-png.png';
import PngGreenBooster from '@assets/png/boosters/green-booster-png.png';
import PngYellowBooster from '@assets/png/boosters/yellow-booster-png.png';

export const genCurrentTimeString = (): string => {
  const now: Date = new Date();
  return `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}:${now.getMilliseconds()}`;
};

export const getHumanReadable = (str: string): string => {
  let i;
  const frags = str.split('-');

  if (str === 'tree-house') {
    return 'Treehouse';
  }

  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    if (frags[i] === 'Orouter') {
      frags[i] = 'ōRouter';
    }
    if (frags[i] === 'Carpetbaggers') {
      frags[i] = "Carpetbagger's";
    }
    if (frags[i] === 'For') {
      frags[i] = 'for';
    }
    if (frags[i] === 'To') {
      frags[i] = 'to';
    }
    if (frags[i] === 'Of') {
      frags[i] = 'of';
    }
    if (frags[i] === 'The') {
      frags[i] = 'the';
    }
    if (frags[i] === 'Wizard') {
      frags[i] = "Wizard's";
    }
    if (frags[i] === 'Okeefe') {
      frags[i] = "O'Keefe";
    }
    if (frags[i] === 'Dragons') {
      frags[i] = "Dragon's";
    }
    if (frags[i] === 'Fishtank') {
      frags[i] = 'Fish Tank';
    }
    if (frags[i] === 'A') {
      frags[i] = 'a';
    }
    if (frags[i] === 'In') {
      frags[i] = 'in';
    }
  }
  return frags.join(' ');
};

export const getChestCardName = (
  type: string,
  rarity: string,
  trait: string,
) => {
  if (rarity === 'rare') {
    rarity = 'Rare';
  }

  if (rarity === 'super') {
    rarity = 'Super Rare';
  }

  if (rarity === 'ultra') {
    rarity = 'Ultra Rare';
  }
  return (
    rarity +
    ' ' +
    trait.charAt(0).toUpperCase() +
    trait.slice(1).toLowerCase() +
    "'s" +
    ' ' +
    'Chest' +
    ' ' +
    type
  );
};

export const buildString = (...args: string[]): string => args.join(' ');
export const getDateFormat = (): string => 'Do MMM YYYY ';
export const getTimeFormat = (withSeconds = true): string =>
  withSeconds ? 'HH:mm:ss' : 'HH:mm';
export const getDateTimeFormat = (): string =>
  `${getDateFormat()} ${getTimeFormat()}`;

export type VerificationTokenAction = {
  type: 'initialization' | 'resetPassword';
  token: string;
};

export const getTokenDataFromUrl = (): VerificationTokenAction | false => {
  const urlParams = new URLSearchParams(window.location.search);
  const initializationToken = urlParams.get('initializationToken');
  const resetPasswordToken = urlParams.get('resetPasswordToken');

  if (initializationToken) {
    return {
      type: 'initialization',
      token: initializationToken,
    };
  }

  if (resetPasswordToken) {
    return {
      type: 'resetPassword',
      token: resetPasswordToken,
    };
  }

  return false;
};

/**
 * Resolves amount of time needed for reveal per cycle
 *
 * @param {number} treatsInvested - amount of treats invested in position per one reveal cycle
 * @returns {number} - amount of hours the reveal will take
 */
export const getHoursToFinishUpgrade = (treatsInvested: number) => {
  let estimatedHours = 0;

  if (treatsInvested >= 1) {
    estimatedHours += 168;
  }

  if (treatsInvested >= 2) {
    estimatedHours -= 20;
  }

  if (treatsInvested >= 3) {
    estimatedHours -= (treatsInvested > 5 ? 3 : treatsInvested - 2) * 10;
  }

  if (treatsInvested >= 6) {
    estimatedHours -= (treatsInvested > 9 ? 4 : treatsInvested - 5) * 5;
  }

  if (treatsInvested >= 10) {
    estimatedHours -= (treatsInvested > 20 ? 11 : treatsInvested - 9) * 3;
  }

  if (treatsInvested >= 21) {
    estimatedHours -= (treatsInvested - 20) * 1;
  }

  if (estimatedHours <= 0) {
    return 0;
  }

  return estimatedHours;
};

export const numberToLocaleString = (n: number) =>
  n.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const ORDER_PAYMENT_TYPE_CUSTOM_GW = 'cs-crypto-gw_v1';
export const ORDER_PAYMENT_TYPE_FIAT = 'fiat';
export const ORDER_PAYMENT_TYPE_WERT = 'wert';
export const ORDER_PAYMENT_TYPE_BITPAY = 'crypto';

export const getHumanReadableOrderType = (type: string) => {
  switch (type) {
    case ORDER_PAYMENT_TYPE_FIAT:
      return 'Credit Card (Stripe)';
    case ORDER_PAYMENT_TYPE_WERT:
      return 'Credit Card (Wert)';
    case ORDER_PAYMENT_TYPE_BITPAY:
      return 'Crypto (BitPay)';
    default:
      return 'Crypto (Direct ETH)';
  }
};

export const shortenEthAddress = (addr: string) =>
  addr.toString().slice(0, 6) + '...' + addr.toString().slice(-4);

export function formatNumber(num, precision = 2) {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];

  if (num < 1000) {
    return num.toFixed(0);
  }

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted =
      (num / found.threshold).toFixed(precision).replace('.0', '') +
      found.suffix;
    return formatted;
  }

  return num;
}

export const getRarityName = (rarity: string) => {
  if (rarity === 'rare') {
    rarity = 'Rare';
  }

  if (rarity === 'super') {
    rarity = 'Super Rare';
  }

  if (rarity === 'ultra') {
    rarity = 'Ultra Rare';
  }

  return rarity;
};

export const getColorByRarity = (rarity: string) => {
  switch (rarity) {
    case 'rare':
      return 'rgba(255, 0, 0, 0.9)';
    case 'super':
      return 'rgba(0, 255, 0, 0.9)';
    case 'ultra':
      return 'rgba(255, 221, 0, 0.9)';
    case 'Material':
      return '';
    default:
      return 'rgba(255, 0, 0, 0.9)';
  }
};

export const getBoosterImgFromRarity = (rarity) => {
  switch (rarity) {
    case 'rare':
      return PngRedBooster;
    case 'super':
      return PngGreenBooster;
    case 'ultra':
      return PngYellowBooster;
    default:
      return PngRedBooster;
  }
};

export function updateSortedBoosters(
  prevState,
  availableBoosters,
  typeMappings,
) {
  const newArray = { ...prevState };

  for (const key of Object.keys(newArray)) {
    newArray[key] = [];
  }

  for (const booster of availableBoosters) {
    let type = booster.meta.type;

    if (typeMappings && typeMappings[type]) {
      type = typeMappings[type];
    }

    newArray[type].push(booster.id);
  }

  return newArray;
}
