import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TChestState,
  TSetIsChestOpen,
  TSetChestReceived,
  TSetOLandPlot,
  TSetBoosterRarity,
  TSetOCashReceivedFromChest,
  TSetShowBigWinText,
  TSetUltimatePrize,
  TSetIsClaimSubmitLoading,
  TSetUsersClaimAddress,
  TSetPrizeChecked,
  TSetPrizeLoading,
  TSetMatchedPrize,
  TSetOCashFlipped,
  TSetChestFlipped,
  TSetOLandFlipped,
  TSetBoosterFlipped,
  TSetUltimateFlipped,
  TSetActiveFlipCardIndex,
  TSetImageIndex,
  TSetShowUltimateOverlay,
} from './types';

const initialState: TChestState = {
  isChestOpen: false,
  chestReceived: '',
  oLandPlot: null,
  boosterRarity: null,
  oCashReceivedFromChest: 0,
  showBigWinText: false,
  ultimatePrize: false,
  isClaimSubmitLoading: false,
  usersClaimAddress: null,
  prizeChecked: false,
  prizeLoading: false,
  matchedPrize: null,
  oCashFlipped: true,
  chestFlipped: true,
  oLandFlipped: true,
  boosterFlipped: true,
  ultimateFlipped: true,
  activeFlipCardIndex: 0,
  imageIndex: 0,
  showUltimateOverlay: false,
};

const chestSlice = createSlice({
  name: 'chest',
  initialState,
  reducers: {
    setIsChestOpen(state, action: PayloadAction<TSetIsChestOpen>) {
      state.isChestOpen = action.payload.isChestOpen;
    },
    setChestReceived(state, action: PayloadAction<TSetChestReceived>) {
      state.chestReceived = action.payload.chestReceived;
    },
    setOLandPlot(state, action: PayloadAction<TSetOLandPlot>) {
      state.oLandPlot = action.payload.oLandPlot;
    },
    setBoosterRarity(state, action: PayloadAction<TSetBoosterRarity>) {
      state.boosterRarity = action.payload.boosterRarity;
    },
    setOCashReceivedFromChest(
      state,
      action: PayloadAction<TSetOCashReceivedFromChest>,
    ) {
      state.oCashReceivedFromChest = action.payload.oCashReceivedFromChest;
    },
    setShowBigWinText(state, action: PayloadAction<TSetShowBigWinText>) {
      state.showBigWinText = action.payload.showBigWinText;
    },
    setUltimatePrize(state, action: PayloadAction<TSetUltimatePrize>) {
      return {
        ...state,
        ultimatePrize: action.payload.ultimatePrize,
      };
    },
    setIsClaimSubmitLoading(
      state,
      action: PayloadAction<TSetIsClaimSubmitLoading>,
    ) {
      state.isClaimSubmitLoading = action.payload.isClaimSubmitLoading;
    },
    setUsersClaimAddress(state, action: PayloadAction<TSetUsersClaimAddress>) {
      state.usersClaimAddress = action.payload.usersClaimAddress;
    },
    setPrizeChecked(state, action: PayloadAction<TSetPrizeChecked>) {
      state.prizeChecked = action.payload.prizeChecked;
    },
    setPrizeLoading(state, action: PayloadAction<TSetPrizeLoading>) {
      state.prizeLoading = action.payload.prizeLoading;
    },
    setMatchedPrize(state, action: PayloadAction<TSetMatchedPrize>) {
      state.matchedPrize = action.payload.matchedPrize;
    },
    setOCashFlipped(state, action: PayloadAction<TSetOCashFlipped>) {
      state.oCashFlipped = action.payload.oCashFlipped;
    },
    setChestFlipped(state, action: PayloadAction<TSetChestFlipped>) {
      state.chestFlipped = action.payload.chestFlipped;
    },
    setOLandFlipped(state, action: PayloadAction<TSetOLandFlipped>) {
      state.oLandFlipped = action.payload.oLandFlipped;
    },
    setBoosterFlipped(state, action: PayloadAction<TSetBoosterFlipped>) {
      state.boosterFlipped = action.payload.boosterFlipped;
    },
    setUltimateFlipped(state, action: PayloadAction<TSetUltimateFlipped>) {
      state.ultimateFlipped = action.payload.ultimateFlipped;
    },
    setActiveFlipCardIndex(
      state,
      action: PayloadAction<TSetActiveFlipCardIndex>,
    ) {
      state.activeFlipCardIndex = action.payload.activeFlipCardIndex;
    },
    setImageIndex(state, action: PayloadAction<TSetImageIndex>) {
      state.imageIndex = action.payload.imageIndex;
    },
    setShowUltimateOverlay(
      state,
      action: PayloadAction<TSetShowUltimateOverlay>,
    ) {
      state.showUltimateOverlay = action.payload.showUltimateOverlay;
    },
    resetState: (state: TChestState): TChestState => ({
      ...state,
      isChestOpen: false,
      chestReceived: '',
      oLandPlot: null,
      boosterRarity: null,
      oCashReceivedFromChest: 0,
      showBigWinText: false,
      ultimatePrize: false,
      isClaimSubmitLoading: false,
      usersClaimAddress: null,
      prizeChecked: false,
      prizeLoading: false,
      oCashFlipped: true,
      chestFlipped: true,
      oLandFlipped: true,
      boosterFlipped: true,
      ultimateFlipped: true,
      activeFlipCardIndex: 0,
      imageIndex: 0,
      showUltimateOverlay: false,
    }),
  },
});

export default chestSlice;
