import React, { FC, useCallback, useState } from 'react';
import { ReactComponent as SvgChevron } from '@assets/svg/chevron-down.svg';
import { ButtonOutlineSC, ButtonPrimarySC } from '@components/Button.styled';
import { getColorByRarity, getRarityName } from '@utils/common';
import { toast } from 'react-toastify';
import Toast from '@components/Toast';
import { closeModal, openChestModal } from '@store/common';
import { useDispatch } from 'react-redux';
import api from '@utils/api';
import {
  getRQKAuctionsKey,
  getRQKUsersAuctionsKey,
  queryClient,
} from '@utils/rq-utils';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import { TNullableNumber, TNullableString } from '@custom-types/common';
import { defaultTippyProps } from '@utils/ui';
import Tippy from '@tippyjs/react';

type TListingFormProps = {
  dominantBooster: string | null;
  positionDetails: any;
};

export type TDurationPreset = {
  duration: string;
  durationValue: string;
};

export const durationPresets: TDurationPreset[] = [
  {
    duration: '1 Hour',
    durationValue: 'hour',
  },
  {
    duration: '6 Hours',
    durationValue: 'six_hours',
  },
  {
    duration: '1 Day',
    durationValue: 'day',
  },
  {
    duration: '7 Days',
    durationValue: 'seven_days',
  },
  {
    duration: '30 Days',
    durationValue: 'thirty_days',
  },
];

const Required = () => {
  return (
    <span>
      <sup>*</sup>
    </span>
  );
};

const ListingForm: FC<TListingFormProps> = ({
  dominantBooster,
  positionDetails,
}) => {
  const dispatch = useDispatch();
  const [isAdvancedGridVisible, setIsAdvancedGridVisible] = useState(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [duration, setDuration] = useState<TDurationPreset>(durationPresets[2]);

  const [minimumPrice, setMinimumPrice] = useState<TNullableNumber>(null);

  const [formattedMinimumPrice, setFormattedMinimumPrice] =
    useState<TNullableString>(null);

  const [buyNowPrice, setBuyNowPrice] = useState<TNullableNumber>(null);

  const [formattedBuyNowPrice, setFormattedBuyNowPrice] =
    useState<TNullableString>(null);

  const [durationDropdownShown, setDurationDropdownShown] =
    useState<boolean>(false);

  const [listingPrice, setListingPrice] = useState<TNullableNumber>(null);

  const [formattedListingPrice, setFormattedListingPrice] =
    useState<TNullableString>(null);

  const handleSetListingPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/,/g, '');
    if (!isNaN(Number(value))) {
      setListingPrice(Number(value));
      setFormattedListingPrice(Number(value).toLocaleString());
    }
  };

  const handleSetReservePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/,/g, '');
    if (!isNaN(Number(value))) {
      setMinimumPrice(Number(value));
      setFormattedMinimumPrice(Number(value).toLocaleString());
    }
  };

  const handleSetBuyNowPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/,/g, '');
    if (!isNaN(Number(value))) {
      setBuyNowPrice(Number(value));
      setFormattedBuyNowPrice(Number(value).toLocaleString());
    }
  };

  const handleSubmitBtnClick = useCallback(async () => {
    setIsLoading(true);

    try {
      await api.createAuction({
        itemType: 'land',
        itemId: positionDetails.id,
        startingPrice: listingPrice,
        duration: duration.durationValue,
        minTresholdForSuccess: minimumPrice,
        buyNow: buyNowPrice,
      });
      toast(
        <Toast
          type="success"
          header="Success!"
          body="Auction created successfully"
        />,
      );

      closeModal(dispatch);
    } catch (e: any) {
      const errMsg = e.data.errMsg.split('');
      errMsg[0] = errMsg[0].toUpperCase();
      toast(
        <Toast
          type="error"
          header="Something went wrong. Try again later."
          body={e.data.errMsg}
        />,
      );
    } finally {
      await queryClient.invalidateQueries(getRQKAuctionsKey('getAuctions'));
      await queryClient.invalidateQueries(getRQKAuctionsKey('getBlockedItems'));
      await queryClient.invalidateQueries(
        getRQKUsersAuctionsKey('getUserRunningAuctions'),
      );
      setIsLoading(false);
    }
  }, [listingPrice, buyNowPrice, duration, dominantBooster, minimumPrice]);

  const notBoosted = positionDetails.boosters === null;

  return (
    <div className="form">
      <div className="listing-price">
        <Tippy
          {...defaultTippyProps}
          content={`This is the price that your auction will start at for bidding.`}
        >
          <p>
            Listing Price ⓘ<Required />
          </p>
        </Tippy>
        <input
          type="text"
          maxLength={18}
          value={formattedListingPrice || ''}
          placeholder="Amount (ōCash)"
          onChange={handleSetListingPrice}
        />
      </div>
      <div
        className="advanced-header"
        onClick={() => setIsAdvancedGridVisible(!isAdvancedGridVisible)}
      >
        <h3>Boosted Features (Advanced)</h3>
        <SvgChevron
          style={{
            transform: isAdvancedGridVisible ? 'rotate(180deg)' : 'none',
          }}
        />
      </div>
      {isAdvancedGridVisible && (
        <div className="advanced-grid">
          <div className="duration">
            <Tippy
              {...defaultTippyProps}
              content={`The time your Auction listing will be active for.`}
            >
              <p>Duration ⓘ {!notBoosted && <Required />}</p>
            </Tippy>
            <button
              className={`duration-btn`}
              disabled={notBoosted}
              onClick={() => setDurationDropdownShown(!durationDropdownShown)}
            >
              {notBoosted ? 'Only boosted ōLand' : duration.duration}{' '}
              {!notBoosted && (
                <SvgChevron
                  style={{
                    transform: durationDropdownShown
                      ? 'rotate(180deg)'
                      : 'none',
                  }}
                />
              )}
            </button>
            {durationDropdownShown && (
              <div className="duration-dropdown">
                {durationPresets.map((preset) => (
                  <div
                    key={preset.duration}
                    onClick={() => {
                      setDuration(preset);
                      setDurationDropdownShown(!durationDropdownShown);
                    }}
                  >
                    {preset.duration}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="queue">
            <Tippy
              {...defaultTippyProps}
              content={`The queue that your Auction will be placed in (equivalent to the highest level booster applied to your land).`}
            >
              <p>Auction Queue ⓘ</p>
            </Tippy>
            <input
              className={notBoosted ? 'unavailable' : ''}
              style={{ color: getColorByRarity(dominantBooster as string) }}
              disabled={true}
              type="text"
              placeholder={
                notBoosted
                  ? 'Only boosted ōLand'
                  : getRarityName(dominantBooster as any)
              }
              value={getRarityName(dominantBooster as any)}
            />
          </div>
          <div className="minimum-price">
            <Tippy
              {...defaultTippyProps}
              content={`This is the minimum price that must be reached in order for your auction to be successful.`}
            >
              <p>Reserve Price ⓘ</p>
            </Tippy>
            <input
              className={notBoosted ? 'unavailable' : ''}
              type="text"
              maxLength={18}
              disabled={notBoosted}
              placeholder={notBoosted ? 'Only boosted ōLand' : 'Amount (ōCash)'}
              value={formattedMinimumPrice || ''}
              onChange={handleSetReservePrice}
            />
          </div>
          <div className="buy-now-price">
            <Tippy
              {...defaultTippyProps}
              content={`The price a user can pay to purchase your plot immediately.`}
            >
              <p>Buy It Now Price ⓘ</p>
            </Tippy>
            <input
              className={notBoosted ? 'unavailable' : ''}
              type="text"
              disabled={notBoosted}
              maxLength={18}
              placeholder={notBoosted ? 'Only boosted ōLand' : 'Amount (ōCash)'}
              value={formattedBuyNowPrice || ''}
              onChange={handleSetBuyNowPrice}
            />
          </div>
        </div>
      )}
      {notBoosted && (
        <ButtonOutlineSC
          className="color bounce"
          onClick={() => openChestModal(dispatch)}
        >
          Get Boosters
        </ButtonOutlineSC>
      )}

      <div className="confirm">
        <p>Confirm Listing</p>
        {notBoosted ? (
          <p className="red">Only boosted lands can be cancelled</p>
        ) : (
          <p className="gray">
            Once listed you can cancel via the Auction Results page
          </p>
        )}

        <ButtonPrimarySC
          onClick={handleSubmitBtnClick}
          disabled={
            !listingPrice ||
            isLoading ||
            (buyNowPrice as number) < (minimumPrice as number) ||
            !duration
          }
        >
          {isLoading ? (
            <SvgSpinner
              fill={'white'}
              style={{ maxHeight: 40, height: '100%' }}
            />
          ) : (
            'Create Listing'
          )}
        </ButtonPrimarySC>
      </div>
    </div>
  );
};

export default ListingForm;
