import styled, { css } from 'styled-components';

export const SharedInputStyled = css`
  padding: 12px;
  color: ${({ theme }) => theme.text.main};
  background-color: ${({ theme }) => theme.input.background} !important;
  background: ${({ theme }) => theme.input.background} !important;
  border: 1px solid ${({ theme }) => theme.input.border};
  border-radius: 10px;
  width: 100%;
  height: 42px;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.blue.blue100};
  }

  @media only screen and (max-width: 883px) {
    font-size: 12px !important;
    padding: 8px !important;
  }
`;

export const InputSC = styled.input`
  ${SharedInputStyled};

  &::placeholder {
    color: ${({ theme }) => theme.input.placeholder};
  }

  border: 1.5px solid ${({ theme }) => theme.input.border};
  padding: 12px;
  min-width: 64px;

  :focus {
    box-shadow: 0 0 0 2px rgb(0 125 250 / 60%);
    border-color: ${({ theme }) => theme.blue.blue100};
    outline: none;
  }

  :focus-visible {
    outline: none;
  }

  :disabled {
    background-color: ${({ theme }) => theme.input.border} !important;
    border: 1.5px solid ${({ theme }) => theme.input.border} !important;
    color: ${({ theme }) => theme.neutral.neutral7};
    font-weight: 600;
    cursor: not-allowed;
  }

  &.quantity-input {
    font-weight: 500 !important;
    padding: 20px 12px 8px 12px !important;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: inherit;
      -moz-appearance: inherit;
      appearance: auto;
    }
  }

  &.bid-input {
    font-weight: 700;
    :focus {
      border-color: ${({ theme }) => theme.blue.blue100};
      box-shadow: none !important;
      color: ${({ theme }) => theme.blue.blue100};
    }
  }

  @media only screen and (max-width: 883px) {
    font-size: 12px;
    padding: 8px;

    &.referral-input {
      height: 35px;
    }
  }
`;

export const InputWrapperSC = styled.div`
  ${SharedInputStyled};

  display: flex;
  justify-content: start;

  margin-top: 16px;
  margin-bottom: 8px;
`;

export const PlainInputSC = styled.input`
  border-width: 0;
  flex-grow: 1;
  flex-basis: 2;
  margin-top: 0 !important;
`;
