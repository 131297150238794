import React, { FC } from 'react';
import { V2BannerCardSC } from './V2AuctionBar.styled';
import olandSqVideo from '@assets/animations/oland-sq.mp4';
import { ButtonOutlineSC } from '@components/Button.styled';
import { getRarityName } from '@utils/common';
import {
  TV2BannerCardProps,
  TV2BannerCardValueProps,
} from './V2AuctionBar.types';

const CardValue: FC<TV2BannerCardValueProps> = ({ value, title, color }) => {
  return (
    <div className="floor">
      <p>{title}</p>
      <h3 style={{ color: color }}>{value}</h3>
    </div>
  );
};

const V2BannerCard: FC<TV2BannerCardProps> = ({ rarity, floor, color }) => {
  return (
    <V2BannerCardSC className={rarity} color={color}>
      <video
        width="auto"
        height="auto"
        autoPlay={true}
        loop={true}
        playsInline={true}
        preload={'auto'}
        muted
      >
        <source src={olandSqVideo} type="video/mp4" />
      </video>
      <CardValue title="Rarity" value={getRarityName(rarity)} color={color} />
      <CardValue
        title="Floor price"
        value={floor === Infinity ? 'N/A' : floor.toLocaleString('en-US')}
      />
      <ButtonOutlineSC className="color bounce">
        Boosted Auctions
      </ButtonOutlineSC>
    </V2BannerCardSC>
  );
};

export default V2BannerCard;
