export const collections = [
  {
    collection: 'ōFriends',
    price: '$89',
    openseaLink: 'https://opensea.io/collection/ofriends',
    image: 'https://raw.seadn.io/files/377758ee98214d3a91f7f11537c0b112.png',
  },
  {
    collection: 'Crypto Punks',
    price: '$80,200',
    openseaLink: 'https://opensea.io/collection/cryptopunks',
    image:
      'https://dl.openseauserdata.com/cache/originImage/files/3b34b743096f0e5c201c3a7b38e1f795.png',
  },
  {
    collection: 'Bored Ape Yacht Club',
    price: '$44,000',
    openseaLink: 'https://opensea.io/collection/boredapeyachtclub',
    image:
      'https://i.seadn.io/gae/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB?auto=format&dpr=1&w=384',
  },
  {
    collection: 'Mutant Ape Yacht Club',
    price: '$8,600',
    openseaLink: 'https://opensea.io/collection/mutant-ape-yacht-club',
    image:
      'https://i.seadn.io/gae/lHexKRMpw-aoSyB1WdFBff5yfANLReFxHzt1DOj_sg7mS14yARpuvYcUtsyyx-Nkpk6WTcUPFoG53VnLJezYi8hAs0OxNZwlw6Y-dmI?auto=format&dpr=1&w=384',
  },
  {
    collection: 'CLONE X',
    price: '$2,400',
    openseaLink: 'https://opensea.io/collection/clonex',
    image:
      'https://dl.openseauserdata.com/cache/originImage/files/cdf99799e723d15619c23a43119c7a7f.png',
  },
  {
    collection: 'Azuki',
    price: '$7,700',
    openseaLink: 'https://opensea.io/collection/azuki',
    image:
      'https://i.seadn.io/gae/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT?auto=format&dpr=1&w=384',
  },
  {
    collection: 'Milady Maker',
    price: '$4,100',
    openseaLink: 'https://opensea.io/collection/milady',
    image:
      'https://i.seadn.io/gae/a_frplnavZA9g4vN3SexO5rrtaBX_cBTaJYcgrPtwQIqPhzgzUendQxiwUdr51CGPE2QyPEa1DHnkW1wLrHAv5DgfC3BP-CWpFq6BA?auto=format&dpr=1&w=384',
  },
  {
    collection: 'Pudgy Penguins',
    price: '$6,400',
    openseaLink: 'https://opensea.io/collection/pudgypenguins',
    image:
      'https://dl.openseauserdata.com/cache/originImage/files/8bb81005f7d72339ebd9bd4f6cabfa3c.png',
  },
  {
    collection: 'VeeFriends',
    price: '$3,000',
    openseaLink: 'https://opensea.io/collection/veefriends',
    image:
      'https://i.seadn.io/gae/5y-UCAXiNOFXH551w5bWdZEYOCdHPwbqmcKb-xa3uVQEjQgxvih3HtZWSmzqDqd0uk7kIqFrZhw32Gt6xPBFg4t_n9BKhpou-dwnOg?auto=format&dpr=1&w=384',
  },
  {
    collection: 'Doodles',
    price: '$2,500',
    openseaLink: 'https://opensea.io/collection/doodles-official',
    image:
      'https://i.seadn.io/gae/7B0qai02OdHA8P_EOVK672qUliyjQdQDGNrACxs7WnTgZAkJa_wWURnIFKeOh5VTf8cfTqW3wQpozGedaC9mteKphEOtztls02RlWQ?auto=format&dpr=1&w=384',
  },
  {
    collection: 'Moonbirds',
    price: '$2,400',
    openseaLink: 'https://opensea.io/collection/proof-moonbirds',
    image: 'https://i.seadn.io/gcs/files/4bce6187fea476154b311dafaf327c89.png',
  },
  {
    collection: 'The Potatoz',
    price: '$2,300',
    openseaLink: 'https://opensea.io/collection/thepotatoz',
    image:
      'https://i.seadn.io/gcs/files/129b97582f0071212ee7cf440644fc28.gif?auto=format&dpr=1&w=384',
  },
  {
    collection: 'Kanpai Pandas',
    price: '$1,600',
    openseaLink: 'https://opensea.io/collection/kanpai-pandas',
    image:
      'https://i.seadn.io/gcs/files/74096ecd2c3119eca2bf0f59a3921455.gif?auto=format&dpr=1&w=384',
  },
  {
    collection: 'rektguy',
    price: '$1,079',
    openseaLink: 'https://opensea.io/collection/rektguy',
    image:
      'https://i.seadn.io/gcs/files/0d5f1b200a067938f507cbe12bbbabc2.jpg?auto=format&dpr=1&w=384',
  },
  {
    collection: 'mfers',
    price: '$800',
    openseaLink: 'https://opensea.io/collection/mfers',
    image:
      'https://i.seadn.io/gae/J2iIgy5_gmA8IS6sXGKGZeFVZwhldQylk7w7fLepTE9S7ICPCn_dlo8kypX8Ju0N6wvLVOKsbP_7bNGd8cpKmWhFQmqMXOC8q2sOdqw?auto=format&dpr=1&w=384',
  },
  {
    collection: 'Sappy Seals',
    price: '$690',
    openseaLink: 'https://opensea.io/collection/sappy-seals',
    image:
      'https://dl.openseauserdata.com/cache/originImage/files/03c1f1cf61ae1620c4f0882e17814fd1.png',
  },
  {
    collection: 'Impostors Genesis Aliens',
    price: '$480',
    openseaLink: 'https://opensea.io/collection/impostors-genesis-aliens',
    image:
      'https://i.seadn.io/gae/oyEO1RajjrJuCYtbXX9Oa3FhoRvX5BO_TkFc8PTUkN5egJwFG2mlM0lRl7zuOvBj_HciCY35D4exJm0b3DZya6qxpyhk0aBN7oCH?auto=format&dpr=1&w=384',
  },
  {
    collection: 'Lazy Lions',
    price: '$400',
    openseaLink: 'https://opensea.io/collection/lazy-lions',
    image:
      'https://i.seadn.io/gae/kFZpw-bkoyH03qFbfqkwkkhHVxT7qclK_tYFmhU1K2HegU3v2wSMmhL6TIgw7Stx1KIoKs1sdJQ4My71ktXV7GygPBjaQZCACERt?auto=format&dpr=1&w=384',
  },
  {
    collection: 'KILLABEARS',
    price: '$1,600',
    openseaLink: 'https://opensea.io/collection/killabears',
    image:
      'https://i.seadn.io/gcs/files/4a875f997063f4f3772190852c1c44f0.png?auto=format&dpr=1&w=384',
  },
  {
    collection: 'Murakami.Flowers Official',
    price: '$600',
    openseaLink: 'https://opensea.io/collection/murakami-flowers-2022-official',
    image:
      'https://i.seadn.io/gae/8g0poMCQ5J9SZHMsBrefrXbwzFmOQ-333l5OtbqqPW8TSGO9Stm2Rhd7kwHKsKIZPLxDjzISeeDTZ1H35t7GswPRoIfzTnNPsLs7rxw?auto=format&dpr=1&w=384',
  },
  {
    collection: 'a KID called BEAST',
    price: '$400',
    openseaLink: 'https://opensea.io/collection/akidcalledbeast',
    image:
      'https://i.seadn.io/gcs/files/48e81fed9fa93c77c004f8013c6cf268.png?auto=format&dpr=1&w=384',
  },
  {
    collection: 'JRNY CLUB',
    price: '$520',
    openseaLink: 'https://opensea.io/collection/jrny-club-official',
    image:
      'https://i.seadn.io/gcs/files/a36bb10efc0809bead74ba523faaa226.png?auto=format&dpr=1&w=384',
  },
  {
    collection: 'Chimpers',
    price: '$600',
    openseaLink: 'https://opensea.io/collection/chimpersnft',
    image:
      'https://i.seadn.io/gcs/files/439a65d82d04b4fd2ec7ba7928909cf1.png?auto=format&dpr=1&w=384',
  },
  {
    collection: 'Habbo Avatars',
    price: '$220',
    openseaLink: 'https://opensea.io/collection/habbo-avatars',
    image:
      'https://dl.openseauserdata.com/cache/originImage/files/d33f59f145d5cfa721512f54b709f760.png',
  },
  {
    collection: 'Goons of Balatroon',
    price: '$70',
    openseaLink: 'https://opensea.io/collection/goonsofbalatroon',
    image:
      'https://dl.openseauserdata.com/cache/originImage/files/7218fd08509b47672b35013c04e1d6e5.png',
  },
  {
    collection: 'Otherdeed for Otherside',
    price: '$700',
    openseaLink: 'https://opensea.io/collection/otherdeed',
    image:
      'https://i.seadn.io/gcs/files/9583ab4792a83cd81d5075b59514a34a.png?auto=format&dpr=1&w=384',
  },
  {
    collection: 'Decentraland',
    price: '$800',
    openseaLink: 'https://opensea.io/collection/decentraland',
    image:
      'https://i.seadn.io/gae/5KIxEGmnAiL5psnMCSLPlfSxDxfRSk4sTQRSyhPdgnu70nGb2YsuVxTmO2iKEkOZOfq476Bl1hAu6aJIKjs1myY?auto=format&dpr=1&w=384',
  },
  {
    collection: 'Sandbox',
    price: '$800',
    openseaLink: 'https://opensea.io/collection/sandbox',
    image:
      'https://i.seadn.io/gae/SXH8tW1siikB80rwCRnjm1a5xM_MwTg9Xl9Db6mioIk9HIlDM09pVoSR7GKJgS6ulSUpgW9BDtMk_ePX_NKgO9A?auto=format&dpr=1&w=384',
  },
];
