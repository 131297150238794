import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

const ProgressSkeleton = () => {
  return (
    <div className="progress-skeleton">
      <Skeleton containerClassName="title" />
      <Skeleton containerClassName="progress" count={4} />
      <div className="lower">
        <Skeleton containerClassName="description" count={4} />
        <Skeleton containerClassName="tiers" count={4} />
      </div>
    </div>
  );
};

const MintWindowsSkeleton = () => {
  return (
    <div className="mint-windows-skeleton">
      <Skeleton containerClassName="right" count={2} />
      <div className="left">
        <Skeleton containerClassName="progress" count={1} />
        <Skeleton containerClassName="tiers" count={4} />
      </div>
    </div>
  );
};

const UserInfoSkeleton = () => {
  return (
    <div className="user-skeleton">
      <Skeleton containerClassName="details" count={2} />
      <Skeleton containerClassName="balance" count={1} />
    </div>
  );
};

const MissionsSkeleton = () => {
  return (
    <div className="missions-skeleton">
      <Skeleton containerClassName="title" />
      <div className="lower">
        <Skeleton containerClassName="table" count={8} />
      </div>
    </div>
  );
};

const LeaderboardSkeleton = () => {
  return (
    <div className="leaderboard-skeleton">
      <Skeleton containerClassName="title" />

      <div className="lower">
        <Skeleton containerClassName="table" count={4} />
      </div>
    </div>
  );
};

export enum EDashboardSection {
  Progress = 'progress',
  MintWindows = 'mintWindows',
  UserInfo = 'userInfo',
  Missions = 'missions',
  Leaderboard = 'leaderboard',
}

type TOgemSkeletonsProps = {
  type: EDashboardSection;
};

const OgemSkeletons: FC<TOgemSkeletonsProps> = ({ type }) => {
  switch (type) {
    case EDashboardSection.Progress:
      return <ProgressSkeleton />;
    case EDashboardSection.MintWindows:
      return <MintWindowsSkeleton />;
    case EDashboardSection.UserInfo:
      return <UserInfoSkeleton />;
    case EDashboardSection.Missions:
      return <MissionsSkeleton />;
    case EDashboardSection.Leaderboard:
      return <LeaderboardSkeleton />;
    default:
      return <Skeleton containerClassName="default" />;
  }
};

export default OgemSkeletons;
