import React from 'react';

import Text, { TextVariant } from '@components/Text';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import { ArtifactSectionSC } from '../ArtifactsPage.styled';
import { getHumanReadable } from '@utils/common';
import { purposeDescriptions, TLandReward } from '@data/gifts';
import ArtifactCard from './ArtifactCard';
import {
  getArtifactAvailableForSell,
  getArtifactQty,
  getRQKArtifacts,
} from '@utils/rq-utils';
import { useQueryClient } from 'react-query';

const badgeColors = {
  artifact: '#AB9143',
  magic: '#0C8338',
  movement: '#6B0A01',
  resource: '#6C3078',
  communication: '#333596',
};

export type TArtifactSectionProps = {
  type: string;
  cards: TLandReward[];
};

/**
 * @constructor
 */
const ArtifactSection: React.FunctionComponent<TArtifactSectionProps> = ({
  type,
  cards,
}) => {
  console.debug('!!! --- Rendering ArtifactSection --- !!!');

  const queryClient = useQueryClient();

  const ownedArtifacts = queryClient.getQueryData(
    getRQKArtifacts('getArtifacts'),
  ) as any;

  const getAvailableForSell = (name) =>
    getArtifactAvailableForSell(name, ownedArtifacts);
  return (
    <ArtifactSectionSC>
      <Text variant={TextVariant.CardTitle} style={{ fontWeight: 600 }}>
        {getHumanReadable(type)}
      </Text>
      <Text
        variant={TextVariant.CardText}
        style={{ fontWeight: 500 }}
        className="gray"
      >
        {purposeDescriptions[type]}
      </Text>
      <div className="artifacts-container">
        {cards.map((artifact, index) => {
          return (
            <Tippy
              {...defaultTippyProps}
              content={`${getHumanReadable(artifact.id)}: ${
                artifact.energy
              } ⚡️`}
              key={index}
            >
              <div>
                <ArtifactCard
                  img={artifact.png}
                  type={type}
                  color={badgeColors[type]}
                  artifactQty={getArtifactQty(artifact.id)}
                  availableForSell={getAvailableForSell(artifact.id)}
                />
              </div>
            </Tippy>
          );
        })}
      </div>
    </ArtifactSectionSC>
  );
};

export default ArtifactSection;
