import React, { useEffect, useRef } from 'react';
import olandSqVideo from '@assets/animations/oland-sq.mp4';
import PngBullCard from '@assets/png/bull_card.png';

export type TVideoProps = {
  focus: boolean;
  isGen6: boolean;
  hidden?: boolean;
};

const CardVideo: React.FC<TVideoProps> = ({
  focus,
  isGen6,
  hidden = false,
}) => {
  const ref = useRef<HTMLVideoElement | any>(null);

  const loop = () => {
    ref.current.play();
  };

  const onEndedLoop = () => {
    if (focus) loop(); // when ended check if its focused then loop
  };

  useEffect(() => {
    if (focus) loop(); // when focused then loop
    if (focus === false) {
      ref.current.pause();
    }
  }, [focus]);

  return (
    <div className={isGen6 ? `gen6` : `notGen6`}>
      {isGen6 && <img src={PngBullCard} className="bull" />}
      {isGen6 && <div className="bull-overlay" />}
      {olandSqVideo && (
        <video
          style={{ visibility: hidden ? 'hidden' : 'visible' }}
          ref={ref}
          width="auto"
          height="auto"
          autoPlay={true}
          loop={true}
          playsInline={true}
          preload={'auto'}
          muted
          onEnded={onEndedLoop}
        >
          <source src={olandSqVideo} type="video/mp4" />
        </video>
      )}
    </div>
  );
};

export default CardVideo;
