import React, { useCallback } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '@store/index';
import userInterface from '@store/user-interface';
import ModalSC from './Modal.styled';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { AxiosResponse } from 'axios';
import api from '@utils/api';
import { useQuery } from 'react-query';
import { defaultModalProps } from './modal-settings';
import GlobeAnim from '@pages/Home/GlobeAnim';

const OLandModal: React.FunctionComponent = () => {
  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string };
  const dispatch = useDispatch();

  // ??
  const { data: profileData } = useQuery('getProfile', async () =>
    api.getProfile(),
  );

  const {
    data: {
      data: { positions },
    },
  } = profileData as AxiosResponse;

  const handleCloseModal = useCallback(() => {
    dispatch(
      userInterface.setModal({
        type: null,
        token: null,
      }),
    );
  }, []);

  const locations = positions.map((item) => ({
    lat: item.lat,
    lng: item.lng,
    name: item.position,
    type: item.type,
    size: 0.25,
    color: 'rgba(36, 138, 255, 1)',
  }));

  const revealedLocations = locations.filter(function (el) {
    return el.type === 0;
  });

  return (
    <ReactModal
      isOpen={type === 'oLandModal'}
      {...defaultModalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC>
        <div
          className="header"
          style={{ alignItems: 'center', marginBottom: 12 }}
        >
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            Your Plots
          </Text>
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <div>
          <div style={{ width: '100%' }}>
            <GlobeAnim
              places={revealedLocations}
              pinMarkers={false}
              rotate={false}
            />
          </div>
        </div>
      </ModalSC>
    </ReactModal>
  );
};

export default OLandModal;
