import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import api from '@utils/api';
import { useQueryClient } from 'react-query';
import { getRQKTwitterAuth, getRQKTwitterRewards } from '@utils/rq-utils';
import { toast } from 'react-toastify';
import Toast from '@components/Toast';

export default function TwitterCallback() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const state = searchParams.get('state');
    const code = searchParams.get('code');

    if (!state || !code) {
      return;
    }

    (async () => {
      setIsLoading(true);
      try {
        const response = (
          await api.postTwiterCallbackHandle({
            state,
            code,
          })
        ).data;
        if (response.status === 'ok') {
          if (response.data.bonus === 0) {
            toast(
              <Toast
                type="success"
                header="Success!"
                body="You have successfully connected your Twitter account!"
              />,
            );
          } else {
            toast(
              <Toast
                type="success"
                header="Success!"
                body="You have successfully claimed 10 free ōCash!"
              />,
            );
          }
        }
      } catch (error: any) {
        toast(
          <Toast
            type="error"
            header="Error"
            body="This Twitter handle may be linked to an existing account. If you
              believe there is an error, please contact our support team."
          />,
        );
      } finally {
        await queryClient.invalidateQueries(
          getRQKTwitterAuth('getTwitterAuth'),
        );
        await queryClient.invalidateQueries(
          getRQKTwitterRewards('getTwitterRewards'),
        );
        setIsLoading(false);
      }
    })();
  }, [location]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  navigate('/');
  return null;
}
