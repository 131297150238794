import { roundArrow } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/scale.css';

export type TFontType = 'Sofia Pro';
export type TFontWeight = 'Black' | 'Bold' | 'SemiBold' | 'Regular';

export function getFontStyle(font: TFontType, weight: TFontWeight) {
  if (weight === 'Regular') {
    return font;
  }
  return `${font} ${weight}`;
}

export const defaultTippyProps = {
  arrow: roundArrow,
  inertia: true,
  animation: 'scale',
  theme: 'light-custom',
  appendTo: () => document.body,
};
