import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TRootState } from '@store/index';
import orderStore from '@store/order';
import { ButtonPrimarySC, StyledLinkBtn } from '@components/Button.styled';
import AddressEntry from './components/AddressEntry';
import TermsFooter from './components/TermsFooter';
import { O_ROUTER_PRODUCT_ID } from '@store/order/products';
import { EPurchaseProcessSteps } from '@components/Modal/CheckoutProcess/PurchaseModal';

const BillingAddressModal = () => {
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState<null | Record<string, string>>(
    null,
  );
  const [hasError, setHasError] = useState(true);

  const order = useSelector((store: TRootState) => store.order);

  const handleFormChange = useCallback((values, errors) => {
    setHasError(!(Object.keys(errors).length === 0 && values.city));
    setFormValues(values);
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       EVIL_GLOBAL_STRIPE_PROMISE.handle = await loadStripe(
  //         String(process.env.REACT_APP_STRIPE_KEY),
  //       );
  //     } catch (e) {
  //       toast(
  //         <Toast
  //           type="error"
  //           header="Error"
  //           body="Looks like there is a problem with Stripe. Let's try another payment method"
  //         />,
  //       );
  //       Logger.log('Stripe could not be loaded');
  //     }
  //   })();
  // }, []);

  const handleContinueBtnClick = async () => {
    const values = formValues as Record<string, string>;

    const billingAddress = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      street: values.street,
      streetOpt: values.streetOpt,
      city: values.city,
      postal: values.postal,
      country: values.country,
      region: values.region,
      vatId: values.vatId,
    };

    dispatch(orderStore.setBillingAddress({ billingAddress }));

    // ??
    if (order.purchaseProductType === 'oCash') {
      dispatch(
        orderStore.setOrderStep({
          step: EPurchaseProcessSteps.StripePayment,
        }),
      );
    } else {
      dispatch(
        orderStore.setOrderStep({
          step: EPurchaseProcessSteps.PaymentSelector,
        }),
      );
    }
  };

  const handleGoBack = useCallback(() => {
    if (order.purchaseProductType !== 'oRouter') {
      dispatch(
        orderStore.setOrderStep({
          step: EPurchaseProcessSteps.PaymentSelector,
        }),
      );
    } else {
      dispatch(
        orderStore.setOrderStep({
          step: EPurchaseProcessSteps.ShippingAddress,
        }),
      );
    }
  }, []);

  return (
    <div className="body">
      <AddressEntry type="billing" onChange={handleFormChange} />
      <ButtonPrimarySC
        type="submit"
        style={{ margin: '16px 0px' }}
        onClick={handleContinueBtnClick}
        disabled={hasError}
      >
        Confirm billing address
      </ButtonPrimarySC>
      <hr />
      <StyledLinkBtn onClick={handleGoBack}>Go back</StyledLinkBtn>
      <TermsFooter noPreorder={order.productId !== O_ROUTER_PRODUCT_ID} />
    </div>
  );
};

export default BillingAddressModal;
