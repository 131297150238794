import { ButtonOutlineSC } from '@components/Button.styled';
import userInterface from '@store/user-interface';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

type TUnlockEarlyBtnProps = {
  positionId: string;
  positionType: number;
};

const UnlockEarlyBtn: React.FunctionComponent<TUnlockEarlyBtnProps> = ({
  positionId,
  positionType,
}) => {
  const dispatch = useDispatch();
  const { setModal, setEarlyPositionReveal } = userInterface;

  /**
   * Start ōLand location
   * reveal process
   */
  const openLocationUnlockModal = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      setEarlyPositionReveal({
        earlyPositionReveal: { id: positionId, type: positionType },
      }),
    );
    // open Address Check modal
    dispatch(
      setModal({
        type: 'locationUnlockModal',
        token: null,
      }),
    );
  }, []);
  return (
    <Tippy
      {...defaultTippyProps}
      content={`The location of ōLand takes time to unlock for Gen 2 and later. Click to unlock early!`}
    >
      <ButtonOutlineSC
        className="smaller dark"
        type="button"
        style={{ fontWeight: 700 }}
        onClick={openLocationUnlockModal}
      >
        UNLOCK EARLY
      </ButtonOutlineSC>
    </Tippy>
  );
};

export default UnlockEarlyBtn;
