import { TNullableString } from '@custom-types/common';

export const LS_ACCESS_TOKEN = 'access_token';
export const LS_REFRESH_TOKEN = 'refresh_token';

export const setAccessToken = (accessToken: string): void => {
  localStorage.setItem(LS_ACCESS_TOKEN, accessToken);
};

export const getAccessToken = (): TNullableString => {
  // check query params
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get('accessToken');
  if (accessToken) {
    setAccessToken(accessToken);
    return accessToken;
  }

  return localStorage.getItem(LS_ACCESS_TOKEN);
};

export const setRefreshToken = (refreshToken: string): void => {
  localStorage.setItem(LS_REFRESH_TOKEN, refreshToken);
};

export const getRefreshToken = (): TNullableString => {
  // check query params
  const urlParams = new URLSearchParams(window.location.search);
  const refreshToken = urlParams.get('refreshToken');
  if (refreshToken) {
    setRefreshToken(refreshToken);
    return refreshToken;
  }

  return localStorage.getItem(LS_REFRESH_TOKEN);
};

export const resetTokens = (): void => {
  localStorage.removeItem(LS_ACCESS_TOKEN);
  localStorage.removeItem(LS_REFRESH_TOKEN);
};

export const SS_MODAL_SHOWN = 'modal_shown';

export const getReferralModalShown = () =>
  Boolean(sessionStorage.getItem(SS_MODAL_SHOWN) === 'true');

export const setReferralModalShown = (value: boolean) => {
  sessionStorage.setItem(SS_MODAL_SHOWN, Boolean(value).toString());
};
