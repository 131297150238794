import styled, { keyframes } from 'styled-components';

export const glowAnim = keyframes`
0% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7));
}

70% {
  transform: scale(1);
  box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
}

100% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
}
`;

export default styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.neutral.neutral4};
  padding: 24px;
  background: ${({ theme }) => theme.background.regular.bg1};
  z-index: 5;

  @media only screen and (max-width: 1024px) {
    display: none;
  }

  #grid {
    display: grid;
    grid-template-columns: 2fr 3fr;
  }

  .referral-content {
    display: flex;
    align-items: end;
    justify-content: end;
    position: relative;
    width: 100%;
    a {
      transition: 0.2s;
      :hover {
        opacity: 0.7;
      }
    }
    @media only screen and (max-width: 1024px) {
      text-align: center;
    }
  }
`;

export const StyledPositionHeader = styled.div`
  padding: 16px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: none;

  @media only screen and (max-width: 1024px) {
    display: block;
    border-bottom: 1px solid ${({ theme }) => theme.input.border};
    margin-bottom: 16px;
    padding: 16px 0px;
  }
`;

export const HeaderBtnContainerSC = styled.div`
  position: relative;

  .notification {
    position: absolute;
    right: -10px;
    top: -6px;
    background: red;
    color: white;
    font-weight: 800;
    border-radius: 999px;
    width: 20px;
    height: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
