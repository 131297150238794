import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import { TRootState } from '@store/index';
import userInterfaceStore from '@store/user-interface';
import orderStore from '@store/order';
import { ButtonOutlineSC, ButtonPrimarySC } from '@components/Button.styled';
import Text, { TextVariant } from '@components/Text';
import Row, { GapSizes, JustificationTypes } from '@components/Row';
import api from '@utils/api';
import TermsFooter from './components/TermsFooter';
import CartSubtotal from './components/CartSubtotal';
import { EPurchaseProcessSteps } from '@components/Modal/CheckoutProcess/PurchaseModal';

const AddressCheckModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const order = useSelector((store: TRootState) => store.order);
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');

  const handleShippingAddressCheck = async () => {
    setIsLoading(true);
    if (order.productCount < 1) {
      dispatch(
        orderStore.setProductCount({
          count: order.productCount + 1,
        }),
      );
    }
    try {
      const result = (await api.resolveDiscount(country, region)).data;
      dispatch(
        orderStore.setCountryAndRegion({
          country,
          region,
        }),
      );
      dispatch(
        orderStore.setDiscount({
          discount: result.data.discount,
        }),
      );

      dispatch(
        orderStore.setOrderStep({
          step: EPurchaseProcessSteps.PurchaseORouterDiscount,
        }),
      );
    } catch (e) {
      // TODO: sentry
      console.error('Error: ', e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectCountry = (val) => {
    // Function to ensure region state is reset (preventing confirmation button from activating and allowing a mistatching Country/Region combination to pass)
    setCountry(val);
    setRegion('');
  };

  const handleSkipPreorder = useCallback(() => {
    dispatch(
      userInterfaceStore.setModal({
        type: 'referralModal',
        token: null,
      }),
    );
  }, []);

  const addressDisabled = region === '';

  return (
    <div className="body">
      <Text variant={TextVariant.CardText}>
        Enter your country and region to see if you qualify for a free or
        discounted ōRouter
      </Text>
      <div>
        <Row
          gapSize={GapSizes.Md}
          mobileWrap={true}
          justify={JustificationTypes.SpaceBetween}
        >
          <CountryDropdown
            value={country}
            onChange={(val) => handleSelectCountry(val)}
            classes="country-dd dropdown"
            blacklist={['IR', 'KP', 'SY', 'CU', 'VE']}
          />
          <RegionDropdown
            country={country}
            value={region}
            onChange={(val) => setRegion(val)}
            blankOptionLabel="Select Region"
            classes="region-dd dropdown"
          />
        </Row>
      </div>
      <Text
        className="gray"
        variant={TextVariant.Disclaimer}
        style={{ paddingTop: 16 }}
      >
        * The country and region entered must match your shipping address at
        checkout in order to be eligible.
      </Text>

      <CartSubtotal type="init" />

      <ButtonPrimarySC
        onClick={handleShippingAddressCheck}
        disabled={addressDisabled}
        style={{ margin: '16px 0px' }}
      >
        {isLoading ? (
          <SvgSpinner
            height={30}
            width={30}
            style={{ display: 'inline', fill: 'white' }}
          />
        ) : (
          'Check for ōRouter discount'
        )}
      </ButtonPrimarySC>
      <ButtonOutlineSC onClick={handleSkipPreorder}>
        No thanks - I just want the free ōLand
      </ButtonOutlineSC>
      <TermsFooter />
    </div>
  );
};

export default AddressCheckModal;
