import React from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Text, { TextVariant } from '@components/Text';
import { ButtonLinkSC, ButtonPrimarySC } from '@components/Button.styled';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import api from '@utils/api';
import Toast from '@components/Toast';
import { getRQKTwitterRewards } from '@utils/rq-utils';
import userInterfaceSlice from '@store/user-interface';
import ExpandableTooltipSC, {
  TExpTooltipProps,
} from './ExpandableTooltip.styled';

const challengeData = [
  {
    title: 'Hash for Cash',
    emoji: '💵',
    tooltip:
      'Create a fun tweet using #ocash / #oland (Can be a part of a reply to Overline. Limited to once daily. [1 ōCash]',
  },
  {
    title: 'Talk to Earn Bruh',
    emoji: '💵💵💵💵💵',
    tooltip:
      'Works as many times as you comment! OPTION 1: Reply to an Overline tweet and get 2 retweets + 200 views on the reply. So you have to make it good! OPTION 2: Get over 500 views on the comment. [5 ōCash]',
  },
  {
    title: 'Teamwork Dreamwork',
    emoji: '💵💵💵💵💵',
    tooltip:
      'A member of the ōLand team / partner retweets your comment OR your tweet! [5 ōCash]',
  },
  {
    title: 'Falcon Punch',
    emoji: '💵💵💵💵💵💵💵💵',
    tooltip:
      'The Overline network main twitter account retweets you. [8 ōCash]',
  },
  {
    title: 'The MegaTweet',
    emoji: '💵💵💵💵💵💵💵💵💵💵',
    tooltip:
      'A reply gets more than 10 retweets and more than 10,000 views. KaBoom! [10 ōCash]',
  },
];

const TwitterTooltip: React.FC<TExpTooltipProps> = ({ variant, shown }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, data: twitterRewards } = useQuery(
    getRQKTwitterRewards('getTwitterRewards'),
    async () => (await api.getClaimableTwitterRewards()).data.data,
  );

  const handleLeaderboardClick = () => {
    dispatch(
      userInterfaceSlice.setShowTwitterTooltip({ showTwitterTooltip: false }),
    );
    navigate('/twitter-leaderboard');
  };

  const handleClaimRewards = async () => {
    try {
      if (isLoading || parseInt(twitterRewards.unclaimed) === 0) {
        return;
      }

      await api.claimTwitterRewards();
      toast(
        <Toast
          type="success"
          header="Rewards claimed!"
          body="You have claimed your rewards, they should now be added to your balance."
        />,
      );
    } catch (e) {
      toast(
        <Toast
          type="error"
          header="Something went wrong"
          body="Unable to claim rewards. Please try again."
        />,
      );
    }
  };

  return (
    <ExpandableTooltipSC className="connect" shown={shown} variant={variant}>
      <div style={{ padding: 24, paddingBottom: 8 }}>
        <Text variant={TextVariant.CardSubtitle} style={{ fontWeight: 600 }}>
          Tweet to Earn Rewards
        </Text>
        <Text
          variant={TextVariant.CardSubtitle}
          className="color"
          style={{ fontWeight: 600 }}
        >
          {isLoading || twitterRewards === null
            ? '0'
            : parseInt(twitterRewards.unclaimed)}{' '}
          ōCash
        </Text>
        <ButtonPrimarySC
          disabled={!twitterRewards || twitterRewards.unclaimed === 0}
          onClick={handleClaimRewards}
        >
          Claim Rewards
        </ButtonPrimarySC>
        <div className="rules">
          <Text
            variant={TextVariant.CardText}
            style={{ fontWeight: 600, marginBottom: 12 }}
          >
            How to Play:
          </Text>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {challengeData.map((item, idx) => (
              <Tippy key={idx} {...defaultTippyProps} content={item.tooltip}>
                <div className="rule-item">
                  <Text variant={TextVariant.Disclaimer}>{item.emoji}</Text>
                  <Text variant={TextVariant.CardText}>{item.title}</Text>
                </div>
              </Tippy>
            ))}
          </div>
        </div>
        <ButtonLinkSC style={{ marginTop: 4 }} onClick={handleLeaderboardClick}>
          <span>Top 100 Leaderboard</span>
        </ButtonLinkSC>
        {/* <Link to="/twitter-leaderboard"></Link> */}
      </div>
    </ExpandableTooltipSC>
  );
};

export default TwitterTooltip;
