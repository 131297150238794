import React from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import twitterLogo from '@assets/svg/Twitter.svg';
import { ReactComponent as SvgChevron } from '@assets/svg/chevron-down.svg';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';

import { TRootState } from '@store/index';
import { handleMetamaskTooltip, handleTwitterTooltip } from '@store/common';
import { getRQKTwitterRewards } from '@utils/rq-utils';
import api from '@utils/api';

import ConnectButton from '../ConnectButton';
import ConnectTwitterTooltip from '../ContextTooltip/TwitterTooltip';
import { EExpTooltipVariant } from '../ContextTooltip/ExpandableTooltip.styled';

const TwitterBtn = () => {
  const dispatch = useDispatch();

  const { isLoading: twitterRewardsIsLoading, data: twitterRewards } = useQuery(
    getRQKTwitterRewards('getTwitterRewards'),
    async () => (await api.getClaimableTwitterRewards()).data.data,
  );

  const showTwitterTooltip = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.showTwitterTooltip,
  ) as boolean;

  if (twitterRewardsIsLoading) return null;

  const handleBtnClick = async () => {
    // we initiate connection, we should get URL
    const result = (await api.initTwitterStatus()).data;
    handleMetamaskTooltip(dispatch, false);
    window.open(result.data.url, '_blank');
  };

  if (twitterRewards === null) {
    return (
      <>
        <ConnectButton qty={10} onClick={handleBtnClick} showBadge={true}>
          Connect Twitter
        </ConnectButton>
        <ConnectTwitterTooltip
          shown={showTwitterTooltip}
          variant={EExpTooltipVariant.ConnectWallet}
        />
      </>
    );
  }

  return (
    <>
      <ConnectButton
        qty={twitterRewards.unclaimed}
        showBadge={twitterRewards != null && twitterRewards.unclaimed > 0}
        onClick={() => {
          handleTwitterTooltip(dispatch, !showTwitterTooltip);
          handleMetamaskTooltip(dispatch, false);
        }}
        disabled={twitterRewardsIsLoading}
      >
        {twitterRewardsIsLoading && (
          <SvgSpinner fill={'white'} style={{ maxHeight: 24 }} />
        )}
        {!twitterRewardsIsLoading && (
          <>
            <img src={twitterLogo} alt="twitter" style={{ height: 14 }} />
            Rewards
            <span>|</span>
            <SvgChevron
              style={{
                width: 12,
                fill: '#fff',
                transform: showTwitterTooltip
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
                transition: '0.2s',
              }}
            />
          </>
        )}
      </ConnectButton>
      <ConnectTwitterTooltip
        shown={showTwitterTooltip}
        variant={EExpTooltipVariant.Balance}
      />
    </>
  );
};

export default React.memo(TwitterBtn);
