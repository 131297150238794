import React from 'react';
import Lottie from 'lottie-react';
import styled from 'styled-components';
import loaderAnim from '@assets/animations/loader.json';

const Loader: React.FunctionComponent = () => {
  return (
    <StyledLoader>
      <div className="center-loader">
        <Lottie animationData={loaderAnim} loop={true} autoPlay={true} />
      </div>
    </StyledLoader>
  );
};

const StyledLoader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  .center-loader {
    width: 100px;
    height: 100px;
    margin: auto;
  }
`;

export default Loader;
