import React, { useState, useCallback } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '@store/index';
import userInterface from '@store/user-interface';
import ModalSC, { ConfirmBoosterApplicationSC } from './Modal.styled';
import Text, { TextVariant } from '@components/Text';
import { defaultModalProps } from './modal-settings';
import api from '@utils/api';
import { TActiveBooster } from '@pages/Home/components/PositionCard/types';
import openBoosterSfx from '@assets/sfx/open-booster.mp3';
import { toast } from 'react-toastify';
import Toast from '@components/Toast';
import {
  getRQKBoosters,
  getRQKPositionsKey,
  getRQKUserKeys,
  queryClient,
} from '@utils/rq-utils';
import { ButtonOutlineSC, ButtonPrimarySC } from '@components/Button.styled';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import { getColorByRarity, getRarityName } from '@utils/common';
import { useQuery } from 'react-query';
const openBoosterSound = new Audio(openBoosterSfx);

const ConfirmBoosterApplicationModal: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string };
  const activeBooster = useSelector(
    (store: TRootState) => store.userInterface.activeBooster,
  ) as TActiveBooster;

  const { data: positionDetail } = useQuery(
    getRQKPositionsKey('getPosition', activeBooster.positionId),
    async () => {
      const result = (await api.getPositionDetail(activeBooster.positionId))
        .data;

      return result.data;
    },
  );

  const dispatch = useDispatch();

  const handleCloseModal = useCallback(() => {
    dispatch(
      userInterface.setModal({
        type: null,
        token: null,
      }),
    );
    dispatch(
      userInterface.setActiveBooster({
        activeBooster: null,
      }),
    );
  }, []);

  const handleApplyBooster = async () => {
    setIsLoading(true);
    try {
      await api.applyBooster(activeBooster.boosterId, activeBooster.positionId);
      await openBoosterSound.play();
    } catch (e) {
      console.error('Booster application failed', e);
      toast(
        <Toast
          type="error"
          header="Booster application failed"
          body="The application of your booster failed. Please try again later."
        />,
      );
    } finally {
      await queryClient.invalidateQueries(
        getRQKBoosters('getBoostersToBeFound'),
      );
      await queryClient.invalidateQueries(getRQKUserKeys('getUserKeys'));

      await queryClient.invalidateQueries(getRQKBoosters('getBoosters'));
      await queryClient.invalidateQueries(
        getRQKPositionsKey('getPosition', activeBooster.positionId),
      );
      setIsLoading(false);
      handleCloseModal();
      dispatch(
        userInterface.setActiveBooster({
          activeBooster: null,
        }),
      );
      toast(
        <Toast
          type="success"
          header="Booster Applied"
          body="Your booster has been applied to your ōLand."
        />,
      );
    }
  };

  const genesis = positionDetail.type === 0;

  return (
    <ReactModal
      isOpen={type === 'confirmBoosterApplication'}
      {...defaultModalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC>
        <ConfirmBoosterApplicationSC className="body">
          <Text variant={TextVariant.CardTitle}>
            Apply booster to your ōLand?
          </Text>
          <Text variant={TextVariant.CardText} className="gray">
            Your are about to apply a{' '}
            <span style={{ color: getColorByRarity(activeBooster.rarity) }}>
              {getRarityName(activeBooster.rarity)}
            </span>{' '}
            booster to ōLand plot{' '}
            <span
              style={{
                color:
                  positionDetail.rarity &&
                  getColorByRarity(positionDetail.rarity),
              }}
            >
              {positionDetail.position.toLocaleString('en-US')}
            </span>
            .
            <br />
            This action is irreversible and will permanently upgrade your
            selected ōLand plot.
            <br />
            <br />
            {positionDetail.rarityMeta &&
              `Plot Location: ${positionDetail.rarityMeta.city}`}
            <br />
            Type: {genesis ? 'Genesis' : `Gen ${positionDetail.type + 1}`}
          </Text>

          <div className="button-wrapper">
            <ButtonOutlineSC className="cancel" onClick={handleCloseModal}>
              Cancel
            </ButtonOutlineSC>
            <ButtonPrimarySC
              className="apply"
              onClick={handleApplyBooster}
              disabled={isLoading}
            >
              {isLoading ? (
                <SvgSpinner
                  fill={'white'}
                  style={{ maxHeight: 24, maxWidth: 24 }}
                />
              ) : (
                'Apply Booster'
              )}
            </ButtonPrimarySC>
          </div>
        </ConfirmBoosterApplicationSC>
      </ModalSC>
    </ReactModal>
  );
};

export default ConfirmBoosterApplicationModal;
