import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { InputSC } from '@components/Input.styled';
import { TRootState } from '@store/index';
import { TUser } from '@store/auth/types';

const ReferralLink = () => {
  const user = useSelector<TRootState>(
    (store: TRootState) => store.auth.user,
  ) as TUser;

  const referralLink = !user.affiliateId
    ? 'Network error, please reload the app'
    : `${window.location.origin}?a=${user.affiliateId}`;

  const [copied, setCopied] = useState(false);

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3500);
  };

  return (
    <InputSC
      onClick={() => copyToClipboard(referralLink)}
      style={{ width: '100%', cursor: 'pointer' }}
      value={copied ? 'Copied to clipboard!' : referralLink}
      readOnly={true}
      className="referral-input"
    />
  );
};

export default ReferralLink;
