import Text, { TextVariant } from '@components/Text';
import userInterface from '@store/user-interface';
import api from '@utils/api';
import { getRQKProfileKey, getRQKUserKeys } from '@utils/rq-utils';
import React from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { ChestBtnSC, SelectchestsC } from '../Modal.styled';
import { EChestType } from '@store/user-interface/types';
import { EKeysModalPhase } from './OcashChestModal';
import PngKey from '@assets/png/treasure/key.png';
import PngRedChest from '@assets/png/treasure/red-chest-png.png';
import PngGreenChest from '@assets/png/treasure/green-chest-png.png';
import PngYellowChest from '@assets/png/treasure/yellow-chest-png.png';
// Red chest anim with alpha channel
import redChestMp4 from '@assets/animations/chests/red/red-chest-hevc-safari.mp4';
import redChestWebm from '@assets/animations/chests/red/red-chest-vp9-chrome.webm';

// Green chest anim with alpha channel
import greenChestMp4 from '@assets/animations/chests/green/green-chest-hevc-safari.mp4';
import greenChestWebm from '@assets/animations/chests/green/green-chest-vp9-chrome.webm';

// Yellow chest anim with alpha channel
import yellowChestMp4 from '@assets/animations/chests/yellow/yellow-chest-hevc-safari.mp4';
import yellowChestWebm from '@assets/animations/chests/yellow/yellow-chest-vp9-chrome.webm';
import { formatNumber } from '@utils/common';
import Loader from '@components/Loader';

// Video Posters

import PosterRedChest from '@assets/png/treasure/posters/red-chest-poster.png';
import PosterGreenChest from '@assets/png/treasure/posters/green-chest-poster.png';
import PosterYellowChest from '@assets/png/treasure/posters/yellow-chest-poster.png';
import PrizeCarousel from './components/PrizeCarousel';
import { toast } from 'react-toastify';
import Toast from '@components/Toast';

export const chests = [
  {
    type: EChestType.Small,
    keys: 3,
    oCashInside: { min: 5, max: 30 },
    color: 'rgb(255,0,0)',
    tier: 'Rare',
    delay: '0s',
    img: PngRedChest,
    mp4: redChestMp4,
    webm: redChestWebm,
    poster: PosterRedChest,
    midReward: 15,
    upperReward: 25,
    openSize: 'small',
  },
  {
    type: EChestType.Medium,
    keys: 30,
    oCashInside: { min: 30, max: 800 },
    color: 'rgb(0,255,0)',
    tier: 'Super Rare',
    delay: '1.5s',
    img: PngGreenChest,
    mp4: greenChestMp4,
    webm: greenChestWebm,
    poster: PosterGreenChest,
    midReward: 300,
    upperReward: 650,
    openSize: 'medium',
  },
  {
    type: EChestType.Large,
    keys: 300,
    oCashInside: { min: 800, max: 10000 },
    color: 'rgb(255,255,0)',
    tier: 'Ultra Rare',
    delay: '0.75s',
    img: PngYellowChest,
    mp4: yellowChestMp4,
    webm: yellowChestWebm,
    poster: PosterYellowChest,
    midReward: 3000,
    upperReward: 7500,
    openSize: 'large',
  },
];

type TBalanceButtonsProps = {
  oCashBal: number;
  availableKeys: number;
};

export const KeyAndOcashBalances: React.FunctionComponent<
  TBalanceButtonsProps
> = ({ oCashBal, availableKeys }) => {
  const dispatch = useDispatch();
  return (
    <div className="buttons">
      <p className="bal">
        💵 {formatNumber(parseFloat(oCashBal.toLocaleString()))}
      </p>
      <div className="keys">
        <div className="value">
          <img src={PngKey} style={{ height: 16 }} />
          {availableKeys}
        </div>
        <button
          className="buy-keys-btn"
          disabled={true}
          onClick={() => {
            return;
            dispatch(
              userInterface.setKeysModalStage({
                keysModalStage: EKeysModalPhase.BuyKeys,
              }),
            );
          }}
        >
          <Text variant={TextVariant.CardText}>Buy Keys</Text>
        </button>
      </div>
    </div>
  );
};

const SelectChestPhase: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const { isLoading: isKeysLoading, data: userKeyData } = useQuery(
    getRQKUserKeys('getUserKeys'),
    async () => (await api.getUserKeys()).data.data,
  );

  const { isLoading: isOCashDataLoading, data: oCashAmountData } = useQuery(
    getRQKProfileKey('getOCashAmount'),
    async () => (await api.getOCashAmount()).data.data,
  );

  if (isOCashDataLoading || isKeysLoading) {
    return <Loader />;
  }

  return (
    <SelectchestsC
      style={{ textAlign: 'center' }}
      className="chest-selection-wrapper"
    >
      <div className="inner-wrapper">
        <KeyAndOcashBalances
          oCashBal={oCashAmountData.oCashAmountAvailable}
          availableKeys={userKeyData.available.length}
        />
        <PrizeCarousel />
        <div className="text">
          <Text variant={TextVariant.CardSubtitle}>Select Chest Type</Text>
        </div>
        <div className="chest-selection">
          {chests.map((type, idx) => {
            return (
              <button
                style={{ background: 'none', border: 'none' }}
                key={idx}
                onClick={() => {
                  if (userKeyData.available.length < type.keys) {
                    toast(
                      <Toast
                        type="warning"
                        header="Insufficient Keys!"
                        body="You need more keys to open this chest."
                      />,
                    );
                    dispatch(
                      userInterface.setKeysModalStage({
                        keysModalStage: EKeysModalPhase.BuyKeys,
                      }),
                    );
                  } else {
                    dispatch(
                      userInterface.setKeysModalStage({
                        keysModalStage: EKeysModalPhase.OpenChest,
                      }),
                    );
                    dispatch(
                      userInterface.setChestType({
                        chestType: type.type,
                      }),
                    );
                  }
                }}
              >
                <Text
                  variant={TextVariant.CardText}
                  style={{ fontWeight: 600 }}
                >
                  {type.keys}x{' '}
                  <img src={PngKey} alt="Key" className="key-img" />
                </Text>
                <ChestBtnSC
                  color={type.color}
                  disabled={userKeyData.available.length < type.keys}
                  style={{ animationDelay: type.delay }}
                  className="animated"
                >
                  <img
                    src={type.img}
                    alt={`${type.type} Chest`}
                    className="chest glow"
                  />
                </ChestBtnSC>
                <Text
                  variant={TextVariant.CardText}
                  style={{ fontWeight: 600, marginTop: 12 }}
                >
                  {type.tier} Chest
                </Text>
              </button>
            );
          })}
        </div>
      </div>
    </SelectchestsC>
  );
};

export default SelectChestPhase;
