import React, { useState, useMemo } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '@store/index';
import userInterfaceStore from '@store/user-interface';
import { ButtonOutlineSC, ButtonPrimarySC } from '@components/Button.styled';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { defaultModalProps } from '@components/Modal/modal-settings';
import api from '@utils/api';
import TermsFooter from './CheckoutProcess/components/TermsFooter';
import ModalSC from './Modal.styled';
import { Query, useQueryClient } from 'react-query';
import { getRQKPositionsKey, getRQKProfileKey } from '@utils/rq-utils';
import { openOCashModal } from '@store/common';

// TODO: Load reward from backend

const LocationUnlockModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);

  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const [view, setView] = useState<'init' | 'review'>('init');

  const { id: positionId, type: positionType } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.earlyPositionReveal,
  ) as any;

  const rewardPrice = positionType === 5 ? 2000 : 200;

  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const handleSubmitRevealRequest = async () => {
    setIsLoading(true);
    try {
      const result = await api.createLocationRevealRequest(positionId);
      setLat(result.data.data.lat);
      setLng(result.data.data.lng);
      setView('review');
      queryClient
        .invalidateQueries(getRQKPositionsKey('getPosition', positionId))
        .then();
    } catch {
      console.error('Could not unlock land');
    } finally {
      setIsLoading(false);
    }
  };

  const {
    state: {
      // @ts-expect-error they don't know
      data: { oCashAmountAvailable },
    },
  } = queryClient
    .getQueryCache()
    .find(getRQKProfileKey('getOCashAmount')) as Query;

  const handleCloseModal = async () => {
    await queryClient.invalidateQueries(getRQKProfileKey('getOCashAmount'));

    dispatch(
      userInterfaceStore.setModal({
        type: null,
        token: null,
      }),
    );
  };

  const handleConfirmClick = async () => {
    setIsLoading(true);
  };

  const handleOpenLocation = () => {
    window.open(
      `https://gps-coordinates.org/my-location.php?lat=${lat}&lng=${lng}`,
      '_blank',
      'noopener,noreferrer',
    );
  };

  const init = useMemo(
    () => (
      <>
        <div className="header">
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            Unlock your ōLand
          </Text>
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <div className="body">
          <Text variant={TextVariant.CardText} className="gray">
            You are about to pay to unlock your ōLand&apos;s location early. Are
            you sure?
          </Text>
          <br />
          <Text variant={TextVariant.CardText} className="gray">
            Price to unlock early:
          </Text>
          <Text variant={TextVariant.Subtitle} className="blue">
            {rewardPrice} ōCash
          </Text>

          <div
            style={{
              display: 'flex',
              gap: 16,
              justifyContent: 'space-between',
              margin: '24px 0px',
            }}
          >
            <ButtonOutlineSC onClick={handleCloseModal}>Close</ButtonOutlineSC>
            <ButtonPrimarySC
              onClick={
                rewardPrice > oCashAmountAvailable
                  ? () => openOCashModal(dispatch)
                  : handleSubmitRevealRequest
              }
              disabled={isLoading}
              className={rewardPrice > oCashAmountAvailable ? 'green' : ''}
            >
              {rewardPrice > oCashAmountAvailable
                ? 'Get more ōCash'
                : 'Unlock early'}
            </ButtonPrimarySC>
          </div>
        </div>
      </>
    ),
    [setView, handleConfirmClick],
  );

  const review = (
    <>
      <div className="header">
        <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
          Congratulations!
        </Text>
        <button onClick={handleCloseModal}>
          <img src={SvgCross} alt="cross-btn" />
        </button>
      </div>
      <div className="body">
        <Text variant={TextVariant.CardText} className="gray">
          Your ōLand has been unlocked early. Click the button below to view
          your location attributes.
        </Text>

        <ButtonOutlineSC style={{ marginTop: 16 }} onClick={handleOpenLocation}>
          Lookup Location
        </ButtonOutlineSC>

        <ButtonOutlineSC style={{ marginTop: 16 }} onClick={handleCloseModal}>
          Close
        </ButtonOutlineSC>
      </div>
    </>
  );

  return (
    <ReactModal
      isOpen={type === 'locationUnlockModal'}
      {...defaultModalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC>
        {view === 'init' && init}
        {view === 'review' && review}
        <TermsFooter noPreorder={true} />
      </ModalSC>
    </ReactModal>
  );
};

export default LocationUnlockModal;
