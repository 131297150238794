import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import Skeleton from 'react-loading-skeleton';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import { InventoryItemSC, TreatBagSC } from './InventoryBar.styled';
import { TOTreatFlavour } from '@store/user-interface/types';
import { getRQKUserRewardsKey } from '@utils/rq-utils';
import InventoryActionButtons from './InventoryActionButtons';
import api from '@utils/api';
import Row, { JustificationTypes } from '@components/Row';
import { TRootState } from '@store/index';
import userInterface from '@store/user-interface';

const treatImages = {
  chocolate: require('@assets/png/treats/chicken.png'),
  lime: require('@assets/png/treats/fish.png'),
  cranberry: require('@assets/png/treats/goat.png'),
  honey: require('@assets/png/treats/honey.png'),
  caramel: require('@assets/png/treats/lobster.png'),
  watermelon: require('@assets/png/treats/watermelon.png'),
};

type UserRewardsData = {
  sortedTreats: {
    chocolate: string[];
    lime: string[];
    cranberry: string[];
    honey: string[];
    caramel: string[];
    watermelon: string[];
  };
  claimableId: string | null;
};

const TreatBag: React.FC<{
  items: string[];
  flavour: TOTreatFlavour;
  isSelected: boolean;
  isEmpty: boolean;
  onClick: () => void;
}> = ({ items, flavour, isSelected, isEmpty, onClick }) => (
  <Tippy
    {...defaultTippyProps}
    content={`${flavour.charAt(0).toUpperCase() + flavour.slice(1)} ōTreats`}
  >
    <InventoryItemSC
      className={`${isSelected && 'selected'} ${isEmpty && 'empty'}`}
      empty={isEmpty}
      selected={isSelected}
      disabled={isEmpty}
      onClick={onClick}
    >
      <div className="outer-wrapper">
        <div className="qty-box">
          <span>{items.length}</span>
        </div>
        <TreatBagSC src={treatImages[flavour]} />
      </div>
    </InventoryItemSC>
  </Tippy>
);

const TreatButtons: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const selectedTreatType = useSelector(
    (store: TRootState) => store.userInterface.selectedTreatType,
  );

  const { isLoading: userRewardsQueryLoading, data } =
    useQuery<UserRewardsData>(
      getRQKUserRewardsKey('getUserRewards'),
      async () => {
        const result = (await api.getUserRewards()).data.data;
        const availableTreats = result.available;
        const claimableTreats = result.claimable;

        const sortedTreats = {
          chocolate: [],
          lime: [],
          cranberry: [],
          honey: [],
          caramel: [],
          watermelon: [],
        };

        for (const oTreat of availableTreats) {
          sortedTreats[oTreat.meta.type].push(oTreat.id);
        }

        return {
          sortedTreats,
          claimableId:
            claimableTreats.length > 0 ? claimableTreats[0].id : null,
        };
      },
    );

  if (userRewardsQueryLoading) {
    return (
      <div>
        <Skeleton containerClassName="action-skeleton" count={3} height={30} />
        <Skeleton containerClassName="treat-skeleton" count={6} />
      </div>
    );
  }

  const { sortedTreats, claimableId } = data as UserRewardsData;

  const allAvailableTreats = Object.values(sortedTreats).flat();

  return (
    <div>
      <InventoryActionButtons
        allAvailableTreats={allAvailableTreats.length}
        claimableId={claimableId as string}
      />
      <Row
        justify={JustificationTypes.SpaceBetween}
        style={{ alignItems: 'center', width: 'auto' }}
        className="treat-row"
      >
        {Object.keys(sortedTreats).map((flavour) => {
          const items = sortedTreats[flavour] || [];
          const isSelected = flavour === selectedTreatType;
          const isEmpty = items.length < 1;

          return (
            <TreatBag
              key={flavour}
              items={items}
              flavour={flavour as TOTreatFlavour}
              isSelected={isSelected}
              isEmpty={isEmpty}
              onClick={() => {
                dispatch(
                  userInterface.setSelectedTreatType({
                    selectedTreatType: flavour as TOTreatFlavour,
                  }),
                );
              }}
            />
          );
        })}
      </Row>
    </div>
  );
};

export default TreatButtons;
