import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import Confetti from 'react-dom-confetti';
import { getHumanReadable } from '@utils/common';
import { TRootState } from '@store/index';
import userInterfaceStore from '@store/user-interface';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import {
  defaultModalProps,
  defaultModalStyle,
} from '@components/Modal/modal-settings';
import ModalSC from './Modal.styled';
import oCashPng from '@assets/png/ocash-paper.png';
import chimeSfx from '@assets/sfx/chime.mp3';
import fanfareSfx from '@assets/sfx/fanfare.mp3';
import { getRQKPositionsKey } from '@utils/rq-utils';
import { ButtonOutlineSC } from '@components/Button.styled';
import treatAnim from '@assets/animations/goat-anim.mp4';
import { allRewards } from 'data/gifts';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';

export const confettiConfig = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 30000,
  stagger: 3,
  width: '10px',
  height: '10px',
  perspective: '500px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const RevealResultModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [playAnim, setPlayAnim] = useState(true);
  const [shootConfetti, setShootConfetti] = useState(false);

  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const revealProcess = useSelector(
    (store: TRootState) => store.userInterface.revealProcess,
  ) as { id: string; result: string | false } | null;

  // Do nothing if there is no reveal in process
  if (revealProcess === null) {
    return null;
  }

  useEffect(() => {
    setTimeout(async () => {
      await setPlayAnim(false);
      const audio = new Audio(
        revealProcess.result === 'dog' ? fanfareSfx : chimeSfx,
      );
      await audio.play();
      await setShootConfetti(true);
    }, 3500);
  }, []);

  const handleCloseModal = async () => {
    await queryClient.invalidateQueries(
      getRQKPositionsKey('getPosition', revealProcess.id),
    );
    dispatch(userInterfaceStore.closeRevealProcessModal());
  };

  const modalProps = {
    ...defaultModalProps,
    style: {
      ...defaultModalStyle,
      content: {
        ...defaultModalStyle.content,
        overflow: 'hidden',
      },
    },
  };

  const upgradeCardImg = allRewards.filter((obj) => {
    if (obj.id === revealProcess.result) {
      return obj;
    }
  });

  return (
    <ReactModal
      isOpen={type === 'revealResultModal'}
      {...modalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC>
        <div className="header">
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            Upgrades Complete!
          </Text>
          {playAnim ? (
            <SvgSpinner
              height={30}
              width={30}
              style={{ display: 'inline', fill: 'black' }}
            />
          ) : (
            <button onClick={handleCloseModal} disabled={playAnim}>
              <img src={SvgCross} alt="cross-btn" />
            </button>
          )}
        </div>
        <div className="body">
          <div className="hoo-hoo" style={{ zIndex: 2, maxWidth: '100%' }}>
            <Confetti active={shootConfetti} config={confettiConfig} />
          </div>
          <div>
            <Text variant={TextVariant.CardText} className="gray">
              {revealProcess.result
                ? 'Congratulations, the ōFriends have successfully upgraded your land!'
                : 'Eep! The ōFriends were grumpy and did not build a new upgrade this time, so they have left you 10 ōCash instead. You can use it to get more ōTreats and try your luck again!'}
            </Text>
            <div style={{ textAlign: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {revealProcess.result ? (
                  <div
                    style={{
                      borderRadius: 10,
                      overflow: 'hidden',
                      maxHeight: 350,
                      position: 'relative',
                      margin: '16px 0px',
                      zIndex: 1,
                    }}
                  >
                    <video
                      style={{
                        borderRadius: 10,
                        opacity: shootConfetti ? 0 : 1,
                      }}
                      autoPlay={true}
                      loop={false}
                      playsInline={true}
                      preload={'auto'}
                      muted
                      className="treat-anim"
                    >
                      <source src={treatAnim} type="video/mp4" />
                    </video>

                    <div
                      className="artifact-card"
                      style={{
                        display:
                          revealProcess.result === 'dog' ? 'flex' : 'block',
                      }}
                    >
                      {revealProcess.result === 'dog' ? (
                        <Text
                          variant={TextVariant.Title}
                          className="color"
                          style={{
                            fontWeight: 600,
                            marginBottom: 12,
                            opacity: playAnim ? 0 : 1,
                            fontSize: 36,
                          }}
                        >
                          <span>ōFriend!</span>
                        </Text>
                      ) : (
                        <div>
                          <Text
                            variant={TextVariant.Title}
                            className="color"
                            style={{
                              fontWeight: 600,
                              marginBottom: 12,
                              opacity: playAnim ? 0 : 1,
                            }}
                          >
                            <span>
                              {getHumanReadable(revealProcess.result)}
                            </span>
                          </Text>
                          <img
                            style={{
                              opacity: playAnim ? 0 : 1,
                              maxWidth: 200,
                            }}
                            src={upgradeCardImg[0].png}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <img src={oCashPng} alt="ōCash" style={{ width: 200 }} />
                  </div>
                )}
              </div>

              <ButtonOutlineSC
                onClick={handleCloseModal}
                style={{ marginTop: 24, maxWidth: 250, margin: 'auto' }}
              >
                Return to your ōLand
              </ButtonOutlineSC>
            </div>
          </div>
        </div>
      </ModalSC>
    </ReactModal>
  );
};

export default RevealResultModal;
