import React from 'react';
import PngRedBooster from '@assets/png/boosters/red-booster-png.png';
import PngGreenBooster from '@assets/png/boosters/green-booster-png.png';
import PngYellowBooster from '@assets/png/boosters/yellow-booster-png.png';

type TBoosterImgProps = {
  rarity: string;
};

const BoosterImage: React.FunctionComponent<TBoosterImgProps> = (
  props: TBoosterImgProps,
) => {
  switch (props.rarity) {
    case 'rare':
      return <img src={PngRedBooster} />;
    case 'super':
      return <img src={PngGreenBooster} />;
    case 'ultra':
      return <img src={PngYellowBooster} />;
    default:
      return <img src={PngRedBooster} />;
  }
};

export default BoosterImage;
