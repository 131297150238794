import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useQueryClient } from 'react-query';
import Auction from '@components/Auction';
import InventoryBar from '@components/InventoryBar';
import PositionsHeader from '@components/PositionsHeader';
import PositionsGrid from '@components/PositionsGrid/PositionsGrid';
import MobilePositionHeader from '@components/PositionsHeader/MobilePositionHeader';
import { getRQKArtifacts, getRQKPositionsKey } from '@utils/rq-utils';
import api from '@utils/api';
import userInterfaceStore from '@store/user-interface';
import { StyledHome } from './Home.styled';
import { getReferralModalShown, setReferralModalShown } from '@utils/storage';
import { TRootState } from '@store/index';
import { TActiveBooster } from './components/PositionCard/types';
import V2AuctionBar from '@components/V2AuctionBar';
import OpenedChestsBanner from '@components/OpenedChestsBanner';

const Home: React.FunctionComponent = () => {
  console.debug('Rendering Home');

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const activeBooster = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.activeBooster,
  ) as TActiveBooster;

  // Prefetch artifacts
  queryClient.prefetchQuery(
    getRQKArtifacts('getArtifacts'),
    async () => await (await api.getArtifacts()).data.data,
  );

  useEffect(() => {
    if (!getReferralModalShown()) {
      setReferralModalShown(true);
      dispatch(
        userInterfaceStore.setModal({
          type: 'referralModal',
          token: null,
        }),
      );
    }
  }, []);

  //

  const { isLoading, data: positionCountData } = useQuery(
    getRQKPositionsKey('getPositionCount'),
    async () => (await api.getPositionCount()).data.data,
  );

  if (isLoading) return null;

  return (
    <>
      <StyledHome
        className={`inner-content ${activeBooster && activeBooster.rarity}`}
      >
        <V2AuctionBar />
        <div className="tray-grid">
          <div className="inventory">
            <Auction />
            <PositionsHeader
              positionsCount={positionCountData.positionsCount}
            />
            <MobilePositionHeader
              positionsCount={positionCountData.positionsCount}
            />
            <PositionsGrid />
          </div>
        </div>
        <InventoryBar />
      </StyledHome>
    </>
  );
};

export default Home;
