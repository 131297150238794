import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactModal from 'react-modal';

import { TRootState } from '@store/index';
import orderStore from '@store/order';
import userInterfaceStore from '@store/user-interface';
import { defaultModalProps } from '@components/Modal/modal-settings';
import OCashModal from '@components/Modal/CheckoutProcess/OcashModal';
import PaymentMethodModal from '@components/Modal/CheckoutProcess/PaymentMethodModal';
import AddressCheckModal from '@components/Modal/CheckoutProcess/AddressCheckModal';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';

import StyledModal from '../Modal.styled';
import BitPayPayment from '@components/Modal/CheckoutProcess/BitPayPayment';
import BillingAddressModal from '@components/Modal/CheckoutProcess/BillingAddressModal';
import ShippingAddressModal from '@components/Modal/CheckoutProcess/ShippingAddressModal';
import DiscountInfoModal from '@components/Modal/CheckoutProcess/DiscountInfoModal';
import WertPaymentModal from '@components/Modal/CheckoutProcess/WertPaymentModal';
import CustomGWPaymentModal from '@components/Modal/CheckoutProcess/CustomGWPaymentModal';

const ModalOuter = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();

  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const handleCloseModal = useCallback(() => {
    dispatch(orderStore.clearCart());
    dispatch(
      userInterfaceStore.setModal({
        type: null,
        token: null,
      }),
    );
  }, []);

  return (
    <ReactModal
      isOpen={type === 'purchaseModal'}
      {...defaultModalProps}
      onRequestClose={handleCloseModal}
    >
      {children}
    </ReactModal>
  );
};

export enum EPurchaseProcessSteps {
  PurchaseOCashInit = 1,
  PurchaseORouterInit,
  PurchaseORouterDiscount,
  PaymentSelector,
  ShippingAddress,
  BillingAddress,
  StripePayment,
  BitPayPayment,
  WertPayment,
  CustomGWPayment,
}

const CheckoutProcessModal = () => {
  const dispatch = useDispatch();

  const currentPurchaseStep = useSelector(
    (store: TRootState) => store.order.currentStep,
  );

  const handleCloseModal = useCallback(() => {
    dispatch(orderStore.clearCart());
    dispatch(
      userInterfaceStore.setModal({
        type: null,
        token: null,
      }),
    );
  }, []);
  1;

  if (!currentPurchaseStep) return null;

  let modalInner: React.ReactNode | null = null;
  let modalHeading = '';

  switch (currentPurchaseStep) {
    case EPurchaseProcessSteps.PurchaseOCashInit:
      modalInner = <OCashModal />;
      modalHeading = 'Buy ōCash';
      break;
    case EPurchaseProcessSteps.PurchaseORouterInit:
      modalInner = <AddressCheckModal />;
      modalHeading = 'Check ōRouter discount';
      break;
    case EPurchaseProcessSteps.PurchaseORouterDiscount:
      modalInner = <DiscountInfoModal />;
      modalHeading = 'Congratulations!';
      break;
    case EPurchaseProcessSteps.PaymentSelector:
      modalInner = <PaymentMethodModal />;
      modalHeading = 'Payment method';
      break;
    case EPurchaseProcessSteps.ShippingAddress:
      modalInner = <ShippingAddressModal />;
      modalHeading = 'Shipping Details';
      break;
    case EPurchaseProcessSteps.BillingAddress:
      modalInner = <BillingAddressModal />;
      modalHeading = 'Billing details';
      break;
    case EPurchaseProcessSteps.StripePayment:
      //modalInner = <StripePaymentModal />;
      modalInner = <div />;
      modalHeading = 'Payment Details';
      break;
    case EPurchaseProcessSteps.WertPayment:
      modalInner = <WertPaymentModal />;
      modalHeading = 'Wert Payment Details';
      break;
    case EPurchaseProcessSteps.BitPayPayment:
      modalInner = <BitPayPayment />;
      modalHeading = 'Payment Details';
      break;
    case EPurchaseProcessSteps.CustomGWPayment:
      modalInner = <CustomGWPaymentModal />;
      modalHeading = 'Ethereum Payment';
      break;
  }

  return (
    <ModalOuter>
      <StyledModal>
        <div className="header" style={{ marginBottom: 16 }}>
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            {modalHeading}
          </Text>
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        {modalInner}
      </StyledModal>
    </ModalOuter>
  );
};

export default CheckoutProcessModal;
