import React, { FC } from 'react';
import { PrizeCarouselSC } from './PrizeCarousel.styled';
import { collections } from './collections';
import InfiniteLooper from '@components/InfiniteLooper';
import Text, { TextVariant } from '@components/Text';

type TPrizeCarouselProps = {
  keySelection?: boolean;
};

const PrizeCarousel: FC<TPrizeCarouselProps> = ({ keySelection }) => {
  return (
    <PrizeCarouselSC>
      {keySelection ? (
        <>
          <Text variant={TextVariant.CardSubtitle} className="title">
            Get Keys
          </Text>
          <Text
            variant={TextVariant.CardText}
            style={{ lineHeight: '125%', marginBottom: 12 }}
          >
            <span>
              Keys open chests containing ōCash, ōLand, and these rare NFTs!
            </span>
          </Text>
        </>
      ) : (
        <h6>Super Prize Pool #1:</h6>
      )}

      <InfiniteLooper speed={50} direction="right">
        {collections.map((collection, index) => {
          return (
            <div className="slide" key={index}>
              <div className="image">
                <img src={collection.image} alt={collection.name} />
              </div>
              <div className="content">
                <h2 className="title">{collection.name}</h2>
                <p className="description">{collection.price}</p>
              </div>
            </div>
          );
        })}
      </InfiniteLooper>
    </PrizeCarouselSC>
  );
};

export default PrizeCarousel;
