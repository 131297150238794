import React from 'react';
import Text, { TextVariant } from '@components/Text';

import { AuctionHeaderSC } from '../Auction.styled';

export type TAuctionHeaderProps = {
  title: string;
};

/**
 * @constructor
 */

const AuctionHeader: React.FunctionComponent<TAuctionHeaderProps> = ({
  title,
}) => {
  return (
    <AuctionHeaderSC>
      <div className="title">
        <div className="pulse">
          <div className="core"></div>
          {/* <div className="radar"></div> */}
        </div>
        <Text
          style={{ fontWeight: 500, margin: '12px 0px' }}
          variant={TextVariant.CardTitle}
        >
          {title}
        </Text>
      </div>
      {/* <div className="auction-controls">
        <div className="arrow-button">{'<'}</div>
        <div>1/4</div>
        <div className="arrow-button">{'>'}</div>
      </div> */}
    </AuctionHeaderSC>
  );
};

export default AuctionHeader;
