import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { allRewards, TArtifact } from '../../../data/gifts';
import { getHumanReadable } from '@utils/common';
import Pagination from '@components/Pagination';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';

type TUpgradeTrayProps = {
  ownedArtifacts: TArtifact[];
  positionDataIsLoading: boolean;
  isGen6: boolean;
};

const UpgradeTray: React.FC<TUpgradeTrayProps> = (props: TUpgradeTrayProps) => {
  const { ownedArtifacts } = props;
  const [firstPageItem, setFirstPageItem] = useState(0);
  const [lastPageItem, setLastPageItem] = useState(15);
  const [page, setPage] = useState(1);
  const [totalPages] = useState(4);

  const handlePrevPage = () => {
    setPage((prevPage) => prevPage - 1);
    setFirstPageItem(firstPageItem - 15);
    setLastPageItem(lastPageItem - 15);
  };

  const handleNextPage = () => {
    setPage((nextPage) => nextPage + 1);
    setFirstPageItem(firstPageItem + 15);
    setLastPageItem(lastPageItem + 15);
  };

  return (
    <>
      <div className="upgrades-tray">
        {allRewards.slice(firstPageItem, lastPageItem).map((reward) => {
          return (
            <Tippy
              {...defaultTippyProps}
              key={reward.id}
              content={`${getHumanReadable(reward.id)}`}
            >
              <div
                className={`reward ${
                  ownedArtifacts
                    .map((e: TArtifact) => e.name)
                    .includes(reward.id)
                    ? 'reward-present'
                    : ''
                }`}
              >
                <img
                  src={reward.img}
                  style={{ width: '100%', height: '100%' }}
                />

                {/* TODO: Debug loading lag */}
                {/* {props.positionDataIsLoading ? (
                  'loading'
                ) : (
                  <img
                    src={reward.img}
                    style={{ width: '100%', height: '100%' }}
                  />
                )} */}
              </div>
            </Tippy>
          );
        })}
      </div>
      <Pagination
        unlocked={ownedArtifacts.length}
        allRewards={allRewards.length}
        totalPages={totalPages}
        currentPage={page}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        isGen6={props.isGen6}
      />
      <ReactTooltip place="top" type="info" effect="solid" />
    </>
  );
};

const memoizedUpgradeTray = React.memo(UpgradeTray);

export default memoizedUpgradeTray;
