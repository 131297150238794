import React, { FC } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '@store/index';
import userInterfaceStore from '@store/user-interface';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { defaultModalProps } from '@components/Modal/modal-settings';
import ModalSC from '../../Modal.styled';
import { V2PurchaseModalSC } from './V2PurchaseModal.styled';
import Bidding from './components/Bidding';
import BuyNow from './components/BuyNow';
import Item from './components/Item';

enum EPurchaseType {
  Bidding = 'Bidding',
  BuyNow = 'BuyNow',
}

const renderPhase = (phase: null | EPurchaseType, buyNow: boolean) => {
  switch (phase) {
    case EPurchaseType.Bidding:
      return <Bidding />;
    case EPurchaseType.BuyNow:
      return <BuyNow />;

    default:
      return buyNow ? <BuyNow /> : <Bidding />;
  }
};

const V2PurchaseModal: FC = () => {
  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(
      userInterfaceStore.setModal({
        type: null,
        token: null,
      }),
    );
  };

  return (
    <ReactModal
      isOpen={type === 'v2PurchaseModal'}
      {...defaultModalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC>
        <div className="header">
          <Text
            style={{ fontWeight: 500, textAlign: 'left', marginBottom: 16 }}
            variant={TextVariant.Subtitle}
          >
            Buy ōLand
          </Text>
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <V2PurchaseModalSC className="body">
          <Item isLoading={true} />
          {renderPhase(null, true)}
        </V2PurchaseModalSC>
      </ModalSC>
    </ReactModal>
  );
};

export default V2PurchaseModal;
