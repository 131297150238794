import React from 'react';
import Text, { TextVariant } from '@components/Text';

// TODO: WTF? Refactor per noPreorder
export type TTermsFooter = {
  noPreorder?: boolean;
};
const TermsFooter: React.FunctionComponent<TTermsFooter> = ({ noPreorder }) => {
  return (
    <div>
      {' '}
      <Text
        variant={TextVariant.CardText}
        style={{ textAlign: 'center', marginTop: 16 }}
      >
        <a
          href="https://docs.overline.network/docs/amcegr-terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms {'&'} Conditions
        </a>
        <span style={{ display: noPreorder ? 'none' : '' }}>
          {'    '}|{'    '}
        </span>
        <a
          href="https://docs.overline.network/docs/amcegr-pre-order-agreement"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: noPreorder ? 'none' : '' }}
        >
          Preorder Agreement
        </a>{' '}
      </Text>
    </div>
  );
};

export default TermsFooter;
