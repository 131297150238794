import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ButtonOutlineSC } from '@components/Button.styled';
import { getRQKArtifacts, getRQKAuctionsKey } from '@utils/rq-utils';
import api from '@utils/api';
import artifactsByPurpose from '@data/gifts';
import { ArtifactsContainerSC } from './ArtifactsPage.styled';
import ArtifactSection from './components/ArtifactSection';

const ArtifactsPage: React.FunctionComponent = () => {
  console.debug('!!! -- Rendering Artifacts Page -- !!!');

  const navigate = useNavigate();

  const { isLoading: artifactPageIsLoading } = useQuery(
    getRQKArtifacts('getArtifacts'),
    async () => (await api.getArtifacts()).data.data,
  );

  const { isLoading: blockedItemsAreLoading } = useQuery(
    getRQKAuctionsKey('getBlockedItems'),
    async () => (await api.getBlockedItems()).data.data,
  );

  if (artifactPageIsLoading || blockedItemsAreLoading) {
    return null;
  }

  return (
    <ArtifactsContainerSC>
      <div className="content-wrapper">
        <ButtonOutlineSC
          onClick={() => navigate('/')}
          style={{ maxWidth: 120, marginBottom: 20 }}
        >
          Go Back
        </ButtonOutlineSC>
        {/* Artifact Sections */}
        {Object.keys(artifactsByPurpose).map((type, index) => (
          <ArtifactSection
            type={type}
            key={index}
            cards={artifactsByPurpose[type]}
          />
        ))}
      </div>
    </ArtifactsContainerSC>
  );
};

export default ArtifactsPage;
