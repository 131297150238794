import Text, { TextVariant } from '@components/Text';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonOutlineSC } from '@components/Button.styled';
import CustomTooltipSC, {
  ECustomTooltipVariant,
  TCustomTooltipProps,
} from './CustomTooltip.styled';
import { openOCashModal } from '@store/common';

const CustomTooltip: React.FunctionComponent<TCustomTooltipProps> = ({
  claimedAmount,
  variant,
  shown,
}) => {
  const [isOpen, setIsOpen] = useState(shown);
  const dispatch = useDispatch();
  const claimed = claimedAmount === undefined ? 0 : claimedAmount;

  const renderInnerContent = (variant) => {
    if (variant === ECustomTooltipVariant.DailyCash) {
      return (
        <>
          <Text
            variant={TextVariant.CardText}
            className="color"
            style={{ fontWeight: 600, fontSize: 16 }}
          >
            You claimed {claimed} free ōCash. Check again tomorrow or buy more
            below:
          </Text>
          <ButtonOutlineSC
            onClick={() => openOCashModal(dispatch)}
            style={{ maxWidth: 200, marginTop: 16 }}
          >
            Buy ōCash
          </ButtonOutlineSC>
        </>
      );
    }

    if (variant === ECustomTooltipVariant.ReferralClaim) {
      return (
        <>
          <Text
            variant={TextVariant.CardText}
            className="color"
            style={{ fontWeight: 600, fontSize: 16 }}
          >
            Share your referral link and earn a free ōTreat for the first
            referral that signs up!
          </Text>
        </>
      );
    }
  };

  return (
    <CustomTooltipSC
      isOpen={isOpen}
      shown={shown}
      variant={variant}
      onClick={() => {
        setIsOpen(false);
      }}
    >
      <div className="inner-content">{renderInnerContent(variant)}</div>
    </CustomTooltipSC>
  );
};

export default CustomTooltip;
