import React, { FC } from 'react';
import ReactCardFlip from 'react-card-flip';
import { useDispatch } from 'react-redux';

import CardBack from './CardBack';
import CardFront from './CardFront';
import chestSlice from '@store/chest';

type TCard = {
  isFlipped: boolean;
  image: string;
  color?: string;
  firstBadge?: string;
  secondBadge?: string;
  cardIndex: number;
};

const Card: FC<TCard> = ({
  isFlipped,
  image,
  color,
  firstBadge,
  secondBadge,
  cardIndex,
}) => {
  const {
    setActiveFlipCardIndex,
    setBoosterFlipped,
    setChestFlipped,
    setOCashFlipped,
    setOLandFlipped,
  } = chestSlice;
  const dispatch = useDispatch();
  const isLand = cardIndex === 3;

  return (
    <ReactCardFlip
      isFlipped={isFlipped}
      flipDirection="horizontal"
      flipSpeedBackToFront={0.6}
      flipSpeedFrontToBack={0.6}
    >
      <CardFront
        className={`card card-front`}
        key="front"
        handleClick={() =>
          dispatch(setActiveFlipCardIndex({ activeFlipCardIndex: cardIndex }))
        }
        image={image}
        isLand={isLand}
        color={color}
        firstBadge={firstBadge}
        secondBadge={secondBadge}
      />
      <CardBack
        className={`card card-back`}
        key="back"
        handleClick={() => {
          dispatch(setActiveFlipCardIndex({ activeFlipCardIndex: cardIndex }));
          switch (cardIndex) {
            case 0:
              dispatch(setOCashFlipped({ oCashFlipped: false }));
              break;
            case 1:
              dispatch(setChestFlipped({ chestFlipped: false }));
              break;
            case 2:
              dispatch(setBoosterFlipped({ boosterFlipped: false }));
              break;
            case 3:
              dispatch(setOLandFlipped({ oLandFlipped: false }));
              break;
            default:
              break;
          }
        }}
      />
    </ReactCardFlip>
  );
};

export default Card;
