import React from 'react';
import { ButtonOutlineSC } from '@components/Button.styled';
import { useNavigate } from 'react-router-dom';
import { HeaderBtnContainerSC } from './PositionsHeader.styled';

const OrdersButton: React.FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <HeaderBtnContainerSC>
      <ButtonOutlineSC onClick={() => navigate('/purchase-orders/')}>
        My Orders
      </ButtonOutlineSC>
    </HeaderBtnContainerSC>
  );
};

export default OrdersButton;
