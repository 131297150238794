import React from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '@store/index';
import userInterfaceStore from '@store/user-interface';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { defaultModalProps } from '@components/Modal/modal-settings';
import ModalSC from './Modal.styled';

const ClaimModal: React.FunctionComponent = () => {
  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(
      userInterfaceStore.setModal({
        type: null,
        token: null,
      }),
    );
  };

  return (
    <ReactModal
      isOpen={type === 'claimModal'}
      {...defaultModalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC>
        <div className="header">
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            Congratulations!
          </Text>
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <div className="body">
          <Text variant={TextVariant.CardText} className="gray">
            Your ōTreat has been successfully claimed and added to your
            inventory bar.
          </Text>
        </div>
      </ModalSC>
    </ReactModal>
  );
};

export type TTreatBagTypes = {
  size: number;
};

export default ClaimModal;
