import React, { useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { loadConfettiPreset } from 'tsparticles-preset-confetti';

export default function MakeItRain() {
  const initParticles = useCallback(async (engine) => {
    await loadConfettiPreset(engine);
    await loadFull(engine);
  }, []);

  return (
    <Particles
      init={initParticles}
      options={{
        fpsLimit: 60,
        fullScreen: {
          enable: true,
          zIndex: 999999,
        },
        preset: 'confetti',
        particles: {
          shape: {
            type: 'character',
            options: {
              character: {
                fill: true,
                font: 'Verdana',
                value: '💵',
                style: '',
                weight: 400,
              },
            },
          },
          life: {
            duration: {
              value: 0,
            },
          },
          number: {
            value: 100,
            max: 0,
            density: {
              enable: true,
            },
          },
          move: {
            gravity: {
              enable: false,
            },
            decay: 0,
            direction: 'bottom',
            speed: 2,
            outModes: {
              default: 'out',
              left: 'out',
              right: 'out',
              bottom: 'out',
              top: 'out',
            },
          },
          size: {
            value: 12,
          },
          opacity: {
            value: 0.8,
            animation: {
              enable: false,
            },
          },
        },
        background: {
          color: 'none',
        },
        emitters: [],
        interactivity: {
          detectsOn: 'window',
          events: {
            onClick: {
              enable: true,
              mode: 'repulse',
            },
          },
        },
      }}
    />
  );
}
