import styled from 'styled-components';

const PaginationSC = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 24px;
  color: ${({ theme }) => theme.neutral.neutral8};
  align-items: center;

  .unlock-counter {
    color: ${({ theme }) => theme.neutral.neutral8};
  }

  border-radius: 0px 0px 5px 5px;

  .pagination-button {
    border-radius: 5px;
    background: white;
    color: ${({ theme }) => theme.neutral.neutral7};
    border: 2px solid ${({ theme }) => theme.neutral.neutral7};

    :disabled {
      color: ${({ theme }) => theme.neutral.neutral7};
      border: 2px solid ${({ theme }) => theme.neutral.neutral7};
      opacity: 0.25;
    }
  }

  .button-container {
    gap: 8px;
  }

  @media only screen and (max-width: 776px) {
    padding: 0px;
    margin-top: 8px;
    .pagination-page-info {
      display: none;
    }
    .pagination-button {
      width: 1.5rem;
      border: 1.5px solid ${({ theme }) => theme.neutral.neutral7};
      :disabled {
        border: 1.5px solid ${({ theme }) => theme.neutral.neutral7};
      }
    }
    .unlock-counter {
      //   display: none;
      font-size: 0.5rem;
    }

    .button-container {
      width: 100%;
      justify-content: flex-end;
      gap: 4px;
    }
  }
`;

export default PaginationSC;
