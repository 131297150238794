import { colorThemes } from '@assets/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import React from 'react';
import { faStar } from '@fortawesome/free-solid-svg-icons';

type TStarsProps = {
  starData: any[];
};

const Stars: React.FC<TStarsProps> = ({ starData }) => {
  return (
    <Tippy {...defaultTippyProps} content={`You have unlocked 0/5 stars.`}>
      <div
        style={{
          display: 'flex',
          gap: 8,
          marginTop: '0.5rem',
          width: 100,
        }}
      >
        {starData.map((o, idx) => (
          <FontAwesomeIcon
            key={idx}
            className="star"
            style={{
              color: colorThemes.light.neutral.neutral6,
              textAlign: 'center',
              alignSelf: 'center',
            }}
            icon={faStar}
          />
        ))}
      </div>
    </Tippy>
  );
};

export default Stars;
