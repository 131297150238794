import React, { useState } from 'react';
import Select from 'react-select';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '@store/index';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { defaultModalProps } from '@components/Modal/modal-settings';
import ModalSC from './Modal.styled';
import { ButtonPrimarySC } from '@components/Button.styled';
import { getRQKAuctionsKey, getRQKPositionsKey } from '@utils/rq-utils';
import api from '@utils/api';
import { useQuery, useQueryClient } from 'react-query';
import { closeModal } from '@store/common';
import { findEnergyLevel } from '@data/gifts';
import { TArtifactPlacement } from '@store/user-interface/types';
import { CustomOptionSC } from '@pages/AuctionResults/AuctionResults.styled';
import { ReactComponent as SvgPower } from '@assets/svg/icons/power.svg';
import { colorThemes } from '@assets/styles';
import { allRewards } from 'data/gifts';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';

type TRSOption = {
  value: string;
  label: string;
  energy: number;
  existing: number;
  generation: number;
  // TODO: ensure api not leaking locked lat/lngs, then use locked option to render warning if locked plot selected to plat upgrade
  locked: boolean;
};

// Frontend will be handling these issues
// -- energy score
// -- if artifact there
const ClaimAuctionModal: React.FunctionComponent = () => {
  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const artifactPlacement = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.artifactPlacement,
  ) as TArtifactPlacement;

  const [selectedPlot, setSelectedPlot] = useState<null | TRSOption>(null);

  const { isLoading: auctionDataIsLoading, data: auctionData } = useQuery(
    //'artifactPlacement.auctionId'
    getRQKAuctionsKey('getAuction', artifactPlacement.auctionId),
    async () => {
      const data = (await api.getAuction(artifactPlacement.auctionId)).data
        .data;
      return data;
    },
    {
      enabled: type === 'claimAuctionModal', // display only on this modal
    },
  );

  const { isLoading: availablePlacementIsLoading, data: options } = useQuery(
    getRQKPositionsKey('getAvailabeForPlacement'),
    async () => {
      const options: TRSOption[] = [];

      const positions = (await api.getPositionsAvailableForPlacement()).data
        .data;

      for (const position of positions) {
        let energyLevel = 0;
        let howManyTimes = 0;
        for (const artifact of position.artifacts) {
          energyLevel += findEnergyLevel(artifact.artifactId);
          if (artifact.artifactId === auctionData.artifact.artifactId) {
            howManyTimes++;
          }
        }

        options.push({
          value: position.id,
          label: position.position,
          energy: energyLevel,
          existing: howManyTimes,
          generation: position.type,
          // locked: position.lat === null,
          locked: true,
        });
      }

      return options;
    },
    {
      enabled: type === 'claimAuctionModal' && !auctionDataIsLoading, // display only on this modal
    },
  );

  const CustomOption = (props: any) => {
    const { data, innerRef, innerProps } = props;

    const artifactObject = allRewards.filter((obj) => {
      if (auctionDataIsLoading) return null;
      if (obj.id === auctionData.artifact.artifactId) {
        return obj;
      }
    });

    const genesis = data.generation === 0;

    return (
      <CustomOptionSC
        ref={innerRef}
        {...innerProps}
        className="custom-option"
        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr' }}
      >
        <div style={{ textAlign: 'left' }}>
          Plot # {data.label.toLocaleString()}
        </div>
        <div style={{ textAlign: 'center' }}>
          {genesis ? 'Genesis' : `Gen ${data.generation + 1}`}
        </div>
        <Tippy
          {...defaultTippyProps}
          content={`This plot has a total energy score of: ${data.energy.toLocaleString()}.`}
        >
          <div
            className="power-score"
            style={{
              fontWeight: 600,
              color: colorThemes.light.blue.blue100,
              textAlign: 'center',
            }}
          >
            <SvgPower
              fill={colorThemes.light.blue.blue100}
              style={{ height: 16, width: 16 }}
            />{' '}
            {data.energy.toLocaleString()}
          </div>
        </Tippy>

        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <Tippy
            {...defaultTippyProps}
            content={`You currently have ${data.existing} artifacts of a similar type on this plot.`}
          >
            <div
              className="icon-wrapper"
              style={{
                filter: data.existing ? 'saturate(100%)' : 'saturate(0%)',
              }}
            >
              {auctionData === undefined ? (
                <div>Loading</div>
              ) : (
                <>
                  <img src={artifactObject[0].img} alt="cactus" />
                  <div
                    className="label"
                    style={{
                      background:
                        data.existing > 0
                          ? colorThemes.light.blue.blue100
                          : colorThemes.light.neutral.neutral4,

                      color:
                        data.existing > 0
                          ? 'white'
                          : colorThemes.light.neutral.neutral7,
                    }}
                  >
                    {data.existing}
                  </div>
                </>
              )}
            </div>
          </Tippy>
        </div>
      </CustomOptionSC>
    );
  };

  const handleBtnClick = async () => {
    try {
      if (selectedPlot && selectedPlot.value) {
        await api.placeArtifact(auctionData.id, selectedPlot.value);
        queryClient.invalidateQueries(getRQKAuctionsKey('getUserAuctions'));
        queryClient.invalidateQueries(
          getRQKPositionsKey('getPosition', selectedPlot.value),
        );
      }
    } catch (err) {
      console.error('Error while updating app', err);
    } finally {
      closeModal(dispatch);
    }
  };

  if (availablePlacementIsLoading || auctionDataIsLoading) {
    return null;
  }

  return (
    <ReactModal
      isOpen={
        type === 'claimAuctionModal' &&
        !auctionDataIsLoading &&
        !availablePlacementIsLoading
      }
      {...defaultModalProps}
      onRequestClose={() => closeModal(dispatch)}
    >
      <ModalSC>
        <div className="header">
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            Claim your upgrade
          </Text>
          <button onClick={() => closeModal(dispatch)}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <div className="body">
          <Text variant={TextVariant.CardText} className="gray">
            You have successfully won an auction. Select the plot you would like
            to apply your upgrade to.
          </Text>
          <div style={{ margin: '24px 0px' }}>
            <Select
              defaultValue={selectedPlot}
              onChange={setSelectedPlot}
              options={options}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              placeholder="Select ōLand plot"
              menuPortalTarget={document.body}
              components={{ Option: CustomOption }}
            />
          </div>
          {/* TODO: onClick function to apply upgrade to land */}
          <ButtonPrimarySC
            disabled={!Boolean(selectedPlot)}
            onClick={handleBtnClick}
          >
            Apply upgrade
          </ButtonPrimarySC>
          {/* TODO: Uncomment once revealed status confirmed */}
          {/* {selectedPlot?.locked && (
            <Tippy
              {...defaultTippyProps}
              content={`This plot will need to be unlocked before you will be able to re-list the artifact for auction.`}
            >
              <WarningLabel className="warning-box">
                ⓘ Warning: the plot you have selected is still locked. Are you
                sure you want to claim the artifact to this plot?
              </WarningLabel>
            </Tippy>
          )} */}
        </div>
      </ModalSC>
    </ReactModal>
  );
};

export type TTreatBagTypes = {
  size: number;
};

export default ClaimAuctionModal;
