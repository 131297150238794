import React, { useState, useEffect, useCallback } from 'react';
import WertWidget from '@wert-io/widget-initializer';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { TRootState } from '@store/index';
import api from '@utils/api';
import orderStore from '@store/order';
import userInterfaceStore from '@store/user-interface';
import { getRQKUserKeys } from '@utils/rq-utils';
import Lottie from 'lottie-react';
import loaderAnim from '@assets/animations/loader.json';

const WertPaymentModal = () => {
  const [isLoading, setIsLoading] = useState(true);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productId = useSelector(
    (store: TRootState) => store.order.productId,
  ) as string;

  const initWertWidget = useCallback(() => {
    (async () => {
      try {
        // Make Request on our backend to get the signed data
        const response = (await api.signTransaction(productId)).data;

        const { orderId, signedData } = response.data;

        const otherWidgetOptions = {
          partner_id: process.env.REACT_APP_WERT_PARTNER_ID, // your partner id
          click_id: orderId, // unique id of purhase in your system
          origin:
            process.env.REACT_APP_ENVIRONMENT === 'production'
              ? undefined
              : 'https://sandbox.wert.io', // this option needed only in sandbox
        };

        const nftOptions = {
          extra: {
            item_info: {
              author: 'Overline',
              image_url:
                'https://staging.forbeswallet.com/image/overline_nft.jpeg',
              name: 'ōLand Treasure Chest',
              seller: 'Overline',
            },
          },
        };

        signedData.click_id = String(signedData.click_id);

        const wertOpts = {
          ...signedData,
          ...otherWidgetOptions,
          ...nftOptions,
          listeners: {
            loaded: () => setIsLoading(false),
            close: () => {
              // reset the order state
              dispatch(orderStore.clearCart());
              // remove the modal
              dispatch(
                userInterfaceStore.setModal({
                  type: null,
                  token: null,
                }),
              );
              // re-fetch key amount
              queryClient.invalidateQueries(getRQKUserKeys('getUserKeys'));

              // redirect to orders pages
              navigate('/purchase-orders');
            },
          },
        };

        const wertWidget = new WertWidget(wertOpts);

        wertWidget.mount();
      } catch (e) {
        console.log('Err', e);
      }
    })();
  }, []);

  // get polygon price in usd using axios and coingecko api
  useEffect(() => {
    initWertWidget();
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Lottie
          animationData={loaderAnim}
          loop={true}
          autoPlay={true}
          style={{
            width: 64,
            height: 64,
          }}
        />
      </div>
    );
  }

  return null;
};

export default WertPaymentModal;
