import React from 'react';
import Text, { TextVariant } from '@components/Text';
import ReferralLink from '@components/ReferralLink';
import { colorThemes } from '@assets/styles';
import CustomTooltip from '@components/CustomTooltip';
import { ECustomTooltipVariant } from '@components/CustomTooltip/CustomTooltip.styled';
import StyledPositionHeader from './PositionsHeader.styled';
import OrdersButton from './OrdersButton';

type TPositionsHeaderProps = {
  positionsCount: number;
};

const PositionsHeader = ({ positionsCount }: TPositionsHeaderProps) => {
  return (
    <StyledPositionHeader>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <div id="grid">
          <div>
            <Text
              style={{ fontWeight: 500, margin: '12px 0px' }}
              variant={TextVariant.CardTitle}
            >
              My ōLand
            </Text>
            <div className="tray-stats">
              <div className="header-stat">
                <Text variant={TextVariant.Button} style={{ marginBottom: 0 }}>
                  Plots: <span className="blue">{positionsCount}</span>
                </Text>
              </div>
              {/* <div className="header-stat">
                <Text variant={TextVariant.Button} style={{ marginBottom: 0 }}>
                  Size:{' '}
                  <span className="blue">
                    {(positionsCount * 1.67).toFixed(2)} Acres
                  </span>
                </Text>
              </div> */}
              <div style={{ maxWidth: 150, minWidth: 125 }}>
                <OrdersButton />
              </div>
            </div>
          </div>
          <div className="referral-content">
            <div style={{ position: 'relative', width: '100%' }}>
              <CustomTooltip
                variant={ECustomTooltipVariant.ReferralClaim}
                shown={positionsCount < 2}
              />
              <Text
                style={{ fontWeight: 400, margin: '12px 0px' }}
                variant={TextVariant.Description}
              >
                Get ōLand for each person that registers with this link:
              </Text>

              <div
                style={{
                  display: 'flex',
                  gap: 24,
                  alignItems: 'center',
                }}
              >
                <ReferralLink />
                <a href="https://twitter.com/overlinenetwork">
                  <svg
                    height="20"
                    viewBox="0 0 50 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M50 4.72264C48.1509 5.52223 46.1519 6.04697 44.103 6.32184C46.2269 5.07246 47.8511 3.09845 48.6007 0.749625C46.6267 1.92404 44.4278 2.72364 42.104 3.1984C40.2299 1.22439 37.5562 0 34.6327 0C28.9605 0 24.3878 4.52274 24.3878 10.0949C24.3878 10.8945 24.4628 11.6692 24.6127 12.3938C16.1169 11.969 8.57071 7.97101 3.49825 1.84907C2.62369 3.34832 2.09895 5.07246 2.09895 6.94652C2.09895 10.4448 3.92304 13.5432 6.64667 15.3173C4.9975 15.2923 3.37331 14.8176 2.02399 14.068V14.1679C2.02399 19.0655 5.57221 23.1384 10.2199 24.0879C9.3953 24.3128 8.47076 24.4128 7.52124 24.4128C6.87156 24.4128 6.1969 24.3878 5.57221 24.2379C6.89655 28.2359 10.6697 31.1844 15.1424 31.2594C11.6692 33.958 7.22139 35.5572 2.44877 35.5572C1.5992 35.5572 0.7996 35.5072 0 35.4073C4.57271 38.3308 9.94502 39.98 15.7421 39.98C34.6077 39.98 44.9025 24.6127 44.9025 11.2694C44.9025 10.8196 44.8776 10.3948 44.8526 9.97001C46.9016 8.54572 48.6007 6.77161 50 4.72264Z"
                      fill={colorThemes.light.neutral.neutral6}
                    />
                  </svg>
                </a>
                {/* <a href="https://discord.com/invite/overline">
                  <svg
                    height="20"
                    viewBox="0 0 50 39"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_93_103)">
                      <path
                        d="M42.3271 3.44916C39.1403 1.9869 35.7229 0.909579 32.1498 0.292551C32.0847 0.280643 32.0197 0.310403 31.9862 0.369924C31.5467 1.15162 31.0599 2.17141 30.7189 2.97296C26.8758 2.39761 23.0525 2.39761 19.2882 2.97296C18.9472 2.15359 18.4427 1.15162 18.0012 0.369924C17.9677 0.312388 17.9027 0.282629 17.8376 0.292551C14.2665 0.907607 10.8491 1.98493 7.66028 3.44916C7.63268 3.46106 7.60901 3.48092 7.59331 3.50669C1.11123 13.1908 -0.66448 22.6368 0.206625 31.9658C0.210567 32.0114 0.236187 32.0551 0.271663 32.0828C4.54835 35.2235 8.69106 37.1302 12.7568 38.394C12.8219 38.4139 12.8908 38.3901 12.9323 38.3365C13.894 37.0231 14.7513 35.6382 15.4864 34.1819C15.5298 34.0966 15.4884 33.9954 15.3997 33.9617C14.0399 33.4458 12.745 32.8169 11.4994 32.1027C11.4009 32.0451 11.393 31.9042 11.4837 31.8368C11.7458 31.6404 12.008 31.436 12.2582 31.2296C12.3035 31.192 12.3666 31.184 12.4199 31.2078C20.6027 34.9438 29.4616 34.9438 37.5478 31.2078C37.6011 31.182 37.6642 31.19 37.7114 31.2277C37.9618 31.434 38.2239 31.6404 38.488 31.8368C38.5786 31.9042 38.5727 32.0451 38.4742 32.1027C37.2286 32.8308 35.9337 33.4458 34.5719 33.9597C34.4832 33.9935 34.4438 34.0966 34.4872 34.1819C35.238 35.6362 36.0954 37.0211 37.0394 38.3345C37.0788 38.3901 37.1497 38.4139 37.2148 38.394C41.3003 37.1302 45.443 35.2235 49.7196 32.0828C49.7571 32.0551 49.7808 32.0134 49.7847 31.9677C50.8273 21.1825 48.0385 11.8139 42.3921 3.50866C42.3783 3.48092 42.3547 3.46106 42.3271 3.44916ZM16.7084 26.2854C14.2448 26.2854 12.2149 24.0237 12.2149 21.246C12.2149 18.4683 14.2054 16.2065 16.7084 16.2065C19.231 16.2065 21.2413 18.4882 21.2018 21.246C21.2018 24.0237 19.2113 26.2854 16.7084 26.2854ZM33.3224 26.2854C30.8589 26.2854 28.8289 24.0237 28.8289 21.246C28.8289 18.4683 30.8194 16.2065 33.3224 16.2065C35.8451 16.2065 37.8553 18.4882 37.8159 21.246C37.8159 24.0237 35.8451 26.2854 33.3224 26.2854Z"
                        fill={colorThemes.light.neutral.neutral6}
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_93_103">
                        <rect width="50" height="38.7324" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a> */}
                {/*
                  <button
                    style={{
                      width: 30,
                      height: 30,
                      border: 'none',
                      background: 'none',
                    }}
                    onClick={() => {
                      setCartOpen(!cartOpen);
                    }}
                  >
                    <SvgCart style={{ width: 30, height: 30 }} />
                  </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledPositionHeader>
  );
};

export default PositionsHeader;
