import styled from 'styled-components';

export type TStyledPositionGrid = {
  positions: number;
};

export const PositionsGridSC = styled.div`
  display: flex;
  justify-content: space-between;

  .grid-buttons {
    margin-bottom: 12px;
    display: flex;
    gap: 16px;

    @media only screen and (max-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
    }
  }

  // Dropdown
  .filter-select__menu {
    background: ${({ theme }) => theme.input.background} !important;
    border: 1.5px solid ${({ theme }) => theme.input.border} !important;
    border-radius: 12px;
  }

  .filter-select__control {
    border-radius: 12px;
    background: ${({ theme }) => theme.input.background} !important;
    border: 1.5px solid ${({ theme }) => theme.input.border} !important;
    cursor: pointer;
  }

  .filter-select__multi-value {
    background: ${({ theme }) => theme.background.regular.bg1} !important;
    border: 1.5px solid ${({ theme }) => theme.input.border} !important;
    color: ${({ theme }) => theme.red} !important;
    border-radius: 6px;
  }

  .filter-select__multi-value__label {
    color: ${({ theme }) => theme.text.main} !important;
  }

  .filter-select__option {
    color: ${({ theme }) => theme.text.main} !important;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.gray} !important;
    }
  }

  @media only screen and (max-width: 1024px) {
    display: block;
    .rarity-wrapper {
      margin-bottom: 16px;
    }
  }
`;

export default styled.div<TStyledPositionGrid>`
  display: grid;
  grid-template-columns: repeat(4, minmax(0px, 1fr));
  gap: 24px;
  min-height: 300px;

  .position-wrapper {
    position: relative;
    .blocked-overlay {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      z-index: 99;
      color: white;

      button {
        max-width: 200px;
        margin-top: 1rem;
      }
    }
  }

  .position-card {
    background: none;
    border: none;
    padding: 0;
    z-index: 0;
    height: 100%;
    position: relative;

    &:disabled {
      cursor: not-allowed;
      opacity: 1;
      color: white;
    }
  }

  @media only screen and (max-width: 1440px) {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
    gap: 16px;
  }

  @media only screen and (max-width: 960px) {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    gap: 12px;
  }

  @media only screen and (max-width: 883px) {
    grid-template-rows: auto auto auto;
    grid-template-columns: auto auto;
    margin: auto;
    gap: 8px;

    .item-container {
      width: 100%;
      video {
        width: 100%;
        height: auto;
      }
    }
  }

  width: 100%;
  height: 100%;

  .item-container {
    border-radius: 18px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: saturate(180%) blur(5px);
    border: 1px solid #d2d2d7;
    width: 100%;
    height: 100%;

    .oland-box-anim {
      position: relative;
    }
  }

  .one {
    video {
      opacity: ${(props) => (props.positions >= 1 ? 1 : 0.5)};
      filter: ${(props) =>
        props.positions >= 1 ? 'saturate(1.75)' : 'saturate(0%)'};
    }
    &.no-oland-box {
      display: ${(props) => (props.positions >= 1 ? 'none' : 'flex')};
    }
    &.attribute-box {
      display: ${(props) => (props.positions >= 1 ? 'flex' : 'none')};
    }
  }

  .two {
    video {
      opacity: ${(props) => (props.positions >= 2 ? 1 : 0.5)};
      filter: ${(props) =>
        props.positions >= 2 ? 'saturate(1.75)' : 'saturate(0%)'};
    }
    &.no-oland-box {
      display: ${(props) => (props.positions >= 2 ? 'none' : 'flex')};
    }
    &.attribute-box {
      display: ${(props) => (props.positions >= 2 ? 'flex' : 'none')};
    }
  }

  .three {
    video {
      opacity: ${(props) => (props.positions >= 3 ? 1 : 0.5)};
      filter: ${(props) =>
        props.positions >= 3 ? 'saturate(1.75)' : 'saturate(0%)'};
    }
    &.no-oland-box {
      display: ${(props) => (props.positions >= 3 ? 'none' : 'flex')};
    }
    &.attribute-box {
      display: ${(props) => (props.positions >= 3 ? 'flex' : 'none')};
    }
  }

  .four {
    video {
      opacity: ${(props) => (props.positions >= 4 ? 1 : 0.5)};
      filter: ${(props) =>
        props.positions >= 4 ? 'saturate(1.75)' : 'saturate(0%)'};
    }
    &.no-oland-box {
      display: ${(props) => (props.positions >= 4 ? 'none' : 'flex')};
    }
    &.attribute-box {
      display: ${(props) => (props.positions >= 4 ? 'flex' : 'none')};
    }
  }

  .five {
    video {
      opacity: ${(props) => (props.positions >= 5 ? 1 : 0.5)};
      filter: ${(props) =>
        props.positions >= 5 ? 'saturate(1.75)' : 'saturate(0%)'};
    }
    &.no-oland-box {
      display: ${(props) => (props.positions >= 5 ? 'none' : 'flex')};
    }
    &.attribute-box {
      display: ${(props) => (props.positions >= 5 ? 'flex' : 'none')};
    }
  }

  .six {
    video {
      opacity: ${(props) => (props.positions >= 6 ? 1 : 0.5)};
      filter: ${(props) =>
        props.positions >= 6 ? 'saturate(1.75)' : 'saturate(0%)'};
    }
    &.no-oland-box {
      display: ${(props) => (props.positions >= 6 ? 'none' : 'flex')};
    }
    &.attribute-box {
      display: ${(props) => (props.positions >= 6 ? 'flex' : 'none')};
    }
  }

  .attribute-box,
  .no-oland-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    padding: 8px;

    div {
      font-size: 8px;
    }
  }
`;
