import styled, { css } from 'styled-components';

export enum ECustomTooltipVariant {
  DailyCash = 'DailyCash',
  RewardClaimed = 'RewardClaimed',
  ReferralClaim = 'ReferralClaim',
}

export type TCustomTooltipProps = {
  variant: ECustomTooltipVariant;
  shown: boolean;
  isOpen?: boolean;
  claimedAmount?: number;
};

export default styled.button<TCustomTooltipProps>`
  ${({ variant = ECustomTooltipVariant.DailyCash }) => {
    switch (variant) {
      case ECustomTooltipVariant.DailyCash:
        return css`
          top: -135px;
          left: 24px;
          text-align: center;
        `;
      case ECustomTooltipVariant.RewardClaimed:
        return css``;
      case ECustomTooltipVariant.ReferralClaim:
        return css`
          right: 0px;
          top: -25px;

          @media only screen and (max-width: 776px) {
            top: 65px;
          }
        `;
    }
  }}

  position: absolute;
  background-color: ${({ theme }) => theme.background.regular.bg1};
  transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
  transition-property: background-color, -webkit-backdrop-filter;
  transition-property: background-color, backdrop-filter;
  transition-property: background-color, backdrop-filter,
    -webkit-backdrop-filter;
  box-shadow: 0 5px 10px rgb(0 0 0 / 34%);
  border-radius: 10px;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  overflow: hidden;
  max-width: 350px;
  gap: 10px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  z-index: 1;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  transform: ${(props) =>
    props.isOpen
      ? 'skewY(0) rotate(0) translateY(0)'
      : 'skewY(-5deg) rotate(5deg) translateY(-30px)'};

  .inner-content {
    padding: 12px;
  }

  border: 1px solid ${({ theme }) => theme.neutral.neutral4};

  .reward-header {
    background: ${({ theme }) => theme.blue.blue100};
    color: white;
    padding: 12px;
  }
`;
