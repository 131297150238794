import Wrapper from '@components/Wrapper';
import styled from 'styled-components';
import meshGradient from '@assets/png/mesh-gradient.png';

export const AuctionResultsSC = styled(Wrapper)`
  min-height: 100vh;
  overflow: auto;
  box-shadow: rgb(0 0 0 / 16%) 2px 4px 16px;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.background.inverted.bg1};
  .content-wrapper {
    max-width: 1260px;
    padding-top: 20px;
    padding-bottom: 80px;
    margin: auto;

    .table-container {
      border-radius: 10px;
      overflow: scroll;
      border: 1.5px solid ${({ theme }) => theme.input.border};
    }

    .no-items {
      padding: 40px;
      border-radius: 10px;
      background: white;
      margin: auto;
      text-align: center;
      width: auto;
      font-weight: 500;
    }
  }
`;

export const AuctionsTableSC = styled.table`
  width: 100%;
  text-align: center;

  tr {
    width: 100%;
    :not(:last-child) {
      border-bottom: 1.5px solid ${({ theme }) => theme.input.border};
    }

    :first-child {
      background: ${({ theme }) => theme.neutral.neutral7};
    }

    :not(:first-child) {
      background: ${({ theme }) => theme.input.background};

      :hover {
        background: ${({ theme }) => theme.neutral.neutral2};
      }
    }
  }

  th {
    padding: 12px;
    font-weight: 500;
    color: white;
  }

  td {
    padding: 12px;
    color: ${({ theme }) => theme.text.main};
    &.energy {
      color: ${({ theme }) => theme.blue.blue100};
    }
  }

  .price {
    color: ${({ theme }) => theme.blue.blue100} !important;
    font-weight: 600;
  }

  .link {
    background: none;
    border: none;
    color: ${({ theme }) => theme.blue.blue100};

    :hover {
      text-decoration: underline;
    }
  }
`;

export const CustomOptionSC = styled.div`
  padding: 8px 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;

  align-items: center;
  background: white;
  border-bottom: 1.5px solid ${({ theme }) => theme.neutral.neutral4};

  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.blue.blue10};
  }

  .icon-wrapper {
    position: relative;
    border: 1.5px solid ${({ theme }) => theme.neutral.neutral4};
    border-radius: 5px;
    background-image: url(${meshGradient});
    background-position: center;
    background-size: cover;

    &#opening {
      background-image: none !important;

      img {
        width: 50px;
        height: 50px;
      }
      @media only screen and (max-width: 1024px) {
        img {
          width: 35px;
          height: 35px;
        }
      }
    }

    padding: 2px;
    display: flex;
    align-items: center;

    img {
      width: 25px;
      height: 25px;
    }

    .label {
      position: absolute;
      top: -5px;
      right: -5px;
      border-radius: 999px;
      width: 15px;
      height: 15px;
      font-size: 12px;
      text-align: center;
      font-weight: 600;
    }
  }
`;
