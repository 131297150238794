import { chestAnimationHover } from '@components/Modal/Modal.styled';
import { glowAnimation } from '@components/V2AuctionBar/V2AuctionBar.styled';
import styled, { keyframes } from 'styled-components';

export type TInventoryBarSCProps = {
  scrollingDown?: boolean;
  expanded: boolean;
};

export type TInventoryItemProps = {
  empty: boolean;
  selected: boolean;
};

export type TBoosterButtonProps = {
  empty: boolean;
  color: string;
};

export const borderAnimation = keyframes`
50% {
  border:1.5px solid #FBF7C6;
}
`;

export default styled.div<TInventoryBarSCProps>`
  z-index: 991;
  bottom: 0px;
  position: fixed;
  transition: all 0.4s ease-in-out;
  margin: auto;
  left: 0;
  right: 0;
  width: 100%;

  .collapse-btn {
    margin-right: 24px;
  }

  .keys-wrapper {
    display: flex;
    justify-content: start;
    position: relative;
    margin-left: 24px;
    margin-bottom: 12px;

    .booster-waiting-container {
      position: absolute;
      top: -15px;
      left: -15px;
      .booster-waiting {
        position: absolute;
      }
    }
  }

  .keys {
    background: ${({ theme }) => theme.background.regular.bg1};
    border: 1.5px solid ${({ theme }) => theme.input.border};
    border-radius: 2px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 8px;
    justify-content: space-between;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

    animation: ${borderAnimation} 0.8s infinite;

    .key-bal {
      position: relative;
      margin-top: 16px;

      h1 {
        margin: 0px;
        text-align: right;
        font-size: 0.8rem;
        color: ${({ theme }) => theme.text.main};
      }
    }

    .chest {
      max-height: 40px;
      animation: ${chestAnimationHover} 2s infinite;
      cursor: pointer;
    }

    .key-img {
      position: absolute;
      bottom: 0px;
      right: 0px;
      max-height: 40px;
    }
  }

  .inventory-body {
    background: ${({ theme }) => theme.background.inverted.bg2};
    box-shadow: 0 5px 10px rgb(0 0 0 / 34%);
    border-radius: 0px 0px 0px 0px;
    border: 1.5px solid ${({ theme }) => theme.input.border};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;

    @media only screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
  }

  .inventory-balance {
    border-radius: 0px;
    border-bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      height: 1.15rem;
      fill: ${({ theme }) => theme.neutral.neutral8};
    }

    h1 {
      font-weight: 600;
      font-size: 0.9rem !important;
      color: ${({ theme }) => theme.text.main};
      @media only screen and (max-width: 883px) {
        font-size: 10px !important;
      }
    }

    .ocash-balance {
      span {
        background: ${({ theme }) => theme.blue.blue10} !important;
        padding: 8px;
        border-radius: 18px;

        img {
          margin-right: 0.25rem;
        }
      }
    }

    .airdrop-balance {
      .ogem-rank {
        display: flex;
        gap: 1rem;
        position: relative;
      }

      margin-top: 0.5rem;
      h1 {
        font-weight: 500;
      }
      .bal {
        color: ${({ theme }) => theme.blue.blue100};
        font-weight: 700;

        img {
          margin-right: 0.25rem;
        }
      }

      .usd {
        font-weight: 400;
      }
    }
  }

  .toolbar-btn {
    width: 150px;
  }

  .expand {
    display: none;
  }

  .booster-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    .pack-wrapper {
      display: flex;
      gap: 12px;
      align-items: center;
      width: auto;
    }
  }

  @media only screen and (max-width: 1024px) {
    bottom: ${(props) => (props.expanded ? '-4px' : '-225px')};

    .booster-container {
      align-items: flex-start;
      justify-content: flex-start;
    }
    .expand {
      background: none;
      border: 1px solid ${({ theme }) => theme.input.border};
      border-radius: 999px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;

      svg {
        fill: ${({ theme }) => theme.text.main};
      }
    }
    .toolbar-btn {
      width: 120px;
    }
  }

  // Skeleton Loaders

  .action-skeleton,
  .treat-skeleton,
  .booster-skeleton {
    display: flex;
    gap: 12px;
    justify-content: space-between;
    margin: 12px 0px;
  }

  .treat-skeleton,
  .booster-skeleton {
    .react-loading-skeleton {
      height: 60px;

      @media only screen and (max-width: 1024px) {
        height: 40px;
      }
    }
  }

  .booster-skeleton {
    .react-loading-skeleton {
      width: 60px;
      @media only screen and (max-width: 1024px) {
        width: 40px;
      }
    }
  }
`;

export const InventoryHeaderSC = styled.div`
  background: ${({ theme }) => theme.background.regular.bg2};
  border-radius: 0px 0px 0px 0px;
  padding: 8px 12px;
  border-bottom: 1.5px solid ${({ theme }) => theme.input.border};
`;

export const InventoryItemSC = styled.button<TInventoryItemProps>`
  border-radius: 5px;
  border: 2px solid ${({ theme }) => theme.input.border};
  padding: 8px;
  height: 100%;
  margin: 8px;
  position: relative;
  background-color: ${({ theme }) => theme.background.regular.bg1};
  outline: none;
  transition: all 0.3s ease-in-out;
  cursor: ${(props) => (props.empty ? 'not-allowed' : 'pointer')};
  @media only screen and (max-width: 772px) {
    padding: 4px;
    margin: 8px 4px;
  }

  &.selected {
    border: 2px solid ${({ theme }) => theme.blue.blue100};

    .qty-box {
      border: 2px solid ${({ theme }) => theme.blue.blue100};
      color: ${({ theme }) => theme.blue.blue100};
    }
  }

  &.empty {
    background-color: ${({ theme }) => theme.background.regular.bg2};

    .qty-box {
      background-color: ${({ theme }) => theme.background.regular.bg2};
    }
  }

  .qty-box {
    font-weight: 700;
    line-height: 100%;
    white-space: nowrap;
    text-align: center;
    border-radius: 5px;
    color: ${({ theme }) => theme.text.main};
    background-color: ${({ theme }) => theme.background.regular.bg1};
    border: 2px solid ${({ theme }) => theme.input.border};
    width: 80%;
    position: absolute;
    right: 0px;
    margin: auto;
    left: 0px;
    bottom: -0.5rem;
    font-size: 0.7rem;
    z-index: 3;

    @media only screen and (max-width: 772px) {
      width: 90%;
      padding: 1px 2px;
    }
  }

  img {
    opacity: ${(props) => (props.empty ? '0.7' : '1')};
    -webkit-filter: ${(props) => (props.empty ? 'saturate(0)' : 'saturate(1)')};
    filter: ${(props) => (props.empty ? 'saturate(0)' : 'saturate(1)')};
  }

  .outer-wrapper {
    text-align: center;
  }
`;

export const BoosterButtonSC = styled.button<TBoosterButtonProps>`
  border-radius: 5px;
  border: 2px solid ${(props) => props.color};
  padding: 8px;
  height: 100%;
  margin: 8px;
  position: relative;
  background-color: ${({ theme }) => theme.background.regular.bg1};

  outline: none;
  transition: all 0.3s ease-in-out;
  cursor: ${(props) => (props.empty ? 'not-allowed' : 'pointer')};

  .cancel {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 40px;
    width: 40px;

    .inner {
      border-radius: 999px;
      img {
        padding: 8px;
      }
    }
  }

  .qty-box {
    position: absolute;
    right: -10px;
    top: -10px;
    border-radius: 999px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    color: ${({ theme }) => theme.text.main};
    background-color: ${({ theme }) => theme.background.regular.bg1};
    border: 2px solid ${(props) => props.color};
  }
  @media only screen and (max-width: 772px) {
    padding: 4px;
    margin: 8px 4px;
  }

  animation: ${(props) => glowAnimation(props.color)} 3s linear infinite;
  :hover {
    box-shadow: 0px 0px 64px 8px ${(props) => props.color};
  }

  :disabled {
    box-shadow: none;
  }

  &.empty {
    border-color: ${({ theme }) => theme.input.border};
    background-color: ${({ theme }) => theme.background.regular.bg2};

    .qty-box {
      background-color: ${({ theme }) => theme.background.regular.bg2};
      border-color: ${({ theme }) => theme.input.border};
    }
  }

  img {
    width: 40px;
    height: 40px;
    opacity: ${(props) => (props.empty ? '0.7' : '1')};
    -webkit-filter: ${(props) => (props.empty ? 'saturate(0)' : 'saturate(1)')};
    filter: ${(props) => (props.empty ? 'saturate(0)' : 'saturate(1)')};
    transform: scale(1.2);

    @media only screen and (max-width: 1024px) {
      width: 30px;
      height: 30px;
    }
  }
`;

export const TreatBagSC = styled.img`
  height: 50px;
  width: 50px;

  @media only screen and (max-width: 772px) {
    height: 30px;
    width: 30px;
  }
`;

export const BoosterInventoryBtnSC = styled.button`
  outline: none !important;
  border: 2px solid ${({ theme }) => theme.input.border};
  background-color: ${({ theme }) => theme.background.regular.bg1};
  border-radius: 5px;
  padding: 8px;
  height: 100%;
  margin: 8px;
  .inner {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
      padding-top: 8px;
    }

    h1 {
      font-size: 10px;
      color: ${({ theme }) => theme.text.main};
      font-weight: 600;
    }
  }
`;

export const ClaimableBoosterSC = styled.img`
  width: 30px;
  height: 30px;
  transition: opacity 1s ease-in-out;

  opacity: 0;

  &.active {
    opacity: 1;
  }
`;
