import styled from 'styled-components';

export type TRarityDisplayProps = {
  rarity: any | null;
  positionId: string;
  cardType: string;
};

export const RarityDisplaySC = styled.div`
  .rarity-score {
    position: absolute;
    top: 12px;
    right: 12px;

    .rarity-icons {
      display: flex;
      justify-content: space-between;
      gap: 8px;

      #icon-inner {
        font-size: 24px;
      }
    }

    @media only screen and (max-width: 776px) {
      top: 8px;
      right: 8px;

      .rarity-icons {
        display: flex;
        justify-content: space-between;
        gap: 4px;

        #icon-inner {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.8rem;
        }
      }
    }
  }
`;
