import { closeModal } from '@store/common';
import api from '@utils/api';
import {
  getRQKAuctionsKey,
  getRQKPositionsKey,
  getRQKUsersAuctionsKey,
} from '@utils/rq-utils';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { V2CancelModalSC } from './V2CancelModal.styled';
import { defaultModalProps } from '@components/Modal/modal-settings';
import ReactModal from 'react-modal';
import ModalSC from '../../Modal.styled';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { TRootState } from '@store/index';
import { useQueryClient } from 'react-query';
import { ButtonRedSC } from '@components/Button.styled';
import { toast } from 'react-toastify';
import Toast from '@components/Toast';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import { TArtifact, getEnergyLevel } from '@data/gifts';
import { returnDominantBooster } from '@pages/Home/components/PositionCard/GenericPosition';
import { ListingCardSC } from '../V2ListingModal/V2ListingModal.styled';
import CardVideo from '@pages/Home/components/CardVideo';
import {
  gen6Style,
  generateClassNames,
} from '@pages/Home/components/PositionCard/PositionCard';
import Loader from '@components/Loader';

type TAuctionData = {
  id: string;
  type: string;
  submitted: string;
  status: string;
  position: string;
  artifact: string | null;
  startingPrice: number;
  buyNow: number;
  minTresholdForSuccess: number;
  auctionEndAt: string;
  queueType: string;
  denomination: string;
  user: string;
  createdAt: string;
  updatedAt: string;
};

const V2CancelModal: FC = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [cancelIsLoading, setCancelIsLoading] = useState(false);
  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };
  const { activePositionId } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface,
  ) as { activePositionId: string };
  const [activeListing, setActiveListing] = useState<TAuctionData | null>(null);

  const cachedUserAuctions = queryClient.getQueryData(
    getRQKUsersAuctionsKey('getUserRunningAuctions'),
  ) as any;

  useEffect(() => {
    const listing = cachedUserAuctions.find(
      (auction) => auction.position === activePositionId,
    );
    setActiveListing(listing);
  }, [cachedUserAuctions, activePositionId]);

  const handleBtnClick = async () => {
    if (activeListing) {
      setCancelIsLoading(true);
      try {
        await api.cancelAuction(activeListing.id);
        toast(
          <Toast
            type="success"
            header="Auction cancelled"
            body="Your listing was successfully cancelled."
          />,
        );
      } catch (e) {
        toast(
          <Toast
            type="error"
            header="Something went wrong."
            body="Unable to cancel listing. Try again later or contact support."
          />,
        );
      } finally {
        closeModal(dispatch);
        await queryClient.invalidateQueries(
          getRQKAuctionsKey('getBlockedItems'),
        );
        await queryClient.invalidateQueries(getRQKAuctionsKey('getAuctions'));
        await queryClient.invalidateQueries(
          getRQKAuctionsKey('getUserAuctions'),
        );
        await queryClient.invalidateQueries(
          getRQKUsersAuctionsKey('getUserRunningAuctions'),
        );
        setCancelIsLoading(false);
      }
    }
  };

  const positionDetails = queryClient.getQueryData(
    getRQKPositionsKey('getPosition', activePositionId),
  ) as any;

  const hasDogHouse = positionDetails.artifacts
    ? positionDetails.artifacts.map((e: TArtifact) => e.name).includes('dog')
    : false;

  const cardType = hasDogHouse ? 'golden' : 'basic';

  const dominantBooster = positionDetails.boosters
    ? returnDominantBooster(positionDetails.boosters)
    : null;

  const isGen6 = positionDetails.type === 5;

  const cardClassNames = useMemo(
    () => generateClassNames({ dominantBooster, cardType, isGen6 }),
    [dominantBooster, cardType, isGen6],
  );
  const energy = getEnergyLevel(positionDetails.artifacts);

  if (!activeListing || !cachedUserAuctions) {
    return <Loader />;
  }
  return (
    <ReactModal
      isOpen={type === 'v2CancelAuctionModal'}
      {...defaultModalProps}
      onRequestClose={() => closeModal(dispatch)}
    >
      <ModalSC>
        <div className="header">
          <Text
            style={{ fontWeight: 500, textAlign: 'center', marginBottom: 16 }}
            variant={TextVariant.Subtitle}
          >
            Cancel Listing
          </Text>
          <button onClick={() => closeModal(dispatch)}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <V2CancelModalSC>
          <Text variant={TextVariant.CardText} className="gray">
            You are about to cancel your listing. This will remove your item
            from the marketplace and return it to your inventory.
          </Text>
          <div className="details">
            <ListingCardSC
              className={cardClassNames}
              style={isGen6 ? gen6Style : {}}
            >
              <CardVideo focus={true} isGen6={isGen6} />
            </ListingCardSC>

            <Text variant={TextVariant.CardText} className="gray">
              ōLand: {positionDetails.position.toLocaleString('en-US')}
            </Text>

            <Text variant={TextVariant.CardText} className="gray">
              <span>Type: </span>
              <span>
                {positionDetails.type === 0
                  ? 'Gensis'
                  : `Gen ${positionDetails.type + 1}`}
              </span>
            </Text>
            <Text variant={TextVariant.CardText} className="gray">
              <span>Location: </span>
              <span>{positionDetails.rarityMeta.city}</span>
            </Text>
            <Text variant={TextVariant.CardText} className="gray">
              Energy: ⚡️{energy}
            </Text>
            <Text variant={TextVariant.CardText} className="gray">
              Upgrades: {positionDetails.positionUpgrades.length}
            </Text>
            {/* TODO re-enable when added to endpoint */}
            {/* <Text variant={TextVariant.CardText} className="gray">
              Highest Bid:{' '}
              {activeListing.winningBid
                ? activeListing.winningBid.amount.toLocaleString('en-US')
                : 'N/A'}
            </Text> */}
          </div>

          <ButtonRedSC
            onClick={handleBtnClick}
            disabled={cancelIsLoading || !activeListing.id}
          >
            {cancelIsLoading ? (
              <SvgSpinner fill={'white'} style={{ maxHeight: '1rem' }} />
            ) : (
              'Cancel Listing'
            )}
          </ButtonRedSC>
        </V2CancelModalSC>
      </ModalSC>
    </ReactModal>
  );
};

export default V2CancelModal;
