import { ButtonOutlineSC } from '@components/Button.styled';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import React from 'react';
import UpgradeTray from '@pages/Home/components/UpgradeTray';
import UpgradingTimer from '../../UpgradingTimer';
import CardVideo from '../../CardVideo';
import RarityDisplay from '@components/RarityDisplay';
import { colorThemes } from '@assets/styles';
import { ReactComponent as SvgPower } from '@assets/svg/icons/power.svg';
import { TArtifact } from '@data/gifts';

type TLocationBtnProps = {
  positionId: string;
  rarity: string;
  upgradeOverlayShown: boolean;
  upgrading: boolean;
  revealDate: string;
  upgradeProgress: number;
  focus: boolean;
  energy: number;
  cardType: string;
  artifacts: TArtifact[];
  positionDataIsLoading: boolean;
  isGen6: boolean;
};

const CardGraphic: React.FunctionComponent<TLocationBtnProps> = ({
  positionId,
  rarity,
  upgradeOverlayShown,
  upgrading,
  revealDate,
  upgradeProgress,
  focus,
  energy,
  cardType,
  artifacts,
  positionDataIsLoading,
  isGen6,
}) => {
  return (
    <div className="video-container">
      <CardVideo focus={focus} isGen6={isGen6} />
      {upgradeOverlayShown && (
        <UpgradingTimer
          positionId={positionId}
          upgrading={upgrading}
          revealDate={revealDate}
          percentage={upgradeProgress}
        />
      )}

      <Tippy
        {...defaultTippyProps}
        content={`Your energy score determines the earning potential of your ōLand.`}
      >
        <div className="power-score">
          <ButtonOutlineSC
            className={cardType === 'golden' ? 'smaller dark' : 'smaller blue'}
          >
            <SvgPower
              fill={colorThemes.light.blue.blue100}
              style={{ height: 16, width: 16 }}
            />{' '}
            {energy.toLocaleString()}
          </ButtonOutlineSC>
        </div>
      </Tippy>

      <RarityDisplay
        rarity={rarity}
        positionId={positionId}
        cardType={cardType}
      />

      <div className="upgrade-inventory">
        <UpgradeTray
          ownedArtifacts={artifacts}
          positionDataIsLoading={positionDataIsLoading}
          isGen6={isGen6}
        />
      </div>
    </div>
  );
};

export default CardGraphic;
