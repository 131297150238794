import Decimal from 'decimal.js';

class MetaMask {
  private static instance: MetaMask = new MetaMask();
  private ethereum: any = null;

  private constructor() {
    if (MetaMask.instance) {
      throw new Error('Error: This is singleton');
    }
    MetaMask.instance = this;
    if (typeof window.ethereum !== 'undefined') {
      this.ethereum = window.ethereum;
    }
  }

  public static getInstance(): MetaMask {
    return MetaMask.instance;
  }

  public requestPayment = async (ethAddress: string, value: string) => {
    if (!this.ethereum) {
      return false;
    }

    const accounts = await this.ethereum.request({
      method: 'eth_requestAccounts',
    });

    const dVal = new Decimal(value);
    const wei = dVal.mul(1000000000000000000);
    const hex = wei.toHex();

    this.ethereum
      .request({
        method: 'eth_sendTransaction',
        params: [
          {
            from: accounts[0],
            to: ethAddress,
            value: hex, // value
          },
        ],
      })
      .catch((error) => console.error(error));
  };
}

const metaMask = MetaMask.getInstance();
export default metaMask;
