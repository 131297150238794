// Svg Icons
import SvgFlowerFarm from '@assets/png/new-artifacts/flower-farm.png';
import SvgRockGarden from '@assets/png/new-artifacts/rock-garden.png';
import SvgRopeBridge from '@assets/png/new-artifacts/rope-bridge.png';
import SvgCarrierPigeons from '@assets/png/new-artifacts/carrier-pigeons.png';
import SvgDutchWindmill from '@assets/png/new-artifacts/dutch-windmill.png';
import SvgCactusForrest from '@assets/png/new-artifacts/cactus-forest.png';
import SvgOliveTree from '@assets/png/new-artifacts/olive-tree.png';
import SvgBirdHouse from '@assets/png/new-artifacts/bird-house.png';
import SvgWaterWell from '@assets/png/new-artifacts/water-well.png';
import SvgFungiFarm from '@assets/png/new-artifacts/fungi-farm.png';
import SvgFishtank from '@assets/png/new-artifacts/fishtank.png';
import SvgTireSwing from '@assets/png/new-artifacts/tire-swing.png';
import SvgSandbox from '@assets/png/new-artifacts/sandbox.png';
import SvgWoodenDock from '@assets/png/new-artifacts/wooden-dock.png';
import SvgTurboTrampoline from '@assets/png/new-artifacts/turbo-trampoline.png';
// Conflict with fairy house image
import SvgTreeHouse from '@assets/png/new-artifacts/tree-house.png';
import SvgBallPit from '@assets/png/new-artifacts/ball-pit.png';
import SvgWaterslide from '@assets/png/new-artifacts/waterslide.png';
import SvgScorchedLandingPad from '@assets/png/new-artifacts/scorched-landing-pad.png';
import SvgWalkieTalkie from '@assets/png/new-artifacts/walkie-talkie.png';
import SvgEnergyBridge from '@assets/png/new-artifacts/energy-bridge.png';
import SvgSmokeSignals from '@assets/png/new-artifacts/smoke-signals.png';
import SvgTinCansOnAWire from '@assets/png/new-artifacts/tin-cans-on-a-wire.png';
import SvgRustedCircuitBoard from '@assets/png/new-artifacts/rusted-circuit-board.png';
import SvgFairyHouse from '@assets/png/new-artifacts/fairy-house.png';
import SvgCandyTree from '@assets/png/new-artifacts/candy-tree.png';
import SvgRoseTree from '@assets/png/new-artifacts/rose-tree.png';
// jpg not png
import SvgRabbitBurrow from '@assets/png/new-artifacts/rabbit-burrow.jpg';
import SvgTokenMint from '@assets/png/new-artifacts/token-mint.png';
import SvgRobotFactory from '@assets/png/new-artifacts/robot-factory.png';
import SvgDoorToCandyland from '@assets/png/new-artifacts/door-to-candyland.png';
import SvgDoorToWonderland from '@assets/png/new-artifacts/door-to-wonderland.png';
import SvgDoorToCatlantis from '@assets/png/new-artifacts/door-to-catlantis.png';
import SvgDoorToTheFountainOfYouth from '@assets/png/new-artifacts/door-to-the-fountain-of-youth.png';
import SvgDoorToTheKingdomOfTheSurreal from '@assets/png/new-artifacts/door-to-the-kingdom-of-the-surreal.png';
import SvgJadeSundial from '@assets/png/new-artifacts/jade-sundial.png';
import SvgFerrisWheel from '@assets/png/new-artifacts/ferris-wheel.png';
import SvgTeleporter from '@assets/png/new-artifacts/teleporter.png';
import SvgMessageInABottle from '@assets/png/new-artifacts/message-in-a-bottle.png';
import SvgTotemPole from '@assets/png/new-artifacts/totem-pole.png';
import SvgAntiqueCarousel from '@assets/png/new-artifacts/antique-carousel.png';
import SvgBottleOfOkeefe from '@assets/png/new-artifacts/bottle-of-okeefe.png';
import SvgDinosaurTotForStewards from '@assets/png/new-artifacts/dinosaur-tooth-for-stewards.png';
import SvgPieceOfShipwreckForExplorers from '@assets/png/new-artifacts/piece-of-shipwreck-for-explorers.png';
import SvgMaginLampForMystics from '@assets/png/new-artifacts/magic-lamp-for-mystics.png';
import SvgTelescopeForPhilosophers from '@assets/png/new-artifacts/telescope-for-philosophers.png';
import SvgCarpetbaggersDiceForRulers from '@assets/png/new-artifacts/carpetbaggers-dice-for-rulers.png';
import SvgAncientBustForPhilosophers from '@assets/png/new-artifacts/ancient-bust-for-philosophers.png';
import SvgStarGasSampleForInnovators from '@assets/png/new-artifacts/star-gas-sample-for-innovators.png';
import SvgTemporalRiftForBuilders from '@assets/png/new-artifacts/temporal-rift-for-builders.png';
import SvgRainbowFarm from '@assets/png/new-artifacts/rainbow-farm.png';
import SvgWizardHatForInnovators from '@assets/png/new-artifacts/wizard-hat-for-innovators.png';
import SvgDragonNestForRulers from '@assets/png/new-artifacts/dragons-nest-for-rulers.png';
import SvgFairyWingsForStewards from '@assets/png/new-artifacts/fairy-wings-for-stewards.png';
import SvgCloakOfInvisibilityForExplorers from '@assets/png/new-artifacts/cloak-of-invisibility-for-explorers.png';
import SvgGoldenHarpForRulers from '@assets/png/new-artifacts/golden-harp-for-rulers.png';
import SvgEnchantedMusicBoxForMystics from '@assets/png/new-artifacts/enchanted-music-box-for-mystics.png';
import SvgGlowingMallardForPhilosophers from '@assets/png/new-artifacts/glowing-mallard-for-philosophers.png';
import SvgBubbleWandForBuilders from '@assets/png/new-artifacts/bubble-wand-for-builders.png';
import SvgWolfCollarForExplorers from '@assets/png/new-artifacts/wolf-collar-for-explorers.png';
import SvgMorleite3000 from '@assets/png/new-artifacts/morleite3000.png';

import SvgORouterUpverter from '@assets/png/deviceglow.png';
import SvgDog from '@assets/png/new-artifacts/dog-house.png';
import SvgSingingFlowerForMystics from '@assets/png/new-artifacts/singing-flower-for-mystics.png';

// PNG Cards

import PngFlowerFarm from '@assets/png/artifacts/flower-farm.png';
import PngRockGarden from '@assets/png/artifacts/rock-garden.png';
import PngRopeBridge from '@assets/png/artifacts/rope-bridge.png';
import PngCarrierPigeons from '@assets/png/artifacts/carrier-pigeons.png';
import PngDutchWindmill from '@assets/png/artifacts/dutch-windmill.png';
import PngCactusForrest from '@assets/png/artifacts/cactus-farm.png';
import PngOliveTree from '@assets/png/artifacts/olive-tree.png';
import PngBirdHouse from '@assets/png/artifacts/bird-house.png';
import PngWaterWell from '@assets/png/artifacts/water-well.png';
import PngFungiFarm from '@assets/png/artifacts/fungi-farm.png';
import PngFishtank from '@assets/png/artifacts/fishtank.png';
import PngTireSwing from '@assets/png/artifacts/tire-swing.png';
import PngSandbox from '@assets/png/artifacts/sandbox.png';
import PngWoodenDock from '@assets/png/artifacts/wooden-dock.png';
import PngTurboTrampoline from '@assets/png/artifacts/turbo-trampoline.png';
import PngTreeHouse from '@assets/png/artifacts/treehouse.png';
import PngBallPit from '@assets/png/artifacts/ballpit.png';
import PngWaterslide from '@assets/png/artifacts/waterslide.png';
import PngScorchedLandingPad from '@assets/png/artifacts/scorched-landing-pad.png';
import PngWalkieTalkie from '@assets/png/artifacts/walkie-talkie.png';
import PngEnergyBridge from '@assets/png/artifacts/energy-bridge.png';
import PngSmokeSignals from '@assets/png/artifacts/smoke-signals.png';
import PngTinCansOnAWire from '@assets/png/artifacts/tin-cans-on-a-wire.png';
import PngRustedCircuitBoard from '@assets/png/artifacts/rusted-circuit-board.png';
import PngFairyHouse from '@assets/png/artifacts/fairy-house.png';
import PngCandyTree from '@assets/png/artifacts/candy-tree.png';
import PngRoseTree from '@assets/png/artifacts/rose-tree.png';
import PngRabbitBurrow from '@assets/png/artifacts/rabbit-burrow.png';
import PngTokenMint from '@assets/png/artifacts/token-mint.png';
import PngRobotFactory from '@assets/png/artifacts/robot-factory.png';
import PngDoorToCandyland from '@assets/png/artifacts/door-to-candyland.png';
import PngDoorToWonderland from '@assets/png/artifacts/door-to-wonderland.png';
import PngDoorToCatlantis from '@assets/png/artifacts/door-to-catlantis.png';
import PngDoorToTheFountainOfYouth from '@assets/png/artifacts/door-to-the-fountain-of-youth.png';
// No card currently
import PngDoorToTheKingdomOfTheSurreal from '@assets/png/artifacts/door-to-the-kingdom-of-the-surreal.png';
import PngJadeSundial from '@assets/png/artifacts/jade-sundial.png';
import PngFerrisWheel from '@assets/png/artifacts/ferris-wheel.png';
import PngTeleporter from '@assets/png/artifacts/teleporter.png';
import PngMessageInABottle from '@assets/png/artifacts/message-in-a-bottle.png';
import PngTotemPole from '@assets/png/artifacts/totem-pole.png';
import PngAntiqueCarousel from '@assets/png/artifacts/antique-carousel.png';
import PngBottleOfOkeefe from '@assets/png/artifacts/bottle-of-okeefe.png';
import PngDinosaurTotForStewards from '@assets/png/artifacts/dinosaur-tooth-for-stewards.png';
import PngPieceOfShipwreckForExplorers from '@assets/png/artifacts/piece-of-shipwreck-for-explorers.png';
import PngMaginLampForMystics from '@assets/png/artifacts/magic-lamp-for-mystics.png';
import PngTelescopeForPhilosophers from '@assets/png/artifacts/telescope-for-philosophers.png';
import PngCarpetbaggersDiceForRulers from '@assets/png/artifacts/carpetbaggers-dice-for-rulers.png';
import PngAncientBustForPhilosophers from '@assets/png/artifacts/ancient-bust-for-philosophers.png';
import PngStarGasSampleForInnovators from '@assets/png/artifacts/star-gas-for-innovators.png';
import PngTemporalRiftForBuilders from '@assets/png/artifacts/temporal-rift-for-builders.png';
import PngRainbowFarm from '@assets/png/artifacts/rainbow-farm.png';
import PngWizardHatForInnovators from '@assets/png/artifacts/wizards-hat-for-innovators.png';
import PngDragonNestForRulers from '@assets/png/artifacts/dragons-nest-for-rulers.png';
import PngFairyWingsForStewards from '@assets/png/artifacts/fairy-wings-for-stewards.png';
import PngCloakOfInvisibilityForExplorers from '@assets/png/artifacts/cloak-of-invisibility-for-explorers.png';
import PngGoldenHarpForRulers from '@assets/png/artifacts/golden-harp-for-rulers.png';
import PngEnchantedMusicBoxForMystics from '@assets/png/artifacts/enchanted-music-box-for-mystics.png';
import PngGlowingMallardForPhilosophers from '@assets/png/artifacts/glowing-mallard-for-philosophers.png';
import PngBubbleWandForBuilders from '@assets/png/artifacts/bubble-wand-for-builders.png';
import PngWolfCollarForExplorers from '@assets/png/artifacts/wolf-collar-for-explorers.png';
import PngMorleite3000 from '@assets/png/artifacts/morleite3000.png';
import PngORouterUpverter from '@assets/png/deviceglow.png';
import PngDog from '@assets/png/artifacts/dog-house.png';
import PngSingingFlowerForMystics from '@assets/png/artifacts/singing-flower-for-mystics.png';

export const purposeDescriptions = {
  artifact:
    'Artifacts have unique and rare capabilities. Holding an artifact is the easiest way to enahnce earning power among lands.',
  movement:
    'Movement cards allow you to explore the universe by creating connections with otherwise hidden or unreachable places or pairing up lands with your friends.',
  magic:
    'Magic helps imbue your land with ore protection and power. Mystical happenings occur where there are magic items on your land.',
  communication:
    'The ultimate community tool in the decentralized metaverse. Create chatrooms, filter and sort messages, become a wireless cell provider, access limited conversations, and start fully wireless companies.',
  resource:
    'Resources allow you to easilt create building blocks that contribute to the rector. They are also a necessary ingredient for early commercial activities in ōLand',
  companion:
    'Companions are your guides and protectors and will help you navigate through the metaverse.',
};

const artifactsByPurpose: { [key: string]: TLandReward[] } = {
  artifact: [],
  movement: [],
  magic: [],
  communication: [],
  resource: [],
  companion: [],
};

export type TPurpose = keyof typeof artifactsByPurpose;

export type TLandReward = {
  id: string;
  type: 'big' | 'basic';
  energy: number;
  img: string;
  png: string;
  purpose: TPurpose;
};

export const findEnergyLevel = (id: string) => {
  const reward = allRewards.find((r) => r.id === id);
  return reward ? reward.energy : 0;
};

export type TArtifact = {
  id: string;
  position?: string;
  name: string;
};

export const getEnergyLevel = (artifacts: TArtifact[]) => {
  let energyLevel = 0;

  for (const artifact of artifacts) {
    for (const basic of basicRewards) {
      if (basic.id === artifact.name) {
        energyLevel += basic.energy;
      }
    }

    for (const big of bigRewards) {
      if (big.id === artifact.name) {
        energyLevel += big.energy;
      }
    }
  }

  return energyLevel;
};

//

/**
 * List of basic rewards, the order of the array matters
 */
export const basicRewards: TLandReward[] = [
  {
    id: 'flower-farm',
    type: 'basic',
    energy: 250,
    img: SvgFlowerFarm,
    png: PngFlowerFarm,
    purpose: 'resource',
  },
  {
    id: 'rock-garden',
    type: 'basic',
    energy: 250,
    img: SvgRockGarden,
    png: PngRockGarden,
    purpose: 'resource',
  },
  {
    id: 'rope-bridge',
    type: 'basic',
    energy: 250,
    img: SvgRopeBridge,
    png: PngRopeBridge,
    purpose: 'movement',
  },
  {
    id: 'carrier-pigeons',
    type: 'basic',
    energy: 300,
    img: SvgCarrierPigeons,
    png: PngCarrierPigeons,
    purpose: 'communication',
  },
  {
    id: 'dutch-windmill',
    type: 'basic',
    energy: 300,
    img: SvgDutchWindmill,
    png: PngDutchWindmill,
    purpose: 'movement',
  },
  {
    id: 'cactus-forest',
    type: 'basic',
    energy: 300,
    img: SvgCactusForrest,
    png: PngCactusForrest,
    purpose: 'resource',
  },
  {
    id: 'olive-tree',
    type: 'basic',
    energy: 300,
    img: SvgOliveTree,
    png: PngOliveTree,
    purpose: 'communication',
  },
  {
    id: 'bird-house',
    type: 'basic',
    energy: 300,
    img: SvgBirdHouse,
    png: PngBirdHouse,
    purpose: 'communication',
  },
  {
    id: 'water-well',
    type: 'basic',
    energy: 350,
    img: SvgWaterWell,
    png: PngWaterWell,
    purpose: 'resource',
  },
  {
    id: 'fungi-farm',
    type: 'basic',
    energy: 350,
    img: SvgFungiFarm,
    png: PngFungiFarm,
    purpose: 'resource',
  },
  {
    id: 'fishtank',
    type: 'basic',
    energy: 350,
    img: SvgFishtank,
    png: PngFishtank,
    purpose: 'resource',
  },
  {
    id: 'tire-swing',
    type: 'basic',
    energy: 350,
    img: SvgTireSwing,
    png: PngTireSwing,
    purpose: 'resource',
  },
  {
    id: 'sandbox',
    type: 'basic',
    energy: 350,
    img: SvgSandbox,
    png: PngSandbox,
    purpose: 'magic',
  },
  {
    id: 'wooden-dock',
    type: 'basic',
    energy: 350,
    img: SvgWoodenDock,
    png: PngWoodenDock,
    purpose: 'movement',
  },
  {
    id: 'turbo-trampoline',
    type: 'basic',
    energy: 375,
    img: SvgTurboTrampoline,
    png: PngTurboTrampoline,
    purpose: 'movement',
  },
  {
    id: 'tree-house',
    type: 'basic',
    energy: 375,
    img: SvgTreeHouse,
    png: PngTreeHouse,
    purpose: 'resource',
  },
  {
    id: 'ball-pit',
    type: 'basic',
    energy: 375,
    img: SvgBallPit,
    png: PngBallPit,
    purpose: 'magic',
  },
  {
    id: 'waterslide',
    type: 'basic',
    energy: 375,
    img: SvgWaterslide,
    png: PngWaterslide,
    purpose: 'movement',
  },
  {
    id: 'scorched-landing-pad',
    type: 'basic',
    energy: 375,
    img: SvgScorchedLandingPad,
    png: PngScorchedLandingPad,
    purpose: 'movement',
  },
  {
    id: 'walkie-talkie',
    type: 'basic',
    energy: 375,
    img: SvgWalkieTalkie,
    png: PngWalkieTalkie,
    purpose: 'communication',
  },
  {
    id: 'energy-bridge',
    type: 'basic',
    energy: 460,
    img: SvgEnergyBridge,
    png: PngEnergyBridge,
    purpose: 'movement',
  },
  {
    id: 'smoke-signals',
    type: 'basic',
    energy: 460,
    img: SvgSmokeSignals,
    png: PngSmokeSignals,
    purpose: 'communication',
  },
  {
    id: 'tin-cans-on-a-wire',
    type: 'basic',
    energy: 460,
    img: SvgTinCansOnAWire,
    png: PngTinCansOnAWire,
    purpose: 'communication',
  },
  {
    id: 'rusted-circuit-board',
    type: 'basic',
    energy: 460,
    img: SvgRustedCircuitBoard,
    png: PngRustedCircuitBoard,
    purpose: 'resource',
  },
  {
    id: 'fairy-house',
    type: 'basic',
    energy: 460,
    img: SvgFairyHouse,
    png: PngFairyHouse,
    purpose: 'artifact',
  },
  {
    id: 'candy-tree',
    type: 'basic',
    energy: 600,
    img: SvgCandyTree,
    png: PngCandyTree,
    purpose: 'resource',
  },
  {
    id: 'rose-tree',
    type: 'basic',
    energy: 600,
    img: SvgRoseTree,
    png: PngRoseTree,
    purpose: 'resource',
  },
  {
    id: 'rabbit-burrow',
    type: 'basic',
    energy: 600,
    img: SvgRabbitBurrow,
    png: PngRabbitBurrow,
    purpose: 'movement',
  },
  {
    id: 'token-mint',
    type: 'basic',
    energy: 650,
    img: SvgTokenMint,
    png: PngTokenMint,
    purpose: 'resource',
  },
  {
    id: 'robot-factory',
    type: 'basic',
    energy: 650,
    img: SvgRobotFactory,
    png: PngRobotFactory,
    purpose: 'resource',
  },
  {
    id: 'door-to-candyland',
    type: 'basic',
    energy: 670,
    img: SvgDoorToCandyland,
    png: PngDoorToCandyland,
    purpose: 'communication',
  },
  {
    id: 'door-to-wonderland',
    type: 'basic',
    energy: 670,
    img: SvgDoorToWonderland,
    png: PngDoorToWonderland,
    purpose: 'resource',
  },
  {
    id: 'door-to-catlantis',
    type: 'basic',
    energy: 670,
    img: SvgDoorToCatlantis,
    png: PngDoorToCatlantis,
    purpose: 'magic',
  },
  {
    id: 'door-to-the-fountain-of-youth',
    type: 'basic',
    energy: 670,
    img: SvgDoorToTheFountainOfYouth,
    png: PngDoorToTheFountainOfYouth,
    purpose: 'resource',
  },
  // Missing
  {
    id: 'door-to-the-kingdom-of-the-surreal',
    type: 'basic',
    energy: 670,
    img: SvgDoorToTheKingdomOfTheSurreal,
    png: PngDoorToTheKingdomOfTheSurreal,
    purpose: 'resource',
  },
  {
    id: 'jade-sundial',
    type: 'basic',
    energy: 750,
    img: SvgJadeSundial,
    png: PngJadeSundial,
    purpose: 'artifact',
  },
  {
    id: 'ferris-wheel',
    type: 'basic',
    energy: 750,
    img: SvgFerrisWheel,
    png: PngFerrisWheel,
    purpose: 'resource',
  },
  {
    id: 'teleporter',
    type: 'basic',
    energy: 750,
    img: SvgTeleporter,
    png: PngTeleporter,
    purpose: 'movement',
  },
  {
    id: 'message-in-a-bottle',
    type: 'basic',
    energy: 750,
    img: SvgMessageInABottle,
    png: PngMessageInABottle,
    purpose: 'artifact',
  },
  {
    id: 'totem-pole',
    type: 'basic',
    energy: 750,
    img: SvgTotemPole,
    png: PngTotemPole,
    purpose: 'communication',
  },
  {
    id: 'antique-carousel',
    type: 'basic',
    energy: 800,
    img: SvgAntiqueCarousel,
    png: PngAntiqueCarousel,
    purpose: 'movement',
  },
  {
    id: 'bottle-of-okeefe',
    type: 'basic',
    energy: 830,
    img: SvgBottleOfOkeefe,
    png: PngBottleOfOkeefe,
    purpose: 'magic',
  },
  {
    id: 'dinosaur-tooth-for-stewards',
    type: 'basic',
    energy: 830,
    img: SvgDinosaurTotForStewards,
    png: PngDinosaurTotForStewards,
    purpose: 'artifact',
  },
  {
    id: 'piece-of-shipwreck-for-explorers',
    type: 'basic',
    energy: 830,
    img: SvgPieceOfShipwreckForExplorers,
    png: PngPieceOfShipwreckForExplorers,
    purpose: 'artifact',
  },
  {
    id: 'magic-lamp-for-mystics',
    type: 'basic',
    energy: 830,
    img: SvgMaginLampForMystics,
    png: PngMaginLampForMystics,
    purpose: 'artifact',
  },
  {
    id: 'telescope-for-philosophers',
    type: 'basic',
    energy: 830,
    img: SvgTelescopeForPhilosophers,
    png: PngTelescopeForPhilosophers,
    purpose: 'artifact',
  },
  {
    id: 'carpetbaggers-dice-for-rulers',
    type: 'basic',
    energy: 830,
    img: SvgCarpetbaggersDiceForRulers,
    png: PngCarpetbaggersDiceForRulers,
    purpose: 'artifact',
  },
  {
    id: 'ancient-bust-for-philosophers',
    type: 'basic',
    energy: 830,
    img: SvgAncientBustForPhilosophers,
    png: PngAncientBustForPhilosophers,
    purpose: 'artifact',
  },
  {
    id: 'star-gas-sample-for-innovators',
    type: 'basic',
    energy: 830,
    img: SvgStarGasSampleForInnovators,
    png: PngStarGasSampleForInnovators,
    purpose: 'artifact',
  },
  {
    id: 'temporal-rift-for-builders',
    type: 'basic',
    energy: 830,
    img: SvgTemporalRiftForBuilders,
    png: PngTemporalRiftForBuilders,
    purpose: 'artifact',
  },
  {
    id: 'rainbow-farm',
    type: 'basic',
    energy: 830,
    img: SvgRainbowFarm,
    png: PngRainbowFarm,
    purpose: 'magic',
  },
  {
    id: 'wizard-hat-for-innovators',
    type: 'basic',
    energy: 990,
    img: SvgWizardHatForInnovators,
    png: PngWizardHatForInnovators,
    purpose: 'magic',
  },
  {
    id: 'dragons-nest-for-rulers',
    type: 'basic',
    energy: 990,
    img: SvgDragonNestForRulers,
    png: PngDragonNestForRulers,
    purpose: 'magic',
  },
  {
    id: 'fairy-wings-for-stewards',
    type: 'basic',
    energy: 990,
    img: SvgFairyWingsForStewards,
    png: PngFairyWingsForStewards,
    purpose: 'magic',
  },
  {
    id: 'cloak-of-invisibility-for-explorers',
    type: 'basic',
    energy: 990,
    img: SvgCloakOfInvisibilityForExplorers,
    png: PngCloakOfInvisibilityForExplorers,
    purpose: 'magic',
  },
  {
    id: 'golden-harp-for-rulers',
    type: 'basic',
    energy: 990,
    img: SvgGoldenHarpForRulers,
    png: PngGoldenHarpForRulers,
    purpose: 'magic',
  },
  {
    id: 'enchanted-music-box-for-mystics',
    type: 'basic',
    energy: 990,
    img: SvgEnchantedMusicBoxForMystics,
    png: PngEnchantedMusicBoxForMystics,
    purpose: 'magic',
  },
  {
    id: 'glowing-mallard-for-philosophers',
    type: 'basic',
    energy: 990,
    img: SvgGlowingMallardForPhilosophers,
    png: PngGlowingMallardForPhilosophers,
    purpose: 'magic',
  },
  {
    id: 'bubble-wand-for-builders',
    type: 'basic',
    energy: 990,
    img: SvgBubbleWandForBuilders,
    png: PngBubbleWandForBuilders,
    purpose: 'magic',
  },
  {
    id: 'wolf-collar-for-explorers',
    type: 'basic',
    energy: 990,
    img: SvgWolfCollarForExplorers,
    png: PngWolfCollarForExplorers,
    purpose: 'artifact',
  },
  {
    id: 'morleite-3000',
    type: 'basic',
    energy: 991,
    img: SvgMorleite3000,
    png: PngMorleite3000,
    purpose: 'resource',
  },
  {
    id: 'orouter-upverter',
    type: 'basic',
    energy: 2000,
    img: SvgORouterUpverter,
    png: PngORouterUpverter,
    purpose: 'resource',
  },
];

export const bigRewards: TLandReward[] = [
  {
    id: 'dog',
    type: 'big',
    energy: 0,
    img: SvgDog,
    png: PngDog,
    purpose: 'companion',
  },
  {
    id: 'singing-flower-for-mystics',
    type: 'big',
    energy: 3300,
    img: SvgSingingFlowerForMystics,
    png: PngSingingFlowerForMystics,
    purpose: 'magic',
  },
];

export const allRewards = basicRewards.concat(bigRewards);

for (let i = 0; i < allRewards.length; i += 1) {
  artifactsByPurpose[allRewards[i].purpose].push(allRewards[i]);
}
export default artifactsByPurpose;
