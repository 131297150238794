import React from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '@store/index';
import ModalSC from './Modal.styled';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { defaultModalProps } from './modal-settings';
import artifactsByPurpose, { purposeDescriptions } from 'data/gifts';
import UpgradeInventorySection from '@components/UpgradeInventorySection';
import { closeModal } from '@store/common';

const UpgradesInventoryModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  if (process.env.REACT_APP_AUCTIONS_ENABLED !== 'true') {
    return null;
  }

  return (
    <ReactModal
      isOpen={type === 'upgradesInventoryModal'}
      {...defaultModalProps}
      onRequestClose={() => closeModal(dispatch)}
    >
      <ModalSC>
        <div className="header">
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            Your Upgrades
          </Text>
          <button onClick={() => closeModal(dispatch)}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <div className="body upgrades-inventory">
          {Object.keys(artifactsByPurpose)
            .filter((key) => key !== 'companion')
            .map((key, idx) => (
              <UpgradeInventorySection
                key={idx}
                title={key.toUpperCase()}
                description={purposeDescriptions[key]}
                items={artifactsByPurpose[key]}
              />
            ))}
        </div>
      </ModalSC>
    </ReactModal>
  );
};

export default UpgradesInventoryModal;
