import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import numeral from 'numeral';

import Text from '@components/Text';
import Row, { JustificationTypes } from '@components/Row';
import { O_CASH_PRODUCTS_AMOUNT } from '@store/order/products';
import { ButtonPrimarySC, StyledLinkBtn } from '@components/Button.styled';
import { TRootState } from '@store/index';
import CartSubtotal from '@components/Modal/CheckoutProcess/components/CartSubtotal';
import { USD_NUMERAL_FMT } from '@utils/common';
import api from '@utils/api';
import orderStore from '@store/order';
import { EPurchaseProcessSteps } from '@components/Modal/CheckoutProcess/PurchaseModal';
import PaymentConditions from '@components/Modal/CheckoutProcess/components/PaymentConditions';

const BiPayPayment = () => {
  const dispatch = useDispatch();

  const order = useSelector((store: TRootState) => store.order);

  const [bitPayLoading, setBitPayLoading] = useState(false);

  const [termsAgreed, setTermsAgreed] = useState(false);
  const [marketingOptIn, setMarketingOptIn] = useState(false);

  const handleGoBack = useCallback(() => {
    dispatch(
      orderStore.setOrderStep({
        step: EPurchaseProcessSteps.PaymentSelector,
      }),
    );
  }, []);

  const handleBitPayClick = async () => {
    setBitPayLoading(true);

    try {
      // TODO Only Create order IF oCash
      // Create whole order here
      const orderData = (
        await api.createOrder({
          products: [
            {
              id: order.productId,
              quantity: order.productCount,
            },
          ],
          shippingAddress: null,
          billingAddress: null,
        })
      ).data;

      const { id } = orderData.data;

      const paymentRequestData = (
        await api.createPaymentRequest(id, {
          type: 'crypto',
        })
      ).data;

      // set marketing API
      if (marketingOptIn) {
        await api.setMarketingAgreement(true);
      }
      // then redirect to BitPay payment Gateway
      window.location.replace(paymentRequestData.data.url);
    } catch (e) {
      console.error('Bitpay create payment err: ', e);
    } finally {
      setBitPayLoading(false);
    }
  };

  return (
    <div className="body">
      {order.purchaseProductType === 'keys' && (
        <CartSubtotal type="keys" style={{ marginTop: 16, marginBottom: 16 }} />
      )}
      {order.purchaseProductType === 'oRouter' && (
        <CartSubtotal type="full" style={{ marginTop: 16, marginBottom: 16 }} />
      )}
      {order.purchaseProductType === 'oCash' && (
        <>
          <Row justify={JustificationTypes.SpaceBetween}>
            <Text>Buying:</Text>
            <Text>
              {O_CASH_PRODUCTS_AMOUNT.get(order.productId as string)} ōCash
            </Text>
          </Row>
          <Row justify={JustificationTypes.SpaceBetween} style={{ margin: 0 }}>
            <Text>Cost: </Text>
            <Text>{numeral(order.total).format(USD_NUMERAL_FMT)}</Text>
          </Row>
        </>
      )}
      <PaymentConditions
        termsAgreed={termsAgreed}
        marketingOptIn={marketingOptIn}
        onTermsAgreement={() => setTermsAgreed((terms) => !terms)}
        onMarketingOpt={() => setMarketingOptIn((optInt) => !optInt)}
      />
      <ButtonPrimarySC
        type="submit"
        disabled={bitPayLoading || !termsAgreed}
        onClick={handleBitPayClick}
      >
        {bitPayLoading ? 'Redirecting to Bitpay' : 'Pay with Bitpay'}
      </ButtonPrimarySC>
      <StyledLinkBtn style={{ marginTop: 16 }} onClick={handleGoBack}>
        Go back
      </StyledLinkBtn>
    </div>
  );
};

export default BiPayPayment;
