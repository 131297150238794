import { resetTokens, setReferralModalShown } from '@utils/storage';
import store from '@store/index';
import authSlice from '@store/auth';
import api from '@utils/api';

export const signOut = (notifyBackend = true): void => {
  const { signOut } = authSlice;

  setReferralModalShown(false);

  const f = () => {
    resetTokens();
    // notify the redux
    // redirect to sign in window
    window.location.href = `${location.origin}${
      process.env.REACT_APP_ENVIRONMENT !== 'development'
        ? '/app/auth'
        : '/auth'
    }`;
    store.dispatch(signOut());
    // reset local storage data
  };

  if (notifyBackend) {
    api.signOut().then(f);
  } else {
    f();
  }
};
