import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonOutlineSC } from '@components/Button.styled';
import { ChestsContainerSC } from './ChestsPage.styled';
import chestsByRarity from '@data/chests';
import ChestSection from './components/ChestSection';

const ChestsPage: React.FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <ChestsContainerSC>
      <div className="content-wrapper">
        <ButtonOutlineSC
          onClick={() => navigate('/')}
          style={{ maxWidth: 120, marginBottom: 20 }}
        >
          Go Back
        </ButtonOutlineSC>
        {Object.keys(chestsByRarity).map((type, index) => (
          <ChestSection type={type} key={index} cards={chestsByRarity[type]} />
        ))}
      </div>
    </ChestsContainerSC>
  );
};

export default ChestsPage;
