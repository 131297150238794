import { ButtonOutlineSC } from '@components/Button.styled';
import Toast from '@components/Toast';
import api from '@utils/api';
import { getRQKPositionsKey } from '@utils/rq-utils';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { RarityDisplaySC, TRarityDisplayProps } from './RarityDisplay.styled';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';

const RarityDisplay: React.FunctionComponent<TRarityDisplayProps> = ({
  rarity,
  cardType,
  positionId,
}) => {
  const [rarityLoading, setRarityLoading] = useState(false);
  const rarityCalculated = rarity != null;
  const queryClient = useQueryClient();
  return (
    <RarityDisplaySC>
      <Tippy
        {...defaultTippyProps}
        content={
          rarityCalculated
            ? `Your ōLand is ${rarityLoading ? 'loading' : rarity}.`
            : `Check the rarity level of your land`
        }
      >
        <div className="rarity-score">
          {rarityLoading ? (
            <>
              <SvgSpinner
                fill={'black'}
                style={{
                  maxHeight: 30,
                  maxWidth: 30,
                  margin: 'auto',
                }}
              />
            </>
          ) : (
            <>
              {rarityCalculated ? (
                <>
                  <div className="rarity-icons">
                    <div
                      id="icon-inner"
                      style={{
                        filter:
                          rarity === 'Rare' ? 'saturate(100%)' : 'saturate(0%)',
                      }}
                    >
                      {rarity === 'Rare' ? '🟥' : '🟨'}
                    </div>

                    <div
                      id="icon-inner"
                      style={{
                        filter:
                          rarity === 'Super-Rare'
                            ? 'saturate(100%)'
                            : 'saturate(0%)',
                      }}
                    >
                      {rarity === 'Super-Rare' ? '🟩' : '🟨'}
                    </div>

                    <div
                      id="icon-inner"
                      style={{
                        filter:
                          rarity === 'Ultra-Rare'
                            ? 'saturate(100%)'
                            : 'saturate(0%)',
                      }}
                    >
                      🟨
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <ButtonOutlineSC
                    className={
                      cardType === 'golden' ? 'smaller dark' : 'smaller blue'
                    }
                    onClick={async (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setRarityLoading(true);

                      try {
                        await api.checkRarity(positionId);
                        toast(
                          <Toast
                            type="success"
                            header="Success"
                            body="Your ōLand's rarity was successfully revealed!"
                          />,
                        );
                      } catch (e) {
                        console.error(e);
                        toast(
                          <Toast
                            type="error"
                            header="Request failed"
                            body="Unable to retrieve rarity, check your connection and try again."
                          />,
                        );
                      } finally {
                        await queryClient.invalidateQueries(
                          getRQKPositionsKey('getPosition', positionId),
                        );
                        setRarityLoading(false);
                      }
                    }}
                  >
                    Check Rarity
                  </ButtonOutlineSC>
                </>
              )}
            </>
          )}
        </div>
      </Tippy>
    </RarityDisplaySC>
  );
};

export default RarityDisplay;
