import React from 'react';
import ToastSC, { TToastType } from './Toast.styled';
import { ToastProps } from 'react-toastify/dist/types';
import Cross from '@assets/svg/icons/Cross.svg';
import Check from '@assets/svg/icons/Check.svg';
import styled from 'styled-components';

export type TToastProps = {
  type: TToastType;
  header: string;
  body: string;
  links?: boolean;
} & Partial<ToastProps>;

const Toast: React.FunctionComponent<TToastProps> = ({
  type,
  header,
  body,
  links,
  closeToast,
}: TToastProps) => {
  return (
    <ToastSC type={type}>
      <Container className="header">
        <HeaderFlex>
          <Icon alt="icon" src={type == 'error' ? Cross : Check} />
          <span>{header}</span>
        </HeaderFlex>

        <button onClick={closeToast}>
          <span>Close</span>
        </button>
      </Container>
      <Container className="body">
        {links ? (
          <span>
            We are experiencing heavy traffic. Try again later and follow our{' '}
            <a href="https://twitter.com/overlinenetwork">Twitter</a> for
            announcements.
          </span>
        ) : (
          body
        )}
      </Container>
    </ToastSC>
  );
};

const HeaderFlex = styled.div`
  display: flex;
  align-items: center;
  align-content: center;

  span {
    color: ${({ theme }) => theme.primary};
    font-weight: 600;
  }
`;

const Icon = styled.img`
  height: 25px;
  margin-right: 0.5rem;
`;

const Container = styled.div`
  padding: 0.25rem 0.75rem;

  &.body {
    padding: 1rem 0.75rem;
  }
`;

export default Toast;
