import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  TSetColorTheme,
  TSetModal,
  TUserInterfaceState,
  TSetSelectedTreatType,
  TPlaceOTreatsOnPosition,
  TSetPositionIdToMint,
  TSetRevealProcessResult,
  TSetMobileView,
  TSetFreeOCashAmountClaimed,
  TSetPositionIdToEarlyReveal,
  TSetArtifactSelection,
  TSetOwnedPositionArtifacts,
  TSetWinningModalContent,
  TSetArtifactPlacement,
  TSetShowMetamaskTooltip,
  TSetConnectedAddress,
  TSetShowTwitterTooltip,
  COLOR_THEME_DARK,
  TSetExpandInventoryBar,
  TSetKeysModalStage,
  TSetChestType,
  TSetBooster,
  TSetActivePositionId,
} from './types';

const defaultState: TUserInterfaceState = {
  colorTheme: COLOR_THEME_DARK,
  mobileView: false,
  modal: {
    type: null,
    token: null,
  },
  sidebarOpen: false,
  cartOpen: false,
  revealProcess: null,
  selectedTreatType: null,
  oTreatPlacement: null,
  minting: null,
  earlyPositionReveal: null,
  freeOCashAmountClaimed: 0,
  selectedPositionArtifacts: null,
  auction: {
    itemId: null,
    type: null,
    startingPrice: 0,
    purpose: null,
  },
  artifactSelection: null,
  ownedPositionArtifacts: [],
  winningModalContent: null,
  artifactPlacement: null,
  connectedAddress: null,
  showMetamaskTooltip: false,
  showTwitterTooltip: false,
  expandInventoryBar: true,
  keysModalStage: null,
  chestType: null,
  activeBooster: null,
  activePositionId: null,
};

const userInterfaceSlice = createSlice({
  name: 'userInterface',
  initialState: defaultState,
  reducers: {
    // OK
    setColorTheme: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetColorTheme>,
    ): TUserInterfaceState => ({
      ...state,
      colorTheme: action.payload.colorTheme,
    }),
    // OK
    setMobileView: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetMobileView>,
    ): TUserInterfaceState => ({
      ...state,
      mobileView: action.payload.mobileView,
    }),

    // OK
    setFreeOCashAmountClaimed: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetFreeOCashAmountClaimed>,
    ): TUserInterfaceState => ({
      ...state,
      freeOCashAmountClaimed: action.payload.amountClaimed,
    }),

    setEarlyPositionReveal: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetPositionIdToEarlyReveal>,
    ): TUserInterfaceState => ({
      ...state,
      earlyPositionReveal: action.payload.earlyPositionReveal,
    }),

    // OK
    setSelectedTreatType: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetSelectedTreatType>,
    ): TUserInterfaceState => {
      if (action.payload.selectedTreatType === state.selectedTreatType) {
        return {
          ...state,
          selectedTreatType: null,
        };
      }
      return {
        ...state,
        selectedTreatType: action.payload.selectedTreatType,
      };
    },

    /**
     * OTreat Placement
     */
    placeOTreatsOnPosition: (
      state: TUserInterfaceState,
      action: PayloadAction<TPlaceOTreatsOnPosition>,
    ): TUserInterfaceState => {
      // set position and open modal
      return {
        ...state,
        modal: {
          type: 'treatPlacingModal',
          token: null,
        },
        oTreatPlacement: action.payload.selectedPlot,
      };
    },

    closePositionPlacementModal: (
      state: TUserInterfaceState,
    ): TUserInterfaceState => {
      return {
        ...state,
        modal: {
          type: null,
          token: null,
        },
        oTreatPlacement: null,
      };
    },

    setArtifactSelection: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetArtifactSelection>,
    ): TUserInterfaceState => ({
      ...state,
      artifactSelection: action.payload.artifactSelection,
      modal: {
        type: 'auctionListingModal',
        token: null,
      },
    }),

    setOwnedPositionArtifacts: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetOwnedPositionArtifacts>,
    ): TUserInterfaceState => ({
      ...state,
      ownedPositionArtifacts: action.payload.ownedPositionArtifacts,
    }),

    /**
     * Reveal process
     */
    setRevealProcessResult: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetRevealProcessResult>,
    ): TUserInterfaceState => ({
      ...state,
      modal: {
        type: 'revealResultModal',
        token: null,
      },
      revealProcess: action.payload.revealProcess,
    }),

    closeRevealProcessModal: (
      state: TUserInterfaceState,
    ): TUserInterfaceState => {
      return {
        ...state,
        modal: {
          type: null,
          token: null,
        },
        revealProcess: null,
      };
    },

    setModal: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetModal>,
    ): TUserInterfaceState => ({
      ...state,
      modal: {
        ...state.modal,
        type: action.payload.type,
        token: action.payload.token,
      },
    }),

    setPositionIdToMint: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetPositionIdToMint>,
    ): TUserInterfaceState => ({
      ...state,
      minting: action.payload.minting,
    }),

    setWinningModal: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetWinningModalContent>,
    ): TUserInterfaceState => ({
      ...state,
      winningModalContent: action.payload.winningModalContent,
      modal: {
        ...state.modal,
        type: 'winningModal',
        token: null,
      },
    }),
    setArtifactPlacement: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetArtifactPlacement>,
    ): TUserInterfaceState => ({
      ...state,
      artifactPlacement: action.payload.artifactPlacementContent,
      modal: {
        ...state.modal,
        type: 'claimAuctionModal',
        token: null,
      },
    }),
    clearMintingProcess: (state: TUserInterfaceState): TUserInterfaceState => ({
      ...state,
      minting: null,
    }),

    // Twitter Tooltip

    setShowTwitterTooltip: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetShowTwitterTooltip>,
    ): TUserInterfaceState => ({
      ...state,
      showTwitterTooltip: action.payload.showTwitterTooltip,
    }),

    // Metamask connection

    setShowConnectTooltip: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetShowMetamaskTooltip>,
    ): TUserInterfaceState => ({
      ...state,
      showMetamaskTooltip: action.payload.showMetamaskTooltip,
    }),

    setConnectedAddress: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetConnectedAddress>,
    ): TUserInterfaceState => ({
      ...state,
      connectedAddress: action.payload.connectedAddress,
    }),

    // Main Inventory Bar

    setExpandInventoryBar: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetExpandInventoryBar>,
    ): TUserInterfaceState => ({
      ...state,
      expandInventoryBar: action.payload.expandInventoryBar,
    }),

    // Keys Modal

    setKeysModalStage: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetKeysModalStage>,
    ): TUserInterfaceState => ({
      ...state,
      keysModalStage: action.payload.keysModalStage,
    }),

    setChestType: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetChestType>,
    ): TUserInterfaceState => ({
      ...state,
      chestType: action.payload.chestType,
    }),

    // Boosters

    setActiveBooster: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetBooster>,
    ): TUserInterfaceState => ({
      ...state,
      activeBooster: action.payload.activeBooster,
    }),

    // Active Plot

    setActivePositionId: (
      state: TUserInterfaceState,
      action: PayloadAction<TSetActivePositionId>,
    ): TUserInterfaceState => ({
      ...state,
      activePositionId: action.payload.activePositionId,
    }),
  },
});

export default userInterfaceSlice;
