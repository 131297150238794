import { ButtonPrimarySC } from '@components/Button.styled';
import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

const BuyNow: FC = () => {
  return (
    <div className="buy-now">
      <div className="price">
        <h3>Buy now price</h3>
        <div>
          <Skeleton count={1} height={24} />
          <Skeleton count={1} height={16} />
        </div>
      </div>
      <div className="method">
        <h3>Payment method</h3>
        <div className="method-options">
          <label>
            <input
              type="radio"
              name="myRadio"
              value="option1"
              defaultChecked={true}
            />
            ōCash
          </label>
          <label>
            <input type="radio" name="myRadio" value="option2" /> Crypto
          </label>
          <label>
            <input type="radio" name="myRadio" value="option3" /> Credit Card
          </label>
        </div>
      </div>
      <div className="confirm">
        <ButtonPrimarySC disabled>Complete purchase</ButtonPrimarySC>
      </div>
    </div>
  );
};

export default BuyNow;
