import Text, { TextVariant } from '@components/Text';
import React from 'react';
import { MissionsSC } from '../OgemsDashboard.styled';
import { TOgemDashboardComponentProps } from './ProgressBar';
import { ReactComponent as SvgLock } from '@assets/svg/ogems/lock.svg';
import { openOCashModal } from '@store/common';
import { useDispatch } from 'react-redux';

const ComingSoon = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Text variant={TextVariant.CardText} className="subtitle">
        <SvgLock
          height={24}
          width={24}
          style={{
            display: 'inline',
            marginRight: '0.25rem',
            stroke: '#565f81',
          }}
        />
        Coming Soon
      </Text>
    </div>
  );
};

// const InactiveCard = (title: string, multiplier: number, text: string) => {};

const missions = [
  {
    title: 'ōCash Purchase',
    text: 'Increases per ōCash bought',
    rate: 0.00001,
  },
  {
    title: 'Connect Wallet',
    text: 'Increases after connecting Metamask',
    rate: 0.000003,
  },
  {
    title: 'Connect Twitter',
    text: 'Increases after connecting',
    rate: 0.000006,
  },
  {
    title: 'ōLand Upgrades',
    text: 'Increases per upgrade',
    rate: 0.000007,
  },
  {
    title: 'ōLand Upgrade Sets',
    text: 'Increases per full set of upgrades',
    rate: 0.000021,
  },
  {
    title: 'Referrals',
    text: 'Increases per referral',
    rate: 0.0000028,
  },
  {
    title: 'Tweet-to-Earn',
    text: 'Increases per tweet',
    rate: 0,
  },
  {
    title: 'Email-to-Earn',
    text: 'Increases per email opened',
    rate: 0,
  },
  // {
  //   title: 'Mint a Booster',
  //   text: 'Per Booster minted',
  //   rate: 'Coming Soon',
  // },
  {
    title: 'More Coming!',
    text: 'More missions coming soon',
    rate: 0,
  },
  // {
  //   title: 'Own an ōFriend',
  //   text: 'Per ōFriend owned',
  //   rate: '0.1%',
  // },
  // {
  //   title: 'Own a Swamp Boat',
  //   text: 'Per boat',
  //   rate: '0.1%',
  // },
];

const returnTotal = (mission: any, data: any) => {
  switch (mission.title) {
    case 'ōCash Purchase':
      return data.boughtOCashAmount > 0
        ? data.boughtOCashAmount * mission.rate
        : mission.rate.toString();
    case 'Connect Twitter':
      return data.connectedTwitter ? mission.rate : mission.rate.toString();
    case 'Connect Wallet':
      return data.connectedMetamask ? mission.rate : mission.rate.toString();
    case 'ōLand Upgrades':
      return data.upgradesAmount > 0
        ? data.upgradesAmount * mission.rate
        : mission.rate.toString();
    case 'ōLand Upgrade Sets':
      return data.fullSetsAmount > 0
        ? data.fullSetsAmount * mission.rate
        : mission.rate.toString();
    case 'Referrals':
      return data.referredFriends > 0
        ? data.referredFriends * mission.rate
        : mission.rate.toString();
    default:
      return mission.rate;
  }
};

const Missions: React.FunctionComponent<TOgemDashboardComponentProps> = (
  oGemData,
) => {
  const data = oGemData.oGemData;
  const dispatch = useDispatch();

  return (
    <MissionsSC>
      <div className="rate">
        <Text variant={TextVariant.CardText} className="subtitle">
          Earning Rate
        </Text>
        <Text variant={TextVariant.CardTitle} className="title">
          💎{' '}
          {data.currentRewardRate.toLocaleString('en-US', {
            minimumFractionDigits: 6,
            maximumFractionDigits: 6,
          })}
          <span className="small">ōGems /s</span>
        </Text>
      </div>
      <div className="header">
        <Text variant={TextVariant.CardSubtitle} className="title">
          Boost your ōGems
        </Text>
        <Text variant={TextVariant.CardText} className="subtitle">
          Complete missions to collect ōGems faster and increase your rank.
        </Text>
      </div>
      <div className="mission-grid">
        {missions.map((mission, index) => {
          const isComingSoon = mission.rate === 0;

          return (
            <div className="mission-item" key={index}>
              <div className="mission-header">
                <Text
                  variant={TextVariant.CardText}
                  className="title"
                  style={{ display: 'flex', gap: '0.5rem' }}
                >
                  {mission.title}{' '}
                  {mission.title === 'ōCash Purchase' && (
                    <button
                      className="badge"
                      onClick={() => openOCashModal(dispatch)}
                    >
                      <Text variant={TextVariant.CardText} className="title">
                        Buy
                      </Text>
                    </button>
                  )}
                </Text>
                <Text variant={TextVariant.CardText} className="subtitle">
                  {mission.text}
                </Text>
              </div>
              <div
                className={
                  typeof returnTotal(mission, data) === 'string'
                    ? 'rate-score inactive'
                    : 'rate-score active'
                }
                style={{ borderWidth: isComingSoon ? '0px' : '1.5px' }}
              >
                {isComingSoon ? (
                  <ComingSoon />
                ) : (
                  <Text variant={TextVariant.CardText} className="title">
                    💎{' '}
                    {Number(returnTotal(mission, data)).toLocaleString(
                      'en-US',
                      {
                        minimumFractionDigits: 7,
                        maximumFractionDigits: 7,
                      },
                    )}
                    <span className="small">/s</span>
                  </Text>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </MissionsSC>
  );
};

export default Missions;
