import styled from 'styled-components';

const BtnBadgeSC = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  background: white;
  border-radius: 4px;
  top: -4px;
  right: 0px;
  max-height: 12px;
  padding: 2px 8px;
  h1 {
    margin: 0px;
    font-weight: 700;
    color: black;
  }

  img {
    max-height: 20px;
  }
`;

export default BtnBadgeSC;
