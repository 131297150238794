import styled, { css, keyframes } from 'styled-components';
import { getFontStyle } from '@utils/ui';
import { TTextColor } from '@assets/styles/colorThemes';

export enum TextVariant {
  Title = 'Title',
  Subtitle = 'Subtitle',
  Description = 'Description',
  Body = 'Body',
  Button = 'Button',
  Small = 'Small',
  Navigation = 'Navigation',
  CardTitle = 'CardTitle',
  CardSubtitle = 'CardSubtitle',
  CardText = 'CardText',
  Link = 'Link',
  CartNumber = 'CartNumber',
  Disclaimer = 'Disclaimer',
  ColorText = 'ColorText',
  Error = 'Error',
}

export type TTextProps = {
  variant?: TextVariant;
  color?: keyof TTextColor;
  fontSize?: number;
  uppercase?: boolean;
};

const popIn = keyframes`
  0% {
    transform: scale3d(0, 0, 0);
    opacity: 0;
  }
  30% {
    transform: scale3d(1.5, 1.5, 1.5);
    opacity: 1;
  }
  60% { transform: scale3d(1, 1, 1); }
  80% { transform: scale3d(1.15, 1.15, 1.15); }
  100% { transform: scale3d(1, 1, 1); }
`;

const textBounce = keyframes`
  10% { transform: scaleY(0.9) translateY(5%); }
  45% { transform: scaleY(1.2) translateY(-100%); }
  65% { transform: scaleY(0.95) translateY(0); }
  75% { transform: scaleY(1.05) translateY(-25%); }
  85% { transform: scaleY(1) translateY(0); }
  100% { transform: scaleY(1) translateY(0%); }
`;

const popOut = keyframes`
  0% { transform: scale3d(1, 1, 1); }
  60% {
    transform: scale3d(1.25, 1.25, 1.25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
`;

const Text = styled.h1<TTextProps>`
  ${({ variant = TextVariant.Body }) => {
    switch (variant) {
      case TextVariant.Title:
        return css`
          // font-family: ${getFontStyle('Sofia Pro', 'SemiBold')};
          font-size: 56px;
          line-height: 135%;
          font-weight: 500;
        `;
      case TextVariant.Subtitle:
        return css`
          // font-family: ${getFontStyle('Sofia Pro', 'Regular')};
          font-size: 32px;
          line-height: 135%;
          margin-bottom: 0px;

          @media only screen and (max-width: 1024px) {
            font-size: 24px;
          }
        `;
      case TextVariant.Description:
        return css`
          // font-family: ${getFontStyle('Sofia Pro', 'Regular')};
          font-size: 24px;
          line-height: 135%;

          @media only screen and (max-width: 1024px) {
            font-size: 16px;
          }
        `;
      case TextVariant.Body:
        return css`
          // font-family: ${getFontStyle('Sofia Pro', 'Regular')};
          font-size: 18px;
          line-height: 135%;
          font-weight: 400;
        `;
      case TextVariant.Button:
        return css`
          // font-family: ${getFontStyle('Sofia Pro', 'Regular')};
          font-size: 18px;
          line-height: 25px;
          font-weight: 500;
        `;
      case TextVariant.Small:
        return css`
          // font-family: ${getFontStyle('Sofia Pro', 'Regular')};
          font-size: 12px;
          line-height: 135%;
          font-weight: 400;
        `;
      case TextVariant.Navigation:
        return css`
          color: rgba(255, 255, 255, 0.8) !important;
          opacity: 1;
          margin: 0;
          font-size: 14px;
          line-height: 135%;
          font-weight: 400;
          :hover {
            color: white;
            opacity: 1;
          }

          &.mobile-nav {
            color: ${({ theme }) => theme.text.main} !important;

            :hover {
              color: ${({ theme }) => theme.neutral.neutral6} !important;
              opacity: 1;
            }
            font-size: 24px;
          }
        `;
      case TextVariant.CardTitle:
        return css`
          // font-family: ${getFontStyle('Sofia Pro', 'SemiBold')};
          font-size: 32px;
          line-height: 135%;
          margin-bottom: 0px;

          &.success {
            color: #70db96;
          }
          @media only screen and (max-width: 1024px) {
            font-size: 24px;
          }
        `;
      case TextVariant.CardSubtitle:
        return css`
          font-size: 24px;
          line-height: 135%;

          &.order-details {
            font-size: 16px;
            margin-bottom: 0px;
          }

          @media only screen and (max-width: 1024px) {
            font-size: 16px;
          }
        `;
      case TextVariant.Error:
        return css`
          font-size: 24px;
          line-height: 135%;
          color: ${({ theme }) => theme.red};
          @media only screen and (max-width: 1024px) {
            font-size: 16px;
          }
        `;
      case TextVariant.CardText:
        return css`
          // font-family: ${getFontStyle('Sofia Pro', 'SemiBold')};
          font-size: 16px;
          line-height: 135%;
          font-weight: 400;
          margin-bottom: 0px;
          .fine-print {
            font-size: 10px;
          }

          @media only screen and (max-width: 1024px) {
            font-size: 14px;
            &.plot-label {
              font-size: 10px;
              font-weight: 400;

              .fine-print {
                font-size: 8px;
              }
            }
            &.card-subtitle {
              font-size: 16px;
            }
          }

          &.red {
            font-size: 10px;
            color: ${({ theme }) => theme.red};
            font-weight: 700;
          }

          &.green {
            font-size: 10px;
            color: ${({ theme }) => theme.green};
            font-weight: 700;
          }

          &.oland-stats {
            font-size: 12px;
            font-weight: 500;
            color: white;
          }

          &.listing {
            margin-bottom: 8px;
          }

          a {
            color: ${({ theme }) => theme.blue.blue100};
            text-decoration: none;
            :hover {
              text-decoration: underline;
            }
          }

          &.sharing-description {
            font-weight: 500;

            color: ${({ theme }) => theme.text.main};

            @media only screen and (max-width: 776px) {
              font-size: 14px;
            }
          }

          .inline-link {
            color: ${({ theme }) => theme.blue.blue100};
            text-decoration: none;
            cursor: pointer;
            :hover {
              text-decoration: underline;
            }
          }

          &.input-inner-label {
            position: absolute;
            font-size: 10px;
            left: 12px;
            top: 6px;
          }

          &.bold {
            font-weight: 500;
          }
        `;
      case TextVariant.Link:
        return css`
          // font-family: ${getFontStyle('Sofia Pro', 'SemiBold')};
          font-size: 16px;
          line-height: 135%;
          font-weight: 400;
          cursor: pointer;
          color: ${({ theme }) => theme.blue.blue100} !important;
          :hover {
            text-decoration: underline;
          }

          &.footer-link {
            color: black !important;
          }

          @media only screen and (max-width: 883px) {
            font-size: 12px;
          }
        `;
      case TextVariant.CartNumber:
        return css`
          // font-family: ${getFontStyle('Sofia Pro', 'SemiBold')};
          font-size: 24px;
          line-height: 135%;
          font-weight: 400;
          cursor: pointer;
        `;
      case TextVariant.Disclaimer:
        return css`
          // font-family: ${getFontStyle('Sofia Pro', 'SemiBold')};
          font-size: 16px;
          line-height: 135%;
          font-weight: 400;

          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
        `;
      case TextVariant.ColorText:
        return css`
          // font-family: ${getFontStyle('Sofia Pro', 'SemiBold')};
          font-size: 48px;
          font-weight: 600;
          line-height: 100%;
          background: linear-gradient(
            to right,
            #f8ab5e 0,
            #f36961 20%,
            #a176c8 40%,
            #759beb 60%,
            #65beb3 80%,
            #70db96 100%
          );
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        `;
    }
  }}

  color: ${({ color, theme }) => theme.text[color ?? 'main']};
  ${(props) => (props.uppercase ? 'text-transform: uppercase;' : '')}

  &.dgray {
    color: ${({ theme }) => theme.neutral.neutral7};
  }

  &.gray {
    color: #8f9bb3;
  }

  .gray {
    color: #8f9bb3;
  }

  &.green {
    color: ${({ theme }) => theme.green};
  }

  &.dgray {
    color: ${({ theme }) => theme.neutral.neutral7};
  }

  &.color {
    background: linear-gradient(
      to right,
      #f8ab5e 0,
      #f36961 20%,
      #a176c8 40%,
      #759beb 60%,
      #65beb3 80%,
      #70db96 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .color {
    font-weight: 600;
    background: linear-gradient(
      to right,
      #f8ab5e 0,
      #f36961 20%,
      #a176c8 40%,
      #759beb 60%,
      #65beb3 80%,
      #70db96 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.thick {
    font-weight: 600;
  }

  &.blue {
    color: ${({ theme }) => theme.blue.blue100};
  }

  .blue {
    color: ${({ theme }) => theme.blue.blue100};
  }

  &.smaller {
    font-size: 0.8rem;
  }

  &.courier {
    font-family: courier, courier new, serif;
  }

  &.chest-reward-figure {
    -webkit-text-stroke: 1.5px #ad6431;
    span {
      background-image: linear-gradient(
        to bottom,
        #f5f57b 24%,
        #fcfce4 35%,
        #fcfce4 55%,
        #eca83b 60%,
        #ad6431 75%
      );
      color: transparent;
      -webkit-background-clip: text;
    }
  }

  &.popIn {
    animation: ${popIn};
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-duration: 1.5s;
  }

  &.popOut {
    animation: ${popOut};
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-duration: 1.5s;
  }

  &.textBounce {
    animation: ${textBounce};
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-duration: 1.5s;
  }
`;

export default Text;
