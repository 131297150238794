import React from 'react';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';

const Loader: React.FunctionComponent = () => {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
      }}
    >
      <SvgSpinner
        height={50}
        width={50}
        style={{ display: 'inline', fill: 'white' }}
      />
    </div>
  );
};

export default Loader;
