import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import Intro from '@components/Intro';
import { getTokenDataFromUrl } from '@utils/common';
import userInterfaceSlice from '@store/user-interface';

import StyledSignIn from './SignIn.styled';

const SignIn: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { setModal } = userInterfaceSlice;

  useEffect(() => {
    const tokenData = getTokenDataFromUrl();

    if (tokenData) {
      const { type, token } = tokenData;

      // remove token param from url
      const queryParams = new URLSearchParams(location.search);

      if (queryParams.has('initializationToken')) {
        queryParams.delete('initializationToken');
      }

      if (queryParams.has('resetPasswordToken')) {
        queryParams.delete('resetPasswordToken');
      }

      navigate(queryParams.toString());

      dispatch(setModal({ type, token }));
    }
  }, []);

  return (
    <StyledSignIn>
      <div className="inner-content">
        <Intro />
      </div>
    </StyledSignIn>
  );
};

export default SignIn;
