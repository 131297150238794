import React, { FC, useEffect, useMemo, useState } from 'react';
import { V2AuctionCardSC } from '../V2AuctionsPage.styled';
import CardVideo from '@pages/Home/components/CardVideo';
import {
  ButtonOutlineSC,
  ButtonPrimarySC,
  ButtonWhiteSC,
} from '@components/Button.styled';
import { InputSC } from '@components/Input.styled';
import { colorThemes } from '@assets/styles';
import api from '@utils/api';
import { toast } from 'react-toastify';
import Toast from '@components/Toast';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import {
  getRQKAuctionsKey,
  getRQKProfileKey,
  getRQKUsersAuctionsKey,
  queryClient,
} from '@utils/rq-utils';
import { TRootState } from '@store/index';
import { useSelector } from 'react-redux';
import RarityDisplay from '@components/RarityDisplay';
import UpgradeTray from '@pages/Home/components/UpgradeTray';
import { TArtifact, getEnergyLevel } from '@data/gifts';
import { ReactComponent as SvgPower } from '@assets/svg/icons/power.svg';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { generateClassNames } from '@pages/Home/components/PositionCard/PositionCard';
import { returnDominantBooster } from '@pages/Home/components/PositionCard/GenericPosition';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

type TV2AuctionCardProps = {
  auctionData: any;
  positionDetail: any;
};

dayjs.extend(duration);

const getTimeLeft = (endDate: string) => {
  const now = dayjs();
  const end = dayjs(endDate);
  const duration = end.diff(now);

  const days = Math.floor(dayjs.duration(duration).asDays());
  const hours = dayjs.duration(duration).hours();
  const minutes = dayjs.duration(duration).minutes();
  const seconds = dayjs.duration(duration).seconds();

  return `${days}D : ${hours}H : ${minutes}M : ${seconds}S`;
};

const V2AuctionCard: FC<TV2AuctionCardProps> = ({
  auctionData,
  positionDetail,
}) => {
  const [focus, setFocus] = useState(false);
  const [userIsBidding, setUserIsBidding] = useState(false);
  const [bidAmount, setBidAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [buyNowLoading, setBuyNowLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(
    getTimeLeft(auctionData.auctionEndAt),
  );

  const oCashBalance = queryClient.getQueryData(
    getRQKProfileKey('getOCashAmount'),
  ) as any;

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft(auctionData.auctionEndAt));
    }, 1000);

    return () => clearInterval(timer);
  }, [auctionData.auctionEndAt]);

  const currentBid = auctionData.winningBid
    ? auctionData.winningBid.amount
    : auctionData.startingPrice;

  const userId = useSelector<TRootState>(
    (store: TRootState) => store.auth.user?.id,
  ) as string;

  const handleAddBid = async (amount: number) => {
    setIsLoading(true);
    try {
      await api.auctionBid({
        auctionId: auctionData.id,
        amount: amount,
      });

      toast(
        <Toast
          type="success"
          header="Bid successfully placed!"
          body="Your bid was successfully placed - you are now the leading bidder."
        />,
      );
    } catch (err: any) {
      toast(
        <Toast
          type="error"
          header="Something went wrong."
          body={err.auctionData.errMsg}
        />,
      );
    } finally {
      await queryClient.invalidateQueries(getRQKAuctionsKey('getAuctions'));
      await queryClient.invalidateQueries(getRQKAuctionsKey('getBlockedItems'));
      await queryClient.invalidateQueries(getRQKAuctionsKey('getUserAuctions'));
      setIsLoading(false);
    }
  };

  const handlePlusCustomBtnClick = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    const oCash = parseInt(oCashBalance.oCashAmountAvailable);

    if (bidAmount > currentBid && bidAmount <= oCash) {
      await handleAddBid(bidAmount);
    }

    if (bidAmount > oCash) {
      toast(
        <Toast
          type="error"
          header="Insufficient ōCash"
          body="You do not have enough ōCash to place this bid."
        />,
      );
    }
  };

  const handleOpenBiddingOverlay = () => {
    setBidAmount(currentBid + 1);
    setUserIsBidding(true);
  };

  const userIsWinning = auctionData.winningBid?.user === userId;

  const hasDogHouse = positionDetail.artifacts
    ? positionDetail.artifacts.map((e: TArtifact) => e.name).includes('dog')
    : false;

  const cardType = hasDogHouse ? 'golden' : 'basic';

  const isGen6 = positionDetail.type === 5;

  const energy = getEnergyLevel(positionDetail.artifacts);

  const dominantBooster = positionDetail.boosters
    ? returnDominantBooster(positionDetail.boosters)
    : null;

  const cardClassNames = useMemo(
    () => generateClassNames({ dominantBooster, cardType, isGen6 }),
    [dominantBooster, cardType, isGen6],
  );

  const handleBuyNow = async () => {
    setBuyNowLoading(true);
    try {
      await api.buyItNow(auctionData.id);
      toast(
        <Toast
          type="success"
          header="Purchase successful!"
          body="You are the proud owner of a new ōLand."
        />,
      );
    } catch (e) {
      console.error(e);
      toast(
        <Toast
          type="error"
          header="Something went wrong."
          body="Purchase failed. Try again later or contact support."
        />,
      );
    } finally {
      await queryClient.invalidateQueries(getRQKAuctionsKey('getAuctions'));
      await queryClient.invalidateQueries(getRQKAuctionsKey('getBlockedItems'));
      await queryClient.invalidateQueries(getRQKAuctionsKey('getUserAuctions'));
      await queryClient.invalidateQueries(
        getRQKUsersAuctionsKey('getUserRunningAuctions'),
      );
      setBuyNowLoading(false);
    }
  };

  return (
    <V2AuctionCardSC
      className={cardClassNames}
      onMouseOver={() => setFocus(true)}
      onMouseOut={() => setFocus(false)}
      focus={focus}
    >
      {userIsBidding && (
        <div className="bidding-overlay blur">
          <div className="bidding-inner">
            <div className="heading">
              <div>
                Auction ends in <br />
                <span>{timeLeft}</span>
              </div>
              <button onClick={() => setUserIsBidding(false)}>
                <img src={SvgCross} alt="cross-btn" />
              </button>
            </div>
            <div className="bid-options">
              <div className="current-bid">
                <p>Current Bid</p>
                <h5>
                  {(auctionData.winningBid
                    ? auctionData.winningBid.amount
                    : auctionData.startingPrice
                  ).toLocaleString('en-US')}{' '}
                  ōCash
                </h5>
              </div>

              <p>Enter Bid</p>
              <div className="bid-entry">
                <InputSC
                  style={{
                    color: bidAmount < currentBid ? colorThemes.light.red : '',
                  }}
                  placeholder="Amount (ōCash)"
                  type="number"
                  value={bidAmount}
                  onChange={(val) =>
                    setBidAmount(parseFloat(val.currentTarget.value))
                  }
                />
                <ButtonPrimarySC
                  disabled={
                    isLoading ||
                    bidAmount <= currentBid ||
                    isNaN(bidAmount) ||
                    bidAmount > oCashBalance
                  }
                  onClick={(e) => handlePlusCustomBtnClick(e)}
                  className="bid-btn"
                >
                  {isLoading ? (
                    <SvgSpinner
                      fill={'white'}
                      style={{ height: 30, width: 30 }}
                    />
                  ) : (
                    'Bid'
                  )}
                </ButtonPrimarySC>
              </div>
              {auctionData.buyNow && (
                <div className="buy-now">
                  <p>Buy It Now Price</p>
                  <h5>{auctionData.buyNow.toLocaleString('en-US')} ōCash</h5>
                  <ButtonPrimarySC
                    disabled={
                      isLoading ||
                      buyNowLoading ||
                      auctionData.buyNow > oCashBalance
                    }
                    onClick={handleBuyNow}
                  >
                    {buyNowLoading ? (
                      <SvgSpinner style={{ height: 30, width: 30 }} />
                    ) : (
                      'Buy Now'
                    )}
                  </ButtonPrimarySC>
                </div>
              )}

              {userIsWinning && (
                <h5 className="green">
                  You are winning!
                  {currentBid < auctionData.minTresholdForSuccess && (
                    <>
                      <br />
                      <span className="gray">Reserve not met.</span>
                    </>
                  )}
                </h5>
              )}
            </div>
          </div>
        </div>
      )}
      <h6 className="time-remaining">{timeLeft}</h6>
      <div className="video-container">
        {userIsWinning && (
          <div className="winning-overlay blur">
            <h4>
              You are winning!{' '}
              {currentBid < auctionData.minTresholdForSuccess && (
                <>
                  <br />
                  <span>(Reserve not met)</span>
                </>
              )}
            </h4>
          </div>
        )}
        <div className="power-score">
          <ButtonOutlineSC
            className={cardType === 'golden' ? 'smaller dark' : 'smaller blue'}
          >
            <SvgPower
              fill={colorThemes.light.blue.blue100}
              style={{ height: 16, width: 16 }}
            />{' '}
            {energy.toLocaleString()}
          </ButtonOutlineSC>
        </div>

        <RarityDisplay
          rarity={positionDetail.rarity}
          positionId={positionDetail.id}
          cardType={cardType}
        />

        <div className="upgrade-inventory">
          <UpgradeTray
            ownedArtifacts={positionDetail.artifacts}
            positionDataIsLoading={false}
            isGen6={isGen6}
          />
        </div>
        <CardVideo focus={focus} isGen6={auctionData.position.type === 5} />
      </div>
      <div className="card-content">
        <div className="auction-info">
          <h6>
            ōLand #{auctionData.position.position.toLocaleString('en-US')}
          </h6>
          <div className="bid">
            <h6 className="gray">Current Bid</h6>
            <h6>
              {(auctionData.winningBid
                ? auctionData.winningBid.amount
                : auctionData.startingPrice
              ).toLocaleString('en-US')}{' '}
              ōCash
            </h6>
          </div>
        </div>
        <div>
          <ButtonWhiteSC onClick={handleOpenBiddingOverlay}>
            Buy ōLand
          </ButtonWhiteSC>
          <a
            href={`https://gps-coordinates.org/my-location.php?lat=${auctionData.position.lat}&lng=${auctionData.position.lng}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>View Location {'>'}</p>
          </a>
        </div>
      </div>
    </V2AuctionCardSC>
  );
};

export default V2AuctionCard;
