import styled from 'styled-components';

import { devices } from '@assets/styles/screenSizes';

const Wrapper = styled.div`
  margin: auto;
  @media only screen and (${devices.desktop}) {
    max-width: 1440px;
  }
  @media only screen and (${devices.laptopL}) {
    // max-width: 1280px;
  }
  @media only screen and (${devices.laptop}) {
    padding-left: 48px;
    padding-right: 48px;
  }
  @media only screen and (${devices.mobileL}) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media only screen and (${devices.mobileM}) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export default Wrapper;
