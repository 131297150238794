import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  TOrderState,
  TSetProductCount,
  TSetBillingAddress,
  TSetCountryAndRegion,
  TSetShippingAddress,
  TSetProduct,
  TSetDiscount,
  TSetOrderMeta,
  TSetPaymentType,
  TStartPurchaseProcess,
  TSetOrderStep,
  TSetCustomEthPaymentStep,
} from './types';
import { EPurchaseProcessSteps } from '@components/Modal/CheckoutProcess/PurchaseModal';

const defaultAddress = {
  firstName: '',
  lastName: '',
  street: '',
  streetOpt: '',
  postal: '',
  city: '',
  region: '',
  country: '',
};

const calculateTotal = (state: TOrderState) =>
  state.productCount * state.productPrice +
  state.shipping +
  state.tax -
  state.productCount * state.discount;

const defaultState: TOrderState = {
  id: null,
  productId: null,
  productTitle: 'something',
  productPrice: 0,
  productPreReleasePrice: 0,
  productCount: 0,
  discount: 0,
  shipping: 0,
  tax: 0,
  total: 0,
  shippingAddress: { ...defaultAddress, note: '' },
  billingAddress: { ...defaultAddress, vatId: '' },
  paymentType: null,
  purchaseProductType: null,
  currentStep: null,
  customEthPayment: null,
};

const userInterfaceSlice = createSlice({
  name: 'order',
  initialState: defaultState,
  reducers: {
    setProduct: (
      state: TOrderState,
      action: PayloadAction<TSetProduct>,
    ): TOrderState => {
      return {
        ...state,
        productId: action.payload.id,
        productTitle: action.payload.title,
        productPrice: action.payload.price,
        productPreReleasePrice: action.payload.preReleasePrice,
      };
    },

    setProductCount: (
      state: TOrderState,
      action: PayloadAction<TSetProductCount>,
    ): TOrderState => {
      const productCount = action.payload.count;
      return {
        ...state,
        productCount,
        total: calculateTotal({ ...state, productCount }),
      };
    },

    setDiscount: (
      state: TOrderState,
      action: PayloadAction<TSetDiscount>,
    ): TOrderState => {
      return {
        ...state,
        discount: action.payload.discount,
        total: calculateTotal({ ...state, discount: action.payload.discount }),
      };
    },

    setCountryAndRegion: (
      state: TOrderState,
      action: PayloadAction<TSetCountryAndRegion>,
    ): TOrderState => ({
      ...state,
      shippingAddress: {
        ...state.shippingAddress,
        country: action.payload.country,
        region: action.payload.region,
      },
    }),

    setOrderMeta: (
      state: TOrderState,
      action: PayloadAction<TSetOrderMeta>,
    ): TOrderState => {
      const { discount, shipping, tax, total, id } = action.payload;

      return {
        ...state,
        id,
        discount,
        shipping,
        tax,
        total,
      };
    },
    setPaymentType: (
      state: TOrderState,
      action: PayloadAction<TSetPaymentType>,
    ): TOrderState => {
      return {
        ...state,
        paymentType: action.payload.type,
      };
    },
    setShippingAddress: (
      state: TOrderState,
      action: PayloadAction<TSetShippingAddress>,
    ): TOrderState => ({
      ...state,
      shippingAddress: action.payload.shippingAddress,
    }),
    setBillingAddress: (
      state: TOrderState,
      action: PayloadAction<TSetBillingAddress>,
    ): TOrderState => ({
      ...state,
      billingAddress: action.payload.billingAddress,
    }),

    startPurchaseProcess: (
      state: TOrderState,
      action: PayloadAction<TStartPurchaseProcess>,
    ): TOrderState => ({
      ...state,
      currentStep:
        action.payload.type === 'oCash'
          ? EPurchaseProcessSteps.PurchaseOCashInit
          : EPurchaseProcessSteps.PurchaseORouterInit,
      customEthPayment: null,
      purchaseProductType: action.payload.type,
    }),

    setOrderStep: (
      state: TOrderState,
      action: PayloadAction<TSetOrderStep>,
    ): TOrderState => ({
      ...state,
      currentStep: action.payload.step,
    }),

    setEthCustomPayment: (
      state: TOrderState,
      action: PayloadAction<TSetCustomEthPaymentStep>,
    ): TOrderState => ({
      ...state,
      customEthPayment: action.payload,
    }),

    clearCart: (state: TOrderState): TOrderState => ({
      ...state,
      id: null,
      discount: 0,
      shipping: 0,
      tax: 0,
      total: calculateTotal({ ...state, shipping: 0, tax: 0, discount: 0 }),
      shippingAddress: { ...defaultAddress, note: '' },
      billingAddress: { ...defaultAddress, vatId: '' },
      paymentType: null,
      purchaseProductType: null,
      currentStep: null,
    }),
  },
});

export default userInterfaceSlice;
