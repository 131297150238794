import React, { FC } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '@store/index';
import userInterfaceStore from '@store/user-interface';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { defaultModalProps } from '@components/Modal/modal-settings';
import ModalSC from '../../Modal.styled';
import { V2ListingModalSC } from './V2ListingModal.styled';
import ListingCard from './components/ListingCard';
import { getRQKPositionsKey, queryClient } from '@utils/rq-utils';
import { returnDominantBooster } from '@pages/Home/components/PositionCard/GenericPosition';
import ListingForm from './components/ListingForm';
import { TArtifact } from '@data/gifts';

const V2ListingModal: FC = () => {
  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const activePositionId = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.activePositionId,
  ) as string;

  const positionDetails = queryClient.getQueryData(
    getRQKPositionsKey('getPosition', activePositionId),
  ) as any;

  const hasDogHouse = positionDetails.artifacts
    ? positionDetails.artifacts.map((e: TArtifact) => e.name).includes('dog')
    : false;

  const cardType = hasDogHouse ? 'golden' : 'basic';

  const dominantBooster = positionDetails.boosters
    ? returnDominantBooster(positionDetails.boosters)
    : null;

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(
      userInterfaceStore.setModal({
        type: null,
        token: null,
      }),
    );
  };

  return (
    <ReactModal
      isOpen={type === 'v2ListingModal'}
      {...defaultModalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC>
        <div className="header">
          <Text
            style={{ fontWeight: 500, textAlign: 'left', marginBottom: 16 }}
            variant={TextVariant.Subtitle}
          >
            List your ōLand
          </Text>
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <V2ListingModalSC className="body">
          <ListingCard
            isLoading={false}
            positionId={activePositionId}
            dominantBooster={dominantBooster}
            cardType={cardType}
          />
          <ListingForm
            dominantBooster={dominantBooster}
            positionDetails={positionDetails}
          />
        </V2ListingModalSC>
      </ModalSC>
    </ReactModal>
  );
};

export default V2ListingModal;
