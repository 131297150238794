import { colorThemes } from '@assets/styles';
import styled from 'styled-components';

const StyledOrdersPage = styled.div`
  .inner-content {
    margin-top: 48px;

    @media only screen and (max-width: 1024px) {
      padding: 0px 12px;
    }
  }

  .orders-row {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;

    &__left {
      display: flex;
      justify-content: left;

      button {
        min-width: 90px;
      }

      > button:first-child {
        margin-right: 8px;
      }
    }
  }
`;

export default StyledOrdersPage;

export const OrdersContainerSC = styled.div`
  border-radius: 15px;
  overflow: auto;
  box-shadow: rgb(0 0 0 / 16%) 2px 4px 16px;
  max-width: 900px;
  margin: 16px auto 0 auto;

  table {
    width: 100%;
    text-align: center;

    tr {
      :not(:last-child) {
        border-bottom: 1.5px solid ${({ theme }) => theme.input.border};
      }

      :first-child {
        background: ${({ theme }) => theme.neutral.neutral7};
      }

      :not(:first-child) {
        background: ${({ theme }) => theme.input.background};

        :hover {
          background: ${({ theme }) => theme.neutral.neutral2};
        }
      }
    }
  }

  th {
    padding: 12px;
    font-weight: 500;
    color: white;
  }

  td {
    padding: 12px;
    color: ${({ theme }) => theme.text.main};
    &.energy {
      color: ${({ theme }) => theme.blue.blue100};
    }
  }

  td {
    padding: 24px;
    font-size: 16px;

    @media only screen and (max-width: 772px) {
      font-size: 10px;
      padding: 12px;
    }
  }

  th {
    font-size: 18px;
    font-weight: 600;
    padding: 24px;

    @media only screen and (max-width: 772px) {
      font-size: 10px;
      padding: 12px;
    }
  }

  tr {
    border-bottom: 1px solid ${({ theme }) => theme.background.regular.bg2};

    :not(:first-child) {
      :hover {
        background: ${colorThemes.light.blue.blue10};
      }
    }
  }
`;

export const TableLinkSC = styled.button`
  background: none;
  color: ${colorThemes.light.blue.blue100};
  text-decoration: underline;
  border: none;
  font-size: 16px;
  cursor: pointer;

  @media only screen and (max-width: 772px) {
    font-size: 10px;
  }

  :hover {
    opacity: 0.7;
  }
`;

// export default OPSC;
