import React from 'react';
import Tippy from '@tippyjs/react';

import Text, { TextVariant } from '@components/Text';
import { defaultTippyProps } from '@utils/ui';
import { getHumanReadable } from '@utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '@store/index';
import userInterfaceSlice from '@store/user-interface';
import { getArtifactAvailableForSell } from '@utils/rq-utils';

type TUpgradeInventorySection = {
  title: string;
  description: string;
  items: any[];
};
const UpgradeInventorySection: React.FC<TUpgradeInventorySection> = (
  props: TUpgradeInventorySection,
) => {
  const { title, description, items } = props;
  const { setArtifactSelection } = userInterfaceSlice;

  const dispatch = useDispatch();

  const ownedPositionArtifacts =
    useSelector(
      (store: TRootState) => store.userInterface.ownedPositionArtifacts,
    ) || [];

  const artifactIsPresent = (name: string) =>
    ownedPositionArtifacts.filter((e) => e.name === name).length > 0;

  return (
    <>
      <Text
        className="plot-label"
        variant={TextVariant.CardText}
        style={{ fontWeight: 600, margin: 0 }}
      >
        {title}
      </Text>
      <Text
        className="gray"
        variant={TextVariant.CardText}
        style={{ fontWeight: 600, margin: '12px 0px', fontSize: '0.8rem' }}
      >
        {description}
      </Text>
      <div className="upgrades-tray">
        {items.map((artifact) => {
          const available = getArtifactAvailableForSell(
            artifact.id,
            ownedPositionArtifacts,
          );

          return (
            <div key={artifact.id} className="artifact-item">
              <Tippy
                {...defaultTippyProps}
                content={`${getHumanReadable(artifact.id)}`}
              >
                <div>
                  <button
                    className={`reward ${
                      artifactIsPresent(artifact.id) ? 'reward-present' : ''
                    }`}
                    disabled={!Boolean(available)}
                    onClick={() =>
                      available
                        ? dispatch(
                            setArtifactSelection({
                              artifactSelection: {
                                id: available.id,
                                name: artifact.id,
                              },
                            }),
                          )
                        : null
                    }
                  >
                    <img
                      src={artifact.img}
                      style={{ width: '100%', height: '100%' }}
                    />
                    <div className="sell-btn">Sell</div>
                  </button>
                </div>
              </Tippy>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default UpgradeInventorySection;
