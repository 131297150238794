export type TEnv = {
  isDevelopment: boolean;
  isSentryEnabled: boolean;
  baseApiUrl: string;
};

const env: TEnv = {
  isDevelopment: true,
  isSentryEnabled: false,
  baseApiUrl: '****',
};

/**
 * REACT_APP_IS_DEVELOPMENT -> isDevelopment
 *
 * Specifies it the app runs on development conditions
 * Default: true
 */
if (process.env.REACT_APP_IS_DEVELOPMENT !== undefined) {
  const isDevelopment = String(process.env.REACT_APP_IS_DEVELOPMENT);
  env.isDevelopment = isDevelopment === 'true';
}

/**
 * REACT_APP_IS_VERBOSE -> isVerboseEnabled
 *
 * Verbose mode outputs all the stuff to the console
 * Its fine to disable this on production
 * Default: true
 */
if (process.env.REACT_APP_SENTRY_DSN !== undefined) {
  const sentryDSN = String(process.env.REACT_APP_SENTRY_DSN);
  env.isSentryEnabled = sentryDSN.length > 0;
}

/**
 * REACT_APP_MEMBER_API_URL -> memberApiUrl
 *
 * Sets API URL of the member (CORE API)
 * Default: '****'
 */
if (process.env.REACT_APP_BASE_API_URL !== undefined) {
  env.baseApiUrl = String(process.env.REACT_APP_BASE_API_URL);
}

export default env;
