import styled from 'styled-components';
import PngRedBooster from '@assets/png/cursors/cursor-red-booster.png';
import PngGreenBooster from '@assets/png/cursors/cursor-green-booster.png';
import PngYellowBooster from '@assets/png/cursors/cursor-yellow-booster.png';

export const StyledHome = styled.div`
  position: relative;
  .mobile-hidden {
    @media only screen and (max-width: 776px) {
      display: none;
    }
  }

  &.rare,
  &.rare * {
    cursor: url(${PngRedBooster}), auto !important;
  }

  &.super,
  &.super * {
    cursor: url(${PngGreenBooster}), auto !important;
  }

  &.ultra,
  &.ultra * {
    cursor: url(${PngYellowBooster}), auto !important;
  }

  .header-stat {
    border-radius: 10px;
    padding: 8px 12px;

    background-color: transparent;
    border: 1.5px solid ${({ theme }) => theme.input.border};
    h1 {
      font-size: 1rem;
    }

    .blue {
      color: ${({ theme }) => theme.blue.blue100} !important;
      font-weight: 700;
    }

    @media only screen and (max-width: 1024px) {
      padding: 4px 8px;

      h1 {
        font-size: 0.7rem;
      }
    }
  }
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
  }

  // Tray
  .tray-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-bottom: 200px !important;

    .inventory {
      max-width: 100vw;

      @media only screen and (max-width: 600px) {
        border: none;
      }
    }

    @media only screen and (max-width: 1024px) {
      display: block;
    }

    .tray {
      padding: 24px;

      @media only screen and (max-width: 600px) {
        padding: 0px;
      }
    }

    .tray-stats {
      display: flex;
      gap: 24px;
      align-items: center;
      @media only screen and (max-width: 1024px) {
        gap: 12px;
        margin-top: 12px;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .tray-grid {
      display: block;
      margin: 0px 12px;
    }
  }
`;
