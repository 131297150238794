import userInterfaceSlice from '@store/user-interface';
import orderSlice from '@store/order';

const { setModal, setShowConnectTooltip, setShowTwitterTooltip } =
  userInterfaceSlice;
const { startPurchaseProcess } = orderSlice;

export const openOCashModal = (dispatch) => {
  dispatch(
    setModal({
      type: 'ocashChestModal',
      token: null,
    }),
  );
  dispatch(
    startPurchaseProcess({
      type: 'oCash',
    }),
  );
};

export const openChestModal = (dispatch) => {
  // open chest modal
  dispatch(
    setModal({
      type: 'ocashChestModal',
      token: null,
    }),
  );
};

export const closeModal = (dispatch) => {
  dispatch(setModal({ type: null, token: null }));
};

export const handleMetamaskTooltip = (dispatch, show) => {
  dispatch(setShowConnectTooltip({ showMetamaskTooltip: show }));
};

export const handleTwitterTooltip = (dispatch, show) => {
  dispatch(setShowTwitterTooltip({ showTwitterTooltip: show }));
};
