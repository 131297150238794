import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { TRootState } from '@store/index';
import userInterfaceStore from '@store/user-interface';
import { TColorTheme } from '@store/user-interface/types';
import { colorThemes, bsReboot, globalStyle, toastify } from '@assets/styles';
import Layout from '@components/Layout';
import Error from '@pages/Error';

const GlobalStyle = createGlobalStyle`
    ${bsReboot}
    ${toastify}
    ${globalStyle}
  `;

const App: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const { setMobileView } = userInterfaceStore;

  const colorTheme = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.colorTheme,
  ) as TColorTheme;

  const mobileView = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.mobileView,
  );

  const updateDimensions = () => {
    if (window.innerWidth <= 768 && !mobileView) {
      dispatch(setMobileView({ mobileView: true }));
    }

    if (window.innerWidth > 786 && mobileView) {
      dispatch(setMobileView({ mobileView: false }));
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <ThemeProvider theme={colorThemes[colorTheme]}>
      <GlobalStyle />
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ErrorBoundary fallback={<Error />}>
          <Layout />
        </ErrorBoundary>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
