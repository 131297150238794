export const defaultModalStyle = {
  overlay: {
    backgroundColor: 'rgba(18, 22, 25, 0.75)',
    backdropFilter: 'saturate(180%) blur(6px)',
  },
  content: {
    padding: '0',
    borderRadius: '12px',
    border: 'none',
    height: 'fit-content',
    overflow: 'visible',
    inset: 24,
  },
};

export const chestModalStyle = {
  overlay: {
    backgroundColor: 'rgba(18, 22, 25, 0.75)',
    backdropFilter: 'saturate(180%) blur(6px)',
  },
  content: {
    padding: '0',
    borderRadius: '12px',
    border: 'none',
    height: 'fit-content',
    overflow: 'visible',
    inset: 24,
    maxHeight: '80vh',
    maxWidth: 650,
  },
};

export const chestModalProps = {
  style: { ...chestModalStyle },
  closeTimeoutMS: 300,
  shouldCloseOnOverlayClick: true,
};

export const defaultModalProps = {
  style: { ...defaultModalStyle },
  closeTimeoutMS: 300,
  shouldCloseOnOverlayClick: true,
};
