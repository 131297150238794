import Text, { TextVariant } from '@components/Text';
import userInterface from '@store/user-interface';
import React from 'react';
import PngKey from '@assets/png/treasure/key.png';
import { ProductRowSC } from '../Modal.styled';
import { useDispatch } from 'react-redux';
import TermsFooter from './components/TermsFooter';
import { ReactComponent as SvgChevron } from '@assets/svg/chevron-down.svg';
import {
  getRQKBoosterPhase,
  getRQKProductsKey,
  queryClient,
} from '@utils/rq-utils';
import { KEY_PRODUCT_IDS } from '@store/order/products';
import orderStore from '@store/order';
import { EPurchaseProcessSteps } from './PurchaseModal';
import { EKeysModalPhase } from './OcashChestModal';
import ClaimableBooster from '@components/InventoryBar/ClaimableBooster';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import PrizeCarousel from './components/PrizeCarousel';

export type TProduct = {
  id: string;
  title: string;
  description: string;
  price: string;
  preReleasePrice: string;
  availableCount: number;
  createdAt: string;
  updatedAt: string;
};

export const getProductQuantity = (product: TProduct) =>
  parseInt(product.title.split(' ')[0], 10);

export const calculateSavings = (
  products: TProduct[],
  selectedProduct: TProduct,
) => {
  const lowestPriceProductId = 'fbf67243-bd6b-4be4-a70e-9de24ce61620';

  // Find the lower price product
  const lowerPriceProduct = products.find(
    (product) => product.id === lowestPriceProductId,
  ) as TProduct;

  // Calculate selected product's quantity
  const amountOfKeys = getProductQuantity(selectedProduct);

  // Calculate prices
  const highestPrice = parseFloat(lowerPriceProduct.price) / 15;
  const selectedPrice = parseFloat(selectedProduct.price) / amountOfKeys;

  // Calculate discount and percentage discount
  const discount = highestPrice - selectedPrice;
  const percentageDiscount = (discount / highestPrice) * 100;

  // Determine if to return totalSavings or percentageDiscount
  // return parseFloat(selectedProduct.price) < 15
  //   ? totalSavings
  //   : percentageDiscount;

  return percentageDiscount;
};

const SelectKeysPhase: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { startPurchaseProcess } = orderStore;

  const boosterPhase = queryClient.getQueryData(
    getRQKBoosterPhase('getBoosterPhase'),
  ) as any;

  // return only products that are keys
  const products = (
    queryClient.getQueryData(getRQKProductsKey('getProducts')) as any[]
  )
    .filter((el) => KEY_PRODUCT_IDS.indexOf(el.id) !== -1)
    .sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

  const handleKeyGroupClick = (selectedProductId: string) => {
    const { id, price, preReleasePrice, title } = products.find(
      (el) => el.id === selectedProductId,
    );

    // close current modal
    dispatch(userInterface.setModal({ type: null, token: null }));

    dispatch(
      startPurchaseProcess({
        type: 'keys',
      }),
    );

    dispatch(
      userInterface.setModal({
        type: 'purchaseModal',
        token: null,
      }),
    );

    dispatch(
      orderStore.setProduct({
        id, // TODO: TMP normalize values
        title,
        price: parseFloat(price),
        preReleasePrice: parseFloat(preReleasePrice),
      }),
    );

    dispatch(
      orderStore.setProductCount({
        count: 1,
      }),
    );

    dispatch(
      orderStore.setOrderStep({
        step: EPurchaseProcessSteps.PaymentSelector,
      }),
    );
  };

  const handleGoBack = () => {
    dispatch(
      userInterface.setKeysModalStage({
        keysModalStage: EKeysModalPhase.SelectChest,
      }),
    );
  };

  return (
    <div className="product-table-content">
      <div className="text">
        <PrizeCarousel keySelection={true} />
      </div>

      <div className="product-table">
        <div className="table-header">
          <Text variant={TextVariant.CardText}># of keys</Text>
          <Text variant={TextVariant.CardText}>Price</Text>
          <Text variant={TextVariant.CardText}>Savings</Text>
        </div>
        <div className="body-wrapper">
          <div className="table-body">
            {products.map((product: TProduct, idx) => {
              const productQty = getProductQuantity(product);

              return (
                <ProductRowSC
                  key={idx}
                  onClick={() => handleKeyGroupClick(product.id)}
                >
                  <Text variant={TextVariant.CardText}>
                    <img className="key-icon" src={PngKey} alt="Key" /> x
                    {productQty.toLocaleString()}{' '}
                    {boosterPhase.eligible && idx >= 1 && (
                      <>
                        +{' '}
                        <Tippy
                          {...defaultTippyProps}
                          content={`You will receive a random booster for every $14.99 spent IF you meet the eligible ōGem Wave requirements`}
                        >
                          <span className="booster-waiting-wrapper">
                            <ClaimableBooster />
                          </span>
                        </Tippy>
                        <sup>
                          x{(parseInt(product.price) / 14.99).toFixed(0)}
                        </sup>
                      </>
                    )}
                  </Text>
                  <Text
                    variant={TextVariant.CardText}
                    style={{ fontWeight: 300 }}
                  >
                    ${product.price}
                  </Text>
                  <Text variant={TextVariant.CardText}>
                    {idx > 2 && (
                      <>
                        Save{' '}
                        <span style={{ color: '#FFF506', fontWeight: 600 }}>
                          {calculateSavings(products, product).toFixed(2)}%
                        </span>
                      </>
                    )}
                  </Text>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <SvgChevron
                      style={{
                        width: 12,
                        fill: '#fff',
                        transform: 'rotate(-90deg)',
                      }}
                    />
                  </div>
                </ProductRowSC>
              );
            })}
          </div>
        </div>
      </div>
      <div className="return-controls">
        <Text variant={TextVariant.CardText}>Already have keys?</Text>
        <button
          onClick={handleGoBack}
          className="buy-keys-btn"
          style={{ height: 40, fontWeight: 700 }}
        >
          Open Chests
        </button>
      </div>

      <div style={{ marginBottom: 12 }}>
        <TermsFooter noPreorder={true} />
      </div>
    </div>
  );
};

export default SelectKeysPhase;
