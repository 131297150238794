import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import { useQuery } from 'react-query';
import SvgCross from '@assets/svg/icons/x.svg';
import { TRootState } from '@store/index';
import userInterface from '@store/user-interface';
import { getRQKUserKeys } from '@utils/rq-utils';
import api from '@utils/api';
import { chestModalProps } from '../modal-settings';
import ModalSC from '../Modal.styled';
import SelectKeysPhase from './SelectKeysPhase';
import SelectChestPhase from './SelectChestPhase';
import OpenChestPhase from './OpenChestPhase';
import Loader from '@components/Loader';
import { stopAudio } from './components/ChestOpening/openChestSoundEffects';
import chestSlice from '@store/chest';

export enum EKeysModalPhase {
  BuyKeys = 'BuyKeys',
  SelectChest = 'SelectChest',
  OpenChest = 'OpenChest',
}

const renderPhase = (phase: null | EKeysModalPhase, keys: number) => {
  switch (phase) {
    case EKeysModalPhase.BuyKeys:
      return <SelectKeysPhase />;
    case EKeysModalPhase.SelectChest:
      return <SelectChestPhase />;
    case EKeysModalPhase.OpenChest:
      return <OpenChestPhase />;
    default:
      return keys > 0 ? <SelectChestPhase /> : <SelectKeysPhase />;
  }
};

const OcashChestModal: React.FunctionComponent = () => {
  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const { isLoading: isKeysLoading, data: userKeyData } = useQuery(
    getRQKUserKeys('getUserKeys'),
    async () => (await api.getUserKeys()).data.data,
  );

  const { resetState } = chestSlice;

  const keysModalPhase = useSelector(
    (store: TRootState) => store.userInterface.keysModalStage,
  );

  const dispatch = useDispatch();

  const handleCloseModal = useCallback(() => {
    stopAudio();

    dispatch(resetState());
    dispatch(
      userInterface.setModal({
        type: null,
        token: null,
      }),
    );
    dispatch(
      userInterface.setKeysModalStage({
        keysModalStage: null,
      }),
    );
  }, []);

  if (isKeysLoading) {
    return <Loader />;
  }

  return (
    <>
      <ReactModal
        isOpen={type === 'ocashChestModal'}
        {...chestModalProps}
        onRequestClose={handleCloseModal}
      >
        <ModalSC className="chest-modal" color={'red'}>
          <div className="header">
            <button onClick={handleCloseModal}>
              <img src={SvgCross} alt="cross-btn" />
            </button>
          </div>
          <div className="body">
            {renderPhase(keysModalPhase, userKeyData.available.length)}
          </div>
        </ModalSC>
      </ReactModal>
    </>
  );
};

export default OcashChestModal;
