import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

type TItemProps = {
  isLoading: boolean;
};

const Item: FC<TItemProps> = ({ isLoading }) => {
  if (isLoading)
    return (
      <div className="skeleton">
        <Skeleton count={1} height={120} />
        <div className="skeleton-data">
          <Skeleton count={1} height={24} />
          <Skeleton count={1} height={18} />
          <Skeleton count={1} height={18} />
          <Skeleton count={1} height={18} />
        </div>
      </div>
    );
  return <div>Item</div>;
};

export default Item;
