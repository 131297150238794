import styled from 'styled-components';

const StyledGlobalFeed = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  div.event-list {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > div {
      text-align: center;
      transition: font-size 50ms;

      &.first {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 12px;

        @media (max-width: 435px) {
          font-weight: 400;
          margin-bottom: 8px;
          font-size: 16px;
        }
      }

      &.second {
        font-size: 16px;
        opacity: 0.8;
        margin-bottom: 8px;

        @media (max-width: 435px) {
          font-size: 15px;
        }
      }

      &.third {
        font-size: 14px;
        opacity: 0.5;
        margin-bottom: 8px;
      }

      &.fourth {
        font-size: 13px;
        opacity: 0.3;
        margin-bottom: 8px;
      }

      &.fifth {
        font-size: 12px;
        opacity: 0.2;
      }
    }

    .item-enter {
      opacity: 0;
      transform: translateY(-100%);
    }
    .item-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: opacity 400ms, transform 400ms;
    }

    .item-exit {
      //transform: translateY(-100%);
      display: none;
      opacity: 0;
    }

    .item-exit-active {
      //transform: translateY(0);
      display: none;
      transition: opacity 200ms, transform 200ms;
      opacity: 0;
    }

    @media only screen and (max-width: 776px) {
      .feed-event-item {
        font-size: 0.8rem;
      }
    }
  }
`;

export default StyledGlobalFeed;
