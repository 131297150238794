import { ButtonOutlineSC } from '@components/Button.styled';
import userInterface from '@store/user-interface';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as SvgDoghouse } from '@assets/svg/icons/doghouse.svg';

type TLocationBtnProps = {
  positionId: string;
};

const ClaimOfriendBtn: React.FunctionComponent<TLocationBtnProps> = ({
  positionId,
}) => {
  const dispatch = useDispatch();
  const { setPositionIdToMint, setModal } = userInterface;
  return (
    <Tippy
      {...defaultTippyProps}
      content={`Congratulations you found an ōFriend. Click to claim it!`}
    >
      <div className="doghouse-icon">
        <ButtonOutlineSC
          className="claim-ofriend-btn"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            dispatch(
              setPositionIdToMint({
                minting: { id: positionId },
              }),
            );
            dispatch(
              setModal({
                type: 'claimOFriendModal',
                token: null,
              }),
            );
          }}
        >
          <SvgDoghouse />{' '}
          <span className="gold-text">
            {window.innerWidth < 776 ? 'Claim' : 'Claim ōFriend'}
          </span>
        </ButtonOutlineSC>
      </div>
    </Tippy>
  );
};

export default ClaimOfriendBtn;
