import Env from '@utils/env';
import Logger from '@utils/logger';
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from '@utils/storage';
import axios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { signOut } from '@utils/auth';
import {
  TInitializeRequestData,
  TInitializeResponseData,
  TPositionsResponseData,
  TStatisticsResponseData,
} from '@custom-types/common';

export const PURCHASEABLE_OTREAT_ID = '817ad161-8e59-4d34-950f-c8e0934577c5';

export const POSITIONS_PAGE_SIZE = 20;

export const defaultSearchPositionParams = {
  searchBy: {
    revealed: null,
    dogs: null,
    positionId: null,
    rarity: null, // null means all of them
  },
  order: {
    sortBy: 'createdAt',
    direction: 'desc',
  },
  pagination: {
    offset: 0,
    limit: POSITIONS_PAGE_SIZE,
  },
};

class Api {
  static isRefreshing = false;
  static refreshSubscribers = [];
  protected baseURL: string;
  protected axios: AxiosInstance;

  constructor(baseURL: string) {
    this.baseURL = baseURL;

    const axiosInitData = {
      timeout: 30000,
      baseURL,
    };

    this.axios = axios.create(axiosInitData);

    /* Handling our server responses, when errors, get verbose ones */
    this.axios.interceptors.response.use(
      (response) => response,
      (error) => Promise.reject(error.response),
    );

    /**
     * When enabled, add response interceptors for invalid/expired JWT token
     */
    this.axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error) => {
        const { config, data, status } = error;
        const originalRequest = config;
        // Invalid token means, that we are going to reset tokens, because they are useless...they can't be refreshed
        if (
          data.data &&
          (data.data.errCode === 'jwt_token_invalid' ||
            data.data.errCode === 'jwt_invalid_signature' ||
            data.data.errCode === 'jwt_token_not_present' ||
            data.data.errCode === 'user_not_found' ||
            data.data.errCode === 'refresh_token_expired')
        ) {
          Api.isRefreshing = false;
          signOut(false);
        }

        // If we need to refresh...
        if (status === 400 && data.data.errNo === 1102) {
          // Call refresh token, if not already refreshing
          if (!Api.isRefreshing) {
            Api.refreshAccessToken().catch(() => signOut(false));
          }

          // Postpone all requests with invalid token in array
          return new Promise((resolve) => {
            // we are passing a function, that accepts token, all wrapped in Promise
            Api.subscribeTokenRefresh((token: string) => {
              // replace the expired token and retry
              originalRequest.headers.Authorization = `Bearer ${token}`;
              resolve(axios(originalRequest));
            });
          });
        }
        // Create verbose error, not just error message
        return Promise.reject(error.data);
      },
    );

    /**
     * Include bearer token in all requests
     */
    this.axios.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const accessToken = getAccessToken();
        const newConfig: AxiosRequestConfig = config;

        if (accessToken) {
          newConfig.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
          };
        }
        return newConfig;
      },
      (error) => Promise.reject(error),
    );
  }

  /**
   * Pop all requests in queue and give them new access token
   * @param token new access token
   */
  static onRefreshed(token: string): void {
    // in this case an "fnc" is a function, which we call with new token, its original request
    Api.refreshSubscribers.map((fnc: (token: string) => void) => fnc(token));
    // clear an array after reply
    Api.refreshSubscribers = [];
  }

  /**
   * Function to subscribe to refresh token queue
   * @param callback callback after refresh
   */
  static subscribeTokenRefresh(callback: (token: string) => void): void {
    Api.refreshSubscribers.push(callback as never);
  }

  /**
   * Refresh access token if possible
   * @returns  new tokens
   */
  static refreshAccessToken: () => Promise<{
    accessToken: string;
    refreshToken: string;
  }> = async () => {
    const oldAccess: string | null = getAccessToken();

    if (oldAccess === null) {
      Logger.error('Calling refresh without accessToken');
      throw new Error('Calling refresh without accessToken');
    }

    try {
      const res = await axios.post(
        `${Env.baseApiUrl}/account/refresh-token`,
        {
          refreshToken: getRefreshToken(),
        },
        {
          headers: {
            Authorization: `Bearer ${oldAccess}`,
          },
        },
      );

      const { accessToken, refreshToken } = res.data.data;
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      Api.onRefreshed(accessToken);

      return {
        accessToken,
        refreshToken,
      };
    } catch (error) {
      throw error;
    } finally {
      Api.isRefreshing = false;
    }
  };

  getProducts = async () => this.axios.get(`${this.baseURL}/product`);

  public resolveDiscount(country: string, region: string) {
    return this.axios.post(`${this.baseURL}/product/discount`, {
      country,
      region,
    });
  }

  getOfriendBalance(address: string) {
    const options: AxiosRequestConfig = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY as string,
      },
    };
    return axios
      .get(
        `https://api.opensea.io/v2/chain/ethereum/account/${address}/nfts?limit=50`,
        options,
      )
      .catch((err) => console.error(err));
  }

  public getOrders() {
    return this.axios.get(`${this.baseURL}/order`);
  }

  public getOrder(id: string) {
    return this.axios.get(`${this.baseURL}/order/${id}`);
  }

  public getPurchaseOrder(id: string) {
    return this.axios.get(`${this.baseURL}/purchase-orders/${id}`);
  }

  createOrder = async (data) => this.axios.post(`${this.baseURL}/order`, data);

  // TREATS
  public getRewards() {
    return this.axios.get(`${this.baseURL}/reward`);
  }

  public getUserRewards() {
    return this.axios.get(`${this.baseURL}/reward/user/otreats`);
  }

  public createPurchase(rewardId: string, quantity: number) {
    return this.axios.post(`${this.baseURL}/purchase`, {
      rewardId,
      quantity,
    });
  }

  public placeTreat(positionId: string, userRewardIds: string[]) {
    return this.axios.post(`${this.baseURL}/position/place-otreat`, {
      positionId,
      userRewardIds,
    });
  }

  public claimReward(id: string) {
    return this.axios.post(`${this.baseURL}/reward/otreat/claim`, {
      id,
    });
  }

  // KEYS
  public getUserKeys() {
    return this.axios.get(`${this.baseURL}/reward/user/keys`);
  }

  public openChest(type: string) {
    return this.axios.post(`${this.baseURL}/reward/chest/open`, {
      type,
    });
  }

  public createPaymentRequest(orderId: string, data) {
    return this.axios.post(`${this.baseURL}/order/${orderId}/payment`, data);
  }

  public pollCustomGw() {
    return this.axios.get(`${this.baseURL}/polling/eth-gateway`);
  }

  public createLocationRevealRequest(positionId: string) {
    return this.axios.post(`${this.baseURL}/purchase-reveal`, {
      positionId,
    });
  }

  public setFrontendSignIn() {
    return this.axios.patch(`${this.baseURL}/account/set-frontend-sign-in`);
  }

  public revealUpgrades(plotId: string) {
    return this.axios.patch(`${this.baseURL}/position/reveal/${plotId}`);
  }

  public initialize(
    data: TInitializeRequestData,
  ): Promise<AxiosResponse<TInitializeResponseData>> {
    return this.axios.post(`${this.baseURL}/account/initialize`, data);
  }

  public signIn(email: string, password: string, captcha: string) {
    return this.axios.post(`${this.baseURL}/account/sign-in`, {
      email,
      password,
      captcha,
    });
  }

  public requestResetPassword(email: string, captcha: string) {
    return this.axios.post(`${this.baseURL}/account/request-reset-password`, {
      email,
      captcha,
    });
  }

  public resetPassword(token: string, password: string) {
    return this.axios.patch(`${this.baseURL}/account/reset-password`, {
      verificationToken: token,
      password: password,
    });
  }

  mint = async (positionId: string, ethAddress: string) =>
    this.axios.patch(`${this.baseURL}/position/${positionId}/mint`, {
      ethAddress,
    });

  getOCashAmount = () =>
    this.axios.get(`${this.baseURL}/account/o-cash-amount`);

  public getProfile() {
    return this.axios.get(`${this.baseURL}/account/profile`);
  }

  getPositionCount = async () =>
    this.axios.get(`${this.baseURL}/position/position-count`);

  getPositions = async (params: any) => {
    return this.axios.post(`${this.baseURL}/position/search`, {
      ...params,
    });
  };

  getArtifacts = async () => {
    return this.axios.get(`${this.baseURL}/account/artifacts`);
  };

  public getPositionDetail(positionID: string) {
    return this.axios.get(`${this.baseURL}/position/${positionID}`);
  }

  setMarketingAgreement = async (marketingAgreement: boolean) =>
    this.axios.patch(`${this.baseURL}/account/set-marketing-agreement`, {
      marketingAgreement,
    });

  public getStatistics(): Promise<AxiosResponse<TStatisticsResponseData>> {
    return this.axios.get(`${this.baseURL}/position/statistics`);
  }

  public getPositionsForSale(): Promise<AxiosResponse<TPositionsResponseData>> {
    return this.axios.get(`${this.baseURL}/position/for-sale`);
  }

  public claimDailyOCash(): Promise<AxiosResponse> {
    return this.axios.post(`${this.baseURL}/account/claim-daily-ocash`);
  }

  public createSellOffer(
    positionId: string,
    price: number,
  ): Promise<AxiosResponse> {
    return this.axios.post(`${this.baseURL}/position/offer/${positionId}`, {
      price,
    });
  }

  public signOut() {
    return this.axios.patch(`${this.baseURL}/account/sign-out`);
  }

  getAuctions = () => this.axios.get(`${this.baseURL}/auction`);

  getAuction = (id: string) => this.axios.get(`${this.baseURL}/auction/${id}`);

  getUserRunningAuctions = () =>
    this.axios.get(`${this.baseURL}/account/auctions/running`);

  getUserAuctions = () => this.axios.get(`${this.baseURL}/account/auctions`);

  buyItNow = (auctionId: string) =>
    this.axios.post(`${this.baseURL}/auction/${auctionId}/buy-now`);

  cancelAuction = async (auctionId: string) =>
    this.axios.delete(`${this.baseURL}/auction/${auctionId}/cancel`);

  placeArtifact = (auctionId: string, positionId: string) =>
    this.axios.patch(`${this.baseURL}/auction/${auctionId}/assign-artifact`, {
      positionId,
    });

  getBlockedItems = () =>
    this.axios.get(`${this.baseURL}/account/blocked-items`);

  createAuction = (data: any) =>
    this.axios.post(`${this.baseURL}/auction`, data);

  auctionBid = (data: any) =>
    this.axios.post(`${this.baseURL}/auction/bid`, data);

  getPositionsAvailableForPlacement = () =>
    this.axios.get(`${this.baseURL}/position/available-for-artifact-placement`);

  getTwitterStatus = () =>
    this.axios.get(`${this.baseURL}/account/auth/twitter`);

  initTwitterStatus = () =>
    this.axios.post(`${this.baseURL}/account/auth/twitter`);

  postTwiterCallbackHandle = (data) =>
    this.axios.post(`${this.baseURL}/account/auth/twitter/callback`, data);

  getAmazonStatus = () => this.axios.get(`${this.baseURL}/account/auth/amazon`);

  postAmazonCallbackHandle = (data) =>
    this.axios.post(`${this.baseURL}/account/auth/amazon/callback`, data);

  getTwitterLeaderboard = () =>
    this.axios.get(`${this.baseURL}/account/leaderboard`);

  claimTwitterRewards = () =>
    this.axios.post(`${this.baseURL}/account/claim-ocash-reward`);

  getClaimableTwitterRewards = () =>
    this.axios.get(`${this.baseURL}/account/twitter-rewards`);

  checkRarity = (id: string) =>
    this.axios.get(`${this.baseURL}/position/${id}/rarity`);

  checkEmailToEarn = () =>
    this.axios.get(`${this.baseURL}/account/email-to-earn`);

  connectMetamaskAddress = (address: string) =>
    this.axios.post(`${this.baseURL}/account/connect-metamask`, {
      metamaskAddress: address,
    });

  signTransaction = (productId: string) =>
    this.axios.post(`${this.baseURL}/order/wert`, {
      productId,
    });

  refreshDirectEthPayments = () =>
    this.axios.post(`${this.baseURL}/order/refresh-direct-eth-orders`);

  getOgemsData = () => this.axios.get(`${this.baseURL}/ogem/user-rank`);

  getOgemsLeaderboard = () =>
    this.axios.get(`${this.baseURL}/ogem/leaderboard`);

  // Ultimate Rewards

  getUltimateRewards = async () => {
    return this.axios.get(`${this.baseURL}/reward/user/ultimate-prizes`);
  };

  claimUltimateReward = async (rewardId: string, ethAddress: string) =>
    this.axios.patch(
      `${this.baseURL}/reward/user/ultimate-prizes/${rewardId}/claim`,
      {
        ethAddress,
      },
    );

  //  Chests

  getChestCards = async () => {
    return this.axios.get(`${this.baseURL}/reward/user/cards`);
  };

  getOpenedChests = async () => {
    return this.axios.get(`${this.baseURL}/account/opened-chests`);
  };

  //  Boosters

  getBoosters = async () => {
    return this.axios.get(`${this.baseURL}/reward/user/boosters`);
  };

  applyBooster = (boosterId: string, positionId: string) =>
    this.axios.post(`${this.baseURL}/reward/booster/apply`, {
      boosterId,
      positionId,
    });

  getBoosterPhase = async () => {
    return this.axios.get(`${this.baseURL}/reward/booster-phase`);
  };

  /**
   *
   * @param path path to call
   * @param params axios request params
   * @returns axios promise
   */
  public custom = <T>(
    path: string,
    params?: Omit<AxiosRequestConfig, 'baseURL' | 'url'>,
  ): AxiosPromise<T> => {
    return this.axios({
      ...params,
      url: path,
      method: params?.method || 'GET',
    });
  };
}

const api = new Api(Env.baseApiUrl);
export default api;

export const unifyPaymentStatus = (paymentStatus: string, type: string) => {
  if (type === 'cs-crypto-gw_v1' && paymentStatus === 'paid') {
    return 'Payment Successful';
  }

  switch (paymentStatus) {
    case 'requires_payment_method': // Stripe Intent
    case 'requires_action': // Stripe Intent
    case 'new': // Bitpay Invoice
      return 'Waiting for payment'; // Stripe Intent
    case 'processing': // Stripe Intent
    case 'paid': // Bitpay Invoice
    case 'confirmed': // Bitpay Invoice
      return 'Processing';
    case 'complete': // Bitpay Invoice
    case 'succeeded': // Stripe Intent
      return 'Payment Successful';
    case 'canceled': // Stripe Intent
    case 'expired': // Bitpay Invoice
    case 'invalid': // Bitpay Invoice
      return 'Canceled';
    case 'refunded':
      return 'Refunded';
  }
};
