import React, { FC } from 'react';
import { CardSC } from '../CardFlipReveal.styled';
import olandSqVideo from '@assets/animations/oland-sq.mp4';
import { useSelector } from 'react-redux';
import { TRootState } from '@store/index';

type TCardFront = {
  className?: string;
  handleClick: () => void;
  image: string | boolean;
  isLand: boolean;
  color?: string;
  firstBadge?: string;
  secondBadge?: string;
};

const getChestType = (chestRarity: string) => {
  if (chestRarity.includes('++' || '+ +')) return '++';
  if (chestRarity.includes('+')) return '+';
  else return '';
};

const getFirstLetter = (chestRarity: string) => {
  return chestRarity.charAt(0);
};

const CardFront: FC<TCardFront> = ({
  className,
  image,
  handleClick,
  isLand,
  color,
  firstBadge,
  secondBadge,
}) => {
  const { matchedPrize } = useSelector((state: TRootState) => state.chest);
  return (
    <CardSC className={className} onClick={handleClick} color={color}>
      {!matchedPrize && <div className="no-prize">No Prize</div>}
      {firstBadge && (
        <div className="badge">
          {getFirstLetter(firstBadge)}
          {getChestType(secondBadge as string)}
        </div>
      )}
      {isLand ? (
        <video
          width="auto"
          className="carousel-img"
          autoPlay={true}
          loop={true}
          playsInline={true}
          preload={'auto'}
          muted
          style={{ borderRadius: 8 }}
        >
          <source src={olandSqVideo} type="video/mp4" />
        </video>
      ) : (
        <img src={image as string} />
      )}
    </CardSC>
  );
};

export default CardFront;
