import Text, { TextVariant } from '@components/Text';
import React, { useState } from 'react';
import styled from 'styled-components';

export type TTooltipProps = {
  rewardTooltipShown: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export type TTooltipWrapperProps = {
  isOpen: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const RewardClaimTooltip: React.FunctionComponent<TTooltipProps> = ({
  rewardTooltipShown,
}) => {
  const [isOpen, setIsOpen] = useState(rewardTooltipShown);
  return (
    <TooltipWrapper isOpen={isOpen}>
      {/* <div className="reward-header">
        <Text>Daily ōCash Reward</Text>
      </div> */}
      <button
        onClick={() => {
          setIsOpen(false);
        }}
      >
        <div className="inner-content">
          <Text
            variant={TextVariant.CardText}
            className="color"
            style={{ fontWeight: 600, fontSize: 16 }}
          >
            Congratulations! You have a free ōTreat waiting to be claimed!
          </Text>
        </div>
      </button>
    </TooltipWrapper>
  );
};

const TooltipWrapper = styled.div<TTooltipWrapperProps>`
  position: absolute;
  top: -90px;
  right: 0px;
  left: 0px;
  margin: auto;
  background-color: rgba(255, 255, 255, 1);
  transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
  transition-property: background-color, -webkit-backdrop-filter;
  transition-property: background-color, backdrop-filter;
  transition-property: background-color, backdrop-filter,
    -webkit-backdrop-filter;
  box-shadow: 0 5px 10px rgb(0 0 0 / 34%);
  border-radius: 10px;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  overflow: hidden;
  max-width: 350px;
  gap: 10px;
  overflow: hidden;
  text-align: left;
  transition: 0.3s ease-in-out;
  z-index: 1;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  transform: ${(props) =>
    props.isOpen
      ? 'skewY(0) rotate(0) translateY(0)'
      : 'skewY(-5deg) rotate(5deg) translateY(-30px)'};

  .inner-content {
    padding: 12px;
  }

  button {
    background: none;
    border: none;
  }

  .reward-header {
    background: ${({ theme }) => theme.blue.blue100};
    color: white;
    padding: 12px;
  }
`;

export default RewardClaimTooltip;
