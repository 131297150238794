import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TRootState } from '@store/index';
import PaymentChoiceBox from '@components/Modal/CheckoutProcess/components/PaymentChoiceBox';
import { StyledLinkBtn } from '@components/Button.styled';
import { EPurchaseProcessSteps } from '@components/Modal/CheckoutProcess/PurchaseModal';
import orderStore from '@store/order';
import api from '@utils/api';
import userInterface from '@store/user-interface';

const PaymentMethodModal: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<
    false | 'wert' | 'deth' | 'crypto'
  >(false);

  const order = useSelector((store: TRootState) => store.order);

  const cheapestOCashPackage =
    order.productId === '3cbf40e8-c4b5-4ee0-8394-237036e0284c';

  const dispatch = useDispatch();

  const handleGoBack = useCallback(() => {
    if (order.purchaseProductType === 'oCash') {
      dispatch(
        orderStore.setOrderStep({
          step: EPurchaseProcessSteps.PurchaseOCashInit,
        }),
      );
    } else if (order.purchaseProductType === 'oRouter') {
      dispatch(
        orderStore.setOrderStep({
          step: EPurchaseProcessSteps.ShippingAddress,
        }),
      );
    } else {
      // open keys selector modal
      // TODO: set keys modal phase
      // const keysModalPhase = useSelector(
      //   (store: TRootState) => store.userInterface.keysModalStage,
      // );

      dispatch(
        userInterface.setModal({
          type: 'ocashChestModal',
          token: null,
        }),
      );
    }
  }, []);

  const handleCustomCryptoPaymentClick = async () => {
    if (isLoading) return;
    // resolve oCash count
    setIsLoading('deth');
    const orderData = (
      await api.createOrder({
        products: [
          {
            id: order.productId,
            quantity: order.productCount,
          },
        ],
        shippingAddress: null,
        billingAddress: null,
      })
    ).data;

    const paymentRequestData = (
      await api.createPaymentRequest(orderData.data.id as string, {
        type: 'cs-crypto-gw_v1',
      })
    ).data;

    const { ethAmount, ethAddress, oCashAmount } = paymentRequestData.data;

    dispatch(
      orderStore.setEthCustomPayment({
        ethAmount,
        ethAddress,
        oCashAmount,
      }),
    );

    dispatch(
      orderStore.setOrderStep({
        step: EPurchaseProcessSteps.CustomGWPayment,
      }),
    );
    setIsLoading(false);
  };

  const handleWertPaymentClick = () => {
    if (isLoading) return;

    setIsLoading('wert');
    dispatch(
      orderStore.setOrderStep({
        step: EPurchaseProcessSteps.WertPayment,
      }),
    );
  };

  const handleBitPayPaymentClick = () => {
    if (isLoading) return;
    setIsLoading('crypto');
    dispatch(
      orderStore.setOrderStep({
        step: EPurchaseProcessSteps.BitPayPayment,
      }),
    );
  };

  return (
    <div className="body">
      <PaymentChoiceBox
        method="wert"
        loading={isLoading === 'wert'}
        onClick={handleWertPaymentClick}
      />
      {!cheapestOCashPackage && (
        <PaymentChoiceBox
          method="cs-crypto-gw_v1"
          loading={isLoading === 'deth'}
          onClick={handleCustomCryptoPaymentClick}
        />
      )}
      <PaymentChoiceBox
        method="crypto"
        loading={isLoading === 'crypto'}
        onClick={handleBitPayPaymentClick}
      />
      <StyledLinkBtn onClick={handleGoBack}>Go back</StyledLinkBtn>
    </div>
  );
};

export default PaymentMethodModal;
