import { ButtonOutlineSC } from '@components/Button.styled';
import React from 'react';
import { useEffect, useRef, useState, useMemo } from 'react';
import Globe from 'react-globe.gl';
import * as THREE from 'three';
import globeBump from '@assets/globe_materials/bump-large.jpg';
import cloudsReg from '@assets/globe_materials/clouds.png';

function GlobeAnim(places) {
  const [pinMarkers, setPinMarkers] = useState(true);
  const [colorBlue, setColorBlue] = useState('rgba(36, 138, 255, 1)');
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    setTimeout(() => {
      const geometry = new THREE.SphereBufferGeometry(100, 75, 75);
      const material = new THREE.MeshPhongMaterial();

      new THREE.TextureLoader().load(cloudsReg, (texture) => {
        material.map = texture;
        material.transparent = true;
        material.opacity = 0.1;
      });

      const mesh = new THREE.Mesh(geometry, material);

      mesh.scale.set(1.015, 1.015, 1.015);
    });
  }, []);

  const globeMaterial = useMemo(() => {
    const globeMaterialTemp = new THREE.MeshPhongMaterial();
    globeMaterialTemp.bumpScale = 10;

    new THREE.TextureLoader().load(globeBump, (texture) => {
      globeMaterial.bumpMap = texture;
    });

    return globeMaterialTemp;
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(window.innerWidth);
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const locations = places.places;
  const plotOne = locations[0];
  const globeEl = useRef();
  useEffect(
    (rotate) => {
      globeEl.current.controls().autoRotate = rotate;
      globeEl.current.controls().autoRotateSpeed = 0.4;
      const PLOT_ONE = { lat: plotOne.lat, lng: plotOne.lng };

      globeEl.current.pointOfView(PLOT_ONE, 0);
      // globeEl.current.pointOfView(MAP_CENTER, 0);
    },
    [globeEl],
  );

  const width = windowSize >= 600 ? 440 : windowSize - 96;
  const height = windowSize >= 600 ? 550 : 400;
  const markersBlue = colorBlue === 'rgba(36, 138, 255, 1)';

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 24,
          marginBottom: 12,
        }}
      >
        <ButtonOutlineSC
          onClick={() => {
            setPinMarkers(!pinMarkers);
          }}
        >
          {!pinMarkers ? 'Vertical Markers Off' : 'Vertical Markers On'}
        </ButtonOutlineSC>
        <ButtonOutlineSC
          onClick={() => {
            setColorBlue(
              colorBlue === 'rgba(36, 138, 255, 1)'
                ? 'red'
                : 'rgba(36, 138, 255, 1)',
            );
          }}
        >
          {markersBlue ? 'Blue Markers' : 'Red Markers'}
        </ButtonOutlineSC>
      </div>
      <div style={{ borderRadius: 10, overflow: 'hidden' }}>
        <Globe
          width={width}
          height={height}
          ref={globeEl}
          backgroundColor="#fff"
          // globeImageUrl={'//unpkg.com/three-globe/example/img/earth.jpg'}
          animateIn={true}
          pointOfView
          labelsData={locations}
          labelLat={(d) => d.lat}
          labelLng={(d) => d.lng}
          labelText={(d) => d.name}
          labelSize={() => 0.5}
          labelDotRadius={() => 0.5}
          labelColor={() => colorBlue}
          labelResolution={2}
          // bars
          hexBinPointsData={locations}
          hexBinPointWeight="size"
          hexAltitude={() => (pinMarkers ? 0.15 : 0)}
          hexBinResolution={4}
          hexTopColor={() => colorBlue}
          hexSideColor={() => colorBlue}
          hexTransitionDuration={300}
          hexBinMerge={true}
          enablePointerInteraction={true}
          // globeImageUrl={earthLarge}
          globeImageUrl={
            '//unpkg.com/three-globe/example/img/earth-blue-marble.jpg'
          }
          globeMaterial={globeMaterial}
          bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
          onLabelClick={(d) =>
            window.open(
              `https://gps-coordinates.org/my-location.php?lat=${d.lat}&lng=${d.lng}`,
              '_blank',
            )
          }
          labelLabel={(d) => `
          <p><b>Plot: #${d.name}</b></p>
        `}
        />
      </div>
    </>
  );
}

export default React.memo(GlobeAnim);
