import Text, { TextVariant } from '@components/Text';
import Toast from '@components/Toast';
import api from '@utils/api';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import { TRootState } from '@store/index';
import { useDispatch, useSelector } from 'react-redux';
import chestSlice from '@store/chest';

const UltimatePrizeOverlay: FC = () => {
  const dispatch = useDispatch();
  const {
    ultimatePrize,
    isClaimSubmitLoading,
    usersClaimAddress,
    matchedPrize,
  } = useSelector((state: TRootState) => state.chest);

  const {
    setUsersClaimAddress,
    setIsClaimSubmitLoading,
    setShowUltimateOverlay,
  } = chestSlice;
  const handleClaimUltimatePrize = async () => {
    dispatch(setIsClaimSubmitLoading({ isClaimSubmitLoading: true }));

    try {
      if (typeof ultimatePrize !== 'boolean') {
        await api.claimUltimateReward(
          ultimatePrize.id,
          usersClaimAddress as string,
        );
      }
      toast(
        <Toast
          type="success"
          header="Reward Claimed!"
          body="Please allow up to 72 hours for your reward to arrive."
        />,
      );
    } catch (e) {
      toast(
        <Toast
          type="error"
          header="Claim failed."
          body="Something went wrong when claiming. Try again or contact support."
        />,
      );
    } finally {
      dispatch(setShowUltimateOverlay({ showUltimateOverlay: false }));
      dispatch(setIsClaimSubmitLoading({ isClaimSubmitLoading: false }));
    }
  };

  if (!ultimatePrize || !matchedPrize) {
    return <SvgSpinner fill={'white'} style={{ maxHeight: 50 }} />;
  }

  return (
    <div className="ultimate-prize-overlay">
      <Text variant={TextVariant.CardText} className="minor">
        Claim your prize!
      </Text>
      <img src={matchedPrize.image} />
      <Text
        variant={TextVariant.CardSubtitle}
        className="chest-reward-figure popIn"
      >
        <span>{matchedPrize.collection}</span>
      </Text>

      <input
        type="text"
        value={usersClaimAddress || ''}
        onChange={(e) =>
          dispatch(setUsersClaimAddress({ usersClaimAddress: e.target.value }))
        }
        placeholder="Enter your address"
      />
      <Text variant={TextVariant.CardText} className="gray disclaimer">
        * Claimed rewards may take up to 72 hours to arrive
      </Text>
      <button
        className="blue-btn"
        onClick={handleClaimUltimatePrize}
        disabled={isClaimSubmitLoading || usersClaimAddress === null}
      >
        {isClaimSubmitLoading ? <SvgSpinner fill={'white'} /> : 'Claim Prize'}
      </button>
    </div>
  );
};

export default UltimatePrizeOverlay;
