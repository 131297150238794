import styled, { keyframes } from 'styled-components';
import revealOverlay from '@assets/png/reveal-overlay.png';
import { glowAnimation } from '@components/V2AuctionBar/V2AuctionBar.styled';
import JpgRedBackground from '@assets/jpg/card-backgrounds/red.jpeg';
import JpgGreenBackground from '@assets/jpg/card-backgrounds/green.jpeg';
import JpgYellowBackground from '@assets/jpg/card-backgrounds/yellow.jpeg';
import JpgSwampBackground from '@assets/jpg/card-backgrounds/swamp.jpeg';
import JpgBullrunBackground from '@assets/jpg/card-backgrounds/bullrun.jpg';
import JpgGoldBackground from '@assets/jpg/card-backgrounds/gold.jpg';

export type TTrayCardProps = {
  activePlot?: boolean;
  doghouse?: boolean;
  inventoryOpen?: boolean;
  focus?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const sheenAnimation = keyframes`
0% {
  -webkit-filter: hue-rotate(0deg) brightness(100%);
  filter: hue-rotate(0deg) brightness(100%);
}


25% {
  -webkit-filter: hue-rotate(180deg) brightness(125%);
  filter: hue-rotate(180deg) brightness(125%);
}

50% {
  -webkit-filter: hue-rotate(1turn) brightness(150%);
  filter: hue-rotate(1turn) brightness(150%);
}

75% {
  -webkit-filter: hue-rotate(540deg) brightness(125%);
  filter: hue-rotate(540deg) brightness(125%);
}

100% {
  -webkit-filter: hue-rotate(2turn) brightness(100%);
  filter: hue-rotate(2turn) brightness(100%);
}
`;

export const goldAnimation = keyframes`
0% {
  -webkit-filter: brightness(100%);
  filter: brightness(100%);
  background-position: 0% 50%;
}


25% {
  -webkit-filter: brightness(125%);
  filter: brightness(125%);
}

50% {
  -webkit-filter: brightness(150%);
  filter: brightness(150%);
  background-position: 100% 50%;
}

75% {
  -webkit-filter:  brightness(125%);
  filter:  brightness(125%);
}

100% {
  -webkit-filter: brightness(100%);
  filter: brightness(100%);
  background-position: 0% 50%;
}
`;

export const liquidAnimation = keyframes`
0% {
  transform: translateY(0) rotate(0deg);
}
100% {
  transform: translateY(-100%) rotate(500deg);
}`;

export const StyledTrayFrame = styled.div<TTrayCardProps>`
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  padding: 12px;
  width: 100%;
  border: solid;
  border-width: 3px;
  border-color: ${({ theme }) => theme.input.border};
  background-size: contain;
  border-radius: 10px;
  background: ${({ theme }) => theme.card.background};
  transition: all 200ms ease-out 0s;
  position: relative;
  box-shadow: rgb(0 0 0 / 15%) 0px 6px 25px;
  transform: translate3d(0, 0, 0);
  will-change: scroll-position contents;
  backface-visibility: hidden;

  &.shiny:before,
  &.shiny:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-repeat: no-repeat;
    opacity: 0.5;
    mix-blend-mode: color-dodge;
    transition: all 0.33s ease;
    pointer-events: none;
  }

  &.shiny:before {
    background-position: 50% 50%;
    background-size: 300% 300%;
    background-image: linear-gradient(
      115deg,
      transparent 0%,
      transparent 25%,
      transparent 47%,
      transparent 53%,
      transparent 75%,
      transparent 100%
    );
    opacity: 0.5;
    filter: brightness(0.5) contrast(1);
    z-index: 1;
  }

  &.shiny:after {
    opacity: 1;
    background-image: url(https://assets.codepen.io/13471/sparkles.gif),
      url(https://assets.codepen.io/13471/holo.png),
      linear-gradient(
        125deg,
        #ff008450 15%,
        #fca40040 30%,
        #ffff0030 40%,
        #00ff8a20 60%,
        #00cfff40 70%,
        #cc4cfa50 85%
      );
    background-position: 50% 50%;
    background-size: 160%;
    background-blend-mode: overlay;
    z-index: 2;
    filter: brightness(1) contrast(1);
    transition: all 0.33s ease;
    mix-blend-mode: color-dodge;
    opacity: 0.75;
  }

  &.swamp {
    border-color: #2b3618;
    background-image: url(${JpgSwampBackground});
    background-size: cover;
  }

  &.rare {
    border-color: red;
    background-image: url(${JpgRedBackground});
    background-size: cover;
    animation: ${glowAnimation('rgba(255, 0, 0, 0.9)')} 3s linear infinite;
    .plot-label,
    .plot-type {
      color: rgb(48, 59, 80);
    }
  }
  &.super {
    border-color: rgba(0, 255, 0, 1);
    background-image: url(${JpgGreenBackground});
    background-size: cover;
    animation: ${glowAnimation('rgba(0, 255, 0, 0.9)')} 3s linear infinite;
    filter: saturate(175%);
    .plot-label,
    .plot-type {
      color: rgb(48, 59, 80);
    }

    .video-container {
      filter: saturate(0.65) !important;
    }
  }
  &.ultra {
    border-color: rgba(255, 221, 0, 1);
    background-image: url(${JpgYellowBackground});
    background-size: cover;
    animation: ${glowAnimation('rgba(255, 221, 0, 0.9)')} 3s linear infinite;
    filter: saturate(175%);
    .plot-label,
    .plot-type {
      color: rgb(48, 59, 80);
    }

    .video-container {
      filter: saturate(0.65) !important;
    }
  }

  :hover {
    transform: translateY(-4px);

    transition: all 0.1s ease 0s;
  }

  &.gen6 {
    .plot-label {
      color: rgb(48, 59, 80);
    }
  }

  .plot-label {
    color: ${({ theme }) => theme.text.main};
  }

  &#glowing {
    -webkit-box-shadow: 0px 0px 16px 4px rgba(0, 202, 252, 0.75);
    -moz-box-shadow: 0px 0px 16px 4px rgba(0, 202, 252, 0.75);
    box-shadow: 0px 0px 16px 4px rgba(0, 202, 252, 0.75);
  }

  .upgrades-tray {
    display: grid;
    grid-template-columns: repeat(5, auto);
    gap: 8px;
    padding: 24px;

    .reward {
      margin: auto;
      width: 100%;
      border-radius: 5px;
      border: 2px solid ${({ theme }) => theme.neutral.neutral7};
      background: ${({ theme }) => theme.neutral.neutral5};
      opacity: 0.6;
      padding: 4px;
      filter: saturate(0%);
    }

    .reward-present {
      filter: saturate(100%);
      opacity: 1 !important;
      background: ${({ theme }) => theme.neutral.neutral1};
      border: 2px solid ${({ theme }) => theme.neutral.neutral7} !important;
    }

    @media only screen and (max-width: 776px) {
      padding: 0px;
      gap: 2px;

      .reward {
        padding: 2px !important;
        border-width: 1.5px !important;
      }
    }
  }

  .gold-text {
    background: linear-gradient(
      45deg,
      #fcf6ba 0%,
      #fbf5b7 17%,
      #d1b464 33%,
      #bf953f 50%,
      #8a6e2f 67%,
      #9f7928 73%,
      #fdb931 90%,
      #fedb37 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .treat-reveal-text-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 12px;
    background-color: white;
    border-radius: 10px;
    width: 100%;
    max-width: 90%;
    z-index: 5;

    svg > g > rect {
      fill: pink;
    }
  }

  &.doghouse-sheen {
    position: relative;
    z-index: 1;
    overflow: hidden;
    box-shadow: rgb(0 0 0 / 15%) 0px 6px 25px;
    border-color: #bb8c44;
    background-image: url(${JpgGoldBackground});

    .plot-label,
    .plot-type {
      color: rgb(48, 59, 80);
    }
  }

  &.treat-reveal-card {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    min-height: 400px;
    background: rgba(255, 255, 255, 0.75);

    @media only screen and (max-width: 776px) {
      min-height: 220px;
    }

    :hover {
      background: rgba(255, 255, 255, 1);
      cursor: pointer;
    }

    .treat-reveal-sheen {
      opacity: 0.3;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: url(${revealOverlay}),
        linear-gradient(
          45deg,
          rgb(224 32 32),
          rgb(250 100 0) 17%,
          rgb(247 181 0) 33%,
          rgb(109 212 0) 50%,
          rgb(0 145 255) 67%,
          rgb(98 54 255) 83%,
          rgb(182 32 224)
        );
      -webkit-animation: ${sheenAnimation} 6s ease-in-out infinite;
      animation: ${sheenAnimation} 6s ease-in-out infinite;
      background-size: contain;
    }

    .treat-reveal-card:hover .treat-reveal-sheen:before {
      border-width: 20px 20px 0 0;
    }

    .treat-reveal-sheen::after,
    .treat-reveal-sheen::before {
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
      width: 0;
      height: 0;
      content: '';
    }

    .treat-reveal-sheen::before {
      z-index: 3;
      border-color: transparent #f3f3f3;
      border-style: solid;
      border-width: 0;
      transition: border-width 0.4s ease-out;
    }

    .treat-reveal-card:hover .treat-reveal-sheen::after {
      width: 20px;
      height: 20px;
      transition: all 0.4s ease-out;
    }

    .treat-reveal-sheen::after {
      z-index: 2;
      background: #000;
      border-radius: 8px 0 0;
      transform: translateZ(0);
    }

    .treat-reveal-text-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 24px;
      background-color: white;
      border-radius: 10px;
    }
  }

  video {
    min-height: 262px;
    width: 100%;
    opacity: 1;
    border-radius: 5px;
    height: 100%;
    background: ${({ theme }) => theme.neutral.neutral3};
    scale: ${(props) => (props.focus ? 1.15 : 1)};
    transition: 0.3s;
    @media only screen and (max-width: 600px) {
      min-height: 145px;
    }
  }

  .tray-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin: 12px 0px;

    @media only screen and (max-width: 600px) {
      gap: 8px;
      margin-top: 8px;
    }
  }

  &:not(.gen6) {
    .video-container {
      height: 100%;
    }
  }

  .video-container {
    margin-bottom: 12px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    min-height: 262px;
    min-width: 250px;

    @media only screen and (max-width: 600px) {
      min-height: 140px;
      min-width: 140px;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .doghouse-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      height: 35px;

      @media only screen and (max-width: 776px) {
        top: 8px;
        right: 8px;
        height: 20px;

        svg {
          height: 15px !important;
        }
      }

      border-radius: 999px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;

      svg {
        -webkit-animation: ${goldAnimation} 6s ease-in-out infinite;
        animation: ${goldAnimation} 6s ease-in-out infinite;
      }
    }

    .upgrade-inventory {
      position: absolute;
      left: 0;
      right: 0;
      padding: 0px 12px;
      bottom: 12px;
      z-index: 3;

      .upgrade-header {
        border-radius: 5px 5px 0px 0px;
        background: ${({ theme }) => theme.neutral.neutral4};
        color: ${({ theme }) => theme.neutral.neutral8};
        display: flex;
        justify-content: space-between;
        padding: 6px 12px;
      }
    }

    .power-score {
      position: absolute;
      top: 12px;
      left: 12px;
      z-index: 4;
    }

    .rarity-score {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 4;
    }

    @media only screen and (max-width: 776px) {
      margin-bottom: 8px;

      .power-score {
        top: 8px;
        left: 8px;
      }

      .rarity-score {
        top: 8px;
        right: 8px;
      }
    }
  }

  .loading-dummy-text {
    width: 100%;
    background: ${({ theme }) => theme.neutral.neutral3};
    height: 1rem;
    border-radius: 5px;
  }

  .timer-chart {
    width: 14px;
    height: 14px;

    @media only screen and (max-width: 776px) {
      width: 12px;
      height: 12px;
      margin-top: -3px;
    }
  }

  .star {
    height: 14px;

    @media only screen and (max-width: 776px) {
      height: 10px;
    }
  }

  &.gen6 {
    position: relative;
    background-image: url(${JpgBullrunBackground});
    border-color: #235eb5;
    background-size: cover;
    .plot-label,
    .plot-type {
      color: rgb(48, 59, 80);
    }

    .bull {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin: auto;
      z-index: 2;
      width: 75%;
      height: 75%;
      left: 0px;
      right: 0px;
    }

    .bull-overlay {
      position: absolute;
      background: #0c2d48;
      opacity: 0.75;
      height: 100%;
      width: 100%;
      z-index: 1;
    }

    video {
      -webkit-filter: saturate(0%) blur(2px) !important;
      filter: saturate(0%) blur(2px) !important;
    }
  }

  .notGen6 {
    -webkit-filter: saturate(1.75);
    filter: saturate(1.75);
  }
`;

export const LoadingStatePositionSC = styled(StyledTrayFrame)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .card-video-skeleton {
    min-height: 262px;
    min-width: 250px;
    height: 100%;
    display: flex;

    @media only screen and (max-width: 600px) {
      min-height: 140px;
      min-width: 140px;
    }
  }

  .card-body-skeleton-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    .card-stats-skeleton {
      span {
        height: 24px;
        margin-bottom: 12px;
      }
    }
    .card-button-skeleton {
      display: flex;
      justify-content: flex-end;
      span {
        height: 30px;
        max-width: 75%;
      }
    }
  }
`;
