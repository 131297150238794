import React, { useCallback } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '@store/index';
import userInterfaceStore from '@store/user-interface';
import { ButtonPrimarySC } from '@components/Button.styled';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { defaultModalProps } from '@components/Modal/modal-settings';
import TermsFooter from './CheckoutProcess/components/TermsFooter';
import ModalSC from './Modal.styled';
import { TUser } from '@store/auth/types';
import ReferralLink from '@components/ReferralLink';

const InitialWelcomeModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const user = useSelector<TRootState>(
    (store: TRootState) => store.auth.user,
  ) as TUser;

  const handleCloseModal = useCallback(() => {
    dispatch(
      userInterfaceStore.setModal({
        type: null,
        token: null,
      }),
    );
  }, []);

  if (!user) {
    return null;
  }

  return (
    <ReactModal isOpen {...defaultModalProps} onRequestClose={handleCloseModal}>
      <ModalSC>
        <div className="header">
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            Congratulations!
          </Text>
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <div className="body">
          <Text style={{ margin: '16px 0px' }} variant={TextVariant.CardText}>
            You are now officially a landowner in Overline&apos;s wireless
            metaverse, and a member of the Overline DAO.
          </Text>
          <Text variant={TextVariant.ColorText}>
            Address #{user.daoRanking ?? 'Not assigned yet'}
          </Text>
          <div
            style={{ minWidth: 100, alignSelf: 'center', margin: '16px 0px' }}
          >
            <Text
              variant={TextVariant.CardText}
              className="sharing-description"
            >
              Share your referral link with friends and earn 20% of whatever
              your referrals buy:
            </Text>
          </div>

          <ReferralLink />

          <ButtonPrimarySC onClick={handleCloseModal} style={{ marginTop: 24 }}>
            Take me to my ōLand portal
          </ButtonPrimarySC>
        </div>
        <TermsFooter />
      </ModalSC>
    </ReactModal>
  );
};

export default InitialWelcomeModal;
