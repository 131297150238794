import styled from 'styled-components';

export const V2CancelModalSC = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  text-align: center;
  button {
    max-width: 200px;
    margin: auto;
  }

  .details {
    max-width: 300px;
    width: 100%;
    margin: auto;
    border: 1.5px solid ${({ theme }) => theme.input.border};
    border-radius: 8px;
    background: ${({ theme }) => theme.input.background};
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .shiny {
      max-width: 100px;
      margin: auto;
    }
  }
`;
