import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import { useQuery, useQueryClient } from 'react-query';

import Text, { TextVariant } from '@components/Text';
import { ButtonOutlineSC, ButtonPrimarySC } from '@components/Button.styled';
import metamaskLogo from '@assets/png/logos/metamask.png';
import { ReactComponent as SvgCopy } from '@assets/svg/icons/copy.svg';
import { ReactComponent as SvgExplore } from '@assets/svg/icons/explore.svg';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import Toast from '@components/Toast';
import userInterface from '@store/user-interface';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import api from '@utils/api';
import { TRootState } from '@store/index';
import { shortenEthAddress } from '@utils/common';
import ExpandableTooltipSC from './ExpandableTooltip.styled';
import { EExpTooltipVariant } from '../ContextTooltip/ExpandableTooltip.styled';
import { getRQKProfileKey } from '@utils/rq-utils';
import { AxiosResponse } from 'axios';

const Init = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { ethereum } = window;
  const { setConnectedAddress } = userInterface;

  const connectWallet = async () => {
    try {
      if (!ethereum) {
        toast(
          <Toast
            type="error"
            header="Metamask required"
            body="You do not have Metamask installed. Set up a Metamask wallet and try again."
          />,
        );
        return;
      }

      const res = await ethereum.request({ method: 'eth_requestAccounts' });

      if (res === null) {
        toast(
          <Toast
            type="error"
            header="Connection rejected"
            body="You rejected the connection request. Try again if this was not done on purpose."
          />,
        );
        return;
      }

      const metamaskAddress = res[0];

      dispatch(
        setConnectedAddress({
          connectedAddress: metamaskAddress,
        }),
      );

      // Notify backend about successfull connection
      await api.connectMetamaskAddress(metamaskAddress);

      toast(
        <Toast
          type="success"
          header="Rewards claimed!"
          body="You have claimed your rewards, they should now be added to your balance."
        />,
      );

      queryClient.invalidateQueries(getRQKProfileKey('getOCashAmount'));
    } catch (err: any) {
      if (err.data.errNo === 1107) {
        toast(
          <Toast
            type="error"
            header="Connection pending"
            body="Your registration token in the email we've sent you is still valid"
          />,
        );
      } else if (err.data.errNo === 1207 || err.data.errNo === 1208) {
        toast(
          <Toast
            type="success"
            header="Address connected"
            body="Your address was successfully connected"
          />,
        );
      } else {
        toast(
          <Toast
            type="error"
            header="Connection failed"
            body="Something went wrong, contact support or try again later."
          />,
        );
      }
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text
          variant={TextVariant.CardSubtitle}
          style={{ fontWeight: 600, margin: 0, textAlign: 'left' }}
        >
          Connect a wallet
        </Text>
      </div>
      <button className="metamask-btn" onClick={connectWallet}>
        <img src={metamaskLogo} alt="MM-Logo" style={{ width: 24 }} />
        <Text variant={TextVariant.CardSubtitle} style={{ fontWeight: 600 }}>
          Metamask
        </Text>
      </button>
      <Text variant={TextVariant.CardText} style={{ textAlign: 'left' }}>
        By connecting your wallet you are agreeing to our{' '}
        <a className="link">Terms of Service</a>
      </Text>
    </>
  );
};

const Connected = () => {
  const [ethBalance, setEthBalance] = useState(0);
  const [oFriendBal, setOfriendBal] = useState(0);
  const [unopened, setUnopened] = useState(0);
  const [copied, setCopied] = useState(false);

  const { ethereum } = window;
  const provider = ethereum && new ethers.providers.Web3Provider(ethereum);

  const connectedAddress = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.connectedAddress,
  ) as string;

  const { isLoading: isNftBalanceLoading, data: tokenBalance } = useQuery(
    ['getOfriendBalance'],
    async () => {
      const response = (await api.getOfriendBalance(
        ethereum.selectedAddress,
      )) as AxiosResponse<any>;
      return response.data.nfts;
    },
    {
      enabled: ethereum !== undefined && ethereum.selectedAddress != null,
      retry: true,
    },
  );

  useEffect(() => {
    if (tokenBalance) {
      const allOfriends = tokenBalance.filter(
        (obj) =>
          obj.contract === process.env.REACT_APP_OFRIEND_CONTRACT_ADDRESS,
      );

      const unopenedOfriends = tokenBalance.filter(
        (obj) =>
          obj.contract === process.env.REACT_APP_OFRIEND_CONTRACT_ADDRESS &&
          parseInt(obj.identifier) <= 10000,
      );

      (async () => {
        const balance = await provider.getBalance(
          ethereum.selectedAddress.toString(),
        );
        const balanceInEth = ethers.utils.formatEther(balance);
        await setEthBalance(Number(balanceInEth));
        setOfriendBal(allOfriends.length);
        setUnopened(unopenedOfriends.length);
      })();
    }
  }, [isNftBalanceLoading, ethereum.selectedAddress]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div id="address">
          <Tippy {...defaultTippyProps} content={`Your address `}>
            <Text
              variant={TextVariant.CardText}
              style={{ fontWeight: 600, margin: 0 }}
              className="blue"
            >
              {shortenEthAddress(connectedAddress)}
            </Text>
          </Tippy>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 8,
          }}
        >
          <Tippy {...defaultTippyProps} content={`Copy address`}>
            <button
              className="utility-btn"
              onClick={() => {
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 1000);
                navigator.clipboard.writeText(connectedAddress);
              }}
            >
              {copied ? '✓' : <SvgCopy />}
            </button>
          </Tippy>
          <Tippy {...defaultTippyProps} content={`View on Etherscan`}>
            <button
              className="utility-btn"
              onClick={() => {
                window.location.replace(
                  `https://etherscan.io/address/${connectedAddress}`,
                );
              }}
            >
              <SvgExplore />
            </button>
          </Tippy>
        </div>
      </div>
      <div id="balance">
        <Text
          variant={TextVariant.CardSubtitle}
          style={{ textAlign: 'center' }}
          className="gray"
        >
          ETH Balance
        </Text>

        <Text
          variant={TextVariant.Subtitle}
          style={{ textAlign: 'center', fontWeight: 600 }}
          className="blue"
        >
          {ethBalance.toFixed(5) + ' ETH'}
        </Text>

        <Text
          variant={TextVariant.CardSubtitle}
          style={{ textAlign: 'center' }}
          className="gray"
        >
          {/* TODO: Fix ethPrice fetch later */}
          {/* (~ ${ethPrice * ethBalance}) */}
        </Text>
      </div>
      <hr />
      <div id="wallet">
        <div>
          <Text variant={TextVariant.CardText} className="gray">
            Unrevealed ōFriends:
          </Text>
          {isNftBalanceLoading ? (
            <SvgSpinner
              fill={'black'}
              style={{ maxHeight: '1.5rem', maxWidth: '1.5rem' }}
            />
          ) : (
            <Text variant={TextVariant.CardText} className="blue">
              {unopened}
            </Text>
          )}
        </div>
        <div>
          <Text variant={TextVariant.CardText} className="gray">
            Total ōFriends:
          </Text>
          {isNftBalanceLoading ? (
            <SvgSpinner
              fill={'black'}
              style={{ maxHeight: '1.5rem', maxWidth: '1.5rem' }}
            />
          ) : (
            <Text variant={TextVariant.CardText} className="blue">
              {oFriendBal}
            </Text>
          )}
        </div>
      </div>
      <hr />
      <Tippy
        {...defaultTippyProps}
        content={`Reveal your NFT's artwork`}
        delay={400}
      >
        <ButtonPrimarySC
          className="bounce"
          style={{ marginBottom: 16 }}
          onClick={() => window.location.replace(`/app/ofriends`)}
        >
          Reveal ōFriends
        </ButtonPrimarySC>
      </Tippy>
      <ButtonOutlineSC
        onClick={() =>
          window.location.replace(
            `https://opensea.io/${ethereum.selectedAddress}/ofriends`,
          )
        }
      >
        View on Opensea
      </ButtonOutlineSC>
    </>
  );
};

type TMetamaskWalletTooltipProps = {
  isOpen: boolean;
};

const MetamaskWalletTooltip: React.FC<TMetamaskWalletTooltipProps> = ({
  isOpen,
}) => {
  const connectedAddress = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.connectedAddress,
  ) as string;

  return (
    <ExpandableTooltipSC
      variant={
        connectedAddress
          ? EExpTooltipVariant.Balance
          : EExpTooltipVariant.ConnectWallet
      }
      shown={isOpen}
      className="connect"
    >
      <div className="inner-content">
        {!connectedAddress && <Init />}
        {connectedAddress && <Connected />}
      </div>
    </ExpandableTooltipSC>
  );
};

export default MetamaskWalletTooltip;
