import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import numeral from 'numeral';
import oCashPng from '@assets/png/ocash-paper.png';
import orderStore from '@store/order';
import { ButtonPrimarySC, OptionButtonSC } from '@components/Button.styled';
import Text, { TextVariant } from '@components/Text';
import Row from '@components/Row';
import { O_CASH_PRODUCTS_AMOUNT } from '@store/order/products';
import { getRQKProductsKey, getRQKProfileKey } from '@utils/rq-utils';
import { numberToLocaleString, USD_NUMERAL_FMT } from '@utils/common';
import TermsFooter from './components/TermsFooter';
import { EPurchaseProcessSteps } from '@components/Modal/CheckoutProcess/PurchaseModal';
import api from '@utils/api';
import Loader from '@components/Loader';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';

const oCashProductDiscounts = {
  '3cbf40e8-c4b5-4ee0-8394-237036e0284c': 0,
  '43b13f75-8a9a-48b9-8cda-80abb4051add': 50,
  'ae398f37-3cb6-4a95-84a4-a2e1e20d6cd0': 67,
  'e7bea56c-0ee9-478a-9739-e589364932db': 87,
  'ec72de7e-6b5b-452d-90dd-15763edd4e27': 90,
  '35212ec9-52a5-424f-a6a2-522831b48b2b': 92,
};

const OCashModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [selectedProduct, setSelectedProduct] = useState<string>(
    [...O_CASH_PRODUCTS_AMOUNT.keys()][5],
  );

  const products = queryClient.getQueryData(getRQKProductsKey('getProducts'));

  const { isLoading: isProfileLoading, data: profile } = useQuery(
    getRQKProfileKey('getProfile'),
    async () => {
      const result = (await api.getProfile()).data;
      return result.data;
    },
  );

  const handleContinueBtnClick = () => {
    // @ts-expect-error ts doesn't know the structure
    const { id, price, preReleasePrice } = products.find(
      (el) => el.id === selectedProduct,
    );

    dispatch(
      orderStore.setProduct({
        id, // TODO: TMP normalize values
        title: 'ŌCASH Package',
        price: parseFloat(price),
        preReleasePrice: parseFloat(preReleasePrice),
      }),
    );

    dispatch(
      orderStore.setProductCount({
        count: 1,
      }),
    );

    dispatch(
      orderStore.setOrderStep({
        step: EPurchaseProcessSteps.PaymentSelector,
      }),
    );
  };

  if (isProfileLoading) {
    return (
      <div
        style={{
          minHeight: 200,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div className="body">
      <Tippy
        {...defaultTippyProps}
        content={`You can use ōCash to purchase ōTreats and bid on the upcoming auction platform.`}
      >
        <Text variant={TextVariant.CardText} className="gray">
          Current Balance:{' '}
          <span className="blue" style={{ fontWeight: 700 }}>
            {numberToLocaleString(profile.oCashAmount)} ōCash &#9432;
          </span>
        </Text>
      </Tippy>

      <div style={{ width: '100%', textAlign: 'center' }}>
        <img src={oCashPng} alt="ōCash" style={{ width: 150 }} />
      </div>
      <div
        style={{
          display: 'grid',
          gap: 16,
          gridTemplateColumns: '1fr 1fr 1fr',
        }}
      >
        {[...O_CASH_PRODUCTS_AMOUNT.keys()].reverse().map((productId, idx) => (
          <div
            key={idx}
            style={{ alignSelf: 'flex-end', position: 'relative' }}
          >
            <Text
              style={{
                marginBottom: 4,
                textAlign: 'right',
                display:
                  O_CASH_PRODUCTS_AMOUNT.get(productId) === 10
                    ? 'none'
                    : 'block',
              }}
              variant={TextVariant.CardText}
              className="green"
            >
              Save {oCashProductDiscounts[productId]}%
            </Text>

            <OptionButtonSC
              className={`${selectedProduct === productId && 'active'}`}
              onClick={() => {
                setSelectedProduct(productId);
              }}
            >
              {/* @ts-expect-error ts don't know */}
              {O_CASH_PRODUCTS_AMOUNT.get(productId).toLocaleString()}
            </OptionButtonSC>
            <div className="button-subtext">
              {O_CASH_PRODUCTS_AMOUNT.get(productId) === 2250 ||
              O_CASH_PRODUCTS_AMOUNT.get(productId) === 300
                ? 'Popular!'
                : ''}
            </div>
          </div>
        ))}
      </div>
      {/* <Text style={{ marginTop: 24 }}>Discount Code</Text>
      <Row style={{ marginTop: 12, gap: 16 }}>
        <InputSC placeholder="Auction (Applied)" disabled={true} />
      </Row> */}

      <Row style={{ marginTop: 24 }}>
        <Text style={{ marginRight: '0.25rem' }}>Cost: </Text>
        <Text className="blue" style={{ fontWeight: 700 }}>
          {numeral(
            // @ts-expect-error ts doesn't know the structure
            products.find((el) => el.id === selectedProduct).preReleasePrice,
          ).format(USD_NUMERAL_FMT)}{' '}
          {/*(25% off)*/}
        </Text>
      </Row>

      <ButtonPrimarySC
        onClick={handleContinueBtnClick}
        style={{ marginTop: 16 }}
      >
        Continue
      </ButtonPrimarySC>

      <TermsFooter noPreorder={true} />
    </div>
  );
};

export default OCashModal;
