import { colorThemes } from '@assets/styles';
import styled from 'styled-components';

export const ThemeButtonSC = styled.button`
  background: none;
  border: 1.5px solid ${colorThemes.dark.input.border};
  border-radius: 8px;
  padding: 6px;
  max-height: 35px;
  max-width: 35px;
  align-self: center;
  height: 100%;
  width: 100%;
  transition: 0.2s;
  :hover:not(:disabled) {
    background-color: ${colorThemes.dark.input.border};
    opacity: 0.8;
  }
  :focus-visible {
    outline: none;
  }

  :disabled {
    background-color: ${({ theme }) => theme.neutral.neutral3};
    color: ${({ theme }) => theme.neutral.neutral6};
    cursor: not-allowed;
  }

  svg {
    stroke: white;
  }
`;
