import styled, { keyframes } from 'styled-components';

export const glowAnimation = (color: string) => keyframes`
    0% {
    -webkit-box-shadow: 0px 0px 64px 8px ${color};
    -moz-box-shadow: 0px 0px 64px 8px ${color};
    box-shadow: 0px 0px 64px 8px ${color};
    }

    25% {
      -webkit-box-shadow: 0px 0px 32px 6px ${color};
      -moz-box-shadow: 0px 0px 32px 6px ${color};
      box-shadow: 0px 0px 32px 6px ${color};
      }

      50% {
        -webkit-box-shadow: 0px 0px 24px 4px ${color};
        -moz-box-shadow: 0px 0px 24px 4px ${color};
        box-shadow: 0px 0px 24px 4px ${color};
        }

        75% {
          -webkit-box-shadow: 0px 0px 32px 6px ${color};
          -moz-box-shadow: 0px 0px 32px 6px ${color};
          box-shadow: 0px 0px 32px 6px ${color};
          }

        

    100% {
      -webkit-box-shadow: 0px 0px 64px 8px ${color};
      -moz-box-shadow: 0px 0px 64px 8px ${color};
      box-shadow: 0px 0px 64px 8px ${color};
      }
`;

export const V2AuctionBarSC = styled.button`
  background: ${({ theme }) => theme.background.regular.bg1};
  border: 0px;
  border-bottom: 1.5px solid ${({ theme }) => theme.input.border};
  box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
  padding: 12px 24px;
  text-align: center;
  top: 50px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 99;
  width: 100%;
  text-align: center;
  button {
    margin: auto;
  }

  svg {
    fill: ${({ theme }) => theme.text.main};
  }

  .rarity-badge {
    width: 100px;
    border-left: 2px solid;
    border-right: 2px solid;
    border-top: 2px solid;
    border-radius: 8px 8px 0px 0px;
    font-weight: 500;
    color: ${({ theme }) => theme.text.main};
  }
`;

export const V2BannerCardSC = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 1fr 1.5fr;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin: auto;
  gap: 24px;

  video {
    height: 50px;
    border-radius: 8px;
    border: 1.5px solid ${(props) => props.color};
    animation: ${(props) => glowAnimation(props.color as string)} 3s linear
      infinite;
  }

  h3,
  p {
    margin: 0px;
    text-align: left;
  }

  p {
    color: ${({ theme }) => theme.text.secondary};
  }

  h3 {
    color: ${({ theme }) => theme.text.main};
    font-size: 1.25rem;
  }

  button {
    svg {
      fill: ${({ theme }) => theme.text.main};
      height: 0.5rem;
      transform: rotate(-90deg);
    }
  }

  @media only screen and (max-width: 1024px) {
    justify-content: space-between;
    gap: 12px;

    video {
      height: 40px;
    }

    p {
      font-size: 0.75rem;
    }

    h3 {
      font-size: 0.9rem;
    }
  }
`;
