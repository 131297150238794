import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Squash as Hamburger } from 'hamburger-react';
import { ButtonPrimarySC } from '@components/Button.styled';
import { TRootState } from '@store/index';
import { AUTH_STATE_SUCCESS } from '@store/auth/types';
import { signOut } from '@utils/auth';
import { ReactComponent as SvgLogo } from '@assets/svg/logo-white.svg';
import HeaderSC, {
  ButtonsSC,
  StyledContentContainer,
  StyledMenuWrapper,
  StyledInnerContent,
} from './Header.styled';
import Text, { TextVariant } from '@components/Text';
import TwitterButton from './components/TwitterButton';
import MetamaskButton from './components/MetamaskButton';
import ThemeButton from '@components/ThemeButton';

const menuData = [
  {
    title: 'ōRouter',
    link: '/orouter',
  },

  {
    title: 'ōLand',
    link: '/app',
  },
  {
    title: 'ōMap',
    link: '/omap',
  },
  {
    title: 'My Rank',
    link: '/app/ogems',
  },
];

const Header: React.FunctionComponent = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const authState = useSelector<TRootState>(
    (store: TRootState) => store.auth.state,
  );

  const loggedIn = authState === AUTH_STATE_SUCCESS;

  const handleLogoutBtnClick = () => {
    if (loggedIn) {
      signOut();
      navigate('/');
    }
  };

  function renderMobileMenu() {
    return (
      <StyledContentContainer className="mobile-menu" style={{ padding: 0 }}>
        <a
          href="/"
          style={{
            height: '100%',
            alignItems: 'center',
          }}
        >
          <div style={{ cursor: 'pointer' }}>
            <svg
              height="20"
              viewBox="0 0 113 152"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M112.5 19.3L0 18.8V0L112.5 0.5V19.3ZM56.7 38C88.1 38 112.9 63.3 112.9 95.2C113.5 126.2 88.7 152 56.7 152C24.7 152 0.5 126.2 0.5 95.3C0.5 63.3 25.3 38 56.7 38ZM56.7 131.7C76.5 131.7 92.7 115.5 92.7 95.2C92.7 74.9 77 58.2 56.7 58.2C36.4 58.2 20.7 74.4 20.7 95.2C20.7 116 36.5 131.7 56.7 131.7Z"
                fill="white"
              />
            </svg>
          </div>
        </a>

        <div
          style={{
            display: 'flex',
            gap: 12,
            alignItems: 'center',
            position: 'relative',
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          {loggedIn && <TwitterButton />}
          {loggedIn && <MetamaskButton />}
        </div>

        <Hamburger
          color="white"
          toggled={mobileMenuOpen}
          toggle={setMobileMenuOpen}
          size={20}
        />
        <StyledMenuWrapper
          style={{
            visibility: mobileMenuOpen ? 'visible' : 'hidden',
            opacity: mobileMenuOpen ? 1 : 0,
            transform: mobileMenuOpen ? 'translateY(0)' : 'translateY(50px)',
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          {menuData.map((item, index) => (
            <div style={{ cursor: 'pointer' }} key={index}>
              <a
                href={item.link}
                onClick={() => setMobileMenuOpen}
                style={{ textDecoration: 'none' }}
              >
                <Text variant={TextVariant.Navigation} className="mobile-nav">
                  {item.title}
                </Text>
              </a>
            </div>
          ))}
          <div className="btn-wrapper">
            <ButtonsSC
              style={{
                display: loggedIn ? 'flex' : 'none',
              }}
            >
              <ButtonPrimarySC
                style={{ maxWidth: '110px', maxHeight: '30px' }}
                onClick={handleLogoutBtnClick}
              >
                Log Out
              </ButtonPrimarySC>
            </ButtonsSC>
          </div>
          <ThemeButton />
        </StyledMenuWrapper>
      </StyledContentContainer>
    );
  }

  return (
    <>
      <HeaderSC>
        <StyledInnerContent className="inner-content">
          {renderMobileMenu()}
          <div className="main-menu">
            <div style={{ width: '100%' }}>
              <SvgLogo
                style={{ height: 25, width: 18.5 }}
                onClick={() =>
                  (window.location.href = process.env
                    .REACT_APP_OROUTER_LANDING as string)
                }
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: 24,
                width: '100%',
              }}
            >
              {menuData.map((item, index) => (
                <a
                  key={index}
                  href={item.link}
                  style={{ textDecoration: 'none' }}
                >
                  <Text
                    variant={TextVariant.Navigation}
                    style={{ cursor: 'pointer' }}
                  >
                    {item.title}
                  </Text>
                </a>
              ))}
            </div>

            <ButtonsSC
              style={{
                display: loggedIn ? 'flex' : 'none',
                justifyContent: 'flex-end',
                gap: 12,
                width: '100%',
                position: 'relative',
              }}
            >
              {loggedIn && <TwitterButton />}
              {loggedIn && <MetamaskButton />}
              <ThemeButton />
              <ButtonPrimarySC
                style={{ maxHeight: 35, maxWidth: 100 }}
                onClick={handleLogoutBtnClick}
              >
                Log Out
              </ButtonPrimarySC>
            </ButtonsSC>
          </div>
        </StyledInnerContent>
      </HeaderSC>

      {/* {loggedIn && <AirdropBanner userIsAuthed={loggedIn} />} */}
    </>
  );
};

export default React.memo(Header);
