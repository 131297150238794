import styled from 'styled-components';
import { devices } from '../../assets/styles/screenSizes';

const LayoutWrapperSC = styled.div`
  display: flex;
  justify-content: center;

  div.inner-content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media only screen and ${devices.desktop} {
      max-width: 1440px;
    }
    @media only screen and ${devices.laptopL} {
      // max-width: 1280px;
    }
    // @media only screen and ${devices.laptop} {
    //   padding-left: 48px;
    //   padding-right: 48px;
    // }
    // @media only screen and ${devices.mobileL} {
    //   padding-left: 24px;
    //   padding-right: 24px;
    // }
    // @media only screen and ${devices.mobileM} {
    //   padding-left: 12px;
    //   padding-right: 12px;
    // }
    // @media only screen and ${devices.mobileS} {
    //   padding-left: 12px;
    //   padding-right: 12px;
    // }
  }

  .content-page-centered {
    max-width: 500px;
    height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
`;

export default LayoutWrapperSC;
