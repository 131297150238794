import styled, { css, keyframes } from 'styled-components';
import { colorThemes } from '@assets/styles';
import { goldAnimation } from '@pages/Home/components/PositionCard/StyledTrayFrame.styled';

const btnTransition = css`
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  color: ${({ theme }) => theme.secondary};
  border-width: 0;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  align-items: center;
  ${btnTransition}

  :disabled {
    background-color: ${({ theme }) => theme.button.disabled};
    border-color: ${({ theme }) => theme.button.disabled};
    cursor: not-allowed;
  }

  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.button.primary.hover};
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 10px;
  }

  @media only screen and (max-width: 883px) {
    font-size: 0.75rem;
    height: 35px;
    &.inventory {
      font-size: 10px !important;
      :disabled {
        background-color: ${({ theme }) => theme.button.disabled} !important;
        cursor: not-allowed;
      }
    }
  }

  &.smaller {
    @media only screen and (max-width: 883px) {
      height: 30px;
    }
  }
`;

export const StyledLinkBtn = styled.button`
  background-color: transparent;
  border: none;
  text-align: left;
  color: ${({ theme }) => theme.blue.blue100};
`;

export const SkipButtonSC = styled.button`
  background-color: rgba(255, 255, 255, 0) !important;
  border: none;
  text-align: left;
  color: ${({ theme }) => theme.text.main};
`;

const bounceAnimation = keyframes`

    0% {
      transform: scale(1,1) translate(0px, 0px);
    }
    
    30%{
      transform: scale(1,0.8) translate(0px, 10px); 
    }

    75%{
      transform: scale(1,1.1) translate(0px, -15px); 
    }
    
   100% {
      transform: scale(1,1) translate(0px, 0px);
    }
`;

export const shakeAnimation = keyframes`
0%{
  transform: rotate(0deg);
}
10%{
  transform: rotate(10deg);
}
20%{
  transform: rotate(0deg);
}
30%{
  transform: rotate(-7deg);
}
40%{
  transform: rotate(0deg);
}
100%{
  transform: rotate(0deg);
}
`;

export const ButtonLinkSC = styled(Button)`
  color: ${({ theme }) => theme.blue.blue100};
  border: none;
  background: none;
  width: auto;
  font-size: 0.9rem;
  :hover {
    text-decoration: underline;
    background-color: none !important;
    background: none !important;
    border: none !important;
  }
  span {
    margin: 0rem 0.25rem;
  }
  img {
    animation: ${shakeAnimation} 1s infinite;
    height: 28px;
  }

  @media only screen and (max-width: 772px) {
    font-size: 10px;
  }
`;

export const ButtonPrimarySC = styled(Button)`
  background-color: ${({ theme }) => theme.button.primary.background};
  color: white;
  &.color {
    background: linear-gradient(
      to right,
      #f8ab5e 0,
      #f36961 20%,
      #a176c8 40%,
      #759beb 60%,
      #65beb3 80%,
      #70db96 100%
    );
  }

  &.green {
    background-color: ${({ theme }) => theme.green};

    :hover {
      background-color: ${({ theme }) => theme.green} !important;
      filter: brightness(110%) !important;
    }
  }

  border-radius: 8px;
  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.button.primary.hover};
  }
  :focus-visible {
    outline: none;
  }
`;

export const OptionButtonSC = styled(Button)`
  background-color: transparent;
  border: 1.5px solid ${({ theme }) => theme.input.border};
  color: ${({ theme }) => theme.button.secondary.text};

  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.neutral.neutral2};
  }

  :focus-visible {
    outline: none;
  }

  &.active {
    border: 1.5px solid ${({ theme }) => theme.blue.blue100};
    background-color: ${({ theme }) => theme.blue.blue10};

    :hover:not(:disabled) {
      background-color: ${({ theme }) => theme.blue.blue10};
    }
  }
`;

export type TButtonProps = {
  expandedInventory?: boolean;
};

export const ButtonOutlineSC = styled(Button)<TButtonProps>`
  background-color: transparent;
  border: 1.5px solid ${({ theme }) => theme.input.border};
  color: ${({ theme }) => theme.button.secondary.text};

  border-radius: 8px;
  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.input.background};
  }
  :focus-visible {
    outline: none;
  }

  :disabled {
    background-color: ${({ theme }) => theme.neutral.neutral3};
    color: ${({ theme }) => theme.neutral.neutral6};
    cursor: not-allowed;
  }

  &.connect {
    border: 1.5px solid ${colorThemes.dark.input.border} !important;
    background: ${colorThemes.dark.input.background} !important;
    width: 160px;
    color: white !important;

    @media only screen and (max-width: 776px) {
      width: auto;
    }
  }

  &.claim-ofriend-btn {
    display: flex;
    gap: 8px;
    padding: 0rem 0.75rem;

    border-color: ${({ theme }) => theme.neutral.neutral9} !important;
    background: ${({ theme }) => theme.neutral.neutral9} !important;
    border-radius: 5px;
    height: 30px;
    :hover {
      background: ${({ theme }) => theme.neutral.neutral7} !important;
      border-color: ${({ theme }) => theme.neutral.neutral7} !important;
    }

    @media only screen and (max-width: 776px) {
      height: 20px;
      gap: 4px;
      padding: 0rem 0.5rem;
    }
  }

  &.bounce {
    animation: ${bounceAnimation} 1s infinite;
    color: white;
    box-shadow: 5px 10px 15px rgba(36, 138, 255, 0.6);
    background: ${({ theme }) => theme.blue.blue100};
    border-color: ${({ theme }) => theme.blue.blue100};
    @media only screen and (max-width: 1024px) {
      animation: ${(props) =>
        props.expandedInventory
          ? css`
              ${bounceAnimation} 1s infinite
            `
          : 'none'};
    }

    :hover {
      filter: brightness(125%);
      box-shadow: 5px 10px 15px rgba(36, 138, 255, 0.8);
      background: ${({ theme }) => theme.blue.blue100};
      border-color: ${({ theme }) => theme.blue.blue100};
    }

    &.disabled {
      border: none;
      position: relative;
      z-index: 1;
      overflow: hidden;
      box-shadow: 2px 5px 15px rgba(254, 219, 55, 0.4);
      color: ${({ theme }) => theme.neutral.neutral8};
      cursor: not-allowed;
      opacity: 1;

      :hover {
        color: ${({ theme }) => theme.neutral.neutral8};
        opacity: 1;
      }

      :before {
        content: '';
        z-index: -1;
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 12px;
        background: linear-gradient(
          45deg,
          #fcf6ba 0%,
          #fbf5b7 17%,
          #d1b464 33%,
          #bf953f 50%,
          #8a6e2f 67%,
          #9f7928 73%,
          #fdb931 90%,
          #fedb37 100%
        ) !important;
        -webkit-animation: ${goldAnimation} 6s ease-in-out infinite;
        animation: ${goldAnimation} 6s ease-in-out infinite;
      }
    }

    &.color {
      color: white;
      filter: brightness(115%);
      background: linear-gradient(
        to right,
        #f8ab5e 0,
        #f36961 20%,
        #a176c8 40%,
        #759beb 60%,
        #65beb3 80%,
        #70db96 100%
      );
      border: none;
      box-shadow: none;
      @media only screen and (min-width: 1024px) {
        :before {
          content: '';
          z-index: -1;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: linear-gradient(
            to right,
            #f8ab5e 0,
            #f36961 20%,
            #a176c8 40%,
            #759beb 60%,
            #65beb3 80%,
            #70db96 100%
          );
          transform: translate3d(5px, 10px, 0) scale(0.95);
          filter: blur(15px);
          opacity: var(0.7);
          transition: opacity 0.3s;
          border-radius: inherit;
        }
      }

      /* 
    * Prevents issues when the parent creates a 
    * stacking context. (For example, using the transform
    * property )
    */
      .box::after {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        border-radius: inherit;
      }

      :hover {
        color: white;
        filter: brightness(125%);
        background: linear-gradient(
          to right,
          #f8ab5e 0,
          #f36961 20%,
          #a176c8 40%,
          #759beb 60%,
          #65beb3 80%,
          #70db96 100%
        );
        border: none;
      }
    }
  }

  &.blue {
    border-color: ${({ theme }) => theme.blue.blue100};
    color: ${({ theme }) => theme.blue.blue100};
    background: white;
    :hover {
      background: ${({ theme }) => theme.blue.blue100} !important;
      color: white !important;

      svg {
        fill: white !important;
      }
    }
  }

  &.dark {
    border-color: ${({ theme }) => theme.neutral.neutral8} !important;
    color: ${({ theme }) => theme.neutral.neutral8} !important;
    background: white !important;

    svg {
      fill: ${({ theme }) => theme.neutral.neutral8} !important;
    }

    :hover {
      background: ${({ theme }) => theme.neutral.neutral8} !important;
      border-color: ${({ theme }) => theme.neutral.neutral8} !important;
      color: white !important;
      svg {
        fill: white !important;
      }
    }

    @media only screen and (max-width: 776px) {
      line-height: 10px;

      svg {
        max-height: 12.5px;
      }
    }
  }

  &.golden {
    border-color: ${({ theme }) => theme.neutral.neutral8} !important;
    background: rgba(255, 255, 255, 0.2) !important;
    color: ${({ theme }) => theme.neutral.neutral8} !important;

    :hover {
      background: ${({ theme }) => theme.neutral.neutral8} !important;
      color: white !important;
    }
  }

  &.smaller {
    width: auto;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    height: auto;
    font-weight: 600;
    border-radius: 5px;

    &.doghouse {
      border-color: ${({ theme }) => theme.neutral.neutral8} !important;
      background: rgba(255, 255, 255, 1) !important;
      color: ${({ theme }) => theme.neutral.neutral8} !important;

      :hover {
        background: ${({ theme }) => theme.neutral.neutral8} !important;
        color: white !important;
      }
    }

    .red {
      color: ${({ theme }) => theme.red} !important;
    }

    @media only screen and (max-width: 776px) {
      height: 20px !important;
      font-size: 0.5rem;
      padding: 0px 0.5rem;
    }
  }

  &.e2earn {
    min-width: 120px;
  }

  &.flashing {
    animation: ${bounceAnimation} 1s infinite;
    background: ${({ theme }) => theme.background.regular.bg1};
  }
`;

export const ButtonSecondarySC = styled(Button)`
  background-color: ${({ theme }) => theme.button.primary.background};
  border-radius: 18px;
  :hover:not(:disabled) {
    background: ${({ theme }) => theme.button.secondary.hover};
  }

  :focus-visible {
    outline: none;
  }
`;

export const ButtonWhiteSC = styled(Button)`
  background-color: white;
  border: 1px solid white;
  color: black;
  :hover:not(:disabled) {
    background: white;
    color: ${colorThemes.light.primary};
  }

  :hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    border: 1px solid rgba(255, 255, 255, 0.8);
  }

  :focus-visible {
    outline: none;
  }
`;

export const ButtonBlackSC = styled(Button)`
  background-color: black;
  border: 1px solid black;

  :hover:not(:disabled) {
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    color: white;
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border: 1px solid rgba(0, 0, 0, 0.8);
  }

  :focus-visible {
    outline: none;
  }
`;

export const ButtonRedSC = styled(Button)`
  border: 1px solid ${({ theme }) => theme.button.red.background};
  border-radius: 18px;
  background-color: ${({ theme }) => theme.button.red.background};
  transition: 0.3s;
  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.button.red.hover};
    color: white;
  }

  :focus-visible {
    outline: none;
  }
`;

export const ButtonColorSC = styled(Button)`
  border-radius: 18px;
  background: linear-gradient(
    to right,
    #f8ab5e 0,
    #f36961 20%,
    #a176c8 40%,
    #759beb 60%,
    #65beb3 80%,
    #70db96 100%
  );
`;
