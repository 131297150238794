import { ChestBtnSC } from '@components/Modal/Modal.styled';
import { TRootState } from '@store/index';
import React, { FC, RefObject, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chests } from '../../SelectChestPhase';
import api from '@utils/api';
import { toast } from 'react-toastify';
import Toast from '@components/Toast';
import {
  getRQKBoosters,
  getRQKProfileKey,
  getRQKUserKeys,
} from '@utils/rq-utils';
import { useQueryClient } from 'react-query';
import { playCelebrationSfx, playOpenSfx } from './openChestSoundEffects';
import chestOpenSfx from '@assets/sfx/keys/unlock.mp3';
import userInterface from '@store/user-interface';
import { EKeysModalPhase } from '../../OcashChestModal';
import chestSlice from '@store/chest';
import { collections } from '../PrizeCarousel/collections';

const chestOpenSound = new Audio(chestOpenSfx);

type TOpenChestButton = {
  videoRef: RefObject<HTMLVideoElement>;
};

const OpenChestButton: FC<TOpenChestButton> = ({ videoRef }) => {
  const queryClient = useQueryClient();
  const chestType = useSelector(
    (store: TRootState) => store.userInterface.chestType,
  );
  const dispatch = useDispatch();

  const { ultimatePrize } = useSelector((state: TRootState) => state.chest);

  type TUserKeyData = {
    available: string[];
    used: string[];
  };

  const userKeyData = queryClient.getQueryData(
    getRQKUserKeys('getUserKeys'),
  ) as TUserKeyData;

  const availableKeys = userKeyData.available.length;

  const {
    setOCashReceivedFromChest,
    setShowBigWinText,
    setUltimatePrize,
    setOLandPlot,
    setChestReceived,
    setIsChestOpen,
    setBoosterRarity,
    setMatchedPrize,
    setShowUltimateOverlay,
  } = chestSlice;

  const chest = chests.filter((chest) => {
    if (chest.type === chestType) {
      return chest;
    }
  });

  const renderBigWin = () => {
    dispatch(setShowBigWinText({ showBigWinText: true }));
    setTimeout(() => {
      dispatch(setShowBigWinText({ showBigWinText: false }));
    }, 2500);
  };

  const handleOpenChest = useCallback(async (chestType) => {
    if (availableKeys < chest[0].keys) {
      toast(
        <Toast
          type="warning"
          header="Insufficient Keys!"
          body="You need more keys to open this chest."
        />,
      );
      dispatch(
        userInterface.setKeysModalStage({
          keysModalStage: EKeysModalPhase.BuyKeys,
        }),
      );
    } else {
      try {
        const result = (await api.openChest(chestType)).data.data;
        if (result.ultimatePrize) {
          const claimableUltimateRewards = (await api.getUltimateRewards()).data
            .data;

          const claimablePrize =
            claimableUltimateRewards.ultimatePrizes.unclaimed.find(
              (prize) => prize.meta.collection === result.ultimatePrize,
            );
          dispatch(setUltimatePrize({ ultimatePrize: claimablePrize }));
          const matchedPrize = claimablePrize
            ? collections.find(
                (item) => item.collection === claimablePrize.meta.collection,
              )
            : null;

          dispatch(setMatchedPrize({ matchedPrize: matchedPrize }));
          dispatch(setShowUltimateOverlay({ showUltimateOverlay: true }));
        }

        const chestNameArr: string[] = [];
        const level = result.artifact.type;
        const traitName =
          result.artifact.trait.charAt(0).toUpperCase() +
          result.artifact.trait.slice(1) +
          's';

        chestNameArr.push(traitName, level === 'base' ? '' : level);

        dispatch(
          setOLandPlot({
            oLandPlot:
              result.gen5Positon &&
              Number(result.gen5Positon.position).toLocaleString('en-US'),
          }),
        );

        dispatch(setChestReceived({ chestReceived: chestNameArr.join(' ') }));
        dispatch(
          setBoosterRarity({
            boosterRarity: result.booster && result.booster.meta.type,
          }),
        );
        dispatch(
          setOCashReceivedFromChest({ oCashReceivedFromChest: result.amount }),
        );
        dispatch(setIsChestOpen({ isChestOpen: true }));
        playCelebrationSfx(
          result.amount,
          chest[0].midReward,
          chest[0].upperReward,
        );

        result.amount >= chest[0].midReward && renderBigWin();
      } catch (e) {
        toast(
          <Toast
            type="error"
            header="Could not open chest."
            body="Something went wrong when opening the chest. Try again or contact support."
          />,
        );
      } finally {
        await queryClient.invalidateQueries(getRQKProfileKey('getOCashAmount'));
        await queryClient.invalidateQueries(getRQKUserKeys('getUserKeys'));
        await queryClient.invalidateQueries(getRQKBoosters('getBoosters'));
        await queryClient.invalidateQueries('infinite-positions');
      }
    }
  }, []);
  return (
    <ChestBtnSC
      className="chest"
      color={chest[0].color}
      onClick={async () => {
        dispatch(setOCashReceivedFromChest({ oCashReceivedFromChest: 0 }));

        if (videoRef.current) {
          videoRef.current.play();
          videoRef.current.onended = () => {
            handleOpenChest(chest[0].openSize);
          };
        }

        await chestOpenSound.play();
        chestOpenSound.onended = () => {
          playOpenSfx(chest[0].type);
        };
      }}
    >
      {chest[0].webm && chest[0].mp4 && (
        <video
          id="video"
          width="100%"
          height="100%"
          autoPlay={false}
          loop={false}
          playsInline={true}
          preload={'auto'}
          muted
          className="chest glow"
          poster={chest[0].poster}
          ref={videoRef}
        >
          <source src={chest[0].mp4} type="video/quicktime"></source>
          <source src={chest[0].webm} type="video/webm"></source>
        </video>
      )}
    </ChestBtnSC>
  );
};

export default OpenChestButton;
