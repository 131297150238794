import React from 'react';

import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { TRootState } from '@store/index';
import userInterface from '@store/user-interface';
import Text, { TextVariant } from '@components/Text';
import { defaultModalProps } from '@components/Modal/modal-settings';
import ModalSC from '../Modal.styled';
import { ButtonPrimarySC } from '@components/Button.styled';

import { closeModal } from '@store/common';
import { TWinningModalContent } from '@store/user-interface/types';
import { getHumanReadable } from '@utils/common';
import {
  getRQKAuctionsKey,
  getRQKCoinGeckoKey,
  getRQKOCashKey,
  getRQKProfileKey,
} from '@utils/rq-utils';
import { useQuery, useQueryClient } from 'react-query';
import api from '@utils/api';
import { allRewards } from 'data/gifts';

const StyledWinningModal = styled.div`
  img {
    display: block;
    margin: 16px auto;
  }

  > div.row {
    display: flex;
    justify-content: space-between;
  }

  > button {
    margin-top: 24px;
  }
`;

const WinningModal: React.FunctionComponent = () => {
  const { setArtifactPlacement } = userInterface;

  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const winningModalContent = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.winningModalContent,
  ) as TWinningModalContent;

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const ethPrice = queryClient.getQueryData(
    getRQKCoinGeckoKey('getEthPrice'),
  ) as { usd: number };

  const oCashPrice = queryClient.getQueryData(
    getRQKOCashKey('getOCashPrice'),
  ) as number;

  const { isLoading, data } = useQuery(
    getRQKAuctionsKey('getAuction', winningModalContent.auctionId),
    async () => {
      const data = (await api.getAuction(winningModalContent.auctionId)).data
        .data;
      return data;
    },
    {
      enabled: type === 'winningModal', // display only on this modal
    },
  );

  const handleSubmitBtnClick = async () => {
    await queryClient.invalidateQueries(getRQKAuctionsKey('getAuctions'));
    await queryClient.invalidateQueries(getRQKProfileKey('getProfile'));
    dispatch(
      setArtifactPlacement({
        artifactPlacementContent: { auctionId: data.id },
      }),
    );
    return null;
  };

  if (isLoading) {
    return null;
  }

  const artifactObject = allRewards.filter((obj) => {
    if (obj.id === data.artifact.artifactId) {
      return obj;
    }
  });

  return (
    <ReactModal
      isOpen={type === 'winningModal' && !isLoading}
      {...defaultModalProps}
      onRequestClose={() => closeModal(dispatch)}
    >
      <ModalSC>
        <div className="header">
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            You won!
          </Text>
        </div>
        <StyledWinningModal>
          <span>
            {`Congratulations, you were the winning bid for ${getHumanReadable(
              data.artifact.artifactId,
            )}`}
          </span>

          <div id="artifact-icon">
            <img
              src={artifactObject[0].img}
              alt="Artifact Icon"
              style={{ maxWidth: 150, height: 'auto' }}
            />
          </div>

          <span>Winning bid:</span>
          <div className="row">
            <span> {data.winningBidAmount} ōCash</span>
            <span>
              ~(
              {(
                (parseFloat(data.winningBidAmount) * oCashPrice) /
                ethPrice.usd
              ).toFixed(4)}{' '}
              ETH)
            </span>
          </div>
          <ButtonPrimarySC onClick={handleSubmitBtnClick}>
            Claim
          </ButtonPrimarySC>
        </StyledWinningModal>
      </ModalSC>
    </ReactModal>
  );
};

export default WinningModal;
