import styled from 'styled-components';

export const ErrorSC = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  padding: 0 2rem;
  line-height: 1.5;
  flex-direction: column;
  text-align: center;
  color: ${({ theme }) => theme.text.main};

  img {
    max-width: 600px;
    width: 100%;
  }

  .title {
    @media (max-width: 768px) {
      font-size: 1.75rem;
    }
  }

  button {
    margin-bottom: 24px;
    width: 200px;
    font-size: 1rem;
  }

  .body {
    max-width: 600px;
    p,
    h1 {
      color: ${({ theme }) => theme.text.secondary};
    }
  }
`;
