import styled from 'styled-components';

export default styled.div`
  color: ${({ theme }) => theme.text.main};
  display: flex;
  justify-content: space-between;

  p {
    font-size: 20px;
    line-height: 135%;
  }
  img {
    display: block;
  }

  @media only screen and (max-width: 1024px) {
    justify-content: center;
  }

  .gray {
    color: #8f9bb3 !important;
  }

  .no-underline {
    text-decoration: none !important;
  }

  .link-span {
    &:hover {
      text-decoration: underline !important;
    }
  }

  #cf-turnstile {
    margin: auto !important;
  }

  @media only screen and (max-width: 1024px) {
    padding: 24px 24px;
  }
`;
