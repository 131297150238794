import Text from '@components/Text';
import { TRootState } from '@store/index';
import userInterface from '@store/user-interface';
import { numberToLocaleString } from '@utils/common';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import oCashIcon from '@assets/png/ocash-paper.png';
import { ReactComponent as SvgChevron } from '@assets/svg/chevron-down.svg';
import { getRQKBoosterPhase, getRQKProfileKey } from '@utils/rq-utils';
import { useQuery, useQueryClient } from 'react-query';
import api from '@utils/api';
import Skeleton from 'react-loading-skeleton';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';

type TInventoryBalanceProps = {
  airdropBalanceNum: number;
};

const OCashBalance: React.FC<{
  formattedOcashBalance: string;
}> = ({ formattedOcashBalance }) => (
  <div className="ocash-balance">
    <Text style={{ marginBottom: 0 }}>
      ōCash Balance:{' '}
      <span>
        <img style={{ height: 24 }} src={oCashIcon} alt="ōCash Icon" />
        {formattedOcashBalance}
      </span>
    </Text>
  </div>
);

const AirdropBalance: React.FC<{
  formattedAirdropBalance: string;
}> = ({ formattedAirdropBalance }) => {
  return (
    <div className="airdrop-balance">
      <Text style={{ marginBottom: 0 }}>
        Mainnet ōCash airdrop:{' '}
        <span className="bal">{formattedAirdropBalance}</span>
      </Text>
    </div>
  );
};

const GemRank: React.FC = () => {
  const { isLoading: boosterPhaseDataIsLoading, data: oGemPhaseData } =
    useQuery(getRQKBoosterPhase('getBoosterPhase'), async () => {
      const result = (await api.getBoosterPhase()).data.data;
      return result;
    });

  return (
    <div className="airdrop-balance">
      {boosterPhaseDataIsLoading ? (
        <Skeleton count={1} height={18} />
      ) : (
        <Tippy
          {...defaultTippyProps}
          content={
            oGemPhaseData.eligible
              ? `You are eligible to receive a booster for every $14.99 spent on keys!`
              : `You are NOT currently eligible to receive a booster with your key purchases.`
          }
        >
          <Text style={{ marginBottom: 0 }}>
            ōGem Rank:{' '}
            <span>
              {oGemPhaseData.rank.charAt(0).toUpperCase() +
                oGemPhaseData.rank.slice(1)}
            </span>{' '}
            ⓘ
          </Text>
        </Tippy>
      )}
    </div>
  );
};

const InventoryBalance: React.FC<TInventoryBalanceProps> = ({
  airdropBalanceNum,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const oCashBalance = queryClient.getQueryData(
    getRQKProfileKey('getOCashAmount'),
  ) as any;
  const oCashBalanceNum = parseFloat(oCashBalance.oCashAmountAvailable);

  const expandInventoryBar = useSelector<TRootState>(
    (store) => store.userInterface.expandInventoryBar,
  ) as boolean;

  const formattedOcashBalance = useMemo(
    () => numberToLocaleString(oCashBalanceNum),
    [oCashBalanceNum],
  );
  const formattedAirdropBalance = useMemo(
    () => numberToLocaleString(airdropBalanceNum),
    [airdropBalanceNum],
  );

  const handleScroll = () => {
    if (window.scrollY > 0) {
      handleExpandClick();
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleExpandClick = () => {
    dispatch(
      userInterface.setExpandInventoryBar({
        expandInventoryBar: !expandInventoryBar,
      }),
    );
  };

  return (
    <div className="inventory-balance">
      <div>
        <OCashBalance formattedOcashBalance={formattedOcashBalance} />
        <AirdropBalance formattedAirdropBalance={formattedAirdropBalance} />
        <GemRank />
      </div>
      <button className="expand" onClick={handleExpandClick}>
        <SvgChevron
          style={{
            width: 12,
            transform: expandInventoryBar ? 'rotate(0deg)' : 'rotate(180deg)',
            transition: '0.2s',
          }}
        />
      </button>
    </div>
  );
};

export default InventoryBalance;
