import React, { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { LoadingStatePositionSC } from './StyledTrayFrame.styled';

const LoadingStatePosition: React.FunctionComponent = () => (
  <LoadingStatePositionSC activePlot={false}>
    <Skeleton count={1} containerClassName="card-video-skeleton" />
    <div className="card-body-skeleton-wrapper">
      <Skeleton count={2} containerClassName="card-stats-skeleton" />
      <Skeleton count={1} containerClassName="card-button-skeleton" />
    </div>
  </LoadingStatePositionSC>
);

const memoizedLoadingStatePosition = memo(LoadingStatePosition);

export default memoizedLoadingStatePosition;
