import React from 'react';
import Tippy from '@tippyjs/react';
import { useQuery } from 'react-query';

import { LeaderboardSC } from '../OgemsDashboard.styled';
import Text, { TextVariant } from '@components/Text';
import { TOgemDashboardComponentProps } from './ProgressBar';
import { getRQKOgemLeaderboard } from '@utils/rq-utils';
import api from '@utils/api';
import Loader from './Loader';
import { defaultTippyProps } from '@utils/ui';

const Leaderboard: React.FunctionComponent<
  TOgemDashboardComponentProps
> = () => {
  const { isLoading: isOgemDataLoading, data: oGemLeaderboard } = useQuery(
    getRQKOgemLeaderboard('getOgemLeaderboard'),
    async () => (await api.getOgemsLeaderboard()).data.data,
  );

  return (
    <LeaderboardSC>
      <div className="header">
        <Text variant={TextVariant.CardSubtitle} className="title">
          Leaderboard
        </Text>
        <Text variant={TextVariant.CardText} className="subtitle">
          Top 20 users by ōGem balance{' '}
          <Tippy
            {...defaultTippyProps}
            content={`The data may not be 100% precise as they are cached`}
          >
            <strong style={{ cursor: 'pointer' }}>&#9432;</strong>
          </Tippy>
        </Text>
      </div>
      <div className="leaderboard">
        <div className="leaderboard-header">
          <Text variant={TextVariant.CardText} className="subtitle">
            Rank
          </Text>
          <Text variant={TextVariant.CardText} className="subtitle">
            User
          </Text>
          <Text variant={TextVariant.CardText} className="subtitle">
            ōGems
          </Text>
        </div>
        {isOgemDataLoading ? (
          <Loader />
        ) : (
          <div className="leaderboard-body">
            {oGemLeaderboard.map((item, index) => {
              return (
                <div className="leaderboard-item" key={index}>
                  <Text variant={TextVariant.CardText} className="title">
                    {index + 1}
                  </Text>
                  <Text variant={TextVariant.CardText} className="title">
                    {item.twitterHandle === null ? (
                      item.user.slice(0, 6) + '...' + item.user.slice(-6)
                    ) : (
                      <a
                        href={`https://twitter.com/${item.twitterHandle}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        @{item.twitterHandle}
                      </a>
                    )}
                  </Text>
                  <Text variant={TextVariant.CardText} className="title">
                    💎{' '}
                    {item.amount.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Text>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </LeaderboardSC>
  );
};

export default Leaderboard;
