import styled, { keyframes } from 'styled-components';
import meshGradient from '@assets/png/mesh-gradient.png';
import JpgOpenChestBG from '@assets/jpg/open-chest-bg.jpg';
import JpgKeysBG from '@assets/jpg/keys-bg.jpg';
import { colorThemes } from '@assets/styles';

const slideAnimation = keyframes`
from {
  transform: translateX(0%);
}
to {
  transform: translateX(-100%);
}
`;

const jackpotBorder = keyframes`
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
`;

export const chestAnimation = keyframes`
  0% { transform:  rotate(0deg); }
  10% { transform: rotate(-1deg); }
  20% { transform: rotate(1deg); }
  30% { transform: rotate(0deg); }
  40% { transform:  rotate(1deg); }
  50% { transform:  rotate(-1deg); }
  60% { transform:  rotate(0deg); }
  70% { transform:  rotate(0deg); }
`;

export const chestAnimationHover = keyframes`
0% { transform: translate(0.5px, 0px) rotate(0deg); }
10% { transform: translate(-0.5px, -0.5px) rotate(-1.5deg); }
20% { transform: translate(-0.75px, 0px) rotate(1.5deg); }
30% { transform: translate(0px, 0.5px) rotate(0deg); }
40% { transform: translate(0.5px, -0.5px) rotate(1.5deg); }
50% { transform: translate(-0.75px, 0.75px) rotate(-1.5deg); }
60% { transform: translate(-0.75px, 0.5px) rotate(0deg); }
70% { transform: translate(0.5px, 0.5px) rotate(-1.5deg); }
80% { transform: translate(-0.5px, -0.5px) rotate(1.5deg); }
90% { transform: translate(0.5px, 0.5px) rotate(0deg); }
100% { transform: translate(0.5px, -0.5px) rotate(-1.5deg); }
`;

export const pngGlow = (color: string) => keyframes`
    0% {
      -webkit-filter: drop-shadow(0px 0px 64px 8px ${color});
      filter: drop-shadow(0px 0px 64px 8px ${color});
    }

    25% {
      -webkit-filter: drop-shadow(0px 0px 32px 6px ${color});
      filter: drop-shadow(0px 0px 32px 6px ${color});
      }

      50% {
        -webkit-filter: drop-shadow(0px 0px 24px 4px ${color});
        filter: drop-shadow(0px 0px 24px 4px ${color});
        }

        75% {
          -webkit-filter: drop-shadow(0px 0px 32px 6px ${color});
          filter: drop-shadow(0px 0px 32px 6px ${color});
          }

    100% {
      -webkit-filter: drop-shadow(0px 0px 64px 8px ${color});
      filter: drop-shadow(0px 0px 64px 8px ${color});
      }
`;

export type TModalProps = {
  color?: string;
};

export default styled.div<TModalProps>`
  background-color: ${({ theme }) => theme.background.regular.bg1};
  border: 1.5px solid ${({ theme }) => theme.input.border};
  z-index: 998;
  padding: 40px;
  border-radius: 12px;

  .input-label {
    ${({ theme }) => theme.text.main}
  }

  // Wert module
  #wert-module {
    iframe {
      min-height: 580px !important;
    }
  }

  //  React-Select

  .custom-option {
    padding: 12px !important;
    // display: grid !important;
    // grid-template-columns: 1fr 1fr 1fr 1fr !important;
    // gap: 8px !important;
    background: white !important;

    :hover {
      background: ${({ theme }) => theme.neutral.neutral1} !important;
      cursor: pointer !important;
    }
  }

  // Rest

  .rangeslider {
    background: ${({ theme }) => theme.input.background};
    border: 1px solid ${({ theme }) => theme.input.border};
  }

  .rangeslider-horizontal .rangeslider__fill {
    background-color: ${({ theme }) => theme.blue.blue100} !important;
  }

  .rangeslider-horizontal .rangeslider__handle:after {
    display: none !important;
  }

  .rangeslider .rangeslider__handle {
    align-items: center;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    height: 30px;
    width: 30px;
  }

  .rangeslider__handle-label {
    pointer-events: none;
    font-weight: 800;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .treat-anim {
    width: 350px;
    z-index: 1;

    @media only screen and (max-width: 776px) {
      width: 250px;
    }
  }

  .placing-stat {
    border: 1.5px solid ${({ theme }) => theme.neutral.neutral4};
    border-radius: 10px;
    padding: 12px;
    width: 100%;
    margin-top: 12px;
    text-align: center;

    .stat-heading {
      margin-bottom: 8px;
    }

    .gauge-chart-container {
      max-width: 125px;
      max-height: 80px;
      margin: auto;
      text-align: center;
    }

    .gauge-label {
      background: ${({ theme }) => theme.input.background};
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.input.border};
      padding: 4px 8px;
      width: auto;
      h1 {
        font-size: 18px;
      }
    }

    @media only screen and (max-width: 776px) {
      padding: 8px;
      h1 {
        font-size: 16px !important;
      }
      .dgray {
        font-size: 12px !important;
      }
    }
  }

  .artifact-card {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    transition: 0.3s;
    align-items: center;
    text-align: center;
    justify-content: center;
    top: 0;
    z-index: 1;

    h1 {
      font-size: 1.5rem;

      @media only screen and (max-width: 776px) {
        font-size: 1rem;
      }
    }

    img {
      height: 150px;
      @media only screen and (max-width: 776px) {
        height: 100px;
      }
    }
  }

  @media only screen and (max-width: 883px) {
    padding: 12px;
  }

  .first-body-row {
    display: flex;
    justify-content: space-between;
  }

  .header {
    color: ${({ theme }) => theme.secondary};
    background-color: ${({ theme }) => theme.background.regular.bg1};
    display: flex;
    justify-content: space-between;
    font-size: 18px;

    button {
      border: none;
      background: none;
      img {
        width: 25px;
        height: 25px;
      }

      :hover {
        opacity: 0.7;
      }
    }
    span {
      font-weight: 500;
    }
  }

  .treat-bag {
    width: 150px;
    height: 150px;

    @media only screen and (max-width: 776px) {
      width: 75px;
      height: 75px;
    }
  }

  .country-dd {
    width: 100%;
  }

  select {
    border-radius: 10px !important;
    border-color: #d2d2d7 !important;
    padding: 12px !important;
  }

  .modal-input {
    margin: 16px 0px 24px 0px;
  }

  .dropdown {
    border-radius: 10px;
    border-color: #d2d2d7;
    padding: 12px;
    width: 100%;

    select,
    select option {
      color: #000000;
    }

    @media only screen and (max-width: 883px) {
      font-size: 12px !important;
      padding: 8px !important;
    }

    select option:first-child {
      color: ${({ theme }) => theme.input.placeholder};
    }
    :focus {
      box-shadow: 0 0 0 4px rgb(0 125 250 / 60%);
      border-color: ${({ theme }) => theme.blue.blue100};
      outline: none;
    }

    .region-dd {
      width: 100%;
    }

    form {
      label,
      span,
      input {
        display: block;
        width: 100%;
      }

      label {
        margin-top: 16px;
      }

      input,
      span.small {
        margin-top: 8px;
      }

      button {
        margin-top: 16px;
      }

      .captcha-row {
        display: flex;
        margin-top: 16px;

        input {
          width: auto;
        }

        label {
          margin-top: 0;
          flex-grow: 1;
        }
      }
    }
  }

  .custom-tooltip {
    background: ${({ theme }) => theme.blue.blue100};
    color: white;
    opacity: 1 !important;
    border-radius: 10px;
  }

  .glow-device-container {
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.background.regular.bg2};
    width: 55px;
    height: 55px;
    text-align: center;
    position: relative;

    img {
      margin: auto;
      max-height: 50px;
    }

    span {
    }

    @media only screen and (max-width: 883px) {
      height: 45px;
      width: 45px;
      img {
        max-height: 35px;
      }
    }
  }

  canvas {
    height: -webkit-fill-available !important;
  }

  .spec-table {
    td {
      padding: 8px;
      border: 1px solid ${({ theme }) => theme.background.regular.bg2};
    }
  }

  .device-content {
    height: 100%;
    margin: 16px 0px;
    transition: opacity 0.3s ease-out;

    @media only screen and (min-width: 883px) {
      min-height: 500px;
    }

    @media only screen and (max-width: 883px) {
      max-height: 350px;
      video {
        max-height: 250px !important;
      }

      table {
        font-size: 10px;
      }
    }
  }

  .social-row {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;

    .social-button {
      display: flex;
      height: 40px;
      align-items: center;
      border-radius: 10px;
      width: 100%;
      justify-content: center;

      color: white;
      font-weight: 500;
      padding: 0rem 1rem;
      transition: 0.3s;

      &.twitter {
        background-color: #1da1f2;
      }

      &.discord {
        background-color: #7289da;
      }

      img {
        height: 15px;
        margin-right: 0.35rem;
      }

      :hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }

  .looper {
    width: 100%;
    overflow: hidden;
  }

  .looper__innerList {
    display: flex;
    justify-content: center;
    width: fit-content;
  }

  .looper__listInstance {
    display: flex;
    gap: 16px;
    width: max-content;
    animation: ${slideAnimation} linear infinite;
  }

  .button-subtext {
    position: absolute !important;
    font-size: 10px !important;
    background: ${({ theme }) => theme.blue.blue100};
    border-radius: 5px;
    padding: 0px 7.5px;
    color: white;
    font-weight: 500;

    @media only screen and (min-width: 776px) {
      top: 7.5px;
      left: 0px;

      &.otreats-package {
        top: -7.5px;
      }
    }

    @media only screen and (max-width: 776px) {
      bottom: -7.5px;
      right: 0px;
    }
  }

  // Artifacts Modal

  .upgrades-tray {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 8px;
    margin-bottom: 24px;

    .artifact-item {
      width: 100%;
    }

    .reward {
      position: relative;
      max-width: 75px;
      max-height: 75px;
      margin: auto;
      width: 100%;
      border-radius: 5px;
      border: 2px solid ${({ theme }) => theme.neutral.neutral8};
      background: ${({ theme }) => theme.neutral.neutral6};
      opacity: 0.6;
      padding: 4px;
      transition: 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      :hover {
        opacity: 1;
        cursor: pointer;
        img {
          filter: saturate(100%);
        }

        :disabled {
          cursor: not-allowed;
        }

        :not(:disabled) {
          .sell-btn {
            opacity: 1;
            visibility: 1;
          }
        }
      }

      img {
        filter: saturate(0%);
        max-height: 70px;
      }

      .sell-btn {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        justify-content: center;
        right: 0;
        left: 0;
        transition: 0.3s;
        opacity: 0;
        visibility: 0;
        background: ${({ theme }) => theme.red};
        color: white;
        font-weight: 700;
      }
    }

    .reward-present {
      filter: saturate(100%);
      opacity: 1 !important;
      background: ${({ theme }) => theme.neutral.neutral2};
      border: 2px solid ${({ theme }) => theme.green} !important;
      :hover {
        background: ${({ theme }) => theme.background.regular.bg1};
      }

      img {
        filter: saturate(100%);
      }
    }

    @media only screen and (max-width: 776px) {
      padding: 0px;
      gap: 2px;
      grid-template-columns: repeat(4, 1fr);

      .reward {
        padding: 2px !important;
        border-width: 1.5px !important;
      }
    }
  }

  // Auction winner:

  #artifact-icon {
    background-image: url(${meshGradient});
    max-width: 200;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    margin: auto;
  }

  #device-description-row {
    border-bottom: 2px solid ${({ theme }) => theme.background.regular.bg2};
    margin-top: 12px;
  }

  &.booster {
    position: relative;

    .close {
      position: absolute;
      top: 24px;
      right: 24px;
      border: none;
      background: none;
      img {
        width: 25px;
        height: 25px;
      }

      :hover {
        opacity: 0.7;
      }
    }
  }

  // Collector Chest Modal

  &.chest-modal {
    background-color: #151622;
    border: 1.5px solid #575f83;
    z-index: 998;
    padding: 0px;
    position: relative;
    border-radius: 12px;

    h1 {
      color: white;
    }

    &.winner:before,
    &.winner:after {
      content: '';
      position: absolute;
      left: -2px;
      top: -2px;
      background: linear-gradient(
        45deg,
        #fb0094,
        #0000ff,
        #00ff00,
        #ffff00,
        #ff0000,
        #fb0094,
        #0000ff,
        #00ff00,
        #ffff00,
        #ff0000
      );
      background-size: 400%;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      z-index: -1;
      animation: ${jackpotBorder} 20s linear infinite;
    }

    .banner {
      margin-top: 12px;
      background-image: url(${JpgOpenChestBG});
      background-size: contain;
      background-size: cover;
      background-position: center;
      padding: 12px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      border-radius: 12px;
      .buy-keys-btn {
        height: 40px;
        width: 175px;
        margin-top: 12px;
      }
    }

    &.winner:after {
      filter: blur(50px);
    }

    .chest-selection-wrapper {
      color: ${({ theme }) => theme.text.main};

      .divider {
        height: 1.5px;
        width: 150px;
        background-color: ${({ theme }) => theme.text.main};
        margin: 24px auto;
        border-radius: 999px;
      }

      .chest-selection {
        margin-bottom: 32px;
        display: flex;
        gap: 6px;

        button {
          :disabled {
            cursor: not-allowed;
          }
        }
      }

      .key-balances {
        display: flex;
        justify-content: space-between;

        .bal-box {
          background: #282d40;
          border: 1.5px solid #575f83;
          border-radius: 2px;
          box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
          padding: 8px 12px;
          min-width: 150px;
          font-weight: 600;
        }
      }
    }

    .header {
      color: ${({ theme }) => theme.secondary};
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      font-size: 18px;
      position: relative;
      background: transparent;
      align-items: center;

      .treasure-title {
        position: absolute;
        display: flex;
        align-items: center;
        gap: 12px;
        top: -30px;
        margin: auto;
        padding: 12px 24px;
        border: 1.5px solid #575f83;
        background: #151622;
        h1 {
          margin: 0px;
        }
      }

      button {
        background: #971403;
        transform: rotate(45deg);
        padding: 0px;
        border: 2px solid #575f83;
        box-shadow: inset 1px 4px 4px rgba(0, 0, 0, 0.5);
        border-radius: 1px;
        position: absolute;
        top: -12.5px;
        right: -6px;
        z-index: 7;
        img {
          transform: rotate(45deg);
          width: 20px;
          height: 20px;
          margin: 2px;
        }

        :hover {
          filter: saturate(150%);
          opacity: 1;
        }
      }
      span {
        font-weight: 500;
      }

      @media only screen and (max-width: 883px) {
        img {
          width: 15px !important;
          height: 15px !important;
        }
      }
    }

    .product-table-content {
      background: linear-gradient(
          180deg,
          #151622 23%,
          rgba(21, 22, 34, 0.2) 60%,
          #151622 75%
        ),
        url(${JpgKeysBG});
      border: 1px solid #151622;
      background-size: cover;
      .text {
        align-items: center;
        text-align: center;
        margin: 24px 0px 12px 0px;
      }
    }

    .product-table {
      .body-wrapper {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 16px;
          background: linear-gradient(0deg, rgba(21, 22, 34, 0.6), transparent);
        }
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 16px;
          background: linear-gradient(0deg, transparent, rgba(21, 22, 34, 0.6));
        }
      }
      .table-body {
        display: flex;
        flex-direction: column;
        gap: 6px;

        @media only screen and (max-width: 883px) {
          max-height: 240px;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }

      display: grid;
      grid-template-columns: 1fr;
      gap: 12px;
      margin: 0px 6px;

      .table-header {
        display: grid;
        grid-template-columns: 2.75fr 2fr 1fr 0.25fr;
        h1 {
          color: white;
        }
      }
    }

    .return-controls {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      margin-top: 12px;

      button {
        margin: auto;
      }
    }
  }

  .buy-keys-btn {
    :hover {
      filter: saturate(150%);
      opacity: 0.8;
    }
    h1 {
      color: #151622 !important;
      font-weight: 600;
    }

    padding: 4px;
    background: #ffb800;

    border: 1px solid #575f83;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.35);
    border-radius: 2px;
  }

  .value {
    display: flex;
    align-items: center;
    color: white;
    gap: 0.5rem;
    background: #282d40;
    border: 1.5px solid #575f83;
    border-radius: 2px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 8px 12px;
    max-width: 150px;
    width: 100%;
    font-weight: 600;
  }

  .blue-btn {
    background: linear-gradient(180deg, #4fd5fb 0%, #024c84 100%);
    padding: 6px 12px;
    color: white;
    font-weight: 600;
    border: 1.5px solid #575f83;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    max-height: 40px;

    :hover:not(:disabled) {
      filter: saturate(150%);
      opacity: 0.8;
    }

    :disabled {
      cursor: not-allowed;
      background: linear-gradient(180deg, #767da0 0%, #282d40 100%);
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .value {
    }

    .bal {
      width: 100%;
      margin: 0px;
      font-weight: 600;
      color: white;
      text-align: left;
    }

    .keys,
    .ocash {
      width: 100%;
      justify-content: center;
      align-items: center;

      button {
        width: 100%;
      }
    }
  }

  .header .treasure-title {
    top: -50px !important;
    max-height: 50px;
    border-bottom-color: #151622 !important;
  }

  @media only screen and (max-width: 883px) {
    .header .treasure-title {
      top: -35px !important;
      max-height: 35px;
    }

    .buttons {
      display: grid !important;
      grid-template-columns: 1fr 1.5fr !important;
      justify-content: space-between;
      align-items: center;
      .value {
        padding: 4px 8px !important;
        font-size: 12px !important;
      }

      .bal {
        width: 100%;
        margin: 0px;
        font-size: 12px;
        font-weight: 600;
        text-align: left;
      }

      .keys,
      .ocash {
        width: 100%;
        justify-content: center;
        align-items: center;

        button {
          width: 100%;
          h1 {
            font-size: 12px;
          }
        }
      }
    }

    .divider {
      margin: 12px auto !important;
    }

    .product-table {
      margin: 12px 0px !important;

      h1 {
        font-size: 12px !important;
      }
    }

    .product-table {
      gap: 6px !important;
    }

    .chest-selection {
      h1 {
        font-size: 10px !important;
      }
    }
  }

  .inner-wrapper,
  .chest-selection-wrapper,
  .product-table-content {
    border-radius: 12px;
  }

  .upgrades-inventory {
    max-height: 450px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

export const ProductRowSC = styled.button`
  background: #0e0f15;
  display: grid;
  grid-template-columns: 2.75fr 2fr 1fr 0.25fr;
  gap: 12px;
  justify-content: space-between;
  text-align: left;
  padding: 6px;
  align-items: center;
  border-radius: 2px;
  border: none;

  .booster-waiting-wrapper {
    position: relative;
    margin-right: 20px;

    .booster-waiting {
      position: absolute;
      width: 24px;
      height: 24px;
    }
  }

  .key-icon {
    max-height: 18px;
  }

  :hover {
    background: #282d40;
  }

  h1 {
    margin: 0px;
  }

  @media only screen and (max-width: 1024px) {
    h1 {
      font-size: 12px;
    }
  }
`;

export const ChestBtnSC = styled.button`
  background: none;
  border: none;

  &.animated {
    animation: ${chestAnimation} 1s infinite;
    :hover {
      animation: ${chestAnimationHover} 0.5s infinite;
      animation-delay: 0s !important;
    }
  }

  img {
    &.glow {
      -webkit-filter: drop-shadow(
        5px 5px 5px ${(props) => props.color as string}
      );
      filter: drop-shadow(0px 0px 10px ${(props) => props.color as string});
    }
  }
  video {
    &.glow {
      -webkit-filter: drop-shadow(
        5px 5px 5px ${(props) => props.color as string}
      );
      filter: drop-shadow(0px 0px 10px ${(props) => props.color as string});
      -moz-filter: drop-shadow(
        0px 0px 10px ${(props) => props.color as string}
      );
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  .chest {
    text-align: center;
    margin: auto;
  }
`;

export const OpenChestSC = styled.div`
  .check-ultimate {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: white;
      text-align: center;
      font-weight: 600;
      margin-top: 12px;
    }
    button {
      width: 60px;
      height: 60px;
      background: black;
      padding: 0px;
      position: relative;
    }

    img {
      width: 100%;
      height: 100%;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 50px;
      font-weight: 600;
      color: ${({ theme }) => theme.blue.blue100};
      -webkit-text-fill-color: white; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 2.5px;
      -webkit-text-stroke-color: ${({ theme }) => theme.blue.blue100};

      svg {
        height: 65px;
        width: 65px;
        fill: lime;
      }
    }
  }

  &.winner:before,
  &.winner:after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    background: linear-gradient(
      45deg,
      #fb0094,
      #0000ff,
      #00ff00,
      #ffff00,
      #ff0000,
      #fb0094,
      #0000ff,
      #00ff00,
      #ffff00,
      #ff0000
    );
    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: ${jackpotBorder} 20s linear infinite;
  }

  &.winner:after {
    filter: blur(50px);
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    padding: 12px;

    .keys {
      display: flex;
      gap: 12px;
      width: 100%;

      .buy-keys-btn {
        width: 100%;
      }
    }

    .ocash {
      display: flex;
      justify-content: flex-end;
    }

    .sec-btn {
      width: 100%;
      padding: 4px;
      h1 {
        font-weight: 600;
      }
      background: linear-gradient(
        180deg,
        #282d40 0%,
        #202435 52.6%,
        #282d40 100%
      );

      border: 1px solid #575f83;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.35);
      border-radius: 2px;

      :hover {
        opacity: 0.8;
      }
    }
  }

  .reward-buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;

    button {
      :hover {
        filter: saturate(150%);
        opacity: 0.8;
      }
    }
  }

  .open-chest-main {
    position: relative;
    background-image: url(${JpgOpenChestBG});
    background-size: contain;
    background-size: cover;
    background-position: center;
    height: 350px;
    display: flex;
    justify-content: center;

    .ultimate-prize-overlay {
      z-index: 10;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(21, 22, 34, 0.85);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(4px);
      gap: 8px;

      .estimated-value {
        color: lime;
        font-weight: 500;
      }

      button {
        max-width: 150px;
        width: 100%;
        height: 100%;

        svg {
          height: 100%;
          width: auto;
        }
      }

      .minor,
      .gray {
        font-weight: 600;
      }

      .disclaimer {
        text-align: center;
        font-size: 10px;
      }

      input {
        background: ${({ theme }) => theme.input.background};
        border: 1.5px solid ${({ theme }) => theme.input.border};
        padding: 8px;
        border-radius: 8px;
        max-width: 300px;
        width: 100%;
        text-align: center;
        font-size: 10px;
        ::placeholder {
          color: ${({ theme }) => theme.text.secondary};
          font-size: 14px;
        }

        color: white;
      }

      .gray {
        color: ${({ theme }) => theme.text.secondary};
      }

      .chest-reward-figure {
        font-size: 36px;
        -webkit-text-stroke: 1px #ad6431;
      }

      img {
        width: 75px;
        height: 75px;
        border: 1.5px solid ${({ theme }) => theme.text.secondary};
        background: black;
      }

      @media only screen and (max-width: 1024px) {
        .chest-reward-figure {
          font-size: 28px;
        }

        img {
          width: 65px;
          height: 65px;
        }
      }
    }

    button {
      &.chest {
        max-width: 225px;
        position: absolute;
        bottom: 40px;
      }
    }

    .controls {
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 0px;
      padding: 24px;
      width: 100%;
      justify-content: space-between;
    }
  }

  .chest-reward-container {
    background: rgba(21, 22, 34, 0.65);
    width: 100%;
    height: 100%;
    z-index: 9;
    backdrop-filter: blur(2px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .blue-btn {
      margin-top: 12px;
    }

    .carousel-text {
      font-weight: 700;
      color: white;
      text-align: center;
      white-space: nowrap;
    }

    .carousel-item {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }
  }

  .carousel-balance {
    font-size: 42px !important;
    line-height: 70%;
    margin-bottom: 0px;
    -webkit-text-stroke: 1px #ad6431;

    .smaller {
      font-size: 24px !important;
    }
  }

  .chest-reward-figure {
    font-weight: 800;
    font-size: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 0;

    img {
      max-height: 60px;
    }
  }

  .chest-reward {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    max-width: 400px;
    width: 100%;

    .reward-header {
      background: linear-gradient(180deg, #491f16 0%, #220d0a 100%);
      border: 1px solid #575f83;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.35);
      border-radius: 2px;
      padding: 6px;
      display: flex;
      align-items: center;
      width: 150px;
      color: white;
      font-weight: 600;
    }

    .reward-body {
      display: grid;
      grid-template-columns: 1fr;
      gap: 12px;
      padding: 12px;
      background: #151622;
      border-radius: 2px;
      border: 1px solid #575f83;
    }

    @media only screen and (max-width: 1024px) {
      max-width: 90%;
    }

    button {
      color: white;
      font-weight: 600;
      padding: 4px;
    }

    .red-btn {
      background: linear-gradient(180deg, #cd612a 0%, #821e0b 100%);
      /* Rewards - Border Secondary */

      border: 1px solid #575f83;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.35);
      border-radius: 2px;
    }
  }

  @media only screen and (max-width: 1024px) {
    .chest-reward-figure {
      font-weight: 800;
      font-size: 40px;
      text-align: center;
      display: flex;
      align-items: center;
      gap: 6px;
      margin-bottom: 0;

      img {
        height: 32px;
      }
    }

    .carousel-text {
      font-size: 24px;

      .plot {
        font-size: 16px;
      }

      img {
        max-height: 30px;
      }
    }
  }
`;

export const MoneyBagsSC = styled.img`
  max-height: 100px;
  margin: auto;

  &.glow {
    &.glow {
      -webkit-filter: drop-shadow(5px 5px 5px #fbf7c6);
      filter: drop-shadow(5px 5px 5px #fbf7c6);
    }
  }
`;

export const SelectchestsC = styled.div`
  background: linear-gradient(
      180deg,
      #151622 20%,
      rgba(21, 22, 34, 0.2) 55%,
      #151622 85%
    ),
    url(${JpgKeysBG});

  .inner-wrapper {
    background: rgba(21, 22, 34, 0.8);
    backdrop-filter: blur(6px);
  }

  .key-img {
    max-height: 24px;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    padding: 12px 24px;
    .keys {
      display: flex;
      gap: 12px;
      width: 100%;
    }

    .ocash {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .sec-btn {
      width: 100%;
      padding: 4px;
      h1 {
        font-weight: 600;
      }
      background: linear-gradient(
        180deg,
        #282d40 0%,
        #202435 52.6%,
        #282d40 100%
      );

      border: 1px solid #575f83;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.35);
      border-radius: 2px;

      :hover {
        opacity: 0.8;
      }
    }

    .value {
      display: flex;
      align-items: center;
      color: white;
      gap: 0.25rem;
      background: #282d40;
      border: 1.5px solid #575f83;
      border-radius: 2px;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 8px 12px;
      max-width: 150px;
      width: 100%;
      font-weight: 600;
    }
  }

  @media only screen and (max-width: 1024px) {
    .text {
      padding: 0px 24px;
      margin-top: 12px;
    }
  }
`;

export const BoosterInventoryModalSC = styled.div`
  &.body {
    padding: 12px;
  }

  .boosters {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-bottom: 12px;
    button {
      width: 80px;
      height: 80px;
      position: relative;
      background: ${colorThemes.dark.background.regular.bg1};

      .qty-box {
        background: ${colorThemes.dark.background.regular.bg1};
        color: white;
      }
      @media only screen and (max-width: 1024px) {
        width: 60px;
        height: 60px;
      }

      img {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
  .inventory {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 12px;
    background: #282d40;
    border: 1.5px solid #575f83;
    margin-bottom: 12px;
    padding: 12px;
    width: 100%;
    margin-top: 12px;
    max-height: 200px;
    overflow-y: scroll;

    @media only screen and (max-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
      max-height: 162px;
      overflow-y: scroll;
    }

    .oboo-item {
      width: 100%;
      border-radius: 4px;
      position: relative;
      display: flex;
      overflow: hidden;
      border: 1.5px solid #575f83;
      background: #0e0f15;

      img {
        width: 100%;
        height: 100%;
      }
    }

    h1 {
      font-weight: 700;
    }
  }
`;

export const ConfirmBoosterApplicationSC = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  text-align: center;

  span {
    font-weight: 700;
  }

  .button-wrapper {
    display: flex;
    gap: 12px;
    width: 100%;
    button {
      width: 100%;
    }
  }
`;
