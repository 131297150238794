import { StyledTrayFrame } from '@pages/Home/components/PositionCard/StyledTrayFrame.styled';
import styled from 'styled-components';

export const V2AuctionsPageSC = styled.div`
  margin-top: 48px;
  padding: 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  svg {
    fill: ${({ theme }) => theme.text.main};
    height: 0.5rem;
  }

  @media only screen and (max-width: 1024px) {
    padding: 0px 12px;
    margin-top: 24px;

    h1 {
      font-size: 1.25rem;
    }
  }

  h3 {
    color: ${({ theme }) => theme.text.main};
    margin-bottom: 12px;
  }

  .header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .balance {
      background: ${({ theme }) => theme.input.background};
      border-radius: 8px;
      padding: 12px;
      display: flex;
      align-items: center;
      border: 1.5px solid ${({ theme }) => theme.input.border};
      max-width: 200px;
      color: white;
      justify-content: center;
      justify-self: end;
    }

    h1 {
      color: ${({ theme }) => theme.text.main};
      text-align: center;
      margin: 0px;
    }

    button {
      max-width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .buttons {
    .inner {
      display: flex;
      gap: 12px;

      button {
        max-width: 125px;
      }
    }
  }

  .auction-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    margin-top: 2rem;
  }

  .sort-select {
    color: ${({ theme }) => theme.text.main};
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    select {
      background: ${({ theme }) => theme.input.background};
      border: 1.5px solid ${({ theme }) => theme.input.border};
      border-radius: 8px;
      padding: 0.5rem;
      color: ${({ theme }) => theme.text.main};
      max-width: 200px;
    }
  }
`;

export const V2AuctionCardSC = styled(StyledTrayFrame)`
  position: relative;
  padding-top: 0px;
  overflow: hidden;

  &.rare {
    color: black !important;
  }

  .power-score {
    svg {
      fill: ${({ theme }) => theme.blue.blue100};
    }
  }

  .bidding-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: ${({ theme }) => theme.input.background};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 12px;
    z-index: 3;

    &.blur {
      border-radius: 0px;
    }

    .bidding-inner {
      border-radius: 8px;
      border: 1.5px solid ${({ theme }) => theme.input.border};
      width: 100%;
      height: 100%;
      padding: 12px 0px;

      p {
        color: ${({ theme }) => theme.text.secondary};
        margin-bottom: 0.25rem;
      }

      .bid-entry {
        display: grid;
        grid-template-columns: 1fr 0.5fr;
        gap: 12px;
      }

      .heading {
        color: ${({ theme }) => theme.text.secondary};
        line-height: 1.1rem;
        border-bottom: 1.5px solid ${({ theme }) => theme.input.border};
        padding: 0px 12px 12px 12px;
        display: flex;
        justify-content: space-between;

        span {
          color: ${({ theme }) => theme.text.main};
          font-weight: 600;
        }

        button {
          border: none;
          background: none;
          img {
            width: 25px;
            height: 25px;
          }

          :hover {
            opacity: 0.7;
          }
        }
      }
    }

    .green {
      color: ${({ theme }) => theme.green};
      text-align: center;
      margin-top: 0.5rem;

      span {
        font-size: 0.8rem;
      }
    }

    .bid-options {
      padding: 12px;
    }

    .current-bid,
    .buy-now {
      p {
        color: ${({ theme }) => theme.text.secondary};
        margin-bottom: 0.25rem;
      }

      h5 {
        color: ${({ theme }) => theme.text.main};
      }
    }

    .buy-now {
      margin: 12px 0rem;
    }

    .buttons {
      display: flex;
      gap: 12px;
      width: 100%;

      & > :first-child {
        width: 35px;
        height: 35px;
        svg {
          color: ${({ theme }) => theme.text.main};
        }
      }

      .bid-btn {
        width: 100%;
      }
    }

    h6 {
      color: ${({ theme }) => theme.text.main};
      text-align: left !important;
      margin-bottom: 0rem;
    }
  }
  .video-container {
    height: auto !important;
    min-width: 0px;
    min-height: 0px;
    z-index: 2;

    .winning-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      span {
        font-size: 1rem;
      }
    }

    .upgrade-inventory {
      & > :nth-child(2) {
        padding: 12px 0px;
      }
    }

    .upgrades-tray {
      margin: 12px 0px;
      padding: 0px;
    }

    .unlock-counter {
      font-size: 14px !important;
    }
  }

  .time-remaining {
    margin: 0px;
    text-align: center;
    position: relative;
  }

  .card-content {
    display: grid;
    grid-template-columns: 1fr 0.75fr;
    justify-content: space-between;
    align-items: center;
    position: relative;

    & > :nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: center;
      align-items: flex-end;

      a {
        color: #333333;
        text-decoration: none;
        font-weight: 600;
      }
    }

    p,
    h5,
    h6 {
      margin-bottom: 0.5rem;
    }

    .bid {
      .gray {
        margin-bottom: 0.25rem;
        color: #333333;
      }
    }

    h5,
    h6 {
      font-weight: 600;
    }
  }
`;
