import CustomTooltip from '@components/CustomTooltip';
import React, { useCallback } from 'react';
import RewardClaimTooltip from './RewardClaimTooltip';
import Row, { JustificationTypes } from '@components/Row';
import { ButtonOutlineSC } from '@components/Button.styled';
import api from '@utils/api';
import { getRQKProfileKey, getRQKUserRewardsKey } from '@utils/rq-utils';
import { toast } from 'react-toastify';
import Toast from '@components/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { ECustomTooltipVariant } from '@components/CustomTooltip/CustomTooltip.styled';
import { TRootState } from '@store/index';
import userInterface from '@store/user-interface';
import { openChestModal } from '@store/common';
import { useQueryClient } from 'react-query';

type TInventoryActionButtonsProps = {
  claimableId: string | null;
  allAvailableTreats: number;
};

const InventoryActionButtons: React.FunctionComponent<
  TInventoryActionButtonsProps
> = ({ claimableId, allAvailableTreats }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const oCashBalance = queryClient.getQueryData(
    getRQKProfileKey('getOCashAmount'),
  ) as any;
  const oCashBalanceNum = parseFloat(oCashBalance.oCashAmountAvailable);

  const freeOCashAmountClaimed = useSelector(
    (store: TRootState) => store.userInterface.freeOCashAmountClaimed,
  );
  const oTreatPlacement = useSelector(
    (store: TRootState) => store.userInterface.oTreatPlacement,
  ) as { id: string; currentOTreatAmount: number };

  const expandInventoryBar = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.expandInventoryBar,
  ) as boolean;

  const selectedTreatType = useSelector(
    (store: TRootState) => store.userInterface.selectedTreatType,
  );

  const handleClaimFreeOTreatClick = useCallback(async () => {
    try {
      if (!claimableId) {
        return;
      }
      await api.claimReward(claimableId);
      await queryClient.invalidateQueries(
        getRQKUserRewardsKey('getUserRewards'),
      );
      await queryClient.invalidateQueries(
        getRQKUserRewardsKey('getAvailableUserRewards'),
      );
      toast(
        <Toast
          type="success"
          header="Successfully Claimed!"
          body="You successfully claimed your free ōTreats!"
        />,
      );
    } catch (e) {
      // TODO: sentry
      console.error('Error when claiming reward: ', e);
    }
  }, [claimableId]);

  const handleUpgradeBtnClick = useCallback(
    (event) => {
      oTreatPlacement === null
        ? event.currentTarget.classList.toggle('disabled')
        : dispatch(
            userInterface.placeOTreatsOnPosition({
              selectedPlot: {
                id: oTreatPlacement.id,
                currentOTreatAmount: oTreatPlacement.currentOTreatAmount,
              },
            }),
          );
    },
    [dispatch, oTreatPlacement],
  );

  const openOTreatModal = useCallback(() => {
    dispatch(
      userInterface.setModal({
        type: 'oTreatModal',
        token: null,
      }),
    );
  }, [dispatch]);

  return (
    <div className="inventory-buttons">
      <CustomTooltip
        claimedAmount={freeOCashAmountClaimed}
        variant={ECustomTooltipVariant.DailyCash}
        shown={freeOCashAmountClaimed > 0}
      />

      <RewardClaimTooltip rewardTooltipShown={claimableId != null} />
      <Row
        justify={JustificationTypes.SpaceBetween}
        style={{ margin: 0, alignItems: 'center', gap: 8 }}
      >
        <ButtonOutlineSC
          onClick={() => openChestModal(dispatch)}
          style={{ maxWidth: 150 }}
          expandedInventory={expandInventoryBar}
          className={oCashBalanceNum < 10 ? 'bounce inventory' : 'inventory'}
        >
          1. Get ōCash
        </ButtonOutlineSC>
        {claimableId === null ? (
          <ButtonOutlineSC
            onClick={openOTreatModal}
            style={{ maxWidth: 150 }}
            expandedInventory={expandInventoryBar}
            className={oCashBalanceNum > 10 ? 'bounce inventory' : 'inventory'}
          >
            2. Buy ōTreats
          </ButtonOutlineSC>
        ) : (
          <ButtonOutlineSC
            onClick={handleClaimFreeOTreatClick}
            className="inventory color bounce"
            style={{ maxWidth: 150 }}
            expandedInventory={expandInventoryBar}
          >
            2. Get Free ōTreat
          </ButtonOutlineSC>
        )}
        <ButtonOutlineSC
          onClick={handleUpgradeBtnClick}
          style={{ maxWidth: 150 }}
          expandedInventory={expandInventoryBar}
          className={allAvailableTreats > 0 ? 'bounce inventory' : 'inventory'}
        >
          {selectedTreatType === null ? '3. Upgrade Land' : '3. Select Land'}
        </ButtonOutlineSC>
      </Row>
    </div>
  );
};

export default InventoryActionButtons;
