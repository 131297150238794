import React, { useCallback, useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '@store/index';
import userInterface from '@store/user-interface';
import ModalSC from './Modal.styled';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import nodeSpin from '@assets/animations/spinning-glow-whitebg.mp4';
import { defaultModalProps } from './modal-settings';
import Row from '@components/Row';

const DeviceModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const [detailsActive, setDetailsActive] = useState(false);
  const { setModal } = userInterface;

  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const handleCloseModal = useCallback(() => {
    dispatch(setModal({ type: null, token: null }));
  }, []);

  return (
    <ReactModal
      isOpen={type === 'deviceModal'}
      {...defaultModalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC>
        <div className="header">
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            Meet the ōRouter
          </Text>
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <div className="body">
          <Row id="device-description-row">
            <TabButtonSC
              onClick={() => {
                setDetailsActive(false);
              }}
              id="left"
              className={detailsActive ? '' : 'active'}
            >
              Description
            </TabButtonSC>
            <TabButtonSC
              onClick={() => {
                setDetailsActive(true);
              }}
              id="right"
              className={detailsActive ? 'active' : ''}
            >
              Details
            </TabButtonSC>
          </Row>
          <div className="device-content">
            {detailsActive ? (
              <>
                <table className="spec-table">
                  <tr>
                    <td>Dimensions</td>
                    <td>100.5mm x 19.5mm x 65.5mm</td>
                  </tr>
                  <tr>
                    <td>Weight (Planned)</td>
                    <td>0.155 kg</td>
                  </tr>
                  <tr>
                    <td>Range</td>
                    <td>1.6 sq km (expected) - 64 sq km (unobstructed)</td>
                  </tr>
                  <tr>
                    <td>Mobile App Compatability</td>
                    <td>iOS/Android</td>
                  </tr>
                  <tr>
                    <td>Antennas</td>
                    <td>4</td>
                  </tr>
                  {/* <tr>
                  <td>Bandwidth</td>
                  <td>256 kbps</td>
                </tr> */}
                  <tr>
                    <td>Transmission Protocol</td>
                    <td>LORA, Bluetooth, {'&'} PoD</td>
                  </tr>
                  <tr>
                    <td>Dust Sealed | Water Resistant</td>
                    <td>Yes (Pending Rating)</td>
                  </tr>
                  <tr>
                    <td>Exposed Port</td>
                    <td>1x USB-C</td>
                  </tr>
                  <tr>
                    <td>Included in Package</td>
                    <td>1x ōRouter, 1x USB-C cable</td>
                  </tr>
                </table>
              </>
            ) : (
              <>
                <Text variant={TextVariant.CardText}>
                  {`The world's first Web3 Router. Generate passive income and provide Web3 connectivity to your community.`}
                </Text>
                <video
                  width="auto"
                  height="350"
                  style={{ width: '100%', margin: '16px 0px' }}
                  autoPlay={true}
                  loop={true}
                  playsInline={true}
                  preload={'auto'}
                  muted
                >
                  <source src={nodeSpin} type="video/mp4" />
                </video>
              </>
            )}
            <a
              href={`${window.location.origin}/orouter`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text variant={TextVariant.Link} style={{ marginTop: 24 }}>
                Learn More {'>'}
              </Text>
            </a>
          </div>
        </div>
      </ModalSC>
    </ReactModal>
  );
};

const TabButtonSC = styled.button`
  border: 2px solid ${({ theme }) => theme.background.regular.bg2};
  background: none;
  padding: 16px;
  text-align: center;
  min-width: 125px;
  border-bottom: none;
  border-color: ${({ theme }) => theme.background.regular.bg2};
  background: ${({ theme }) => theme.background.regular.bg1};
  &.active {
    background: white;
  }

  &#left {
    border-top-left-radius: 18px;
  }

  &#right {
    border-top-right-radius: 18px;
    border-left: none;
  }

  @media only screen and (max-width: 883px) {
    padding: 8px;
    font-size: 12px;
  }
`;

export default DeviceModal;
