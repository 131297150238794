import { EChestType } from '@store/user-interface/types';
import chestOpenSfx from '@assets/sfx/keys/unlock.mp3';
import openBangSfx from '@assets/sfx/keys/box-open.mp3';
import smallWinSfx from '@assets/sfx/keys/small-win.mp3';
import mediumWinSfx from '@assets/sfx/keys/medium-win.mp3';
import bigWinSfx from '@assets/sfx/keys/big-win.mp3';
import angelicSfx from '@assets/sfx/keys/angelic-short.mp3';
import wowSfx from '@assets/sfx/keys/Wooow.mp3';
import applausSfx from '@assets/sfx/keys/applaus-loop.mp3';
import noUltimateSfx from '@assets/sfx/keys/no-ultimate.mp3';
import fanfareSfx from '@assets/sfx/fanfare.mp3';

const smallWin = new Audio(smallWinSfx);
const mediumWin = new Audio(mediumWinSfx);
const bigWin = new Audio(bigWinSfx);
const chestBangSound = new Audio(openBangSfx);
const angelicSound = new Audio(angelicSfx);
const wowSound = new Audio(wowSfx);
const chestOpenSound = new Audio(chestOpenSfx);
const applausSound = new Audio(applausSfx);
const noUltimateSound = new Audio(noUltimateSfx);
const fanfareSound = new Audio(fanfareSfx);

const sounds = [
  smallWin,
  mediumWin,
  bigWin,
  chestBangSound,
  angelicSound,
  wowSound,
  chestOpenSound,
  applausSound,
  noUltimateSound,
  fanfareSound,
];

export const bigWinSound = async () => {
  applausSound.loop = true;
  await bigWin.play();
  await applausSound.play();
};

export const playCelebrationSfx = async (
  result: number,
  midReward: number,
  upperReward: number,
) => {
  if (result < midReward) return await smallWin.play();
  if (result >= midReward && result < upperReward)
    return await mediumWin.play();
  if (result >= upperReward) return await bigWinSound();
};

export const playOpenSfx = async (type: EChestType) => {
  switch (type) {
    case EChestType.Small:
      return chestBangSound.play();
    case EChestType.Medium:
      return chestBangSound.play(), wowSound.play();
    case EChestType.Large:
      return chestBangSound.play(), angelicSound.play();
    default:
      return chestBangSound.play();
  }
};

export const stopAudio = () => {
  applausSound.loop = false;
  sounds.map((sound) => {
    sound.pause();
    sound.currentTime = 0;
  });
};
