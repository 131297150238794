import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import api, { unifyPaymentStatus } from '@utils/api';
import Text, { TextVariant } from '@components/Text';
import { ButtonOutlineSC, ButtonPrimarySC } from '@components/Button.styled';
import Loader from '@components/Loader';
import StyledOrdersPage, {
  OrdersContainerSC,
  TableLinkSC,
} from './OrdersPage.styled';
import { getRQKProfileKey } from '@utils/rq-utils';
import { openOCashModal } from '@store/common';
import {
  getHumanReadableOrderType,
  ORDER_PAYMENT_TYPE_CUSTOM_GW,
} from '@utils/common';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import Toast from '@components/Toast';

const OrdersPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [refreshBtnLoading, setRefreshBtnLoading] = useState(false);

  const { isLoading: isOrdersDataLoading, data: ordersResultData } = useQuery(
    'getPurchaseOrder',
    async () => {
      const orders = (await api.getOrders()).data.data;

      for (const order of orders) {
        const orderPayments = order.orderPayments.filter(
          (op) => op.status !== 'waiting',
        );
        order.type = orderPayments[0] ? orderPayments[0].type : null;
        order.dEthTotal = null;

        if (order.type === ORDER_PAYMENT_TYPE_CUSTOM_GW) {
          order.dEthTotal = 0;
          for (const orderPayment of orderPayments) {
            for (const tx of orderPayment.meta.txs) {
              order.dEthTotal += tx.usd;
            }
          }

          order.dEthTotal = order.dEthTotal.toFixed(2);
        }
      }

      return orders;
    },
  );

  const { isLoading: isEthGatewayLoading } = useQuery(
    'pollEthGateway',
    async () => (await api.pollCustomGw()).data.data,
    {
      enabled: !isOrdersDataLoading,
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const handleGoBack = async () => {
    await queryClient.invalidateQueries(getRQKProfileKey('getProfile'));
    navigate('/');
  };

  const handleRefreshOrdersBtnClick = async () => {
    setRefreshBtnLoading(true);
    try {
      const result = (await api.refreshDirectEthPayments()).data.data;

      if (result) {
        await queryClient.invalidateQueries('getPurchaseOrder');
        toast(
          <Toast
            type="success"
            header="Refresh Payments"
            body="Outstanding Direct ETH order(s) found. ōCash has been transferred and the order can now be found in Your Orders below."
          />,
        );
      } else {
        toast(
          <Toast
            type="success"
            header="Refresh Payments"
            body="No outstanding Direct ETH orders found. If you feel this is wrong, screenshot this page and send it to support."
          />,
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setRefreshBtnLoading(false);
    }
  };

  if (isEthGatewayLoading || isOrdersDataLoading) return <Loader />;

  if (ordersResultData.length === 0) {
    return (
      <div className="content-page-centered">
        <Text
          variant={TextVariant.CardTitle}
          style={{ textAlign: 'center', marginBottom: 16 }}
        >
          No orders found
        </Text>
        <Text
          variant={TextVariant.CardText}
          style={{ textAlign: 'center', marginBottom: 16 }}
        >
          If you have existing orders, please check your connection or reach out
          to us.
        </Text>
      </div>
    );
  }

  return (
    <StyledOrdersPage>
      <div style={{ marginTop: 48 }} className="inner-content">
        <Text
          variant={TextVariant.CardTitle}
          style={{ textAlign: 'center', marginBottom: 16 }}
        >
          Your Orders
        </Text>

        <div className="orders-row">
          <div className="orders-row__left">
            <ButtonPrimarySC onClick={() => openOCashModal(dispatch)}>
              Buy ōCash
            </ButtonPrimarySC>
            <ButtonOutlineSC
              disabled={refreshBtnLoading}
              onClick={handleRefreshOrdersBtnClick}
            >
              Refresh
            </ButtonOutlineSC>
          </div>
          <div className="orders-row__right">
            <ButtonOutlineSC onClick={handleGoBack}>
              Back to ōLand
            </ButtonOutlineSC>
          </div>
        </div>

        <OrdersContainerSC>
          <table>
            <tr>
              <th>Order #</th>
              <th>Type</th>
              <th>Total</th>
              <th>Status</th>
              <th>Actions</th>
              <th>Date Created</th>
            </tr>
            {ordersResultData
              .filter((item) => {
                const paymentStatus = item.orderPayments[0]
                  ? item.orderPayments[0].status
                  : null;
                return (
                  paymentStatus !== 'requires_payment_method' && // init stripe
                  paymentStatus !== 'waiting' && // init custom eth gw
                  paymentStatus !== 'new' && // init bitpay
                  paymentStatus !== null
                );
              })
              .map((item, index) => {
                const orderPayment = item.orderPayments[0];
                return (
                  <tr key={index}>
                    <td>{item.orderNumber}</td>

                    <td>{getHumanReadableOrderType(item.type)}</td>
                    <td>
                      {item.dEthTotal ? (
                        <Tippy
                          {...defaultTippyProps}
                          content={`Total value of ETH transferred for order`}
                        >
                          <span>${item.dEthTotal}</span>
                        </Tippy>
                      ) : (
                        <span>${item.total}</span>
                      )}
                    </td>
                    <td>
                      {unifyPaymentStatus(
                        orderPayment.status ?? null,
                        orderPayment.type,
                      )}
                    </td>
                    <td>
                      <TableLinkSC
                        onClick={() => navigate(`/purchase-orders/${item.id}`)}
                      >
                        Details
                      </TableLinkSC>
                      {/*<TableLinkSC>Download Invoice</TableLinkSC>*/}
                    </td>
                    <td>{dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                  </tr>
                );
              })}
          </table>
        </OrdersContainerSC>
      </div>
    </StyledOrdersPage>
  );
};

export default OrdersPage;
