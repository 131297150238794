import React, { useState, useMemo } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import chicken from '@assets/png/treats/chicken.png';
import fish from '@assets/png/treats/fish.png';
import goat from '@assets/png/treats/goat.png';
import honey from '@assets/png/treats/honey.png';
import lobster from '@assets/png/treats/lobster.png';
import watermelon from '@assets/png/treats/watermelon.png';
import { TRootState } from '@store/index';
import userInterfaceStore from '@store/user-interface';
import {
  ButtonOutlineSC,
  ButtonPrimarySC,
  OptionButtonSC,
} from '@components/Button.styled';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { defaultModalProps } from '@components/Modal/modal-settings';
import api, { PURCHASEABLE_OTREAT_ID } from '@utils/api';
import TermsFooter from './CheckoutProcess/components/TermsFooter';
import ModalSC from './Modal.styled';
import Row from '@components/Row';
import InfiniteLooper from '@components/InfiniteLooper';
import { Query, useQueryClient } from 'react-query';
import { getRQKProfileKey, getRQKUserRewardsKey } from '@utils/rq-utils';
import { openOCashModal } from '@store/common';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import { numberToLocaleString } from '@utils/common';
import Toast from '@components/Toast';
import { toast } from 'react-toastify';

const treatPackages = [2500, 1000, 200, 30, 10, 1];

export const treatBags = [chicken, fish, goat, honey, lobster, watermelon];
const O_TREAT_PRICE = 9.99;

const OTreatModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);

  const [oTreatAmount, setOTreatAmount] = useState(treatPackages[2]);
  const [oCashCost, setOCashCost] = useState(treatPackages[2] * O_TREAT_PRICE);

  const [view, setView] = useState<'init' | 'confirmation' | 'review'>('init');

  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const {
    state: {
      // @ts-expect-error they don't know
      data: { oCashAmountAvailable },
    },
  } = queryClient
    .getQueryCache()
    .find(getRQKProfileKey('getOCashAmount')) as Query;

  const handleCloseModal = async () => {
    dispatch(
      userInterfaceStore.setModal({
        type: null,
        token: null,
      }),
    );
    await queryClient.invalidateQueries(getRQKProfileKey('getOCashAmount'));
    await queryClient.invalidateQueries(getRQKUserRewardsKey('getUserRewards'));
    await queryClient.invalidateQueries(
      getRQKUserRewardsKey('getAvailableUserRewards'),
    );
  };

  const handleOTreatPackageClick = (oTreatCnt: number) => {
    setOTreatAmount(oTreatCnt);
    setOCashCost(oTreatCnt * O_TREAT_PRICE);
  };

  const handleOTreatOrder = async () => {
    setIsLoading(true);
    try {
      if (oTreatAmount === null || oTreatAmount <= 0) {
        return;
      }
      await api.createPurchase(PURCHASEABLE_OTREAT_ID, oTreatAmount);
    } catch (e) {
      // TODO: sentry
      console.error('Error when creating order: ', e);
    } finally {
      setIsLoading(false);
      handleCloseModal();
      toast(
        <Toast
          type="success"
          header="Success!"
          body="Your new ōTreats have been added to your inventory bar."
        />,
      );
    }
  };

  const init = useMemo(
    () => (
      <>
        <div className="header">
          <Tippy
            {...defaultTippyProps}
            content={`ōTreats attract ōFriends to upgrade your land. If you're lucky one might stay!`}
          >
            <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
              Purchase ōTreats{' '}
              <span
                style={{
                  fontSize: '1rem',
                }}
              >
                &#9432;
              </span>
            </Text>
          </Tippy>
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <div className="body">
          <Text variant={TextVariant.CardText} className="gray">
            Current Balance:{' '}
            <span style={{ fontWeight: 700 }} className="blue">
              {numberToLocaleString(oCashAmountAvailable)} ōCash
            </span>
          </Text>
          <br />
          <Text variant={TextVariant.CardText} className="gray">
            Select the amount of ōTreats you would like to purchase. You can use
            ōTreats to upgrade your land and attract rare companions!
          </Text>
          <div>
            <InfiniteLooper speed={10} direction="left">
              {treatBags.map((item, index) => (
                <div key={index}>
                  <TreatBag size={100} src={item} />
                </div>
              ))}
            </InfiniteLooper>
          </div>
          <div
            style={{
              display: 'grid',
              gap: 16,
              gridTemplateColumns: '1fr 1fr 1fr',
              marginTop: 16,
            }}
          >
            {treatPackages.map((item, index) => (
              <div
                key={index}
                style={{ alignSelf: 'flex-end', position: 'relative' }}
              >
                <OptionButtonSC
                  className={`${oTreatAmount === item && 'active'}`}
                  onClick={() => handleOTreatPackageClick(item)}
                >
                  {item.toLocaleString()}x
                </OptionButtonSC>
                <div className="button-subtext otreats-package">
                  {item === 200 ? 'Popular!' : ''}
                </div>
              </div>
            ))}
          </div>

          <Row style={{ marginTop: 24 }}>
            <Text style={{ marginRight: '0.25rem' }}>Cost: </Text>
            <Text className="blue" style={{ fontWeight: 700 }}>
              {' '}
              {numberToLocaleString(oCashCost)} ōCash
            </Text>
          </Row>

          <ButtonPrimarySC
            onClick={() => {
              oCashCost > oCashAmountAvailable
                ? openOCashModal(dispatch)
                : setView('confirmation');
            }}
            // TODO: re-enable and uncomment
            // disabled={true}
            disabled={oTreatAmount <= 0}
            style={{ margin: '16px 0px' }}
            className={oCashCost > oCashAmountAvailable ? 'green' : ''}
          >
            {isLoading ? (
              <SvgSpinner
                height={30}
                width={30}
                style={{ display: 'inline', fill: 'white' }}
              />
            ) : oTreatAmount <= 0 ? (
              'Select Quantity'
            ) : oCashCost > oCashAmountAvailable ? (
              'Insufficient ōCash, buy more!'
            ) : (
              'Continue'
            )}
          </ButtonPrimarySC>
        </div>
      </>
    ),
    [setView, handleOTreatPackageClick],
  );

  const confirmation = useMemo(
    () => (
      <>
        <div className="header">
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            Confirm Purchase
          </Text>
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <div className="body">
          <Text variant={TextVariant.CardText} className="gray">
            Current Balance:{' '}
            <span style={{ fontWeight: 700 }} className="blue">
              {numberToLocaleString(oCashAmountAvailable)} ōCash
            </span>
          </Text>
          <br />
          <Text variant={TextVariant.CardText} className="gray">
            You are about to buy {oTreatAmount}{' '}
            {oTreatAmount > 1 ? 'ōTreats' : 'ōTreat'} for {oCashCost} ōCash, is
            that correct?
          </Text>

          <Row>
            <Text style={{ marginRight: '0.25rem' }}>Cost: </Text>
            <Text className="blue" style={{ fontWeight: 700 }}>
              {oCashCost} ōCash
            </Text>
          </Row>

          <ButtonPrimarySC
            onClick={() => handleOTreatOrder()}
            disabled={oCashCost > oCashAmountAvailable || isLoading}
            style={{ margin: '16px 0px' }}
          >
            {isLoading ? (
              <SvgSpinner
                height={30}
                width={30}
                style={{ display: 'inline', fill: 'white' }}
              />
            ) : (
              'Confirm Purchase'
            )}
          </ButtonPrimarySC>
          <ButtonOutlineSC
            onClick={() => {
              setView('init');
            }}
          >
            No, take me back!
          </ButtonOutlineSC>
        </div>
      </>
    ),
    [handleOTreatOrder, setView],
  );

  return (
    <ReactModal
      isOpen={type === 'oTreatModal'}
      {...defaultModalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC>
        {view === 'init' && init}
        {view === 'confirmation' && confirmation}

        <TermsFooter noPreorder={true} />
      </ModalSC>
    </ReactModal>
  );
};

export type TTreatBagTypes = {
  size: number;
};

export const TreatBag = styled.img<TTreatBagTypes>`
  height: 100px;
  width: 100px;
  margin: auto;
  text-align: center;

  @media only screen and (max-width: 776px) {
    height: 50px;
    width: 50px;
  }
`;

export default OTreatModal;
