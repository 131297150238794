import React, { useCallback } from 'react';
import Confetti from 'react-confetti';
import { useDispatch, useSelector } from 'react-redux';
import numeral from 'numeral';

import { ButtonColorSC, StyledLinkBtn } from '@components/Button.styled';
import Text, { TextVariant } from '@components/Text';
import { USD_NUMERAL_FMT } from '@utils/common';

import { TRootState } from '@store/index';
import orderStore from '@store/order';
import CartSubtotal from './components/CartSubtotal';
import TermsFooter from './components/TermsFooter';
import { EPurchaseProcessSteps } from '@components/Modal/CheckoutProcess/PurchaseModal';

const DiscountInfoModal: React.FunctionComponent = () => {
  const order = useSelector((store: TRootState) => store.order);
  const dispatch = useDispatch();

  const handleGoBack = useCallback(() => {
    dispatch(
      orderStore.setOrderStep({
        step: EPurchaseProcessSteps.PurchaseORouterInit,
      }),
    );
  }, []);

  const handleApplyDiscountClick = useCallback(() => {
    dispatch(
      orderStore.setOrderStep({
        step: EPurchaseProcessSteps.ShippingAddress,
      }),
    );
  }, []);

  return (
    <div className="body">
      <Text style={{ marginBottom: 16 }} variant={TextVariant.CardText}>
        You are eligible to receive the following discount{' '}
        <span style={{ fontWeight: 600 }}>on each ōRouter</span>.
      </Text>
      <Text variant={TextVariant.ColorText}>
        - {numeral(order.discount).format(USD_NUMERAL_FMT)}
      </Text>
      <Text
        className="gray"
        variant={TextVariant.Disclaimer}
        style={{ paddingTop: 16 }}
      >
        * The country and region entered must match your shipping address at
        checkout in order to be eligible.
      </Text>
      <CartSubtotal type="discount" style={{ marginBottom: 16 }} />
      <ButtonColorSC onClick={handleApplyDiscountClick}>
        Apply discount {'&'} add to cart
      </ButtonColorSC>
      <hr />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledLinkBtn onClick={handleGoBack}>Go back</StyledLinkBtn>
      </div>
      <TermsFooter />
      <Confetti run={true} numberOfPieces={100} />
    </div>
  );
};

export default DiscountInfoModal;
