import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { animated } from 'react-spring';
import Bounce from 'react-reveal/Bounce';

import { CardFlipRevealSC } from './CardFlipReveal.styled';
import Card from './components/Card';
import {
  getBoosterImgFromRarity,
  getColorByRarity,
  getRarityName,
} from '@utils/common';
import mediumOcashPng from '@assets/png/treasure/medium-ocash.png';
import olandSqVideo from '@assets/animations/oland-sq.mp4';
import UltimateRollCard from './components/UltimateRollCard';
import { useAnimatedValue } from '@hooks/useAnimatedValue';
import Text, { TextVariant } from '@components/Text';
import { collections } from '../../PrizeCarousel/collections';
import { TRootState } from '@store/index';
import chestSlice from '@store/chest';
import { chests } from '@components/Modal/CheckoutProcess/SelectChestPhase';

const CardFlipReveal: FC = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const {
    oCashReceivedFromChest,
    oLandPlot,
    chestReceived,
    boosterRarity,
    activeFlipCardIndex,
    oCashFlipped,
    chestFlipped,
    oLandFlipped,
    boosterFlipped,
    matchedPrize,
  } = useSelector((state: TRootState) => state.chest);

  const dispatch = useDispatch();

  const { setOCashFlipped } = chestSlice;

  const chestType = useSelector(
    (store: TRootState) => store.userInterface.chestType,
  );

  const chest = chests.filter((chest) => {
    if (chest.type === chestType) {
      return chest;
    }
  });

  useEffect(() => {
    dispatch(setOCashFlipped({ oCashFlipped: false }));
    const timer = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % collections.length);
    }, 500);
    return () => clearInterval(timer);
  }, []);

  const animatedResult = useAnimatedValue({
    value: oCashReceivedFromChest,
    midReward: chest[0].midReward,
    delayTime: 2500,
    duration1: 4000,
    duration2: 1000,
  });

  const renderActiveLoot = (activeIndex: number) => {
    switch (activeIndex) {
      case 0:
        return (
          <>
            <img
              src={mediumOcashPng}
              alt="ocash"
              style={{ filter: 'drop-shadow(0px 0px 6px #fbf7c6)' }}
            />
            <Text
              variant={TextVariant.CardSubtitle}
              className="chest-reward-figure popIn carousel-balance"
            >
              <span>
                {
                  <animated.span>
                    {animatedResult.val.to((val) =>
                      val.toLocaleString('en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }),
                    )}
                  </animated.span>
                }{' '}
                <span className="smaller">ōCash</span>
              </span>
            </Text>
          </>
        );
      case 1:
        return (
          <>
            <Bounce>
              <img
                src={chest[0].img}
                alt="chest"
                style={{ filter: `drop-shadow(0px 0px 6px ${chest[0].color})` }}
              />
              <h5>{`${chest[0].tier} Chest for ${chestReceived}`}</h5>
            </Bounce>
          </>
        );
      case 2:
        return (
          <>
            <Bounce>
              <img
                src={getBoosterImgFromRarity(boosterRarity)}
                alt="booster"
                style={{
                  filter: `drop-shadow(0px 0px 6px ${getColorByRarity(
                    boosterRarity as string,
                  )})`,
                }}
              />
              <h5>{getRarityName(boosterRarity as string)} Booster</h5>
            </Bounce>
          </>
        );
      case 3:
        return (
          <>
            <Bounce>
              <video
                width="auto"
                className="carousel-img"
                autoPlay={true}
                loop={true}
                playsInline={true}
                preload={'auto'}
                muted
                style={{ borderRadius: 8 }}
              >
                <source src={olandSqVideo} type="video/mp4" />
              </video>
              <h5>ōLand #{oLandPlot}</h5>
            </Bounce>
          </>
        );
      case 4:
        return (
          <>
            <Bounce>
              <img
                src={
                  matchedPrize
                    ? matchedPrize.image
                    : collections[imageIndex].image
                }
                alt="Collection item"
              />
              <h5 className={matchedPrize ? `matched-collection` : `gray`}>
                {matchedPrize
                  ? matchedPrize.collection
                  : 'Better luck next time!'}
              </h5>
            </Bounce>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <CardFlipRevealSC>
      <div className="active-item">{renderActiveLoot(activeFlipCardIndex)}</div>
      <h4>Reveal rewards</h4>
      <div className="reveal-cards">
        <Card
          isFlipped={oCashFlipped}
          image={mediumOcashPng}
          color={'#fbf7c6'}
          cardIndex={0}
        />
        <Card
          isFlipped={chestFlipped}
          image={chest[0].img}
          color={chest[0].color}
          firstBadge={chestReceived}
          secondBadge={chestReceived}
          cardIndex={1}
        />
        {boosterRarity && (
          <>
            <Card
              isFlipped={boosterFlipped}
              image={getBoosterImgFromRarity(boosterRarity)}
              color={getColorByRarity(boosterRarity)}
              cardIndex={2}
            />
            <Card
              isFlipped={oLandFlipped}
              image={getBoosterImgFromRarity(boosterRarity)}
              cardIndex={3}
            />
          </>
        )}
        <UltimateRollCard cardIndex={4} />
      </div>
    </CardFlipRevealSC>
  );
};

export default CardFlipReveal;
