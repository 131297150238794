import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AUTH_STATE_INIT,
  AUTH_STATE_SUCCESS,
  TAuthState,
  TSignIn,
} from './types';

const defaultState: TAuthState = {
  state: AUTH_STATE_INIT,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    signIn: (
      state: TAuthState,
      action: PayloadAction<TSignIn>,
    ): TAuthState => ({
      ...state,
      state: AUTH_STATE_SUCCESS,
      user: {
        id: action.payload.id,
        daoRanking: action.payload.daoRanking,
        affiliateId: action.payload.affiliateId,
      },
    }),
    signOut: (): TAuthState => defaultState,
  },
});

export default authSlice;
