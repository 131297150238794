import Text, { TextVariant } from '@components/Text';
import userInterface from '@store/user-interface';
import api from '@utils/api';
import {
  getRQKBoosters,
  getRQKProfileKey,
  getRQKUserKeys,
} from '@utils/rq-utils';
import React, { useRef } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { OpenChestSC } from '../Modal.styled';
import { EKeysModalPhase } from './OcashChestModal';
import Loader from '@components/Loader';
import { TRootState } from '@store/index';
import { KeyAndOcashBalances, chests } from './SelectChestPhase';
import ConfettiParticles from './components/ConfettiParticles';
import MakeItRain from './components/MakeItRain';
import PrizeCarousel from './components/PrizeCarousel';
import { stopAudio } from './components/ChestOpening/openChestSoundEffects';
import OpenChestButton from './components/ChestOpening/OpenChestButton';
import UltimatePrizeOverlay from './components/ChestOpening/UltimatePrizeOverlay';
import collectLootSfx from '@assets/sfx/keys/inventory-grab.mp3';
import CardFlipReveal from './components/ChestOpening/CardFlipReveal';
import chestSlice from '@store/chest';
const collectLootSound = new Audio(collectLootSfx);

const OpenChestPhase: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { isLoading: isOCashDataLoading, data: oCashAmountData } = useQuery(
    getRQKProfileKey('getOCashAmount'),
    async () => (await api.getOCashAmount()).data.data,
  );
  const { isLoading: isKeysLoading, data: userKeyData } = useQuery(
    getRQKUserKeys('getUserKeys'),
    async () => (await api.getUserKeys()).data.data,
  );

  const {
    isChestOpen,
    oCashReceivedFromChest,
    showBigWinText,
    ultimatePrize,
    prizeChecked,
    prizeLoading,
    matchedPrize,
    showUltimateOverlay,
  } = useSelector((state: TRootState) => state.chest);

  const chestType = useSelector(
    (store: TRootState) => store.userInterface.chestType,
  );

  const handleResetRewardState = () => {
    dispatch(chestSlice.resetState());
    stopAudio();
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }
  };

  // User's available Booster balances
  const { isLoading: boosterBalanceIsLoading } = useQuery(
    getRQKBoosters('getBoosters'),
    async () => {
      const result = (await api.getBoosters()).data.data.boosters;
      return result.available;
    },
  );

  if (isKeysLoading || isOCashDataLoading) {
    return <Loader />;
  }

  const chest = chests.filter((chest) => {
    if (chest.type === chestType) {
      return chest;
    }
  });

  const videoRef = useRef<HTMLVideoElement>(null);

  const big =
    oCashReceivedFromChest >= chest[0].midReward &&
    oCashReceivedFromChest < chest[0].upperReward;

  const jackpot = oCashReceivedFromChest >= chest[0].upperReward;

  if (boosterBalanceIsLoading) {
    return <Loader />;
  }

  return (
    <OpenChestSC color="#FBF7C6" className={isChestOpen ? 'winner' : ''}>
      <KeyAndOcashBalances
        oCashBal={oCashAmountData.oCashAmountAvailable}
        availableKeys={userKeyData.available.length}
      />
      {/* Super prize pool carousel */}
      <PrizeCarousel />
      <div className="open-chest-main">
        {/* Play cash shower in the event of a big win */}
        {big && <MakeItRain />}

        {/* If the chest has been opened render rewards display */}
        {isChestOpen && (
          <>
            <div className="chest-reward-container">
              {/* If reward is a jackpot, play the coins animation */}
              {jackpot && <ConfettiParticles />}

              {/* Display text of win type to the user first, then show rewards received */}
              {showBigWinText ? (
                <Text
                  variant={TextVariant.CardSubtitle}
                  className={
                    showBigWinText
                      ? 'chest-reward-figure popIn'
                      : 'chest-reward-figure popOut'
                  }
                >
                  <span>{jackpot ? `JACKPOT!!!` : 'BIG WIN'}</span>
                </Text>
              ) : (
                <>
                  {/* Render ultimate overlay if user received a reward from the prize pool after clicking the UltimateRollButton button below */}
                  {prizeChecked &&
                    ultimatePrize &&
                    matchedPrize &&
                    showUltimateOverlay && <UltimatePrizeOverlay />}

                  {/* Render the card flip reveal interface */}
                  <CardFlipReveal />

                  <div>
                    {/* If the user has checked their ultimate prizes via the UltimateRollButton then render the collect loot button (resets state to open more chests), otherwise the ultimate roll button is shown*/}
                    <button
                      className="blue-btn"
                      disabled={!prizeChecked || prizeLoading}
                      onClick={async () => {
                        handleResetRewardState();
                        collectLootSound.play();
                      }}
                    >
                      Collect Loot
                    </button>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        <div className="controls">
          <div className="value" style={{ width: 'auto' }}>
            Type: {chestType}
          </div>
          <button
            className="buy-keys-btn"
            onClick={() => {
              dispatch(
                userInterface.setKeysModalStage({
                  keysModalStage: EKeysModalPhase.SelectChest,
                }),
              );
            }}
          >
            <Text variant={TextVariant.CardText}>Change Type</Text>
          </button>
        </div>
        <OpenChestButton videoRef={videoRef} />
      </div>
    </OpenChestSC>
  );
};

export default OpenChestPhase;
