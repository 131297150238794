import React, { useEffect } from 'react';
import { ThemeButtonSC } from './ThemeButton.styled';
import { ReactComponent as SvgNightIcon } from '@assets/svg/icons/night-theme.svg';
import { ReactComponent as SvgDayIcon } from '@assets/svg/icons/day-theme.svg';
import { TRootState } from '@store/index';
import {
  COLOR_THEME_DARK,
  COLOR_THEME_LIGHT,
  TColorTheme,
} from '@store/user-interface/types';
import { useDispatch, useSelector } from 'react-redux';
import userInterface from '@store/user-interface';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';

const ThemeButton: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { setColorTheme } = userInterface;

  const colorTheme = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.colorTheme,
  ) as TColorTheme;

  const isDark = colorTheme === COLOR_THEME_DARK;

  const handleBtnClick = () => {
    dispatch(
      setColorTheme({
        colorTheme: isDark ? COLOR_THEME_LIGHT : COLOR_THEME_DARK,
      }),
    );
  };

  useEffect(() => {
    const mq = window.matchMedia('(prefers-color-scheme: dark)');
    dispatch(
      setColorTheme({
        colorTheme:
          mq.media === '(prefers-color-scheme: light)'
            ? COLOR_THEME_LIGHT
            : COLOR_THEME_DARK,
      }),
    );
  }, []);

  return (
    <Tippy
      {...defaultTippyProps}
      content={`Switch to ${isDark ? 'Light Mode' : 'Dark Mode'}`}
      delay={400}
    >
      <ThemeButtonSC onClick={handleBtnClick}>
        {isDark ? <SvgDayIcon /> : <SvgNightIcon />}
      </ThemeButtonSC>
    </Tippy>
  );
};

export default ThemeButton;
