import Text, { TextVariant } from '@components/Text';
import React from 'react';
import { ProgressSC } from '../OgemsDashboard.styled';
import { getRQKBoosterPhase, queryClient } from '@utils/rq-utils';
import ProgressTier from './ProgressTier';

export type TOgemDashboardComponentProps = {
  oGemData: any;
  isLoading: boolean;
};

export const convertTierToNumber = (tier: string) => {
  switch (tier) {
    case 'bronze':
      return 1;
    case 'silver':
      return 2;
    case 'gold':
      return 3;
    case 'diamond':
      return 4;
    default:
      return 0;
  }
};

const tiers = [
  { type: 'Bronze', threshold: 7000000 },
  { type: 'Silver', threshold: 8000000 },
  { type: 'Gold', threshold: 10000000 },
  { type: 'Diamond', threshold: 20000000 },
];

const ProgressBar: React.FunctionComponent<
  TOgemDashboardComponentProps
> = () => {
  const boosterPhase = queryClient.getQueryData(
    getRQKBoosterPhase('getBoosterPhase'),
  ) as any;

  const userRank = convertTierToNumber(boosterPhase.rank);

  return (
    <ProgressSC>
      <div className="header">
        <Text variant={TextVariant.CardTitle} className="title">
          Your Progress
        </Text>
      </div>
      <div className="progress">
        <div className="progress-bar-labels">
          {tiers.map((tier, index) => {
            return (
              <ProgressTier
                key={index}
                isLocked={userRank < index + 1}
                threshold={tier.threshold}
                type={tier.type}
              />
            );
          })}
        </div>
      </div>

      <div className="information">
        <Text variant={TextVariant.CardText} className="body-text">
          ōGems are an experimental rewards point designed to provide exclusive
          access to the most active users within the ōLand ecosystem. They are
          not a non-tradable asset and have no speculative value.
        </Text>
        <div className="tier-grid">
          {/* TODO: replace with actual thresholds once finalized */}
          <Text variant={TextVariant.CardText} className="minor-title header">
            Tiers
          </Text>
          {tiers.map((tier, index) => {
            return (
              <Text
                key={index}
                variant={TextVariant.CardText}
                className="body-text"
              >
                {tier.type}:{' '}
                <span className="highlight">
                  💎 {tier.threshold.toLocaleString('en-US')}
                </span>
              </Text>
            );
          })}
        </div>
      </div>
    </ProgressSC>
  );
};

export default ProgressBar;
