import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInfiniteQuery, useQuery } from 'react-query';
import StyedPositionGrid, { PositionsGridSC } from './PositionsGrid.styled';
import api, {
  defaultSearchPositionParams,
  POSITIONS_PAGE_SIZE,
} from '@utils/api';
import GenericPosition from '@pages/Home/components/PositionCard/GenericPosition';
import {
  getRQKAuctionsKey,
  getRQKUserRewardsKey,
  getRQKUsersAuctionsKey,
} from '@utils/rq-utils';
import { ButtonOutlineSC } from '@components/Button.styled';
import RarityFilter from '@components/RarityFIlter';
import { defaultRarityFilterOptions } from '@components/RarityFIlter/RarityFilter';
import CustomerIoButton from '@components/PositionsHeader/CustomerIoButton';
import { TRootState } from '@store/index';
import { useSelector } from 'react-redux';
import { TActiveBooster } from '@pages/Home/components/PositionCard/types';
import { LoadingStatePositionSC } from '@pages/Home/components/PositionCard/StyledTrayFrame.styled';

const PositionsGrid = () => {
  const navigate = useNavigate();
  const [rarityFilter, setRarityFilter] = useState(
    defaultRarityFilterOptions.map((e) => e.value),
  );

  const handleRarityChange = (opts) => {
    setRarityFilter(opts);
  };

  const { isLoading: userAuctionsLoading } = useQuery(
    getRQKUsersAuctionsKey('getUserRunningAuctions'),
    async () => {
      const result = (await api.getUserRunningAuctions()).data.data;
      return result;
    },
  );

  const { isLoading: blockedItemsAreLoading } = useQuery(
    getRQKAuctionsKey('getBlockedItems'),
    async () => {
      const result = (await api.getBlockedItems()).data.data;
      return result;
    },
  );

  const activeBooster = useSelector(
    (store: TRootState) => store.userInterface.activeBooster,
  ) as TActiveBooster;

  const { isLoading, data, fetchNextPage } = useInfiniteQuery(
    ['infinite-positions', rarityFilter],
    async ({ pageParam }) => {
      return (
        await api.getPositions(
          !pageParam
            ? {
                ...defaultSearchPositionParams,
                searchBy: {
                  ...defaultSearchPositionParams.searchBy,
                  rarity: rarityFilter,
                },
              }
            : pageParam,
        )
      ).data;
    },
    {
      getNextPageParam: (lastResult) => {
        const { pagination } = lastResult.data;
        if (pagination.offset + POSITIONS_PAGE_SIZE > pagination.total) {
          return undefined;
        }
        return {
          ...defaultSearchPositionParams,
          pagination: {
            offset: lastResult.data.pagination.offset + POSITIONS_PAGE_SIZE,
            limit: POSITIONS_PAGE_SIZE,
          },
        };
      },
    },
  );

  const { data: availableTreats } = useQuery(
    getRQKUserRewardsKey('getAvailableUserRewards'),
    async () => {
      const result = await api.getUserRewards();
      const availableTreats = result.data.data.available;
      const filteredOTreats = availableTreats.map((oTreat) => oTreat.id);
      return filteredOTreats;
    },
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 200; // Fetch next page when 200px from the bottom
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - scrollThreshold
      ) {
        fetchNextPage();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [fetchNextPage]);

  return (
    <div className="tray" style={{ overflowY: 'auto', maxWidth: '100vw' }}>
      <PositionsGridSC>
        <div className="grid-buttons">
          <ButtonOutlineSC
            style={{ minWidth: 120 }}
            onClick={() => navigate('/artifacts')}
          >
            All Upgrades
          </ButtonOutlineSC>
          {process.env.REACT_APP_AUCTIONS_ENABLED === 'true' && (
            <ButtonOutlineSC
              style={{ minWidth: 120 }}
              onClick={() => navigate('/auctions')}
            >
              Auction Results
            </ButtonOutlineSC>
          )}
          <CustomerIoButton />
          <ButtonOutlineSC
            style={{ minWidth: 120 }}
            onClick={() => navigate('/chests')}
          >
            Chest Inventory
          </ButtonOutlineSC>
        </div>

        <div className="rarity-wrapper">
          <RarityFilter values={rarityFilter} onChange={handleRarityChange} />
        </div>
      </PositionsGridSC>

      <div className="tray-wrapper">
        <StyedPositionGrid positions={300}>
          {/* TODO SWAMP: Uncomment later when needed */}
          {/* <SwampCard /> */}

          {isLoading || userAuctionsLoading || blockedItemsAreLoading
            ? [...Array(4)].map((_, index) => (
                <LoadingStatePositionSC key={index} />
              ))
            : data?.pages.map((page) =>
                page.data.results.map((item) => (
                  <GenericPosition
                    id={item.id as string}
                    key={item.id as string}
                    activeBooster={activeBooster}
                    toggleGlobalInventoryOn={true}
                    userHasTreats={
                      availableTreats === undefined
                        ? false
                        : availableTreats.length > 0
                    }
                  />
                )),
              )}
        </StyedPositionGrid>
      </div>
    </div>
  );
};

const memoizedPositionsGrid = React.memo(PositionsGrid);

export default memoizedPositionsGrid;
