import React from 'react';

import Text from '@components/Text';
import oCashIcon from '@assets/png/ocash-paper.png';

import BtnBadgeSC from './Badge.styled';

export type TBtnBadgeProps = {
  qty: number;
  showBadge?: boolean;
};

const BtnBadge: React.FC<TBtnBadgeProps> = ({ qty }) => (
  <BtnBadgeSC>
    <Text style={{ fontSize: 10 }}> +</Text>{' '}
    <Text style={{ fontSize: 10 }}> {qty} </Text>
    <img src={oCashIcon} alt="ōCash Icon" />
  </BtnBadgeSC>
);

export default BtnBadge;
