import React from 'react';

import { ButtonOutlineSC } from '@components/Button.styled';
import StyledConnectButton from './ConnectButton.styled';
import BtnBadge from '../Badge/Badge';

export type TConnectBtnProps = {
  qty: number;
  showBadge?: boolean;
  children: React.ReactNode;
  onClick: VoidFunction;
  disabled?: boolean;
};

const ConnectButton: React.FC<TConnectBtnProps> = ({
  qty,
  showBadge,
  onClick,
  children,
  disabled,
}) => {
  return (
    <StyledConnectButton>
      <ButtonOutlineSC
        className="connect"
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </ButtonOutlineSC>
      {showBadge && <BtnBadge qty={qty} showBadge={showBadge} />}
    </StyledConnectButton>
  );
};

export default ConnectButton;
