import React from 'react';
import { useSelector } from 'react-redux';
import { TRootState } from '@store/index';
import Row, { GapSizes, JustificationTypes } from '@components/Row';
import Text, { TextVariant } from '@components/Text';
import Checkbox from '@components/Checkbox';

export type TPaymentConditions = {
  termsAgreed: boolean;
  marketingOptIn: boolean;
  onTermsAgreement: () => void;
  onMarketingOpt: () => void;
};

const PaymentConditions = (props: TPaymentConditions) => {
  const { termsAgreed, marketingOptIn, onTermsAgreement, onMarketingOpt } =
    props;

  const order = useSelector((store: TRootState) => store.order);

  return (
    <>
      <Row
        gapSize={GapSizes.Md}
        justify={JustificationTypes.SpaceBetween}
        style={{
          marginTop: 16,
          marginBottom: 16,
          alignItems: 'center',
        }}
      >
        <Text variant={TextVariant.CardText}>
          I have read and agree to the{' '}
          {order.purchaseProductType !== 'oRouter' && (
            <>
              <a
                href="https://docs.overline.network/docs/amcegr-pre-order-agreement"
                target="_blank"
                rel="noopener noreferrer"
              >
                Preorder Agreement
              </a>{' '}
              and{' '}
            </>
          )}
          <a
            href="https://docs.overline.network/docs/amcegr-terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms {'&'} Conditions
          </a>
        </Text>
        <Checkbox checked={termsAgreed} onClick={onTermsAgreement} />
      </Row>
      <Row
        gapSize={GapSizes.Md}
        justify={JustificationTypes.SpaceBetween}
        style={{
          marginTop: 16,
          marginBottom: 32,
          alignItems: 'center',
        }}
      >
        <Text variant={TextVariant.CardText}>
          Send me marketing and promotional content (optional)
        </Text>
        <Checkbox checked={marketingOptIn} onClick={onMarketingOpt} />
      </Row>
    </>
  );
};

export default PaymentConditions;
