import React, { useCallback } from 'react';
import ReactModal from 'react-modal';
import styled, { keyframes } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import boosterAnim from '@assets/animations/booster-anim.mp4';
import { TRootState } from '@store/index';
import userInterface from '@store/user-interface';
import ModalSC from './Modal.styled';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { defaultModalProps } from './modal-settings';
import { ButtonPrimarySC } from '@components/Button.styled';
import olandSqVideo from '@assets/animations/oland-sq.mp4';

export type TDummyCardProps = {
  glow: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const DummyCard: React.FunctionComponent<TDummyCardProps> = ({
  glow,
}) => {
  return (
    <DummyCardSC className={glow ? 'glow' : 'regular'}>
      <div>
        <video
          width="auto"
          height="auto"
          autoPlay={true}
          loop={true}
          playsInline={true}
          preload={'auto'}
          muted
        >
          <source src={olandSqVideo} type="video/mp4" />
        </video>
      </div>
      <DummyCardTextSC />
      <div style={{ display: 'flex', gap: 8, justifyContent: 'space-between' }}>
        <DummyCardTextSC />
        <DummyCardTextSC />
      </div>
    </DummyCardSC>
  );
};

const GetBoostedModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { setModal } = userInterface;

  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const handleCloseModal = useCallback(() => {
    dispatch(setModal({ type: null, token: null }));
  }, []);

  return (
    <ReactModal
      isOpen={type === 'getBoostedModal'}
      {...defaultModalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC style={{ position: 'relative' }} className="booster">
        <button onClick={handleCloseModal} className="close">
          <img src={SvgCross} alt="cross-btn" />
        </button>
        <div className="body" style={{ textAlign: 'center' }}>
          <Text style={{ fontWeight: 500 }} variant={TextVariant.CardTitle}>
            Get boosted!
          </Text>
          <Text variant={TextVariant.CardText} style={{ margin: '12px 0px' }}>
            🟥 🟩 🟨
          </Text>
          <Text variant={TextVariant.CardText} className="dgray">
            Boosters{' '}
            <span style={{ textDecoration: 'underline', fontWeight: 600 }}>
              permanently{' '}
            </span>
            change your ōLand.
            <br />
            Boosters are randomly assigned rarity values of{' '}
            <span
              style={{
                fontWeight: 500,
              }}
            >
              Rare (🟥)
            </span>
            ,{' '}
            <span
              style={{
                fontWeight: 500,
              }}
            >
              Super Rare (🟩)
            </span>
            , or{' '}
            <span
              style={{
                fontWeight: 500,
              }}
            >
              Ultra Rare (🟨)
            </span>
            .
          </Text>
          <AnimationContainerSC>
            <div>
              <video
                autoPlay={true}
                loop={true}
                playsInline={true}
                preload={'auto'}
                muted
                style={{ width: '100%' }}
              >
                <source src={boosterAnim} type="video/mp4" />
              </video>
            </div>
            <Text variant={TextVariant.CardSubtitle}>+</Text>
            <DummyCard glow={false} />
            <Text variant={TextVariant.CardSubtitle}>=</Text>
            <DummyCard glow={true} />
          </AnimationContainerSC>
          <ButtonPrimarySC
            style={{ margin: '12px 0px' }}
            onClick={() => window.location.replace('/app/ogems')}
          >
            Get Boosted
          </ButtonPrimarySC>
        </div>
      </ModalSC>
    </ReactModal>
  );
};

const glowAnimation = keyframes`
    0% {
    border-color:red;
    -webkit-box-shadow: 0px 0px 64px 8px rgba(255, 0, 0, 0.9);
    -moz-box-shadow: 0px 0px 64px 8px rgba(255, 0, 0, 0.9);
    box-shadow: 0px 0px 64px 8px rgba(255, 0, 0, 0.9);
    }

    33%{
    border-color:red;
    -webkit-box-shadow: 0px 0px 64px 8px rgba(255, 0, 0, 0.9);
    -moz-box-shadow: 0px 0px 64px 8px rgba(255, 0, 0, 0.9);
    box-shadow: 0px 0px 64px 8px rgba(255, 0, 0, 0.9);
    }
    
    35%{
    border-color:green;
    -webkit-box-shadow: 0px 0px 64px 8px rgba(0, 255, 0, 0.9);
    -moz-box-shadow: 0px 0px 64px 8px rgba(0, 255, 0, 0.9);
    box-shadow: 0px 0px 64px 8px rgba(0, 255, 0, 0.9);
    }

    63%{
    border-color:green;
    -webkit-box-shadow: 0px 0px 64px 8px rgba(0, 255, 0, 0.9);
    -moz-box-shadow: 0px 0px 64px 8px rgba(0, 255, 0, 0.9);
    box-shadow: 0px 0px 64px 8px rgba(0, 255, 0, 0.9);
    }
    
   65% {
    border-color:yellow;
    -webkit-box-shadow: 0px 0px 64px 8px rgba(255, 221, 0, 0.9);
    -moz-box-shadow: 0px 0px 64px 8px rgba(255, 221, 0, 0.9);
    box-shadow: 0px 0px 64px 8px rgba(255, 221, 0, 0.9);
    }

    97%{
        border-color:yellow;
        -webkit-box-shadow: 0px 0px 64px 8px rgba(255, 221, 0, 0.9);
        -moz-box-shadow: 0px 0px 64px 8px rgba(255, 221, 0, 0.9);
        box-shadow: 0px 0px 64px 8px rgba(255, 221, 0, 0.9);
    }

    100%{
        border-color:red;
        -webkit-box-shadow: 0px 0px 64px 8px rgba(255, 0, 0, 0.9);
        -moz-box-shadow: 0px 0px 64px 8px rgba(255, 0, 0, 0.9);
        box-shadow: 0px 0px 64px 8px rgba(255, 0, 0, 0.9);
    }
`;

const DummyCardSC = styled.div`
  border: 2px solid;
  border-radius: 8px;
  padding: 6px;
  width: 100%;
  background: white;

  video {
    width: 100%;
    border-radius: 6px;
  }

  &.glow {
    animation: ${glowAnimation} 16s infinite;
  }

  &.regular {
    border-color: ${({ theme }) => theme.input.border};
  }
`;

const DummyCardTextSC = styled.div`
  border-radius: 8px;
  background: gray;
  width: 100%;
  height: 12px;
  margin: 4px 0px;
  background: ${({ theme }) => theme.input.border};

  @media only screen and (max-width: 776px) {
    height: 8px;
  }
`;

const AnimationContainerSC = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 24px 1fr 24px 1fr;
  align-items: center;
  gap: 16px;
  margin: 24px 0px;

  @media only screen and (max-width: 776px) {
    gap: 8px;
    grid-cemplate-columns: 1fr 16px 1fr 16px 1fr;
  }
`;

export default GetBoostedModal;
