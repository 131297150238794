import styled, { keyframes } from 'styled-components';
import meshGradient from '@assets/png/mesh-gradient.png';
import { sheenAnimation } from '@pages/Home/components/PositionCard/StyledTrayFrame.styled';
import { colorThemes } from '@assets/styles';

export const AuctionSC = styled.div`
  padding: 24px;
  padding-right: 0;
  margin-top: 0.5rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.neutral.neutral4};
  position: relative;

  @media only screen and (max-width: 2559.98px) {
    .auction-bar {
      max-width: calc(100vw - 50px);
      position: relative;
    }
  }

  @media only screen and (max-width: 1439.98px) {
    max-width: 1440px;
  }

  @media only screen and (min-width: 2560px) {
    width: calc(100vw - 240px);
    max-width: 1440px;
  }

  .auction-bar {
    max-width: calc(100vw - 50px);
    position: relative;
    /* chrome and chromium based */
    .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
      display: none;
    }

    .react-horizontal-scrolling-menu--scroll-container {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  @media only screen and (max-width: 776px) {
    padding: 0px 0px 24px 0px;

    .auction-bar {
      max-width: 1415px;
      width: calc(100vw - 25px);
    }
  }

  .arrow-button {
    border-radius: 5px;
  }
`;

// Keyframes for live pulse animation

const pulseAnimation1 = keyframes`
0% {
    opacity: 0;
    transform: scale(0);
  }

  30% {
    opacity: 1;
    transform: scale(1.5);
  }

  60% {
    opacity: 1;
    transform: scale(2);
  }

  100% {
    opacity: 0;
    transform: scale(2);
  }
`;

const pulseAnimation2 = keyframes`
0% {
    transform: scale(1, 1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(6, 6);
    opacity: 0;
  }
`;

export const AuctionHeaderSC = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  //   Title Area

  .title {
    display: flex;
    gap: 1rem;
    align-items: center;
    h1 {
      margin-bottom: 0px;
    }
  }

  //   Pusle Animation

  .pulse {
    .core {
      background: ${({ theme }) => theme.green};
      height: 8px;
      width: 8px;
      animation: ${pulseAnimation1} 1.5s ease-in-out infinite;
      border-radius: 999px;
    }

    .radar {
      background: rgba(104, 204, 69, 0.35);
      animation: ${pulseAnimation2} 1.5s ease-in-out infinite;
    }
  }

  .auction-controls {
    display: flex;
    gap: 1rem;
    align-items: center;
    font-weight: 600;

      background: ${({ theme }) => theme.blue.blue100};
      display: flex;
      color: white;
      font-weight: 600;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 5px;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
`;

type TAuctionCardSCProps = {
  userIsTopBidder: boolean;
};
//
export const AuctionCardSC = styled.button<TAuctionCardSCProps>`
  width: 100%;
  width: 300px;
  border-radius: 10px;
  padding: 8px;
  background: ${({ theme }) => theme.background.regular.bg1};
  position: relative;
  overflow: hidden;
  transition: all 200ms ease-out 0s;
  border: 1.5px solid ${({ theme }) => theme.input.border};
  margin: 12px 0px 12px 12px;

  .land-animation {
    height: 150px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;

    .gen6,
    .notGen6 {
      height: 100%;
    }

    video {
      width: 100%;
    }
  }

  &#top-bid {
    background: none !important;

    .gray {
      color: ${({ theme }) => theme.text.main};
    }

    .dgray {
      color: ${({ theme }) => theme.text.main};
    }

    .price {
      color: ${({ theme }) => theme.text.main};
    }

    &::after {
      background: linear-gradient(
        45deg,
        rgba(224, 32, 32, 0.5),
        rgba(250, 100, 0, 0.5) 17%,
        rgba(247, 181, 0, 0.5) 33%,
        rgba(109, 212, 0, 0.5) 50%,
        rgba(0, 145, 255, 0.5) 67%,
        rgba(98, 54, 255, 0.5) 83%,
        rgba(182, 32, 224, 0.5)
      ) !important;
    }
  }

  :hover {
    opacity: 0.75;
    transform: translateY(-4px);

    img {
      scale: 1.15;
    }
  }

  &::after {
    content: '';
    position: absolute;
    background: ${colorThemes.dark.background};
    -webkit-animation: ${sheenAnimation} 6s ease-in-out infinite;
    animation: ${sheenAnimation} 6s ease-in-out infinite;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: -1;
    opacity: ${(props) => (props.userIsTopBidder ? '1' : `0`)};
  }

  img {
    width: fill-available;
    position: relative;
    transition: 0.3s;

    //:hover {
    //  transform: scale(1.2);
    //}
  }

  .power-score {
    position: absolute;
    top: 12px;
    left: 12px;
  }

  .price {
    color: ${({ theme }) => theme.blue.blue100};
  }

  .bid-input {
    max-height: 40px !important;
    border-radius: 5px 0px 0px 5px;
    transition: 0.3s;
  }

  .bid-button {
    border-radius: 0px !important;
    width: 50px !important;
    max-height: 40px !important;
    transition: 0.3s;
  }

  .bid-button-100 {
    width: 80px !important;
    border-radius: 0px 5px 5px 0px !important;
    font-weight: 600;
    max-height: 40px !important;
  }

  @media only screen and (max-width: 1024px) {
    width: 225px;
    .power-score {
      top: 8px;
      left: 8px;
    }

    .bid-input {
      max-height: 30px !important;
    }

    .bid-button {
      width: 40px !important;
      max-height: 30px !important;
    }

    .bid-button-100 {
      font-size: 0.6rem !important;
      max-height: 30px !important;
    }
  }

  //   Active Card

  &.active {
    background: ${(props) =>
      props.userIsTopBidder ? 'none' : 'rgba(36, 138, 255, 0.15)'};
  }

  //   Price Information
  .price-information {
    display: flex;
    justify-content: space-between;

    align-items: end;
  }

  // Top bidder label

  .top-bidder {
    padding: 8px;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-weight: 600;
    color: white;
    background: linear-gradient(
      to right,
      #f8ab5e 0,
      #f36961 20%,
      #a176c8 40%,
      #759beb 60%,
      #65beb3 80%,
      #70db96 100%
    );
  }

  // Power score
  .power-score {
    height: 25px;
    background: white;
    display: flex;
    gap: 8px;
    border-radius: 5px;
    border: 1.5px solid ${({ theme }) => theme.blue.blue100};
    color: ${({ theme }) => theme.blue.blue100};
    font-weight: 700;
    align-items: center;
    padding: 0px 8px;
  }

  .card-image {
    background-image: url(${meshGradient});
    max-height: 150;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    overflow: hidden;
  }
`;
