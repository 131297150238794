import * as Sentry from '@sentry/browser';
import Env from '@utils/env';
import { genCurrentTimeString } from '@utils/common';

// eslint-disable-next-line
const info = (...args: any[]): void => {
  if (Env.isDevelopment) {
    console.info(`[${genCurrentTimeString()}]`, ...args);
  }
};

// eslint-disable-next-line
const debug = (...args: any[]): void => {
  if (Env.isDevelopment) {
    console.log(`[${genCurrentTimeString()}]`, ...args);
  }
  if (Env.isSentryEnabled) {
    Sentry.captureException(args);
  }
};

// eslint-disable-next-line
const log = (...args: any[]): void => {
  if (Env.isDevelopment) {
    console.log(`[${genCurrentTimeString()}]`, ...args);
  }
  if (Env.isSentryEnabled) {
    Sentry.captureException(args);
  }
};

// eslint-disable-next-line
const warn = (...args: any[]): void => {
  if (Env.isDevelopment) {
    console.warn(`[${genCurrentTimeString()}]`, ...args);
  }
};

// eslint-disable-next-line
const error = (...args: any[]): void => {
  if (Env.isDevelopment) {
    console.error(`[${genCurrentTimeString()}]`, ...args);
  }
  if (Env.isSentryEnabled) {
    // SENTRY LOGIC
  }
};

export default {
  debug,
  info,
  log,
  warn,
  error,
};
