import React, { useState, useEffect, createRef } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { getHumanReadable } from '@utils/common';

import {
  createTsHandshakeMessage,
  getWsAddress,
  parseWebSocketMessage,
} from '@utils/ws';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import StyledGlobalFeed from './GlobalFeed.styled';

export enum EEventType {
  BoughtOCash,
  PurchaseOTreat,
  PlacedOTreat,
  SignedIn,
  SignedOut,
  FoundOFriend,
  RevealArtifact,
  AuctionCreated,
  AuctionWon,
}

export type TRMQMessage = {
  t: EEventType;
  i: string;
  m: any;
};

const interpretMessage = (message: TRMQMessage) => {
  const { t, i, m } = message;
  const dateTime = new Date().toLocaleTimeString();
  // we render text based on type

  switch (t) {
    case EEventType.BoughtOCash:
      return `${dateTime}: ōLander ${i} - 💸 upgrades land with ōCash`;
    case EEventType.PurchaseOTreat:
      return `${dateTime}: ōLander ${i} - 💸 claimed ōCash!`;
    case EEventType.PlacedOTreat:
      return `${dateTime}: ōLander ${i} - 🍬  placed ōTreats.`;
    case EEventType.SignedIn:
      return `${dateTime}: ōLander ${i} - ⛰️  ascended to ōLand.`;
    case EEventType.SignedOut:
      return `${dateTime}: ōLander ${i} - 🌎 returned to earth.`;
    case EEventType.RevealArtifact:
      return `${dateTime}: ōLander ${i} - 🏆 has discovered ${getHumanReadable(
        m,
      )} on their land`;
    case EEventType.FoundOFriend:
      return `${dateTime}: ōLander ${i} - 🎉🐕🎉 has found ōFriend #${m}! ${
        10000 - m
      } ōFriends remain roaming the lands.`;
    case EEventType.AuctionCreated:
      const createdData = JSON.parse(m);
      return `${dateTime}:${createdData.p} will start trading for ${createdData.a} ōCash`;
    case EEventType.AuctionCreated:
      const wonData = JSON.parse(m);
      return `${dateTime}:${wonData.p} sold for ${wonData.a} ōCash`;
    default:
      return 'No Message';
  }
};

type TMessage = {
  id: string;
  nodeRef: any;
  message: string;
};

const classes = ['first', 'second', 'third', 'fourth', 'fifth'];

const GlobalFeed = () => {
  const [messages, setMessages] = useState<TMessage[]>([]);

  const { lastMessage, readyState, sendMessage, getWebSocket } = useWebSocket(
    getWsAddress,
    {
      reconnectAttempts: 10,
      reconnectInterval: 3000,
      share: true,
    },
    true,
  );

  // Initialization
  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      // @ts-expect-error a function would be better, but it's up to them
      getWebSocket().binaryType = 'arraybuffer';
      sendMessage(createTsHandshakeMessage());
    }
  }, [readyState]);

  // Handle incoming message
  useEffect(() => {
    if (lastMessage !== null && lastMessage.data) {
      const parsed = parseWebSocketMessage(lastMessage.data);
      if (parsed) {
        const messageObj = {
          id: parsed.i + parsed.t.toString(),
          nodeRef: createRef(),
          message: interpretMessage(parsed),
        };

        setMessages([messageObj, ...messages.slice(0, 4)]);
      }
    }
  }, [lastMessage, setMessages]);

  return (
    <StyledGlobalFeed>
      <TransitionGroup className="event-list">
        {messages.map((f, idx) => (
          <CSSTransition
            key={f.id}
            nodeRef={f.nodeRef}
            timeout={500}
            classNames="item"
          >
            <div className={classes[idx]} ref={f.nodeRef}>
              <span className="feed-event-item">{f.message}</span>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </StyledGlobalFeed>
  );
};

export default GlobalFeed;
