import React, { useState, useEffect } from 'react';
import Select, { StylesConfig } from 'react-select';

export type TColourOption = {
  readonly value: string;
  readonly label: string;
};

export const colourOptions: readonly TColourOption[] = [
  {
    value: 'Material',
    label: '🟨 Material',
  },
  { value: 'Rare', label: '🟥 Rare' },
  {
    value: 'Super-Rare',
    label: '🟩 Super',
  },
  { value: 'Ultra-Rare', label: '🟨 Ultra' },
];

export const defaultRarityFilterOptions = [
  colourOptions[0],
  colourOptions[1],
  colourOptions[2],
  colourOptions[3],
];

const colourStyles: StylesConfig<TColourOption, true> = {
  control: (styles) => ({ ...styles, backgroundColor: 'transparent' }),
  option: (styles, { data }) => {
    const base = {
      ...styles,
      fontSize: 14,
      backgroundColor: 'transparent',

      ':active': {
        ...styles[':active'],
        color: 'rgba(32,129,226,1)',
        backgroundColor: 'transparent',
      },
    };

    if (data.value === 'Material') {
      return {
        ...base,
        filter: 'saturate(0%)',
      };
    }
    return base;
  },
  multiValue: (styles) => {
    return {
      ...styles,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 3,
      backgroundColor: 'transparent',
    };
  },
  multiValueLabel: (styles, { data }) => {
    const base = {
      ...styles,
      padding: '6px 8px',
      backgroundColor: 'transparent',
      fontSize: 14,
    };

    if (data.value === 'Material') {
      return {
        ...base,
        filter: 'saturate(0%)',
      };
    }
    return base;
  },
  multiValueRemove: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
  }),
};

type TRarityFilterProps = {
  values: string[];
  onChange: (opts: string[]) => void;
};

const RarityFilter: React.FC<TRarityFilterProps> = ({ onChange, values }) => {
  const [opts, setOpts] = useState<TColourOption[]>([]);

  useEffect(() => {
    // put options based on strings
    const newOpts: TColourOption[] = [];
    for (const value of values) {
      for (const cOpt of colourOptions) {
        if (cOpt.value === value) {
          newOpts.push(cOpt);
        }
      }
    }
    setOpts(newOpts);
  }, [values]);

  const handleChange = (options) => {
    setOpts(options);
    onChange(options.map((e) => e.value));
  };

  return (
    <Select
      closeMenuOnSelect={false}
      defaultValue={defaultRarityFilterOptions}
      classNamePrefix="filter-select"
      value={opts}
      isMulti
      onChange={handleChange}
      options={colourOptions}
      styles={colourStyles}
    />
  );
};

export default RarityFilter;
