import { useSpring } from 'react-spring';

interface UseAnimatedValueProps {
  value: number;
  midReward: number;
  delayTime: number;
  duration1: number;
  duration2: number;
}

export const useAnimatedValue = ({
  value,
  midReward,
  delayTime,
  duration1,
  duration2,
}: UseAnimatedValueProps) => {
  return useSpring({
    from: { val: 1 },
    to: { val: value },
    delay: value >= midReward ? delayTime : 0,
    config: {
      duration: value >= midReward ? duration1 : duration2,
    },
  });
};
