import React, { FC } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '@store/index';
import userInterfaceStore from '@store/user-interface';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { defaultModalProps } from '@components/Modal/modal-settings';
import ModalSC from '../../Modal.styled';
import { PlotActionsModalSC } from './PlotActionsModal.styled';
import PlotAction from './components/PlotAction';
import { getRQKPositionsKey } from '@utils/rq-utils';
import { useQueryClient } from 'react-query';

const PlotActionsModal: FC = () => {
  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const activePositionId = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.activePositionId,
  ) as string;

  const positionDetails = queryClient.getQueryData(
    getRQKPositionsKey('getPosition', activePositionId),
  ) as any;

  const handleCloseModal = () => {
    dispatch(
      userInterfaceStore.setModal({
        type: null,
        token: null,
      }),
    );
  };

  const currentUpgradeProcessTreatsAmount =
    positionDetails.positionUpgrades.find((pu) => pu.revealed === 0);

  return (
    <ReactModal
      isOpen={type === 'plotActionsModal'}
      {...defaultModalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC>
        <div className="header">
          <Text
            style={{ fontWeight: 500, textAlign: 'left', marginBottom: 16 }}
            variant={TextVariant.Subtitle}
          >
            ōLand Actions
          </Text>
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <PlotActionsModalSC className="body">
          <PlotAction
            currentUpgradeProcessTreatsAmount={
              currentUpgradeProcessTreatsAmount
                ? currentUpgradeProcessTreatsAmount.treatsPlaced
                : 0
            }
          />
        </PlotActionsModalSC>
      </ModalSC>
    </ReactModal>
  );
};

export default PlotActionsModal;
