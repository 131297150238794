import React from 'react';
import PropTypes from 'prop-types';
import PaginationSC from './Pagination.styled';
import Text, { TextVariant } from '@components/Text';

type TPaginationProps = {
  allRewards: number;
  unlocked: number;
  currentPage: number;
  totalPages: number;
  handleNextPage: (page: number) => void;
  handlePrevPage: (page: number) => void;
  isGen6: boolean;
};
const Pagination: React.FC<TPaginationProps> = ({
  currentPage,
  totalPages,
  handlePrevPage,
  handleNextPage,
  unlocked,
  allRewards,
  isGen6,
}) => {
  const baseStyles = { fontWeight: 700, textAlign: 'left' } as any;

  if (isGen6) {
    baseStyles.color = 'white';
  }
  return (
    <PaginationSC>
      <Text
        variant={TextVariant.CardText}
        style={baseStyles}
        className="unlock-counter"
      >
        {unlocked} / {allRewards} Unlocked
      </Text>

      <div
        className="button-container"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <button
          className="pagination-button"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handlePrevPage(currentPage);
          }}
          disabled={currentPage === 1}
        >
          &larr;
        </button>

        <span className="pagination-page-info">
          <Text
            variant={TextVariant.CardText}
            style={baseStyles}
            className="unlock-counter"
          >
            Page {currentPage}/{totalPages}
          </Text>
        </span>

        <button
          className="pagination-button"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleNextPage(currentPage);
          }}
          disabled={currentPage === totalPages}
        >
          &rarr;
        </button>
      </div>
    </PaginationSC>
  );
};

Pagination.propTypes = {
  unlocked: PropTypes.number.isRequired,
  allRewards: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handlePrevPage: PropTypes.func.isRequired,
  handleNextPage: PropTypes.func.isRequired,
};

export default Pagination;
