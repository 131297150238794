import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';

import api from '@utils/api';

import { getRQKProfileKey, getRQKTwitterAuth } from '@utils/rq-utils';

import Toast from '@components/Toast';

export default function AmazonCallback() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const accessToken = searchParams.get('access_token');

    // TODO: Handle Error
    if (!accessToken) {
      return;
    }

    (async () => {
      setIsLoading(true);

      // @ts-expect-error unknown structure
      const { email } = queryClient.getQueryData(
        getRQKProfileKey('getProfile'),
      );
      try {
        const response = (
          await api.postAmazonCallbackHandle({
            accessToken,
          })
        ).data;

        const { status, data } = response;

        if (status === 'ok') {
          if (data === email) {
            toast(
              <Toast
                type="success"
                header="Success!"
                body="We've granted you new GEN6 position"
              />,
            );
          } else {
            toast(
              <Toast
                type="success"
                header="Success!"
                body={`We've created new account for you, chceck your ${data} inbox`}
              />,
            );
          }
        }
      } catch (error: any) {
        toast(
          <Toast
            type="error"
            header="Error"
            body="We were not able to proceed the request. If you
              believe there is an error, please contact our support team."
          />,
        );
      } finally {
        await queryClient.invalidateQueries(
          getRQKTwitterAuth('getTwitterAuth'),
        );
        setIsLoading(false);
      }
    })();
  }, [location]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  navigate('/');
  return null;
}
