import React, { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import Text, { TextVariant } from '@components/Text';
import { colorThemes } from '@assets/styles';
import { AuctionCardSC } from '../Auction.styled';
import { ButtonPrimarySC } from '@components/Button.styled';
import { ReactComponent as SvgPower } from '@assets/svg/icons/power.svg';
import { InputSC } from '@components/Input.styled';
import Toast from '@components/Toast';
import { getRQKCoinGeckoKey } from '@utils/rq-utils';
import api from '@utils/api';
import { getHumanReadable } from '@utils/common';
import { allRewards } from 'data/gifts';
import olandVideo from '@assets/animations/oland.mp4';

const renderCardImage = (type) => {
  const artifact = allRewards.find((r) => r.id === type);
  if (artifact != undefined) {
    const image = artifact.img;
    return (
      <div className="card-image">
        <img src={image} style={{ maxHeight: 150, width: 'auto' }} />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: 150,
        height: 150,
        background: colorThemes.light.neutral.neutral3,
      }}
    >
      <SvgSpinner fill={'black'} style={{ maxHeight: 50 }} />
    </div>
  );
};

export type TAuctionCardProps = {
  id: string;
  title: string;
  bidDisabled: boolean;
  currentPrice: number;
  power: number;
  active: boolean;
  oCashPrice: number;
  type: string;
  userIsTopBidder: boolean;
};

/**
 * @constructor
 */

const AuctionCard: React.FunctionComponent<TAuctionCardProps> = ({
  id,
  title,
  bidDisabled,
  currentPrice,
  power,
  active,
  oCashPrice,
  type,
  userIsTopBidder,
}) => {
  const queryClient = useQueryClient();
  const inputRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [bidInputValue, setBidInputValue] = useState(currentPrice);
  const [currentBid, setCurrentBid] = useState(currentPrice);

  const ethPrice = queryClient.getQueryData(
    getRQKCoinGeckoKey('getEthPrice'),
  ) as { usd: number };

  useEffect(() => {
    setCurrentBid(currentPrice);
  }, [currentPrice]);

  useEffect(() => {
    if (isActive === true) {
      inputRef.current?.focus();
    }
  }, [isActive]);

  const handleAddBit = async (amount: number) => {
    try {
      setIsLoading(true);
      await api.auctionBid({
        auctionId: id,
        amount: amount,
      });
      toast(
        <Toast
          type="success"
          header="Bid successfully placed!"
          body="Your bid was successfully placed - you are now the leading bidder."
        />,
      );
      setCurrentBid(amount);
    } catch (err: any) {
      toast(
        <Toast type="error" header="Bid is too low" body={err.data.errMsg} />,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlusCustomBtnClick = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (bidInputValue > currentBid) {
      await handleAddBit(bidInputValue);
    }
  };

  const handlePlus100BtnClick = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const amountToBid = currentBid + 100;
    await handleAddBit(amountToBid);
  };

  return (
    <AuctionCardSC
      className={`${isActive && 'active'}`}
      id={`${userIsTopBidder && 'top-bid'}`}
      onClick={() => setIsActive(!isActive)}
      userIsTopBidder={userIsTopBidder}
    >
      <div
        style={{
          position: 'relative',
          borderRadius: 7,
          marginBottom: 8,
        }}
      >
        {type === 'land' ? (
          <div className="land-animation">
            {olandVideo && (
              <video
                width="auto"
                height="auto"
                autoPlay={true}
                loop={true}
                playsInline={true}
                preload={'auto'}
                muted
              >
                <source src={olandVideo} type="video/mp4" />
              </video>
            )}
          </div>
        ) : (
          renderCardImage(title)
        )}

        <div className="power-score">
          <SvgPower
            fill={colorThemes.light.blue.blue100}
            style={{ height: 16, width: 16 }}
          />{' '}
          {power.toLocaleString()}
        </div>
        {userIsTopBidder && <div className="top-bidder">You are leading!</div>}
      </div>
      <Text
        className="plot-label"
        variant={TextVariant.CardText}
        style={{ fontWeight: 700, textAlign: 'left', marginBottom: 8 }}
      >
        {type === 'land' ? title : getHumanReadable(title)}
      </Text>
      <div className="price-information">
        <div>
          <Text
            className="gray plot-label"
            variant={TextVariant.CardText}
            style={{
              fontWeight: 400,
              textAlign: 'left',
            }}
          >
            Current Bid
          </Text>
          <Text
            className="plot-label price"
            variant={TextVariant.CardText}
            style={{
              fontWeight: 700,
              textAlign: 'left',
              marginBottom: 12,
            }}
          >
            {currentBid} ōCash
          </Text>
        </div>
        <Text
          className="dgray plot-label"
          variant={TextVariant.CardText}
          style={{
            fontWeight: 600,
            textAlign: 'left',
            marginBottom: 12,
          }}
        >
          ~({((currentBid * oCashPrice) / ethPrice.usd).toFixed(4)} ETH)
        </Text>
      </div>

      {isActive && (
        <div style={{ display: 'flex' }}>
          <InputSC
            style={{
              color: bidInputValue < currentBid ? colorThemes.light.red : '',
            }}
            className="bid-input"
            placeholder="Enter Bid"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handlePlusCustomBtnClick(e);
              }
            }}
            ref={inputRef}
            type="number"
            min={currentBid}
            value={bidInputValue}
            disabled={isLoading}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onChange={(val) =>
              setBidInputValue(parseFloat(val.currentTarget.value))
            }
          />

          <ButtonPrimarySC
            className="bid-button"
            onClick={(e) => handlePlusCustomBtnClick(e)}
            disabled={isLoading || bidInputValue <= currentBid}
          >
            ✓
          </ButtonPrimarySC>

          <ButtonPrimarySC
            className="bid-button-100 green"
            disabled={!active}
            onClick={(e) => handlePlus100BtnClick(e)}
          >
            + 100
          </ButtonPrimarySC>
        </div>
      )}
      {!isActive && (
        <ButtonPrimarySC
          className="smaller"
          style={{ borderRadius: 5 }}
          disabled={bidDisabled}
        >
          Place Bid
        </ButtonPrimarySC>
      )}
    </AuctionCardSC>
  );
};

export default AuctionCard;
