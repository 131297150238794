import styled, { css } from 'styled-components';

export enum EExpTooltipVariant {
  ConnectWallet = 'ConnectWallet',
  Balance = 'Balance',
}

export type TExpTooltipProps = {
  variant: EExpTooltipVariant;
  shown: boolean;
};

const ExpandableTooltipSC = styled.div<TExpTooltipProps>`
  ${({ variant = EExpTooltipVariant.ConnectWallet }) => {
    switch (variant) {
      case EExpTooltipVariant.ConnectWallet:
        return css`
          top: 55px;
          text-align: center;

          .rules {
            background: ${({ theme }) => theme.background.regular.bg2};
            color: ${({ theme }) => theme.text.main};
            border-radius: 10;
            margin-top: 24px;
            padding: 12px;
          }

          .rule-item {
            width: 100%;
            :nth-child(1),
            :nth-child(2) {
              border-top: 1px solid rgba(255, 255, 255, 0);
            }

            :nth-child(even) {
              border-right: 1px solid rgba(255, 255, 255, 0);
            }

            :nth-child(odd) {
              border-left: 1px solid rgba(255, 255, 255, 0);
              border-right: 1px solid rgba(255, 255, 255, 0);
            }

            :nth-child(4) {
              border-bottom: 1px solid ${({ theme }) => theme.neutral.neutral6} !important;
            }

            :last-child {
              border-right: 1px solid ${({ theme }) => theme.neutral.neutral6};
            }

            border: 1px solid ${({ theme }) => theme.neutral.neutral6};
            border-bottom: 1px solid rgba(255, 255, 255, 0) !important;
            padding: 4px;
          }

          @media only screen and (max-width: 1024px) {
            right: 0px;
            max-width: 280px;
            width: 100%;
          }
        `;
      case EExpTooltipVariant.Balance:
        return css`
          top: 55px;
          text-align: center;

          @media only screen and (max-width: 1024px) {
            right: 0px;
            max-width: 310px;
          }
        `;
    }
  }}

  position: absolute;
  box-shadow: 0 5px 10px rgb(0 0 0 / 34%);
  border-radius: 10px;
  opacity: ${(props) => (props.shown ? 1 : 0)} !important;
  overflow: hidden;

  width: 100%;
  gap: 10px;
  overflow: hidden;
  transition: 0.3s ease-in-out !important;
  z-index: 1;
  visibility: ${(props) => (props.shown ? 'visible' : 'hidden')} !important;
  transform: ${(props) =>
    props.shown
      ? 'skewY(0) rotate(0) translateY(0) !important'
      : 'skewY(-5deg) rotate(5deg) translateY(-30px) !important'};
  border: 1.5px solid ${({ theme }) => theme.neutral.neutral4};

  background-color: ${({ theme }) => theme.background.regular.bg1};
  transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
  transition-property: background-color, -webkit-backdrop-filter;
  transition-property: background-color, backdrop-filter;
  transition-property: background-color, backdrop-filter,
    -webkit-backdrop-filter;

  .inner-content {
    padding: 16px 12px;
    width: 100%;
  }

  &.connect {
    max-width: 400px;
    .rules {
      background: ${({ theme }) => theme.background.regular.bg2};
      color: ${({ theme }) => theme.text.main};
      border-radius: 10;
      border: 1.5px solid ${({ theme }) => theme.input.border};
      margin-top: 24px;
      padding: 12px 0px;

      .rule-item {
        margin-bottom: 12px;
        h1 {
          font-size: 14px;
          margin: 0px;
        }
      }

      @media only screen and (max-width: 1024px) {
        .rule-item {
          h1 {
            font-size: 12px;
          }
        }
      }
    }
  }

  .metamask-btn {
    margin: 12px 0px;
    width: 100%;
    border: none;
    border-radius: 10px;
    background: ${({ theme }) => theme.blue.blue10};
    transition: 0.3s;
    display: flex;
    gap: 12px;
    padding: 16px;
    align-items: center;

    h1 {
      margin: 0px;
    }

    :hover {
      background: ${({ theme }) => theme.blue.blue20};
    }
  }

  #balance {
    margin: 12px 0px;
  }

  #wallet {
    div {
      display: flex;
      justify-content: space-between;
    }

    h1 {
      font-weight: 500;
    }
  }

  .utility-btn {
    background: ${({ theme }) => theme.blue.blue10};
    border-radius: 5px;
    border: none;
    width: 30px;
    height: 30px;
    transition: 0.3s;
    color: ${({ theme }) => theme.blue.blue100};
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      background: ${({ theme }) => theme.blue.blue30};
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  #address {
    padding: 8px;
    border: 1.5px solid ${({ theme }) => theme.neutral.neutral4};
    border-radius: 5px;
  }
`;

export default ExpandableTooltipSC;
