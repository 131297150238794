import Text, { TextVariant } from '@components/Text';
import React, { useEffect, useState } from 'react';
import { UserSC } from '../OgemsDashboard.styled';
import { TOgemDashboardComponentProps } from './ProgressBar';
import api from '@utils/api';
import { useQuery } from 'react-query';
import { getRQKProfileKey } from '@utils/rq-utils';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as SvgUser } from '@assets/svg/ogems/user.svg';

const Gem = () => {
  return <div className="gem">💎</div>;
};

const UserInfo: React.FunctionComponent<TOgemDashboardComponentProps> = (
  oGemData,
) => {
  const data = oGemData.oGemData;

  const { data: profile } = useQuery(
    getRQKProfileKey('getProfile'),
    async () => {
      const result = (await api.getProfile()).data;
      return result.data;
    },
  );

  const [balance, setBalance] = useState(() => {
    return data.lastUpdate
      ? data.oGemBalance +
          data.currentRewardRate *
            (Math.floor(new Date().getTime() / 1000) - data.lastUpdate)
      : data.oGemBalance;
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setBalance((b) => b + data.currentRewardRate / 4);
    }, 250);
    return () => clearInterval(interval);
  }, []);

  const balanceAfterDecimal = parseInt(balance.toString().split('.')[1]);

  const animatedBalance = useSpring({
    val: balance,
    from: { val: data.oGemBalance },
  });

  const animatedDecimal = useSpring({
    val: balanceAfterDecimal,
    from: { val: 0 },
  });

  return (
    <UserSC>
      <div className="header">
        <div style={{ display: 'flex', gap: '12px' }}>
          <div className="profile">
            <SvgUser height={24} width={24} style={{ display: 'inline' }} />
          </div>
          <div>
            <Text variant={TextVariant.CardText} className="subtitle">
              Account
            </Text>
            <Text variant={TextVariant.CardTitle} className="title">
              {profile.email}
            </Text>
          </div>
        </div>
        <div className="rate">
          <Text variant={TextVariant.CardText} className="subtitle">
            Rank
          </Text>
          <Text variant={TextVariant.CardTitle} className="title">
            {data.oGemRank.toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Text>
        </div>
      </div>

      <div className="balance-frame">
        <Gem />
        <Gem />
        <Gem />
        <Gem />
        <Gem />
        <Gem />
        <Gem />
        <Gem />
        <Gem />
        <Gem />
        <Gem />
        <div className="live-balance">
          <Text
            variant={TextVariant.CardText}
            className="title"
            style={{ marginTop: '0.3rem' }}
          >
            Your ōGems
          </Text>
          <Text variant={TextVariant.CardTitle} className="title bal">
            <animated.span>
              {animatedBalance.val.to((num) =>
                num.toLocaleString('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
              )}
            </animated.span>
            <span className="decimal">.</span>
            <animated.span className="decimal">
              {animatedDecimal.val.to((num) => num.toString().slice(0, 4))}
            </animated.span>
          </Text>
        </div>
      </div>
    </UserSC>
  );
};

export default UserInfo;
