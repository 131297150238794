import React, { useState, useEffect } from 'react';
import { ClaimableBoosterSC } from './InventoryBar.styled';
import RedBooster from '@assets/png/boosters/red-booster-png.png';
import GreenBoooster from '@assets/png/boosters/green-booster-png.png';
import YellowBooster from '@assets/png/boosters/yellow-booster-png.png';

const ClaimableBooster = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const imageSources = [RedBooster, GreenBoooster, YellowBooster];

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % imageSources.length);
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {imageSources.map((source, index) => (
        <ClaimableBoosterSC
          key={index}
          src={source}
          alt={`Image ${index + 1}`}
          className={`booster-waiting ${index === imageIndex ? 'active' : ''}`}
        />
      ))}
    </>
  );
};

export default ClaimableBooster;
