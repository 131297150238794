import { ButtonOutlineSC } from '@components/Button.styled';
import Text, { TextVariant } from '@components/Text';
import api from '@utils/api';
import { getRQKTwitterLeaderboard } from '@utils/rq-utils';
import React from 'react';
import { useQuery } from 'react-query';
import TwitterLeaderboardPageSC from './TwitterLeaderboardPage.styled';
import { useNavigate } from 'react-router-dom';

const tableHeaders = ['Rank', 'Account', 'ōCash Earned'];

const TwitterLeaderboardPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { isLoading: leaderboardDataLoading, data: leaderboardData } = useQuery(
    getRQKTwitterLeaderboard('getTwitterLeaderboard'),
    async () => (await api.getTwitterLeaderboard()).data.data,
  );

  if (leaderboardDataLoading) {
    return null;
  }

  return (
    <TwitterLeaderboardPageSC>
      <div className="leaderboard-wrapper">
        <ButtonOutlineSC
          onClick={() => navigate('/')}
          style={{ maxWidth: 120, marginBottom: 20 }}
        >
          Go Back
        </ButtonOutlineSC>
        <div className="title-wrapper">
          <Text
            style={{ textAlign: 'center', fontWeight: 500, marginBottom: 12 }}
            variant={TextVariant.Title}
          >
            Tweet to Earn
          </Text>
          <Text
            variant={TextVariant.CardSubtitle}
            style={{ textAlign: 'center', fontWeight: 500, marginBottom: 12 }}
          >
            72 Hour Leaderboard
          </Text>
          <Text
            style={{ textAlign: 'center', fontWeight: 500, marginBottom: 12 }}
            variant={TextVariant.CardText}
            className="gray"
          >
            Connect your Twitter account and earn FREE daily ōCash rewards just
            for engaging on social media. Climb up the ranks and earn top
            prizes!
          </Text>
        </div>

        <table>
          <tr className="header">
            {tableHeaders.map((item, idx) => {
              return <th key={idx}>{item}</th>;
            })}
          </tr>
          <tbody>
            {leaderboardData.slice(0, 100).map((item, idx) => {
              if (item.rank === 1) {
                item.rank = '⭐️ 👑 🥇 ' + item.rank;
              }
              if (item.rank === 2) {
                item.rank = '⭐️ 👑 🥈 ' + item.rank;
              }
              if (item.rank === 3) {
                item.rank = '⭐️ 👑 🥉 ' + item.rank;
              }
              if (item.rank > 3 && item.rank <= 10) {
                item.rank = '⭐️ 👑 ' + item.rank;
              }
              if (item.rank > 10 && item.rank <= 25) {
                item.rank = '⭐️ ' + item.rank;
              }
              return (
                <tr key={idx}>
                  <td>{item.rank}</td>
                  <td>
                    <a
                      href={`https://twitter.com/${item._id}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{ width: '100%' }}
                    >
                      <img src={item.image} alt="pfp" />@{item._id}
                    </a>
                  </td>
                  <td>{item.totalReward.toFixed(0)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </TwitterLeaderboardPageSC>
  );
};

export default TwitterLeaderboardPage;
