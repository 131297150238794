import React from 'react';
import Text, { TextVariant } from '@components/Text';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import { ChestSectionSC } from '../ChestsPage.styled';
import { getChestCardName, getRarityName } from '@utils/common';
import ChestCard from './ChestCard';
import { TChestReward } from '@data/chests';
import { useQuery } from 'react-query';
import { getChestCardQty, getRQKChestCards } from '@utils/rq-utils';
import api from '@utils/api';
import Loader from '@components/Loader';

const badgeColors = {
  rare: 'red',
  super: 'green',
  ultra: 'yellow',
};

export type TChestSectionProps = {
  type: string;
  cards: TChestReward[];
};

/**
 * @constructor
 */
const ChestSection: React.FunctionComponent<TChestSectionProps> = ({
  type,
  cards,
}) => {
  const { isLoading: artifactPageIsLoading } = useQuery(
    getRQKChestCards('getChestCards'),
    async () => (await api.getChestCards()).data.data,
  );

  if (artifactPageIsLoading) {
    return <Loader />;
  }

  if (artifactPageIsLoading) {
    return <Loader />;
  }

  return (
    <ChestSectionSC>
      <Text variant={TextVariant.CardTitle} style={{ fontWeight: 600 }}>
        {getRarityName(type)} Chests
      </Text>
      <div className="artifacts-container">
        {cards.map((chest, index) => {
          const name = getChestCardName(
            chest.type,
            chest.rarity as string,
            chest.trait,
          );
          let chestRarity = chest.rarity;
          if (chestRarity === 'super') {
            chestRarity = 'super-rare';
          }
          if (chestRarity === 'ultra') {
            chestRarity = 'ultra-rare';
          }
          const qty = getChestCardQty(chestRarity + chest.trait + chest.type);
          return (
            <Tippy {...defaultTippyProps} content={`${name}`} key={index}>
              <div>
                <ChestCard
                  img={chest.img}
                  type={type}
                  color={badgeColors[type]}
                  chestQty={qty}
                />
              </div>
            </Tippy>
          );
        })}
      </div>
    </ChestSectionSC>
  );
};

export default ChestSection;
