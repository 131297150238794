import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '@store/index';
import orderStore from '@store/order';

import { ButtonPrimarySC, StyledLinkBtn } from '@components/Button.styled';
import Text, { TextVariant } from '@components/Text';
import AddressEntry from './components/AddressEntry';
import Row, { GapSizes } from '@components/Row';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import api from '@utils/api';
import Checkbox from '@components/Checkbox';
import TermsFooter from './components/TermsFooter';
import { EPurchaseProcessSteps } from '@components/Modal/CheckoutProcess/PurchaseModal';

const ShippingAddressModal = () => {
  const dispatch = useDispatch();
  const order = useSelector((store: TRootState) => store.order);

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<null | Record<string, string>>(
    null,
  );
  const [hasError, setHasError] = useState(true);
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(false);

  const handleFormChange = useCallback((values, errors) => {
    setHasError(!(Object.keys(errors).length === 0 && values.city));
    setFormValues(values);
  }, []);

  const handleBtnPress = async () => {
    setLoading(true);

    const values = formValues as Record<string, string>;

    const shippingAddress = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      street: values.street,
      streetOpt: values.streetOpt,
      city: values.city,
      postal: values.postal,
      country: values.country,
      region: values.region,
      note: values.note,
    };

    try {
      if (billingSameAsShipping) {
        // if same we can create an order, otherwise ask for billing details by
        // rendering another form

        const billingAddress = {
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          street: values.street,
          streetOpt: values.streetOpt,
          city: values.city,
          postal: values.postal,
          country: values.country,
          region: values.region,
          vatId: '',
        };

        dispatch(orderStore.setShippingAddress({ shippingAddress }));
        dispatch(orderStore.setBillingAddress({ billingAddress }));

        const result = (
          await api.createOrder({
            products: [
              {
                id: order.productId,
                quantity: order.productCount,
              },
            ],
            shippingAddress,
            billingAddress,
          })
        ).data;
        const { shipping, discount, tax, total, id } = result.data;
        dispatch(
          orderStore.setOrderMeta({
            id,
            shipping,
            discount,
            tax,
            total,
          }),
        );

        dispatch(
          orderStore.setOrderStep({
            step: EPurchaseProcessSteps.PaymentSelector,
          }),
        );
      } else {
        dispatch(orderStore.setShippingAddress({ shippingAddress }));
        dispatch(
          orderStore.setOrderStep({
            step: EPurchaseProcessSteps.BillingAddress,
          }),
        );
      }
    } catch (e) {
      // TODO: sentry
      console.error('Error when creating order: ', e);
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = useCallback(() => {
    dispatch(
      orderStore.setOrderStep({
        step: EPurchaseProcessSteps.PurchaseORouterDiscount,
      }),
    );
  }, []);

  return (
    <div className="body">
      <AddressEntry type="shipping" onChange={handleFormChange} />
      <div style={{ marginTop: 16 }}>
        <Row
          gapSize={GapSizes.Md}
          style={{
            marginBottom: 32,
            alignItems: 'center',
          }}
        >
          <Text variant={TextVariant.CardText}>
            Use same address for billing
          </Text>
          <Checkbox
            checked={billingSameAsShipping}
            onClick={() => {
              setBillingSameAsShipping(!billingSameAsShipping);
            }}
          />
        </Row>
      </div>

      <ButtonPrimarySC
        type="submit"
        disabled={hasError}
        onClick={handleBtnPress}
      >
        {loading ? (
          <SvgSpinner
            height={30}
            width={30}
            style={{ display: 'inline', fill: 'white' }}
          />
        ) : (
          'Confirm shipping address'
        )}
      </ButtonPrimarySC>
      <hr />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledLinkBtn onClick={handleGoBack}>Go back</StyledLinkBtn>
      </div>
      <TermsFooter />
    </div>
  );
};

export default ShippingAddressModal;
