import React, { useCallback } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { TRootState } from '@store/index';
import userInterfaceStore from '@store/user-interface';
import { ButtonOutlineSC } from '@components/Button.styled';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { defaultModalProps } from '@components/Modal/modal-settings';
import TermsFooter from './CheckoutProcess/components/TermsFooter';
import ModalSC from './Modal.styled';
import ReferralLink from '@components/ReferralLink';
import twitterLogo from '@assets/svg/Twitter.svg';
import discordLogo from '@assets/svg/Discord.svg';

import { getRQKPositionsKey } from '@utils/rq-utils';
import api from '@utils/api';

const ReferralModal: React.FunctionComponent = () => {
  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string };

  const dispatch = useDispatch();

  const { isLoading, data: positionCountData } = useQuery(
    getRQKPositionsKey('getPositionCount'),
    async () => (await api.getPositionCount()).data.data,
  );

  const handleCloseModal = useCallback(() => {
    dispatch(
      userInterfaceStore.setModal({
        type: null,
        token: null,
      }),
    );
  }, []);

  if (isLoading) return null;

  return (
    <ReactModal
      isOpen={type === 'referralModal'}
      {...defaultModalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC>
        {/* Hidden for now */}
        {/* <div id="airdrop-modal-banner">
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            The ōCash airdrop is happening!
          </Text>
          <Text variant={TextVariant.CardText} className="sharing-description">
            All the ōCash you spend in-game is getting a massive reward.
          </Text>
          <ButtonPrimarySC className="white">
            How much will I get?
          </ButtonPrimarySC>
        </div> */}
        <div className="header">
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            Get more ōLand!
          </Text>
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <div className="body">
          <Text style={{ margin: '16px 0px' }} variant={TextVariant.CardText}>
            You currently have:
          </Text>
          <Text variant={TextVariant.ColorText}>
            {positionCountData.positionsCount} ōLand
          </Text>

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 16,
              marginTop: 16,
            }}
          >
            <div style={{ minWidth: 100, alignSelf: 'center' }}>
              <Text
                variant={TextVariant.CardText}
                className="sharing-description"
              >
                Share your referral link with friends and earn 20% of whatever
                your referrals buy:
              </Text>
            </div>
            <ReferralLink />
          </div>
          <div style={{ marginTop: 18 }}>
            <Text variant={TextVariant.CardText} style={{ fontWeight: 500 }}>
              Looking to buy or sell ōLand? Follow us on:
              <div className="social-row">
                <a
                  href="https://twitter.com/overlinenetwork"
                  className="social-button twitter"
                  data-show-count="true"
                >
                  <img src={twitterLogo} alt="twitter" />
                  <span className="social-text">Twitter</span>
                </a>
              </div>
            </Text>
          </div>
          <ButtonOutlineSC onClick={handleCloseModal} style={{ marginTop: 24 }}>
            Take me to my ōLand portal
          </ButtonOutlineSC>
        </div>
        <TermsFooter />
      </ModalSC>
    </ReactModal>
  );
};

export default ReferralModal;
