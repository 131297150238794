import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { Slide, ToastContainer, ToastContainerProps } from 'react-toastify';
import Decimal from 'decimal.js';
import { useQueryClient } from 'react-query';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Pages from '@pages/index';
import Header from '@components/Header';
import { TRootState } from '@store/index';
import { AUTH_STATE_SUCCESS } from '@store/auth/types';
import { getAccessToken, getRefreshToken } from '@utils/storage';
import api from '@utils/api';
import userInterface from '@store/user-interface';
import authSlice from '@store/auth';
import ModalWrapper from '@components/Modal/ModalWrapper';
import {
  getRQKCoinGeckoKey,
  getRQKOCashKey,
  getRQKProductsKey,
  getRQKProfileKey,
} from '@utils/rq-utils';
import Loader from '@components/Loader';
import axios from 'axios';
import AppLogout from '@components/AppLogout';

const ToastContainerSettings: ToastContainerProps = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  transition: Slide,
};

const Layout: React.FunctionComponent = () => {
  const { signIn } = authSlice;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(true);
  const { setFreeOCashAmountClaimed } = userInterface;
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();

  const hasTokens = Boolean(accessToken && refreshToken);

  // amount claimed in URL priocess it TODO:
  const urlParams = new URLSearchParams(window.location.search);
  const amountClaimed = urlParams.get('amountClaimed');

  if (amountClaimed) {
    dispatch(
      setFreeOCashAmountClaimed({ amountClaimed: parseInt(amountClaimed, 10) }),
    );
  }

  const authenticated =
    useSelector((store: TRootState) => store.auth.state) === AUTH_STATE_SUCCESS;

  useEffect(() => {
    (async () => {
      const productResult = (await api.getProducts()).data;
      // pre-fill query with data
      queryClient.setQueryData(
        getRQKProductsKey('getProducts'),
        productResult.data,
      );

      queryClient.setQueryData(getRQKOCashKey('getOCashPrice'), () => {
        const filtered = productResult.data.find(
          (obj) => obj.id === '35212ec9-52a5-424f-a6a2-522831b48b2b',
        );

        const oCashAmountInPackage = new Decimal(
          parseInt(filtered.title.split(' ')[0]),
        );
        const oCashPackagePrice = new Decimal(filtered.price);
        return parseFloat(
          oCashPackagePrice.div(oCashAmountInPackage).toFixed(2),
        );
      });

      /**
       * Fetch ETH price
       * TODO: use react-query for caching
       */
      const coinGeckoResponse = (
        await axios.get(
          'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd',
        )
      ).data;

      queryClient.setQueryData(
        getRQKCoinGeckoKey('getEthPrice'),
        coinGeckoResponse.ethereum,
      );

      const auctionDetailRegex =
        '/auctions/[0-9(a-f|A-F)]{8}-[0-9(a-f|A-F)]{4}-4[0-9(a-f|A-F)]{3}-[89ab][0-9(a-f|A-F)]{3}-[0-9(a-f|A-F)]{12}$';
      const whitelistMatch = location.pathname.match(auctionDetailRegex);

      if (!hasTokens) {
        if (!whitelistMatch) {
          navigate('/auth' + location.search);
        }
        setIsLoading(false);
      }

      if (hasTokens && !authenticated) {
        const result = (await api.getProfile()).data;
        queryClient.setQueryData(getRQKProfileKey('getProfile'), result.data);
        // // process any non-processed custom eth gw payments
        // if (process.env.REACT_APP_IS_DEVELOPMENT !== 'true') {
        //   await api.pollCustomGw().catch(() => {
        //     return null;
        //   });
        // }
        dispatch(
          signIn({
            // @ts-expect-error we have our own structure
            id: jwtDecode(String(accessToken)).sub as string,
            daoRanking: result.data.daoRanking,
            affiliateId: result.data.affiliateId,
          }),
        );
        setIsLoading(false);
      }

      // Do nothing
      if (hasTokens && authenticated) {
        setIsLoading(false);
      }
    })();
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <>
      <SkeletonTheme
        baseColor="#727B8F"
        highlightColor="#adb8cf"
        borderRadius="0.25rem"
      >
        <Header />
        <AppLogout>
          <Pages />
        </AppLogout>
        <ToastContainer {...ToastContainerSettings} />
        <ModalWrapper />
      </SkeletonTheme>
    </>
  );
};

export default Layout;
