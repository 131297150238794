export const KEY_PRODUCT_IDS = [
  'fbf67243-bd6b-4be4-a70e-9de24ce61620',
  '97ec1d35-7420-4ac8-b731-258273c3e336',
  '5c1d6b61-c624-4c11-98c8-1d451a941c1b',
  'a9d02e20-a1ee-406f-9db0-f09de0cf9d3c',
  '2e14c980-1ce8-40f7-b9f1-a09973ac343c',
  '28a3aea2-71a0-47e6-9700-0fe5f28d0ffe',
  '30a6eb0f-eaab-4962-9fe9-6f956999282c',
];

export const O_CASH_PRODUCTS_AMOUNT = new Map([
  ['3cbf40e8-c4b5-4ee0-8394-237036e0284c', 10],
  ['43b13f75-8a9a-48b9-8cda-80abb4051add', 80],
  ['ae398f37-3cb6-4a95-84a4-a2e1e20d6cd0', 300],
  ['e7bea56c-0ee9-478a-9739-e589364932db', 2250],
  ['ec72de7e-6b5b-452d-90dd-15763edd4e27', 10000],
  ['35212ec9-52a5-424f-a6a2-522831b48b2b', 150000],
]);

export const O_ROUTER_PRODUCT_ID = 'f6dd0c0f-8e33-4b3b-8992-2d40fab943e3';
