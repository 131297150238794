import React, { useState, useCallback } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { TRootState } from '@store/index';
import userInterfaceStore from '@store/user-interface';
import { ButtonPrimarySC } from '@components/Button.styled';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { defaultModalProps } from '@components/Modal/modal-settings';

import ModalSC from './Modal.styled';
import TermsFooter from './CheckoutProcess/components/TermsFooter';
import { InputSC } from '@components/Input.styled';
import api from '@utils/api';
import { useQueryClient } from 'react-query';
import { getRQKPositionsKey } from '@utils/rq-utils';

type TClaimedConfirmationProps = {
  onClose: () => void;
  userEthAddress: string;
};

const ClaimedConfirmation: React.FC<TClaimedConfirmationProps> = ({
  onClose,
  userEthAddress,
}: TClaimedConfirmationProps) => {
  return (
    <>
      <div className="header">
        <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
          Congratulations!
        </Text>
        <button onClick={onClose}>
          <img src={SvgCross} alt="cross-btn" />
        </button>
      </div>
      <div className="body">
        <Text
          variant={TextVariant.CardText}
          className="gray"
          style={{ marginBottom: 16, marginTop: 8 }}
        >
          We are pleased to let you know that your request has been received. It
          may take up to 3 days to arrive.
          <br />
          <br />
          Your ōFriend will soon now settle safely into their new home at{' '}
          <span>
            <a
              href={`https://etherscan.io/address/${userEthAddress}#tokentxnsErc721`}
            >
              {userEthAddress}
            </a>
          </span>
          .
        </Text>

        <ButtonPrimarySC
          style={{ margin: '24px 0px 0px 0px' }}
          onClick={() => {
            window.location.replace(
              `https://etherscan.io/address/${userEthAddress}#tokentxnsErc721`,
            );
          }}
        >
          View on Etherscan
        </ButtonPrimarySC>
        <TermsFooter noPreorder={true} />
      </div>
    </>
  );
};

const ClaimOFriendModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { type } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const minting = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.minting,
  ) as { id: string };

  const [userEthAddress, setUserEthAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleBtnClick = async () => {
    setIsLoading(true);
    try {
      await api.mint(minting.id, userEthAddress);
      await queryClient.invalidateQueries(
        getRQKPositionsKey('getPosition', minting.id),
      );
      setIsConfirmed(true);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = useCallback(() => {
    // clear minting state
    dispatch(userInterfaceStore.clearMintingProcess());
    // close modal
    dispatch(
      userInterfaceStore.setModal({
        type: null,
        token: null,
      }),
    );
  }, []);

  return (
    <ReactModal
      isOpen={type === 'claimOFriendModal'}
      {...defaultModalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC>
        {isConfirmed && (
          <ClaimedConfirmation
            onClose={handleCloseModal}
            userEthAddress={userEthAddress}
          />
        )}
        {!isConfirmed && (
          <>
            <div className="header">
              <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
                Collect your ōFriend
              </Text>
              <button onClick={handleCloseModal}>
                <img src={SvgCross} alt="cross-btn" />
              </button>
            </div>
            <div className="body">
              <Text
                variant={TextVariant.CardText}
                className="gray"
                style={{ marginBottom: 16 }}
              >
                Your ōFriend is looking for a new home. Enter the Ethereum
                (ERC-20) address that you would like your ōFriend minted to.
              </Text>
              <Text
                variant={TextVariant.CardText}
                className="blue"
                style={{ fontWeight: 600 }}
              >
                ERC-20 ADDRESS:
              </Text>
              <InputSC
                placeholder="0x...."
                style={{ margin: '16px 0px' }}
                id="ethAddress"
                name="ethAddress"
                type="text"
                required
                value={userEthAddress}
                onChange={(val) => setUserEthAddress(val.currentTarget.value)}
              />
              <Text variant={TextVariant.CardText} className="gray">
                WARNING: please make sure you have entered the correct address
                as transactions cannot be reversed
              </Text>
              <ButtonPrimarySC
                disabled={isLoading}
                style={{ margin: '24px 0px 0px 0px' }}
                onClick={handleBtnClick}
              >
                {isLoading ? `Submitting...` : `Claim ōFriend`}
              </ButtonPrimarySC>
              <TermsFooter noPreorder={true} />
            </div>
          </>
        )}
      </ModalSC>
    </ReactModal>
  );
};

export default ClaimOFriendModal;
