import styled from 'styled-components';

const StyledConnectButton = styled.div`
  position: relative;

  button {
    height: 35px;
    padding: 0px 12px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 8px;
  }
`;

export default StyledConnectButton;
