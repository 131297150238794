import styled from 'styled-components';

import LayoutWrapperSC from '@components/Layout/layout.styled';
import { devices } from '@assets/styles/screenSizes';
import Wrapper from '@components/Wrapper';
import { colorThemes } from '@assets/styles';

export default styled(LayoutWrapperSC)`
  height: 50px;
  background: ${colorThemes.dark.background.regular.bg1};
  border-bottom: 1.5px solid ${({ theme }) => theme.input.border};
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 999;
  border-bottom: 1.5px solid ${({ theme }) => theme.input.border};
  &.banner-top {
    background-color: rgba(29, 29, 31, 0.5);
    display: flex;
    justify-content: space-evenly;
    gap: 40px;
  }

  > div.inner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 1024px) {
      padding: 0px 12px;
    }

    svg {
      cursor: pointer;
    }
  }
`;

export const ButtonsSC = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  svg {
    stroke: white;
  }
`;

export const CartButtonSC = styled.button`
  background: none;
  border: none;
`;

export const StyledInnerContent = styled(Wrapper)`
  @media only screen and (max-width: 1024px) {
    .mobile-menu {
      display: flex;
    }
    .main-menu {
      display: none;
    }
  }

  @media only screen and (min-width: 1024px) {
    .mobile-menu {
      display: none;
    }
    .main-menu {
      display: grid;
      grid-template-columns: 2fr 3fr 2fr;
      gap: 24px;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;

  @media only screen and (${devices.mobileL}) {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  @media only screen and (${devices.mobileM}) {
    padding-left: 24px;
    padding-right: 24px;

    &.mobile-menu {
      padding: none !important;
    }
  }
  @media only screen and (${devices.mobileS}) {
    padding-left: 24px;
    padding-right: 24px;

    &.mobile-menu {
      padding: none !important;
    }
  }
`;

export const StyledMenuWrapper = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  right: 0;
  left: 0;
  height: 100vh;
  background-color: ${({ theme }) => theme.background.regular.bg1};
  transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
  transition-property: background-color, -webkit-backdrop-filter;
  transition-property: background-color, backdrop-filter;
  transition-property: background-color, backdrop-filter,
    -webkit-backdrop-filter;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.15);
  padding: 12px;
  text-align: center;
  transition: 0.3s ease-in-out;
  z-index: 9999;

  .nav-item {
    cursor: pointer;
  }

  svg {
    stroke: ${({ theme }) => theme.text.main};
  }

  .btn-wrapper {
    width: 110px;
    margin: 12px auto;
  }
`;
