import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import { TRootState } from '@store/index';
import { InputSC, SharedInputStyled } from '@components/Input.styled';
import Row, { GapSizes, JustificationTypes } from '@components/Row';

export type TAddressProps = {
  type: 'billing' | 'shipping';
  onChange: (values, errors) => void;
};

const StyledAddressEntryWrapper = styled.div`
  .PhoneInput {
    width: 100%;
    .PhoneInputCountryIcon {
      height: 24px;
      width: 36px;
    }
  }

  .PhoneInput > input {
    ${SharedInputStyled};

    &::placeholder {
      color: ${({ theme }) => theme.input.placeholder};
    }

    border-color: #d2d2d7;
    padding: 12px;

    :focus {
      box-shadow: 0 0 0 2px rgb(0 125 250 / 60%);
      border-color: ${({ theme }) => theme.blue.blue100};
      outline: none;
    }

    :focus-visible {
      outline: none;
    }
  }
`;

const StyledErrorText = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 4px;
`;
const AddressEntry: React.FunctionComponent<TAddressProps> = ({
  type,
  onChange,
}) => {
  // To get country & region
  const shippingAddress = useSelector(
    (store: TRootState) => store.order.shippingAddress,
  );

  const initFormikValues: Record<string, string> = {
    firstName: '',
    lastName: '',
    postal: '',
    phone: '',
    street: '',
    streetOpt: '',
    city: '',
    region: type === 'shipping' ? shippingAddress.region : '',
    country: type === 'shipping' ? shippingAddress.country : '',
  };

  if (type === 'shipping') {
    initFormikValues.note = '';
  } else {
    initFormikValues.vatId = '';
  }

  const yupValidation = {
    firstName: Yup.string()
      .min(2)
      .label('First Name')
      .required('Required field'),
    lastName: Yup.string().min(2).label('Last Name').required('Required field'),
    street: Yup.string().label('Street Address').required('Required field'),
    streetOpt: Yup.string().label('Apt, Building, Suite (Optional)'),
    city: Yup.string().label('City').required('Required field'),
    phone: Yup.string()
      .matches(
        /^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$/g,
        'International format +X YYY... required',
      )
      .label('Phone')
      .required(),
    country: Yup.string().label('Country').required('Required field'),
    region: Yup.string().label('Region').required('Required field'),
    postal: Yup.string().min(4).required('Required field'),
  };

  if (type === 'shipping') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yupValidation.note = Yup.string().label('Note');
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yupValidation.vatId = Yup.string().label('Vat ID');
  }

  const { values, setFieldValue, touched, errors, handleBlur } = useFormik({
    initialValues: initFormikValues,
    validationSchema: Yup.object(yupValidation),
    onSubmit: () => {
      return;
    },
  });

  useEffect(() => {
    onChange(values, errors);
  }, [errors, values]);

  const {
    firstName,
    lastName,
    phone,
    postal,
    street,
    streetOpt,
    city,
    region,
    country,
    note,
    vatId,
  } = values;

  return (
    <StyledAddressEntryWrapper>
      <Row
        justify={JustificationTypes.SpaceBetween}
        gapSize={GapSizes.Md}
        mobileWrap={true}
      >
        <div style={{ width: '100%' }}>
          <InputSC
            id="firstName"
            name="firstName"
            placeholder="First Name"
            type="text"
            onBlur={handleBlur}
            required
            value={firstName}
            onChange={(val) =>
              setFieldValue('firstName', val.currentTarget.value)
            }
          />
          {touched.firstName && errors.firstName && (
            <StyledErrorText>{errors.firstName}</StyledErrorText>
          )}
        </div>
        <div style={{ width: '100%' }}>
          <InputSC
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            type="text"
            required
            onBlur={handleBlur}
            value={lastName}
            onChange={(val) =>
              setFieldValue('lastName', val.currentTarget.value)
            }
          />
          {touched.lastName && errors.lastName && (
            <StyledErrorText>{errors.lastName}</StyledErrorText>
          )}
        </div>
      </Row>

      <Row
        justify={JustificationTypes.SpaceBetween}
        gapSize={GapSizes.Md}
        mobileWrap={true}
      >
        <div style={{ width: '100%' }}>
          <InputSC
            id="street"
            name="street"
            placeholder="Street Address"
            type="text"
            onBlur={handleBlur}
            required
            value={street}
            onChange={(val) => setFieldValue('street', val.currentTarget.value)}
          />
          {touched.street && errors.street && (
            <StyledErrorText>{errors.street}</StyledErrorText>
          )}
        </div>
        <InputSC
          id="streetOpt"
          name="streetOpt"
          onBlur={handleBlur}
          placeholder="Apt, Suite (Optional)"
          type="text"
          value={streetOpt}
          onChange={(val) =>
            setFieldValue('streetOpt', val.currentTarget.value)
          }
        />
      </Row>

      <Row
        justify={JustificationTypes.SpaceBetween}
        gapSize={GapSizes.Md}
        mobileWrap={true}
      >
        <div style={{ width: '100%' }}>
          <InputSC
            id="postal"
            name="postal"
            onBlur={handleBlur}
            placeholder="Zip/Postal Code"
            type="string"
            required
            value={postal}
            onChange={(val) => setFieldValue('postal', val.currentTarget.value)}
          />
          {touched.postal && errors.postal && (
            <StyledErrorText>{errors.postal}</StyledErrorText>
          )}
        </div>
        <div style={{ width: '100%' }}>
          <InputSC
            id="city"
            name="city"
            onBlur={handleBlur}
            placeholder="City"
            type="text"
            required
            value={city}
            onChange={(val) => setFieldValue('city', val.currentTarget.value)}
          />
          {touched.city && errors.city && (
            <StyledErrorText>{errors.city}</StyledErrorText>
          )}
        </div>
      </Row>

      <Row
        justify={JustificationTypes.SpaceBetween}
        gapSize={GapSizes.Md}
        mobileWrap={true}
      >
        <CountryDropdown
          id="country"
          name="country"
          value={country}
          onBlur={handleBlur}
          onChange={
            type === 'shipping'
              ? () => {
                  return;
                }
              : (val) => setFieldValue('country', val)
          }
          classes="country-dd dropdown"
          disabled={type === 'shipping'}
          blacklist={['IR', 'KP', 'SY', 'CU', 'VE']}
        />

        <RegionDropdown
          id="region"
          name="region"
          onBlur={handleBlur}
          country={country}
          value={region}
          onChange={
            type === 'shipping'
              ? () => {
                  return;
                }
              : (val) => setFieldValue('region', val)
          }
          blankOptionLabel="Select Region"
          classes="region-dd dropdown"
          disabled={type === 'shipping'}
        />
      </Row>

      <Row
        justify={JustificationTypes.SpaceBetween}
        gapSize={GapSizes.Md}
        mobileWrap={true}
      >
        <div style={{ width: '100%' }}>
          <PhoneInput
            placeholder="Enter phone number"
            international
            defaultCountry={'US'}
            value={phone}
            id="phone"
            name="phone"
            onBlur={handleBlur}
            required
            rules={{ required: true }}
            onChange={(val) => setFieldValue('phone', val)}
          />
          {touched.phone && errors.phone && (
            <StyledErrorText>{errors.phone}</StyledErrorText>
          )}
        </div>
      </Row>

      {type === 'billing' && (
        <Row justify={JustificationTypes.SpaceBetween} gapSize={GapSizes.Md}>
          <InputSC
            id="vatId"
            name="vatId"
            onBlur={handleBlur}
            placeholder="Vat ID (Optional)"
            type="text"
            value={vatId}
            onChange={(val) => setFieldValue('vatId', val.currentTarget.value)}
          />
        </Row>
      )}
      {type === 'shipping' && (
        <>
          <Row
            justify={JustificationTypes.SpaceBetween}
            gapSize={GapSizes.Md}
            mobileWrap={true}
          >
            <InputSC
              id="note"
              name="note"
              onBlur={handleBlur}
              placeholder="Note (Optional)"
              type="text"
              value={note}
              onChange={(val) => setFieldValue('note', val.currentTarget.value)}
            />
          </Row>
        </>
      )}
    </StyledAddressEntryWrapper>
  );
};

export default AddressEntry;
