import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Text, { TextVariant } from '@components/Text';
import { TBasicPositionProps } from '@pages/Home/components/PositionCard/types';
import { StyledTrayFrame } from './StyledTrayFrame.styled';
import userInterfaceSlice from '@store/user-interface';
import Tippy from '@tippyjs/react';
import { defaultTippyProps } from '@utils/ui';
import {
  getRQKAuctionsKey,
  getRQKPositionsKey,
  getRQKUsersAuctionsKey,
} from '@utils/rq-utils';
import { useCountdown } from '@hooks/useCountdown';
import { useQueryClient } from 'react-query';
import Stars from './Stars';
import UnlockEarlyBtn from './components/UnlockEarlyBtn';
import LocationBtn from './components/LocationBtn';
import ClaimOfriendBtn from './components/ClaimOfriendBtn';
import CardGraphic from './components/CardGraphic';
import { ButtonRedSC } from '@components/Button.styled';
import { ReactComponent as SvgGavel } from '@assets/svg/icons/gavel.svg';

export const gen6Style = {
  width: '100%',
  height: '100%',
};

type GenerateClassNamesProps = {
  dominantBooster: string | null;
  cardType: string;
  isGen6: boolean;
};

export const generateClassNames = ({
  dominantBooster,
  cardType,
  isGen6,
}: GenerateClassNamesProps): string => {
  const classNames: string[] = [];
  if (dominantBooster) {
    classNames.push(dominantBooster as string, 'shiny');
  }
  if (cardType === 'golden') {
    classNames.push('doghouse-sheen');
  }
  if (isGen6) {
    classNames.push('gen6');
  }
  return classNames.join(' ');
};

const PositionCard: React.FC<TBasicPositionProps> = ({
  isActive,
  positionId,
  positionNumber,
  positionType,
  cardType,
  mintAvailable,
  activeLat,
  activeLng,
  upgrading,
  energy,
  positionUpgrades,
  artifacts,
  positionDataIsLoading,
  rarity,
  activeBooster,
  dominantBooster,
}: TBasicPositionProps) => {
  const genesis = positionType === 0;
  const isGen6 = positionType === 5;
  const unrevealed = activeLat === null;
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { setModal, setOwnedPositionArtifacts } = userInterfaceSlice;
  const [revealDate, setRevealDate] = useState('');
  const [focus, setFocus] = useState(false);
  const [upgradeOverlayShown, setUpgradeOverlayShown] = useState(true);
  const [isBlocked, setIsBlocked] = useState(false);

  const cachedBlockedItems = queryClient.getQueryData(
    getRQKAuctionsKey('getBlockedItems'),
  ) as any;

  useEffect(() => {
    if (
      artifacts.length > 0 &&
      artifacts[0].position &&
      cachedBlockedItems.blockedPositions.includes(artifacts[0].position)
    ) {
      setIsBlocked(false);
    }

    if (cachedBlockedItems) {
      const isBlockedInBlockedItems = cachedBlockedItems.blockedPositions.some(
        (item) => item === positionId,
      );
      setIsBlocked(isBlockedInBlockedItems);
    } else setIsBlocked(false);
  }, [artifacts, positionId, cachedBlockedItems]);

  useEffect(() => {
    if (positionUpgrades.length > 0) {
      positionUpgrades.filter((obj) => {
        if (obj.revealed === 0) {
          setRevealDate(obj.revealableAt);
        }
      });
    }
  }, [positionUpgrades]);

  const handlePositionClick = async (id) => {
    dispatch(
      setOwnedPositionArtifacts({
        ownedPositionArtifacts: artifacts,
      }),
    );
    dispatch(
      userInterfaceSlice.setActivePositionId({
        activePositionId: id,
      }),
    );
    dispatch(
      setModal({
        type: 'plotActionsModal',
        token: null,
      }),
    );
    if (activeBooster != null) {
      dispatch(
        userInterfaceSlice.setActiveBooster({
          activeBooster: {
            rarity: activeBooster?.rarity,
            boosterId: activeBooster?.boosterId,
            positionId: positionId,
          },
        }),
      );
      dispatch(
        setModal({
          type: 'confirmBoosterApplication',
          token: null,
        }),
      );
    }
  };

  const launchDate = new Date(revealDate).getTime();

  const [days, hours, minutes, seconds, totalSeconds] =
    useCountdown(launchDate);

  // 604800 = maximum upgrade time

  const upgradeProgress = (totalSeconds / 604800) * 100;

  if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
    queryClient.invalidateQueries(
      getRQKPositionsKey('getPosition', positionId),
    );
  }

  if (isNaN(days) || isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
    queryClient.invalidateQueries(
      getRQKPositionsKey('getPosition', positionId),
    );
  }

  const cardClassNames = useMemo(
    () => generateClassNames({ dominantBooster, cardType, isGen6 }),
    [dominantBooster, cardType, isGen6],
  );

  const dummyValuesForStars = useMemo(
    () => [
      { active: false },
      { active: false },
      { active: false },
      { active: false },
      { active: false },
    ],
    [],
  );

  return (
    <div className="position-wrapper">
      {isBlocked && (
        <div className="blocked-overlay blur">
          <h3>Auction Pending</h3>
          <SvgGavel />
          <ButtonRedSC
            disabled={!dominantBooster}
            onClick={() => (
              dispatch(
                userInterfaceSlice.setActivePositionId({
                  activePositionId: positionId,
                }),
              ),
              dispatch(
                setModal({
                  type: 'v2CancelAuctionModal',
                  token: null,
                }),
              )
            )}
          >
            Cancel
          </ButtonRedSC>
          {!dominantBooster && (
            <p>* Only boosted ōLand auctions can be cancelled</p>
          )}
        </div>
      )}
      <button
        className="position-card"
        onClick={() => handlePositionClick(positionId)}
        disabled={isBlocked}
      >
        <StyledTrayFrame
          activePlot={isActive === positionId}
          className={cardClassNames}
          onMouseOver={() => setFocus(true)}
          onMouseOut={() => setFocus(false)}
          focus={focus}
          style={isGen6 ? gen6Style : {}}
        >
          <CardGraphic
            upgradeProgress={upgradeProgress}
            positionId={positionId}
            upgradeOverlayShown={upgradeOverlayShown}
            upgrading={upgrading}
            rarity={rarity}
            revealDate={revealDate}
            focus={focus}
            energy={energy}
            artifacts={artifacts}
            positionDataIsLoading={positionDataIsLoading}
            isGen6={isGen6}
            cardType={cardType}
          />
          <div style={{ zIndex: 3, position: 'relative' }}>
            <div className="tray-row">
              <Text
                className="plot-label"
                variant={TextVariant.CardText}
                style={{
                  fontWeight: 700,
                  textAlign: 'left',
                }}
              >
                #{positionNumber.toLocaleString()}
              </Text>
              {upgrading ? (
                <>
                  <Tippy
                    {...defaultTippyProps}
                    content={`The ōFriends are busy building improvements on your land.`}
                    disabled={!upgrading}
                  >
                    <button
                      id="upgrade-timer"
                      style={{
                        display: 'flex',
                        gap: '0.5rem',
                        opacity: upgrading ? 1 : 0,
                        background: 'none',
                        border: 'none',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setUpgradeOverlayShown(!upgradeOverlayShown);
                      }}
                    >
                      <Text
                        className="dgray plot-label"
                        variant={TextVariant.CardText}
                        style={{
                          fontWeight: 700,
                          textAlign: 'left',
                        }}
                      >
                        Upgrading <span className="mobile-hidden">&#9432;</span>
                      </Text>
                    </button>
                  </Tippy>
                </>
              ) : (
                <>
                  {cardType === 'golden' && mintAvailable && (
                    <ClaimOfriendBtn positionId={positionId} />
                  )}
                </>
              )}
            </div>

            <div className="tray-row">
              <Text
                className="plot-label plot-type"
                variant={TextVariant.CardText}
              >
                {genesis ? 'Genesis' : `Gen ${positionType + 1}`}
              </Text>
              {!unrevealed ? (
                <LocationBtn
                  activeLat={activeLat}
                  activeLng={activeLng}
                  unrevealed={unrevealed}
                />
              ) : (
                <UnlockEarlyBtn
                  positionId={positionId}
                  positionType={positionType}
                />
              )}
            </div>
            <Stars starData={dummyValuesForStars} />
          </div>
        </StyledTrayFrame>
      </button>
    </div>
  );
};

const memoizedPositionCard = React.memo(PositionCard);

export default memoizedPositionCard;
