import React from 'react';
import { useSelector } from 'react-redux';

import { TRootState } from '@store/index';
import InitializationModal from '@components/Modal/InitializationModal';
import ResetPasswordModal from '@components/Modal/ResetPasswordModal';
import PurchaseModal from '@components/Modal/CheckoutProcess/PurchaseModal';
import InitialWelcomeModal from '@components/Modal/InitialWelcomeModal';
import DeviceModal from '@components/Modal/DeviceModal';
import OLandModal from '@components/Modal/OLandModal';
import ReferralModal from '@components/Modal/ReferralModal';
import ClaimModal from '@components/Modal/ClaimModal';
import TreatPlacingModal from '@components/Modal/TreatPlacingModal';
import ClaimOFriendModal from '@components/Modal/ClaimOfriendModal';
import OTreatModal from '@components/Modal/OTreatModal';
import RevealResultModal from '@components/Modal/RevealResultModal';
import LocationUnlockModal from './LocationUnlockModal';
import UpgradesInventoryModal from './UpgradesInventoryModal';
import ClaimAuctionModal from './ClaimAuctionModal';
import ListingModal from './Auction/ListingModal';
import WinningModal from '@components/Modal/Auction/WinningModal';
import GetBoostedModal from './GetBoostedModal';
import OcashChestModal from './CheckoutProcess/OcashChestModal';
import BoosterInventoryModal from './CheckoutProcess/BoosterInventoryModal';
import ConfirmBoosterApplicationModal from './ConfirmBoosterApplicationModal';
import V2ListingModal from './V2Auction/V2ListingModal/V2ListingModal';
import V2PurchaseModal from './V2Auction/V2PurchaseModal';
import V2CancelModal from './V2Auction/V2CancelModal';
import PlotActionsModal from './components/PlotActionsModal';
import UltimatePrizesModal from './UltimatePrizesModal';

const ModalWrapper: React.FunctionComponent = () => {
  const type = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal.type,
  );

  if (!type) {
    return null;
  }

  switch (type) {
    case 'initialization':
      return <InitializationModal />;
    case 'resetPassword':
      return <ResetPasswordModal />;
    case 'purchaseModal':
      return <PurchaseModal />;
    case 'initialWelcome':
      return <InitialWelcomeModal />;
    case 'deviceModal':
      return <DeviceModal />;
    case 'getBoostedModal':
      return <GetBoostedModal />;
    case 'oLandModal':
      return <OLandModal />;
    case 'referralModal':
      return <ReferralModal />;
    case 'oTreatModal':
      return <OTreatModal />;
    case 'claimModal':
      return <ClaimModal />;
    case 'treatPlacingModal':
      return <TreatPlacingModal />;
    case 'claimOFriendModal':
      return <ClaimOFriendModal />;
    case 'claimAuctionModal':
      return <ClaimAuctionModal />;
    case 'revealResultModal':
      return <RevealResultModal />;
    case 'locationUnlockModal':
      return <LocationUnlockModal />;
    case 'upgradesInventoryModal':
      return <UpgradesInventoryModal />;
    case 'auctionListingModal':
      return <ListingModal />;
    case 'winningModal':
      return <WinningModal />;
    case 'ocashChestModal':
      return <OcashChestModal />;
    case 'boosterInventoryModal':
      return <BoosterInventoryModal />;
    case 'ultimatePrizesModal':
      return <UltimatePrizesModal />;
    case 'confirmBoosterApplication':
      return <ConfirmBoosterApplicationModal />;
    case 'v2ListingModal':
      return <V2ListingModal />;
    case 'v2PurchaseModal':
      return <V2PurchaseModal />;
    case 'v2CancelAuctionModal':
      return <V2CancelModal />;
    case 'plotActionsModal':
      return <PlotActionsModal />;
    default:
      return null;
  }
};

export default React.memo(ModalWrapper);
