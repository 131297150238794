import { css } from 'styled-components';

export default css`
  :root {
    --toastify-z-index: 999999;
  }

  body {
    overflow-x: hidden;
    position: relative;
    min-height: 100vh;
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.background.regular.bg1};
    // padding-bottom: 120px;
    font-family: 'SF Pro Display', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
      'Arial', sans-serif;
    font-size: 14px;
    line-height: 16px;

    .groupie-font {
      font-family: 'groupie-regular', sans-serif;
      font-weight: 600 !important;
      font-style: normal;
      font-size: 18pt !important;

      @media only screen and (max-width: 776px) {
        font-size: 12pt !important;
      }
    }

    .gray {
      color: ${({ theme }) => theme.text.secondary};
    }

    button:focus {
      outline: none;
    }

    h1 {
      font-family: 'SF Pro Display', 'SF Pro Icons', 'Helvetica Neue',
        'Helvetica', 'Arial', sans-serif;
      font-weight: 400;
      font-size: 36px;
      line-height: 186.24%;
    }

    .medium-semi {
      color: ${({ theme }) => theme.text.main};
      font-size: 16px;
      font-weight: 600;
    }

    .small {
      font-size: 12px;
    }

    span.error {
      color: ${({ theme }) => theme.button.secondary.background};
    }

    // Toastify reset
    div.Toastify__toast-container {
      width: 300px;
      border-radius: 5px;
    }

    div.Toastify__toast {
      padding: 0;
      border-radius: 18px;
    }

    div.Toastify__toast-body {
      margin: 0;
      padding: 0;

      > div {
        height: 100%;
      }
    }

    button.Toastify__close-button {
      display: none;
    }

    // React Modal
    div.ReactModal {
      &__Content {
        max-width: 525px;
        height: fit-content;
        padding-bottom: 24px;
        margin-left: auto;
        margin-right: auto;
        position: absolute !important;
        top: 50% !important;
        transform: translate(0, -50%) !important;
      }
    }

    .ReactModal__Overlay {
      opacity: 0;
      transition: transform 0.3s ease-out;
      transition: opacity 0.3s ease-out;
      z-index: 9999;
    }

    .ReactModal__Overlay--after-open {
      opacity: 1;
    }

    .ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }

  .tippy-box[data-theme~='light-custom'] {
    background-color: ${({ theme }) => theme.input.background};
    color: ${({ theme }) => theme.text.main};
    border-radius: 10px;
    padding: 8px;
    border: 1.5px solid ${({ theme }) => theme.input.border};
    text-align: center;
    font-weight: 600;
    box-shadow: 0 5px 10px rgb(0 0 0 / 34%);
    max-width: 300px !important;
  }

  /* The fill */
  .tippy-box[data-theme~='light-custom'] > .tippy-svg-arrow > svg:last-child {
    fill: ${({ theme }) => theme.input.background};
  }

  .blur {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    backdrop-filter: blur(18px);
    z-index: 2;
  }

  input {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${({ theme }) => theme.text.secondary};
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${({ theme }) => theme.text.secondary};
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
