import React from 'react';
import errorImg from '@assets/png/broken-robot.png';
import { ErrorSC } from './Error.styled';
import Header from '@components/Header';
import Text, { TextVariant } from '@components/Text';
import { ButtonOutlineSC } from '@components/Button.styled';

const Error: React.FunctionComponent = () => {
  return (
    <>
      <Header />
      <ErrorSC>
        <img src={errorImg} alt="error" />
        <Text variant={TextVariant.Title} className="title">
          Oops! Something is technically wrong.
        </Text>
        <div className="body">
          <Text variant={TextVariant.Description}>
            We are working on getting things running smoothly again and will
            have things back to normal soon.
          </Text>
          <ButtonOutlineSC
            onClick={() => window.location.replace(`https://overline.network`)}
          >
            Back to homepage
          </ButtonOutlineSC>
          <Text variant={TextVariant.Disclaimer}>
            Have an issue to report? Contact us via the support chat on{' '}
            <a href="https://overline.network" rel="noopener noreferrer">
              www.overline.network
            </a>
          </Text>
        </div>
      </ErrorSC>
    </>
  );
};

export default Error;
