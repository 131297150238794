import React, { FormEvent, useState, useCallback } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { TRootState } from '@store/index';
import userInterface from '@store/user-interface';
import ModalSC from './Modal.styled';
import { InputSC } from '@components/Input.styled';
import { ButtonPrimarySC } from '@components/Button.styled';
import api, { defaultSearchPositionParams } from '@utils/api';
import { setAccessToken, setRefreshToken } from '@utils/storage';
import { TJwtToken } from '@custom-types/common';
import jwtDecode from 'jwt-decode';
import authSlice from '@store/auth';
import { ReactComponent as SvgSpinner } from '@assets/svg/spinner.svg';
import { toast } from 'react-toastify';
import Toast from '@components/Toast';
import Text, { TextVariant } from '@components/Text';
import SvgCross from '@assets/svg/icons/Cross.svg';
import { defaultModalProps } from './modal-settings';
import { getRQKPositionsKey, getRQKProfileKey } from '@utils/rq-utils';

const ResetPasswordModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { type, token } = useSelector<TRootState>(
    (store: TRootState) => store.userInterface.modal,
  ) as { type: string; token: string };

  const { signIn } = authSlice;

  const { setModal, setFreeOCashAmountClaimed } = userInterface;

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const navigate = useNavigate();

  const resetPassword = async () => {
    try {
      const result = await api.resetPassword(token, password);
      const { accessToken, refreshToken } = result.data.data;

      setAccessToken(accessToken);
      setRefreshToken(refreshToken);

      setPassword('');
      setPasswordConfirmation('');
      dispatch(setModal({ type: null, token: null }));

      // MERGE THIS CODE WITH INITIALIZATION AND INTRO MODAL
      // Prefetch the data
      await queryClient.prefetchQuery(
        getRQKPositionsKey('getPositions'),
        async () =>
          (
            await api.getPositions(defaultSearchPositionParams)
          ).data.data,
      );

      const profileResult = (await api.getProfile()).data.data;
      queryClient.setQueryData(getRQKProfileKey('getProfile'), profileResult);

      const decodedHeader: TJwtToken = jwtDecode(String(accessToken));
      dispatch(
        signIn({
          id: decodedHeader.sub,
          daoRanking: profileResult.daoRanking,
          affiliateId: profileResult.affiliateId,
        }),
      );

      // Ask for daily oCash right after user signs up
      const claimResult = (await api.claimDailyOCash()).data;
      dispatch(
        setFreeOCashAmountClaimed({
          amountClaimed: claimResult.data.amountClaimed,
        }),
      );

      toast(
        <Toast
          type="success"
          header="Success!"
          body="You have successfully set your password, and now you are signed in. "
        />,
      );

      navigate('/');
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (e.data && e.data.errCode === 'verif_token_expired') {
        toast(
          <Toast
            type="error"
            header="Error"
            body="Verification token expired. Please create new password change request."
          />,
        );
      } else {
        toast(
          <Toast
            type="error"
            header="Error"
            body="Something went wrong. Please contact support if the problem persists."
          />,
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    resetPassword().then();
  };

  const handleCloseModal = useCallback(() => {
    dispatch(
      userInterface.setModal({
        type: null,
        token: null,
      }),
    );
  }, []);

  return (
    <ReactModal
      isOpen={type === 'resetPassword'}
      {...defaultModalProps}
      onRequestClose={handleCloseModal}
    >
      <ModalSC>
        <div className="header">
          <Text style={{ fontWeight: 500 }} variant={TextVariant.Subtitle}>
            Reset Password
          </Text>
          <button onClick={handleCloseModal}>
            <img src={SvgCross} alt="cross-btn" />
          </button>
        </div>
        <div className="body">
          <form onSubmit={handleFormSubmit} autoComplete="on">
            <span className="small input-label">
              Must be at least 8-characters long and contain both letters and
              numbers.
            </span>
            <InputSC
              value={password}
              required
              className="modal-input"
              type="password"
              pattern="^(?=.*[A-Z])(?=.*\d)(.){8,}$"
              title="Must have at least 8 characters, one upper and one number"
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            <label className="input-label" htmlFor="password">
              Confirm password:
            </label>
            <InputSC
              value={passwordConfirmation}
              required
              type="password"
              className="modal-input"
              pattern="^(?=.*[A-Z])(?=.*\d)(.){8,}$"
              title="Must have at least 8 characters, one upper and one number"
              onChange={(e) => setPasswordConfirmation(e.currentTarget.value)}
            />

            <ButtonPrimarySC disabled={isLoading}>
              {isLoading ? (
                <SvgSpinner
                  height={30}
                  width={30}
                  style={{ display: 'inline', fill: 'white' }}
                />
              ) : null}
              Submit
            </ButtonPrimarySC>
          </form>
        </div>
      </ModalSC>
    </ReactModal>
  );
};

export default ResetPasswordModal;
